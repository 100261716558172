<template>
  <b-button-group size="xs">
    <b-button :to="build_url(item)" size="xs" :variant="real_variants.view">
      <icon :icon="real_icons.view" />
    </b-button>
    <b-button
      :variant="real_variants.delete"
      size="xs"
      v-if="!is_deleted && has_delete_method"
      @click="show_delete_window = true"
    >
      <icon :icon="real_icons.delete" />
    </b-button>
    <b-modal v-model="show_delete_window" title="Удалить" @ok="delete_obj">
      <obj-form
        v-model="item"
        :description="item._description"
        mode="view"
        :line_props="{ 'label-cols': 4 }"
        :obj_id="item.obj_id"
        :obj_type="item.obj_type"
      />
    </b-modal>
  </b-button-group>
</template>

<script>
import { as_icon, Icon } from "../layot/FontAwesomeIcon";
import { BButton, BButtonGroup, BModal } from "bootstrap-vue";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { obj_is_deleted } from "../../functions";
import ObjForm from "../ObjForm";
const default_icons = {
  view: as_icon(faEye),
  delete: as_icon(faTrash),
};
const default_variants = {
  view: "outline-primary",
  delete: "danger",
};
export default {
  name: "ActionsCell",
  props: {
    to: {
      default: null,
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    variants: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    icons: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  components: {
    icon: Icon,
    BButton,
    BButtonGroup,
    ObjForm,
    BModal,
  },
  computed: {
    obj_type() {
      return this.item.obj_type;
    },
    is_deleted() {
      return obj_is_deleted(this.item) && this.item._description.urls.delete;
    },
    modal_exist() {
      return this.index === 0;
    },
    has_delete_method() {
      const urls = this.item._description?.urls;
      const delete_url = urls?.delete;
      return delete_url ?? this.item._description?.custom_delete;
    },
    real_icons() {
      return { ...default_icons, ...this.icons };
    },
    real_variants() {
      return { ...default_variants, ...this.variants };
    },
  },
  data() {
    return {
      show_delete_window: false,
    };
  },
  methods: {
    build_url(item) {
      return this.to
        ? this.to(item)
        : {
            name: "object",
            params: { type: this.obj_type, id: this.item.obj_key },
          };
    },
    delete_obj() {
      // удалить объект
      this.$store.dispatch(`${this.item.obj_type}/delete`, this.item.obj_key);
    },
  },
};
</script>

<style>
</style>