<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
  >
    {{ get_data(node.data) }}
    <!--- hide title <div class="title">{{node.name}}</div> -->
    <!-- Outputs-->
    <b-card-title class="title">
      <Socket
        v-for="output in inputs()"
        :key="output.key"
        v-socket:input="output"
        type="input"
        :class="['input-socket', output.key]"
        :socket="output.socket"
      />Голосовое меню
      <obj-modal
        class="settings"
        :buttonProps="{
          variant: 'outline',
          class: 'border-0',
        }"
        @click="modal_show = true"
        :obj_id="service.service_id"
        obj_type="ivr"
        noDelete
      >
        <template #btnContent>
          <icon :icon="icons.gear" />
        </template>
        <template #form>
          <b-form-group label="Кнопки" label-cols="3">
            <b-checkbox-group
              buttons
              class="ivr-buttons"
              v-model="enabled_outputs"
            >
              <b-checkbox
                v-for="o in btn_options"
                :key="o.order"
                button-variant="outline-primary"
                :disabled="output_active(o.val)"
                :class="[`b-${o.order}`]"
                :value="o.val"
              >
                {{ o.val }}
              </b-checkbox>
            </b-checkbox-group>
          </b-form-group>
        </template>
      </obj-modal>
    </b-card-title>
    <b-modal v-model="modal_show" title="Редактирование">
      <div
        class="control"
        v-for="(control, _i) in controls()"
        :key="_i"
        v-control="control"
      />
      <b-form> </b-form>
    </b-modal>
    <h2 class="name" v-if="service">
      {{ service.obj_name }}
    </h2>
    <h2 class="name file">
      <media-name-cell-vue
        :item="service"
        related
        :value="service.player"
        :field="{
          key: 'hello',
          field_desc: {
            target: 'hello',
          },
        }"
        :index="0"
      />
    </h2>
    <h2 class="name file">
      <media-name-cell-vue
        :item="service"
        related
        :value="service.player"
        :field="{
          key: 'prompt',
          field_desc: {
            target: 'prompt',
          },
        }"
        :index="0"
      />
    </h2>

    <div v-for="output in outputs()" :key="output.key" class="output-line">
      <div v-show="show_output(output)" class="out-ivr">
        <span class="name"> {{ output.key | human_name }}</span>
        <Socket
          v-socket:input="output"
          type="output"
          :class="['output-socket', output.key]"
          :socket="output.socket"
        />
      </div>
    </div>
    <!-- Inputs-->
    <!-- <div class="input" v-for="input in inputs()" :key="input.key">
      <Socket
        v-socket:input="input"
        type="input"
        :socket="input.socket"
      ></Socket>
      <div class="input-title" v-show="!input.showControl()">
        {{ input.name }}
      </div>
      <div
        class="input-control"
        v-show="input.showControl()"
        v-control="input.control"
      ></div>
    </div> -->
  </b-card>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { as_icon, Icon } from "@/components/layot";
import { faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";
import { Fragment } from "../../../../layot";
import { orderBy } from "lodash-es";
import { ObjForm } from "../../../..";
import ObjModal from "../../../../objects/ObjModal.vue";
import MediaNameCellVue from "../../../../objects/media/MediaNameCell.vue";

export default {
  name: "IVRComponent",
  mixins: [ComponentMixinVue],
  components: {
    MediaNameCellVue,
    Socket: VueRenderPlugin.Socket,
    Icon,
    ObjForm,
    Fragment,
    ObjModal,
  },
  data: () => ({
    additional_data: false,
    members: [],
    enabled_outputs: [],
    disabled_outputs: [],
  }),
  mounted() {
    this.$watch(
      () => this.enabled_outputs.length,
      () => {
        this.editor.view.updateConnections({ node: this.node });
      }
    );
  },
  filters: {
    human_name: (v) => (v == "TimeoutAction" ? "Таймаут" : v),
  },
  methods: {
    show_output(output) {
      let ret = false;
      const enabled = [...this.enabled_outputs, ...config.alwaysShow];
      // if (output.key == "0") return true;
      if (enabled.indexOf(output.key) != -1) {
        ret = true;
      } else {
        if (output.connections.length) {
          this.enabled_outputs.push(output.key);
          ret = true;
        }
      }
      return ret;
    },
    output_active(key) {
      let ret = false;
      for (const output of this.outputs()) {
        if (output.key == key) {
          if (output.connections.length) {
            ret = true;
          }
        }
      }
      return ret;
    },
  },
  computed: {
    btn_options: () =>
      orderBy(
        ["1", "2", "3", "4", "5", "6", "7", "8", "9", "#", "0", "*"].map(
          (o, i) => ({ order: i, val: o })
        ),
        ["order"]
      ),
    icons: () => ({
      gear: as_icon(faCog),
      plus: as_icon(faPlus),
    }),
    config: () => config,
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.btn-group.ivr-buttons {
  display: flex;
  flex-wrap: wrap;
  width: 161px;
  border-radius: 5px;
  border: 1px solid var(--primary);
  label.btn {
    width: 50px;
    border-radius: 0;
    border: none;
    margin: 0;
    &.disabled {
      background-color: $obit-success;
      color: white;
    }
  }
}
.card.ivr-node {
  padding-bottom: 10px;
  max-width: 250px;
  .name {
    font-size: 2em;
    line-height: 0.9em;
    max-width: calc(100% - 20px);
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;
    color: white;
    .settings {
      width: fit-content;
      display: inline;
      background-color: transparent;
      & > button.btn {
        color: white;
      }
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .output-line {
    margin-right: -4px;
    .out-ivr {
      border: 2px solid var(--primary);
      border-radius: 50px;
      max-width: 98%;
      width: fit-content;
      height: 32px;
      margin-bottom: 5px;
      float: right;
      .name {
        display: block;
        font-size: 24px;
        line-height: 1.2em;
        padding-left: 0.8em;
        padding-right: 30px;
        width: max-content;
        max-width: fit-content;
      }
      .output-socket.output {
        position: relative;
        left: calc(100% - 31px);
        top: -32px;
        &.timeout {
          background-color: $obit-danger !important;
        }
      }
    }
  }
  h2.name.file {
    margin: 0;
    padding-bottom: 0;
  }
}
</style>