import { as_icon } from '../components/layot'
import { faYoutube, faFacebook, faVk, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { OBJ_COMPONENTS } from '../object'
import './components'

const TOKEN_HEADER = 'X-Obit-Token';
const ENV = window.APP_CONST
// eslint-disable-next-line
// const API_SERVER = 'https://stage-instance-behemoth-test.lobby.obit.ru'
const API_URL = window.CONFIG?.API_URL || process.env.VUE_APP_API_URL
const API_SERVER = API_URL.replace(/\/$/, '')
const API_REPORTS_SERVER = API_SERVER
const SERVER = API_SERVER + '/';
const DEFAULT_MESSAGE_TIME = 4;

// library.add(faYoutube, faFacebook, faVk, faTwitter)

const DELTA_OUTDATE = 10 * 1000  // 3 секунды

const SERVICE_TYPE_INTERNAL_LINE = 'internal_line'

const aaa_root = 'aaa'
const AUTH_LINKS = {
    auth: `${aaa_root}/auth`,
    status: `${aaa_root}/status`,
    info: `${aaa_root}/info`,
    logout: `${aaa_root}/logout`,
    check: `${aaa_root}/allow`,
    full_logout: `${aaa_root}/totalLogout`,
};


const SOCIAL_NETWORKS = [
    {
        name: 'ВКонтакте',
        icon: as_icon(faVk),
        link: '#'
    },
    {
        name: 'Twitter',
        icon: as_icon(faTwitter),
        link: '#'
    },
    {
        name: 'FaceBook',
        icon: as_icon(faFacebook),
        link: '#'
    },
    {
        name: 'YouTube',
        icon: as_icon(faYoutube),
        link: '#'
    },
]

const default_promise = new Promise(resolve => resolve())
const DEFAULT_LINE_PROPS = {
    "label-cols-sm": 12,
    "label-cols-md": 6,
    "label-cols-lg": 4,
    "label-cols-xl": 3,
}
const REPORTS_ENUM = [
    'groupByIp', 'groupByMonth', 'groupByDay',
    'callsIn', 'callsOut',
    'callsInByInternalNumbers', 'callsOutByInternalNumbers',
    'reportDirections', 'reportDirectionGroups', 'statByDay', 'statByMonth',
    'recordsList', 'recordsContractList']
const REPORTS_TYPES = REPORTS_ENUM.reduce(
    (r, n) => ({ ...r, [n.toLowerCase()]: n }), {})

export { tariffs } from './tarrifs'
export {
    TOKEN_HEADER, DEFAULT_MESSAGE_TIME, SERVER,
    AUTH_LINKS,
    OBJ_COMPONENTS, SERVICE_TYPE_INTERNAL_LINE,
    default_promise, // register_comp,
    SOCIAL_NETWORKS, DELTA_OUTDATE, ENV,
    API_SERVER, DEFAULT_LINE_PROPS, API_REPORTS_SERVER,
    REPORTS_ENUM, REPORTS_TYPES
};