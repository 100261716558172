<template>
  <scrollable class="menu">
    <b-nav :class="['side-menu', {}]">
      <b-nav-item
          v-if="is_vertical.value && !show_left"
          class="nav-scroll left"
          @click="slideleft"
      ><icon :icon="icons.left"
      /></b-nav-item>
      <b-nav-item v-if="is_vertical.value" class="invisible_el"
      ><span v-visible="toggle_left"
      /></b-nav-item>
      <side-menu-option
          v-for="(m, indx) in real_menu"
          v-bind:key="indx"
          :item="m"
          :index="indx"
      />
      <b-nav-item v-if="is_vertical.value"
      ><span v-visible="toggle_right"
      /></b-nav-item>
      <b-nav-item
          v-if="is_vertical.value && !show_right"
          class="nav-scroll right"
          @click="slideright"
      ><icon :icon="icons.right"
      /></b-nav-item>
      <portal to="manager" :disabled="!is_vertical.value" slim>
        <manager />
      </portal>
    </b-nav>
  </scrollable>
</template>

<script>
import { BNav, BNavItem, VBVisible } from "bootstrap-vue";
import SideMenuOption from "./SideMenuOption.vue";
import Scrollable from "simplebar-vue";
import Manager from "./Manager.vue";
import { Portal } from "portal-vue";
import { as_icon, Icon } from "../layot";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "SideMenu",
  components: {
    BNav,
    SideMenuOption,
    Scrollable,
    Manager,
    Portal,
    BNavItem,
    Icon,
  },
  inject: ["is_vertical"],
  directives: {
    visible: VBVisible,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
      required: false,
    },
  },
  mounted() {
  },
  data() {
    return {
      disabled_options: {},
      options_options: {},
      show_left: true,
      show_right: true,
      scroll_pos: 0,
    };
  },
  computed: {
    real_menu() {
      let ret = this.menu.filter((sm) => {
        if (sm.menu_id === undefined) {
          return true;
        }
        return false;
      });
      return ret;
    },
    icons: () => ({
      left: as_icon(faChevronLeft),
      right: as_icon(faChevronRight),
    }),
  },
  methods: {
    toggle_left(value) {
      this.show_left = value;
    },
    toggle_right(value) {
      this.show_right = value;
    },
    slideright() {
      let a = document.querySelector(
          "#page > div.menu > div > div.simplebar-wrapper > div.simplebar-mask > div > div"
      );
      this.scroll_pos = this.scroll_pos + 0.2 * window.innerWidth;
      a.scrollLeft = this.scroll_pos;
    },
    slideleft() {
      let a = document.querySelector(
          "#page > div.menu > div > div.simplebar-wrapper > div.simplebar-mask > div > div"
      );
      this.scroll_pos =
          this.scroll_pos > 0.2 * window.innerWidth
              ? this.scroll_pos - 0.2 * window.innerWidth
              : 0;
      a.scrollLeft = this.scroll_pos;
    },
  },
};
</script>

<style lang='scss'>
@import "../../../static/SCSS/obit-colors.scss";
.menu {
  max-width: 100vw;
  overflow: auto;
  @media screen and (max-width: 800px) {
    height: 70px;
    .simplebar-track.simplebar-horizontal {
      display: none;
    }
  }
}
.side-menu.nav {
  background-color: $obit-gray;
  padding-top: 3rem;
  min-height: calc(100vh - 90px);
  flex-direction: column;
  @media screen and (max-width: 800px) {
    padding-top: 0;
    width: 100%;
    flex-direction: row;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    padding-top: 15px;
    &.nav {
      flex-wrap: initial !important;
      width: fit-content;
      min-width: 100%;
      // overflow-x: auto;
    }
  }
  .invisible_el {
    width: 0;
  }
  .nav-scroll {
    position: fixed;
    $color-without-alpha: $obit-gray;
    $color-with-alpha: rgba($color-without-alpha, 0.2);
    background: linear-gradient(
      to right,
      $color-without-alpha,
      $color-with-alpha
    );
    & > a {
      color: var(--dark);
      :hover {
        color: #fd6300;
      }
    }
    &.right {
      right: 0;
      background: linear-gradient(
        to right,
        $color-with-alpha,
        $color-without-alpha
      );
    }
  }
}
</style>