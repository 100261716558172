import { type_val } from "./functions"

// валидаторы
const subValidator = (cond, message) => cond ? message : null
const Validator = (value, obj, ...validators) => validators.reduce((ret, val) => {
    let err = val(value, obj)
    if (err && type_val(err) == 'String') {
        ret.push(err)
    } else if (err) {
        ret = ret.concat(err)
    }
    return ret
}, [])
const isNumberValidator = value => {
    const regexp = /^\s*\d+\s*$/, message = 'Номер должен состоять из цифр'
    return subValidator(!regexp.test(value), message)
}
const internalLineLengthValidator = value => {
    const min_length = 3, max_length = 5
    const message = `Номер должен быть длиной от ${min_length} до ${max_length} символов`
    return subValidator(min_length > `${value}`.length || `${value}`.length > max_length, message)
}
const internalLineValidator = value => {
    const number = `${value}`
    const message = `Номер не может начинаться с 1 или 0`
    // console.log(subValidator(number.match(/^1.*/), message))
    return subValidator(number.match(/^[1,0].*/), message)
}
const externalLineValidator = value => {
    const min = 11, max = 15
    const message = `Номер должен быть длиной от ${min} до ${max} чисел`
    const l = value.length
    return subValidator(min > l || max <= l, message)
}
const positiveValue = value => {
    const message = 'Номер должен быть >= 0'
    return subValidator(parseInt(value) < 0, message)
}
const internalOrExternalLine = value => {
    let ret = []
    const internal_line = internalLineLengthValidator(value)
    const external_line = externalLineValidator(value)
    if (internal_line && external_line) {
        ret = ret.concat([internal_line, external_line])
    } else if (!internal_line) {
        // внутренняя линия
        ret = ret.concat(
            [internalLineValidator(value)]
        )
    }
    return ret
}

export {
    internalLineValidator, externalLineValidator, isNumberValidator, internalLineLengthValidator,
    internalOrExternalLine,
    Validator, subValidator, positiveValue
}