
<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { Icon, Fragment } from "@/components/layot";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";

export default {
  name: `${config.apiType}Component`,
  mixins: [ComponentMixinVue],
  components: {
    Socket: VueRenderPlugin.Socket,
    Icon,
    Fragment,
},
  computed: {
    config: () => config,
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.pbxgroup {
  .name {
    font-size: 2em;
    max-width: calc(100% - 20px);
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .output-socket.output {
    position: relative;
    left: calc(100% - 18px);
    &.timeout {
      background-color: $obit-danger !important;
    }
  }
}
</style>