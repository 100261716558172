<template>
  <div class="search_filter">
    <b-form
      inline
      @keyup.enter.stop.prevent="apply_filter"
      @keydown.enter.stop.prevent="apply_filter"
    >
      <b-form-row class="default_filter">
        <b-input-group>
          <b-form-select :options="type_options" v-model="filter.status" />
          <slot name="presearch" />
          <b-form-input
            :placeholder="filter_string"
            v-model="filter.input"
            v-if="_filter_fields.length > 0"
            :class="{ 'border-right-0': filter.input }"
            @focus="focus = true"
            @blur="focus = false"
          />
          <template v-slot:append v-if="filter.input">
            <b-button
              variant="outline"
              :class="[
                'border',
                'border-left-0',
                'text-primary',
                'clear-input',
                { focus: focus },
              ]"
              @click="filter.input = ''"
              ><obit-icon icon="close"
            /></b-button>
          </template>
          <slot name="postsearch" />
          <slot />
        </b-input-group>
        <portal-target
          name="create_button"
          class="ml-auto create-button"
          v-if="is_vertical.value"
        />
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { FilterMixin } from "../../mixins";
export default {
  name: "EmployeeFilter",
  mixins: [FilterMixin],
  methods: {
    default_filter() {
      return {
        input: "",
        status: "active",
      };
    },
    apply_filter() {
      this.$emit("filter");
      let new_objects = this.objects_in.filter(this.rule);
      if (this.filter.status) {
        new_objects = new_objects.filter((r) => r.status == this.filter.status);
      }
      this.objects = new_objects.map((o) => o.obj_id);
      this.$emit("filter");
    },
  },
  computed: {
    type_options: () => {
      const ops = {
        active: "Активен",
        "not-approved": "Отправлено приглашение",
        deleted: "Удален",
        "not-active": "без доступа",
        "has-no-email": "Нет приглашения",
      };
      let options = Object.entries(ops).reduce(
        (r, [k, v]) => r.concat({ text: v, value: k }),
        [{ text: "Все", value: null }]
      );
      return options;
    },
  },
};
</script>

<style>
</style>