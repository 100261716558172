<template>
  <b-tabs>
    <b-tab title="Профиль">
      <user-obj :obj_id="obj_id" />
    </b-tab>
    <b-tab title="Настройки">
      <perm-manager />
    </b-tab>
  </b-tabs>
</template>

<script>
import UserObj from "./User";
import PermManager from "./PermManager";
export default {
  name: "StuffProfile",
  props: ["obj_id"],
  components: { UserObj, PermManager },
};
</script>

<style>
</style>