<template>
  <div class="object-form personal-area">
    <portal v-if="!no_head" to="header" :disabled="transfer_header">
      <div class="def header" v-if="!hide_header">
        <b-button
          v-if="desc.newParam"
          variant="outline"
          class="text-primary back"
          to=".."
        >
          <obit-icon icon="back" />
          Все {{ desc.plural }}
        </b-button>
        <b-button
          v-if="!desc.newParam"
          variant="outline"
          class="text-primary back"
          to="."
        >
          <obit-icon icon="back" />
          Все {{ desc.plural }}
        </b-button>

        <h1 class="header def-header">
          {{ obj.obj_name || `${title}: ${obj.obj_key}` }}
        </h1>
      </div>
    </portal>
    <b-row class="item">
      <b-col>
        <obj-form
          :obj_id="obj_id"
          :obj_type="obj_type"
          v-model="obj"
          mode="view"
          :description="obj._description"
          ref="form"
        >
          <div class="hr-line-dashed" v-if='editable'/>
          <div class="form-group row" v-if='editable'>
            <div class="col-sm-4 col-sm-offset-2">
              <b-button-group>
                <obj-modal
                  :obj_id="obj_id"
                  :obj_type="obj_type"
                  :nextDelete="nextDelete"
                />
              </b-button-group>
            </div>
          </div>
        </obj-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { as_icon } from "../layot";
import ObitIcon from "../obit/ObitIcon";
import { COMPONENTS, Obj, ObjList } from "../objects";
import { OBJ_COMPONENTS } from "../../constants";
import { bindEvent } from "../../functions";
import ObjForm from "../ObjForm";
import ObjModal from "./ObjModal";

import { faExclamationCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import { Portal } from "portal-vue";

export default {
  name: "DefObject",
  props: {
    obj_id: {
      required: true,
    },
    obj_type: {
      required: true,
    },
    no_head: {
      default: false,
      type: Boolean,
      required: false,
    },
    hide_header: {
      required: false,
      default: false,
      type: Boolean,
    },
    hide_edit_header: {
      required: false,
      default: false,
      type: Boolean,
    },
    transfer_header: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  inject: ["link"],
  mounted() {
    const obj_type = this.obj_type; // "added:employee:new"
    const desc = this.desc;
    const store = this.$store;
    bindEvent(this, `added:${obj_type}:${this.obj_id}`, (data) => {
      store.commit("add_message", { type: "success", message: "Создано!" });
      // store.commit('add_message', {type: 'info', message: data})
      this.$router.push({
        name: "object",
        params: { type: obj_type, id: data[desc.obj_listkey || desc.obj_key] },
      });
    });
    bindEvent(this, `updated:${obj_type}:${this.obj_id}`, () => {
      this.loading = false;
      this.savind = false;
      this.$nextTick(() => this.$refs["form"].$forceUpdate());
    });
    bindEvent(this, `error:${obj_type}:${this.obj_id}`, (data) => {
      store.commit("add_message", { type: "danger", message: data });
    });
    bindEvent(
      this,
      "valid",
      (is_valid) => {
        if (is_valid) {
          this.edit_modal = false;
        }
      },
      this
    );
  },
  computed: {
    editable() {
      return this.obj._description?.editable ?? true
    },

    comp() {
      const comps = COMPONENTS[this.obj_type] || [Obj, ObjList];
      return comps[0];
    },
    desc() {
      return OBJ_COMPONENTS[this.obj_type] || {};
    },
    obj() {
      return this.$store.getters[`${this.obj_type}/object`](this.obj_id) || {};
    },
    title() {
      return this.desc.verbose;
    },
    bus() {
      return this;
    },
    prefix: () => "",

    nextDelete() {
      return this.$route.path.split("/").slice(0, -1).join("/");
    },
  },

  methods: {
    event_to_emit(method) {
      return `${this.prefix}${method}`;
    },
    refresh() {
      this.loading = true;
      this.bus.$emit(this.event_to_emit("refresh"));
    },
    save() {
      this.bus.$once(this.event_to_emit("valid"), (val) => {
        this.loading = val;
      });
      this.bus.$once(`error:${this.obj_type}:${this.obj_id}`, () => {
        this.has_error = true;
      });
      this.bus.$emit(this.event_to_emit("save"));
    },
  },
  data() {
    return {
      loading: false,
      savind: false,
      has_error: false,
      icons: {
        sync: as_icon(faSync),
        error: as_icon(faExclamationCircle),
      },
      mode: this.obj_id == "new" ? "new" : "view",
      edit_modal: false,
      edit_data: {},
    };
  },
  components: {
    ObitIcon,
    ObjForm,
    ObjModal,
    Portal,
  },
};
</script>

<style lang='scss'>
.def.header {
  padding-left: 15px;
  padding-top: 15px;
  flex-direction: column;
  .back {
    padding-top: 0px;
    padding-left: 0px;
  }
  .def-header {
    padding: 0;
  }
  h1.header {
    padding-bottom: 0px;
    padding-top: 15px;
    font-weight: 400;
    font-size: 24px;
    line-height: 28.6px;
  }
}
</style>