import { format } from 'date-fns'
import { formatWithOptions } from 'date-fns/fp'
import { ru } from 'date-fns/locale'
import { type_val } from './functions'

const DATE_FORMAT = 'dd.MM.yyyy'
const STANDART_DATE_FORMAT = 'dd.MM.yyyy'
const DATESTATNDART_FORMAT = "yyyy-MM-dd"
const TIME_FORMAT = 'HH:mm:ss'
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`

const _blank_value = [undefined, null]
const isBlank = value => _blank_value.indexOf(value) !== -1

const number_formatter = value => {
  var ret = value
  const formats = {
    '$1 ($2) $3-$4-$5': /^(\d)(\d{3})(\d{3})(\d{2})(\d{2})$/,
    '8 (812) $1-$2-$3 ($4)': /^812(\d{3})(\d{2})(\d{2})(\d+)$/,
    '8 ($1) $2-$3-$4': /^(\d{3})(\d{3})(\d{2})(\d{2})$/,
  }
  if (!ret) return ''
  ret = `${ret}`
  for (const [new_format, format] of Object.entries(formats)) {
    if (format.test(value)) {
      ret = ret.replace(format, new_format)
      break
    }
  }
  return ret
}

const max_length = (value, mx = 15) => {
  return value.length >= mx + 3 ? value.slice(0, mx) + '...' : value
}
const price_per = (value, l) => `${value} руб.${l ? '/' : ''}${l || ''}`
const price = value => price_per(value)
const price_per_month = value => price_per(value, 'мес.')

const reformat = (v) => {
  const t = type_val(v)
  let ret = v
  if (t == 'String') {
    ret = new Date(v.replace(' ', 'T').split('+')[0])
  }
  return ret
}
const date_reformat = (value) => {
  let ret = value
  if (type_val(ret) == 'Date') {
    return ret
  }
  try {
    ret = new Date(value)
    if (ret.toString() == 'Invalid Date') {
      ret = reformat(value)
    }
  } catch (e) {
    ret = reformat(value)
  }
  return ret
}

const api_date_format = 'yyyy-MM-dd'
const timestamp = value => format(value, DATETIME_FORMAT)
const dateformatter = value => value ? formatWithOptions({ locale: ru }, "dd.MM.yyyy")(date_reformat(value)) : value
const datetimeformatter = value => value ? formatWithOptions({ locale: ru }, "dd.MM.yyyy HH:mm:ss")(date_reformat(value)) : value
const format_digits = (value, digits = 2, digit = '0') => `${digit.repeat(digits)}${value}`.slice(-1 * digits)
const apiDateFormatter = value => value ? formatWithOptions({ locale: ru }, api_date_format)(date_reformat(value)) : value
const dateformatter_standart = value => format(value, DATESTATNDART_FORMAT)

const timeformatter = (value) => {
  if (value === undefined) {
    return value
  }
  let second = 0, minute = 0, hour = 0
  second = value ? value % 60 : 0
  if (second == 60) {
    minute += 1
    second = 0
  }
  minute += value ? Math.floor(value / 60) : 0
  hour += value ? Math.floor(minute / 60) : 0
  minute -= hour * 60
  return `${format_digits(hour)}:${format_digits(minute)}:${format_digits(second)}`
}

const texttimeformatter = (value, addMinutes = false, addHours = false) => {
  if (isBlank(value) || value == 0) return '\u2014'
  let ret = ''
  let val = Math.abs(value)
  let second = 0, minute = 0, hour = 0
  second = val ? val % 60 : 0
  if (second == 60) {
    minute += 1
    second = 0
  }
  minute += val ? Math.floor(val / 60) : 0
  hour += val ? Math.floor(minute / 60) : 0
  minute -= hour * 60
  if (hour || addHours) {
    ret += `${hour} ч `
  }
  if (minute || addMinutes || addHours) {
    ret += `${minute} мин `
  }
  if (second) {
    ret += `${second} сек`
  }
  return ret
}

const money_format = (value) => {
  if (isBlank(value)) return value
  return value.toLocaleString('ru-RU', {
    useGrouping: true,
    minimumFractionDigits: 2
  })
}

const time_string = (seconds) => {
  if (!seconds) {
    return "-";
  }
  var time_obj = {
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  var leftover = 0;
  var time_string = "";
  if (seconds >= 3600) {
    leftover = seconds % 3600;
    time_obj.hours = (seconds - leftover) / 3600;
    time_string += `${time_obj.hours} ч `;
    seconds = leftover;
    if (seconds == 0) {
      time_string += "00 мин ";
    }
  }
  if (seconds >= 60) {
    leftover = seconds % 60;
    time_obj.minutes = (seconds - leftover) / 60;
    var strmin = time_obj.minutes.toString();
    if (time_obj.minutes < 10) {
      strmin = "0" + strmin;
    }
    time_string += `${strmin} мин `;
    seconds = leftover;
  }
  if (seconds >= 0) {
    time_obj.seconds = seconds;
    var strsec = time_obj.seconds.toString();
    if (time_obj.seconds < 10) {
      strsec = "0" + strsec;
    }
    time_string += `${strsec} сек`;
  }
  return time_string;
}

export {
  number_formatter, price, price_per_month, price_per, timestamp, timeformatter,
  DATETIME_FORMAT, TIME_FORMAT, DATE_FORMAT, STANDART_DATE_FORMAT,
  money_format, texttimeformatter, dateformatter, datetimeformatter, date_reformat,
  apiDateFormatter, dateformatter_standart, max_length, time_string,
}