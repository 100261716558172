import { ExternalLine, ExternalLine_CONFIG } from './ExternalLine'
import { InternalLine2 } from './InternalLine2'
import { PbxGroup } from './PbxGroup'
import { Start } from './Start'
import { ShchCheck } from './Shcheduler'
import { IVR } from './IVR'
import { PlayFile } from './PlayFile'
import { Hang } from './Hang'
import { DISA } from './DISA'
import {  Integration } from './CRMIntegration'
import {  SIPTermination, SIPTermination_CONFIG } from './SIPTermination'

import { InternalLine2_CONFIG } from './InternalLine2/index'
import { PBXGroup_CONFIG } from './PbxGroup/index'
import { SHCH_CONFIG } from './Shcheduler/index'
import { IVR_CONFIG } from './IVR/index'
import { Hang_CONIFG } from './Hang/index'
import { PlayFile_CONFIG } from './PlayFile/index'
import { DISA_CONFIG } from './DISA/index'
import { UNKNOWN, UNKNOWN_CONFIG } from './Unknown'
import { Integration_CONIFG } from './CRMIntegration/index'

const ComponentsClasses = [Integration,ExternalLine, InternalLine2, PbxGroup, Start, ShchCheck, IVR, PlayFile, Hang, DISA, SIPTermination, UNKNOWN]
const CONFIGS = {
  Integration_CONIFG,ExternalLine_CONFIG, InternalLine2_CONFIG, PBXGroup_CONFIG, SHCH_CONFIG, IVR_CONFIG, Hang_CONIFG, PlayFile_CONFIG, DISA_CONFIG, SIPTermination_CONFIG, UNKNOWN_CONFIG
}
export { CONFIGS }
export { Integration, ExternalLine, InternalLine2, PbxGroup, Start, ShchCheck, IVR, PlayFile, Hang, DISA, SIPTermination, UNKNOWN }
export { ComponentsClasses }