import { buildBooleanField, buildDateTimeField } from '../fields'
import Comp from './NotificationsList.vue'
const description = {
    verbose: 'Сообщение',
    plural: 'Сообщения',
    objs_comp: Comp,
    fields: {
        date: buildDateTimeField('Время'),
        title: 'Заголовок',
        readed: buildBooleanField('Прочитано'),
        message: 'Тело сообщения'
    }
}

const test_data = [
    {  
        date: Date.now(), title: 'С 12.03.2020 до 12.04.2020 личный кабинет будет недоступен',
        message: 'В связи с проведением запланированных технических работ,' +
            'связанных с большим обновлением функционала, услуги личного ' + 
            'кабинета будут недоступны в течение часа после полуночи 12 марта 2020 года. Приносим свои извинения за возможные неудобства.'
    },
    {
        date: Date.now(), title: 'Заканчивается срок, в течение которого вы должны оплатить задолженность',
        message: 'В связи с проведением запланированных технических работ, связанных с большим обновлением функционала,' + 
            ' услуги личного кабинета будут недоступны в течение часа после полуночи 12 марта 2020 года.' + 
            ' Приносим свои извинения за возможные неудобства.'
    },
    {
        date: Date.now(), title: 'С 12.03.2020 до 12.04.2020 личный кабинет будет недоступен',
        message: 'В связи с проведением запланированных технических работ,' +
            'связанных с большим обновлением функционала, услуги личного ' +
            'кабинета будут недоступны в течение часа после полуночи 12 марта 2020 года. Приносим свои извинения за возможные неудобства.',
        readed: true
    },
]

export {description as NOTIFICATIONS_DESC, test_data as test_notifications_data}