<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
    v-parent-class="{ 'active-node': show_additional }"
  >
    {{ get_data(node.data) }}
    <!--- hide title <div class="title">{{node.name}}</div> -->
    <!-- Outputs-->
    <b-card-title class="title">
      <Socket
        v-for="output in inputs()"
        :key="output.key"
        v-socket:input="output"
        type="input"
        class="input-socket"
        :socket="output.socket"
      ></Socket
      >Внутренний номер
      <b-button-group>
        <b-button pill variant="outline" sm @click="show_settings()" disabled>
          <icon class="title-icon" :icon="icons.gear" />
        </b-button>
        <b-dropdown
          dropright
          variant="outline"
          toggle-class="border-0"
          no-caret
          class="additional-info"
          :popper-opts="{ boundary: 'window' }"
          v-if="number.number"
          @show="fetch_additional_data()"
          @shown="show_additional = true"
          @hidden="show_additional = false"
        >
          <template #button-content>
            <icon class="title-icon" :icon="icons.bars" />
          </template>
          <b-overlay :show="!additional_data">
            <fragment v-if="number.number.devices_count">
              <b-dropdown-header
                >Устройств: {{ number.number.devices_count }}</b-dropdown-header
              >
              <b-dropdown-group
                v-for="item in number.number.devices"
                :key="item.service_id"
              >
                <b-dropdown-text v-if="item.type == 'mobile'">
                  <span class="material-icons-outlined"> smartphone </span>
                  {{ item.target }}
                </b-dropdown-text>
                <fragment v-else>
                  <b-dropdown-text>
                    <nobr v-if="item.type == 'sip'"
                      ><span class="material-icons-outlined"> dialer_sip </span>
                      {{ item.account }}
                    </nobr>
                    <nobr v-else>
                      <span class="material-icons-outlined"> dialpad </span>
                      {{ item.caption }}
                    </nobr>
                  </b-dropdown-text>
                  <b-dropdown-text
                    v-if="item.forward == 1"
                    class="add_info_forward"
                  >
                    <span class="material-icons-outlined">
                      call_missed_outgoing
                    </span>
                    {{ item.transfer_always }}
                  </b-dropdown-text>
                  <fragment v-if="item.forward == 2">
                    <b-dropdown-text class="add_info_forward"
                      >Занят - {{ item.transfer_busy }}</b-dropdown-text
                    >
                    <b-dropdown-text class="add_info_forward"
                      >Неответ - {{ item.transfer_timeout }}</b-dropdown-text
                    >
                  </fragment>
                </fragment>
              </b-dropdown-group>

              <!-- <b-dropdown-text
                v-if="
                  !(number.number.devices || []).filter((o) => o.forward).length
                "
              >
                Нет переадресации
              </b-dropdown-text>
              <b-dropdown-group
                v-for="item in (number.number.devices || []).filter(
                  (o) => o.forward
                )"
                :key="item.service_id"
              >
                {{ item }}
              </b-dropdown-group> -->
            </fragment>
            <b-dropdown-text v-else> Нет устройств </b-dropdown-text>
          </b-overlay>
        </b-dropdown>
      </b-button-group>
    </b-card-title>
    <b-modal v-model="modal_show" title="настройки">
      <div
        class="control"
        v-for="(control, _i) in controls()"
        :key="_i"
        v-control="control"
      />
    </b-modal>
    <fragment v-if="number.number">
      <h2 class="name">
        {{ number.number.number }}
      </h2>
      <short-text-span
        tag="h4"
        class="employee"
        v-if="number.employee"
        :text="number.employee.obj_name"
      />
    </fragment>

    <!-- Inputs-->
    <!-- <div class="input" v-for="input in inputs()" :key="input.key">
      <Socket
        v-socket:input="input"
        type="input"
        :socket="input.socket"
      ></Socket>
      <div class="input-title" v-show="!input.showControl()">
        {{ input.name }}
      </div>
      <div
        class="input-control"
        v-show="input.showControl()"
        v-control="input.control"
      ></div>
    </div> -->
  </b-card>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { as_icon, Icon, Fragment } from "@/components/layot";
import { faBars, faCog, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";
import { MDIcon } from "../../../../shared";
import parent_class from "../../../../../directives/parent_class";

export default {
  name: "EmployeeComp",
  mixins: [ComponentMixinVue],
  directives: {
    "parent-class": parent_class,
  },
  components: {
    Socket: VueRenderPlugin.Socket,
    Icon,
    MDIcon,
    Fragment,
  },
  data: () => ({
    additional_data: false,
    hover: false,
    show_additional: false,
  }),
  watch: {
    service_id(from, to) {
      if (from != to) {
        this.additional_data = false;
      }
    },
  },
  methods: {
    isHover(val) {
      this.hover = val;
      if (val) {
        this.$el.parentNode.classList.add("node-hover");
      } else {
        this.$el.parentNode.classList.remove("node-hover");
      }
    },
    fetch_additional_data() {
      console.log("show info", this.employee_id);
      if (this.additional_data) return;
      this.$store
        .dispatch("internal_line2/get", this.number.number.service_id)
        .then(() => (this.additional_data = true));
    },
  },
  computed: {
    config: () => config,
    icons: () => ({
      gear: as_icon(faCog),
      bars: as_icon(faBars),
      mobile: as_icon(faMobileAlt),
    }),
    number() {
      const number = this.service;
      const employee = number
        ? this.$store.getters["employee/object"](number.employee)
        : null;
      return { number, employee };
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.node-hover {
  z-index: 100000;
}
.node.internal_line2-node {
  max-width: inherit;
  .name {
    font-size: 2em;
    font-weight: 300;
  }
  .employee {
    padding-left: 15px;
    padding-bottom: 2px;
    font-weight: 300;
  }
  .add_info_forward {
    margin-left: 20px;
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .b-dropdown-text {
    white-space: pre;
  }
}
</style>