<script>
import { Bar } from "vue-chartjs";
// const { reactiveProp } = mixins;
import { cloneDeep } from "lodash-es";

export default {
  name: "Chart",
  extends: Bar,
  // mixins: [reactiveProp],
  props: ["chartData", "options"],
  data: function () {
    return {
      gradient: "",
    };
  },
  mounted() {
    // if (typeof this.chartData.datasets[0].startGradient !== "undefined") {
    //   this.gradient = this.$refs.canvas
    //     .getContext("2d")
    //     .createLinearGradient(0, 0, 0, 450);
    //   this.gradient.addColorStop(0, this.chartData.datasets[0].startGradient);
    //   this.gradient.addColorStop(1, this.chartData.datasets[0].endGradient);
    //   this.chartData.datasets[0].backgroundColor = this.gradient;
    // }
    this.$watch("chartData", () => this.update(), { deep: true });
    this.renderChart(this.data_with_gradient(), this.options);
  },
  methods: {
    update() {
      this.renderChart(this.data_with_gradient(), this.options);
    },
    data_with_gradient() {
      let ret = cloneDeep(this.chartData);
      if (typeof ret.datasets[0].startGradient !== "undefined") {
        this.gradient = this.$refs.canvas
          .getContext("2d")
          .createLinearGradient(0, 0, 0, 450);
        this.gradient.addColorStop(0, this.chartData.datasets[0].startGradient);
        this.gradient.addColorStop(1, this.chartData.datasets[0].endGradient);
        ret.datasets[0].backgroundColor = this.gradient;
      }
      return ret;
    },
  },
};
</script>

<style>
</style>
