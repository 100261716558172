import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

window.onfocus = () => {
    store.dispatch(`oidcStore/authenticateOidcSilent`).catch(() => {
        store.dispatch(`oidcStore/removeOidcUser`)
        store.dispatch(`oidcStore/authenticateOidc`)
    })
}



