<template>
  <fragment>
    <div :class="{'minimap': true, ...mapClass()}" v-show="!minimized" ref="map">
      <div class="area" @mousedown.stop.prevent="" ref="area">
        <div
          v-for="node in nodes"
          :key="node.id"
          :style="nodeStyle(node)"
          :class="nodeClass(node)"
        />
        <div class="mini-viewport" ref="viewport" :style="viewportStyle()" />
      </div>
    </div>
    <b-button
      variant="outline-primary"
      v-if="minimized"
      @click="minimized = false"
      class="minimap-toggle"
      v-b-tooltip="{ title: 'Миникарта', variant: 'light' }"
    >
      <icon :icon="icons.map" />
    </b-button>
  </fragment>
</template>

<script>
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { as_icon, Fragment, Icon } from "@/components/layot";
import MiniMapComponent from "../minimap-plugin/Map.vue";
import { getCoords } from "@/functions.js";

const GAP = 250;

export default {
  components: { Fragment, Icon },
  mixins: [MiniMapComponent],
  mounted() {
    if (window.innerWidth < 800) {
      this.minimized = true;
    }
    let map = this.$refs.map;

    map.addEventListener("touchend", this.handleMapTap);
    map.addEventListener("mouseup", this.handleMapClick);

    this.$on(["mapTap", "mapClick"], this.handleMinimapClick);
    this.$on(["mapDblTap", "mapDblClick"], this.handleMinimapDoubleClickOrTap);
    //map.addEventListener("click", this.handleMinimapClick);

    //map.addEventListener("dblclick", this.handleMinimapDoubleClickOrTap);

    //map.addEventListener("touchend", this.handleMinimapDoubleClickOrTap);

    this.$watch(
      () => this.minimized,
      () => this.updateTransform()
    );
  },
  beforeDestroy() {
    let map = this.$refs.map;

    map.removeEventListener("touchend", this.handleMapTap);
    map.removeEventListener("mouseup", this.handleMapClick);

    this.$off(["mapTap", "mapClick"], this.handleMinimapClick);
    this.$off(["mapDblTap", "mapDblClick"], this.handleMinimapDoubleClickOrTap);
    //map.removeEventListener("dblclick", this.handleMinimapDoubleClickOrTap);
    //map.removeEventListener("touchend", this.handleMinimapDoubleClickOrTap);
  },
  data() {
    return {
      minimized: false,
      taps: 0,

      tap_last: null,
      taps_delta: null,
      taps_gap: GAP,
      tap_timer: null,
      tap_event: null,

      click_last: null,
      click_gap: GAP,
      clicks_delta: null,
      click_timer: null,
      click_event: null,
    };
  },
  computed: {
    icons: () => ({
      map: as_icon(faMap),
    }),
  },
  methods: {
    handleMapTap(e) {
      e.preventDefault();
      if (!this.tap_timer) {
        this.tap_event = e;
        this.tap_timer = setTimeout(() => {
          let { pageX, pageY } = e.changedTouches[0];
          let { target } = e;
          this.$emit("mapTap", { pageX, pageY, target }, this.tap_event);
          clearTimeout(this.tap_timer);
          this.tap_timer = null;
        }, this.taps_gap);
      } else {
        clearTimeout(this.tap_timer);
        this.tap_timer = null;
        let { pageX, pageY } = e.changedTouches[0];
        let { target } = e;
        this.$emit("mapDblTap", { pageX, pageY, target }, this.tap_event);
      }
    },
    handleMapClick(e) {
      if (!this.click_timer) {
        this.click_event = e;
        this.click_timer = setTimeout(() => {
          let { target, pageX, pageY } = e;
          this.$emit("mapClick", { pageX, pageY, target }, this.click_event);
          clearTimeout(this.click_timer);
          this.click_timer = null;
        }, this.click_gap);
      } else {
        clearTimeout(this.click_timer);
        this.click_timer = null;
        let { target, pageX, pageY } = e;
        this.$emit("mapDblClick", { pageX, pageY, target }, this.click_event);
      }
    },
    handleMinimapClick(e) {
      // get area position in minimap
      let { x, y, k } = this.view.area.transform;
      let { invert } = this.transform;
      let viewport = this.$refs.viewport;
      // let area = this.$refs.area;
      let { clientWidth, clientHeight } = viewport;
      let { sx, sy } = getCoords(viewport, "s");
      let { pageX, pageY } = e;
      let deltaX = clientWidth / 2,
        deltaY = clientHeight / 2;
      if (e.target == viewport) {
        (deltaX = pageX - sx), (deltaY = pageY - sy);
      }

      let dx = sx - pageX + deltaX,
        dy = sy - pageY + deltaY;
      let ox = x + k * invert(dx),
        oy = y + k * invert(dy);
      // console.log("move window", { mx, my });
      this.view.area.translate(ox, oy);
    },
    handleMinimapDoubleClickOrTap() {
      this.minimized = true;
    },
    mapClass() {
      return {
        [this.size]: true,
        custom: true,
      };
    },
    nodeClass(node) {
      let ret = "default";
      if (node.vueContext.node_show) {
        ret = "new";
      }
      if (node.vueContext.node_search) {
        ret = "searched";
      }
      return ["mini-node", ret];
    },
  },
};
</script>

<style lang='scss' >
@import "@/../static/SCSS/obit-colors.scss";
.minimap-toggle {
  position: absolute;
  bottom: 1em;
  right: 1em;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
}
.minimap.custom {
  border: 1px solid lighten($obit-primary, 20%);
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(5px);

  padding: 10px;
  .mini-viewport {
    background: rgba(lighten($obit-primary, 35%), 0.32);
    border: 1px solid $obit-primary;
  }
  .mini-node {
    &.default {
      background: $obit-primary;
    }
    &.searched {
      background: $obit-danger;
    }
    &.new {
      background: $obit-export;
    }
  }
}
</style>