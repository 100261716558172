<template>
    <div class="stream">
        <div class="stream-badge">
            <slot name='badge'>
                <component v-bind:is="icon_comp" :icon='icon' />
            </slot>
        </div>
        <div class="stream-panel">
            <div class="stream-info" v-if='info'>
                <slot name='info'>
                    <router-link :to="{name: 'obj', params: {obj_type: 'user', obj_id: info.user_id}}">
                        <img :src="info.avatar">
                        <span>{{info.user}}</span>
                        <span class="date">{{info.date}}</span>
                    </router-link>
                </slot>
            </div>
            <slot/>
        </div>
    </div>
</template>

<script>
import { Icon } from '.'
export default {
    name: 'ActivityStreamItem',
    props: {
        icon_comp: {
            default: () => Icon,
        },
        icon: {
            default: 'question'
        },
        info: {
            type: Object,
            default: undefined
        }
    }
}
</script>

<style>

</style>