<template>
  <b-list-group>
    <b-list-group-item>
      <b-form-radio :value="null" v-model="selected" style="display: inline" />
      Без приветсвия
    </b-list-group-item>
    <b-list-group-item v-for="media in media_list" v-bind:key="media.media">
      <b-form-radio :value="media.media" v-model="selected" />
      <b-button size="sm" variant="outline">
        <icon icon="play-circle" />
      </b-button>
      {{ media.name }}
      <span class="text-secondary">
        {{ formattime(media.file_length) }}
      </span>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { Icon, library } from "../../layot";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { timeformatter } from "../../../formatters";

library.add(faPlayCircle);

export default {
  name: "MediaSelect",
  props: {
    value: {
      required: true,
    },
  },
  components: { Icon },
  data() {
    return {
      media_list: this.$store.getters.get_objects("media"),
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    formattime: timeformatter,
  },
};
</script>

<style>
.list-group-item > * {
  display: inline;
}
</style>