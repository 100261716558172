var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.real_show),expression:"real_show"}],class:[
    `${_vm.config.objType}-node`,
    'node',
    {
      'node-hover': _vm.hover,
      'node-new': _vm.node_new > 0,
      'node-search': _vm.node_search,
    },
  ],attrs:{"no-body":""},on:{"mouseover":function($event){return _vm.isHover(true)},"mouseleave":function($event){return _vm.isHover(false)}}},[_vm._v(" "+_vm._s(_vm.get_data(_vm.node.data))+" "),_c('b-card-title',{staticClass:"title"},[_vm._l((_vm.inputs()),function(input){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(input),expression:"input",arg:"input"}],key:input.key,class:['input-socket', input.key],attrs:{"type":"input","socket":input.socket}})}),_vm._v(" "+_vm._s(_vm.config.name)+" "),(_vm.service && _vm.has_edit_modal)?_c('obj-modal',{attrs:{"obj_id":_vm.service.service_id,"obj_type":_vm.service.obj_type,"noDelete":""},scopedSlots:_vm._u([{key:"default",fn:function({ show_modal }){return [_c('b-button',{attrs:{"pill":"","variant":"outline","sm":""},on:{"click":show_modal}},[_c('icon',{attrs:{"icon":_vm.icons.gear}})],1)]}}],null,false,3899217352)}):_vm._e()],2),(_vm.service)?_c('short-text-span',{staticClass:"name",attrs:{"tag":"h2","text":_vm.service.obj_name || ''}}):_vm._e(),(!_vm.config.namedOutputs)?_c('fragment',_vm._l((_vm.outputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],key:output.key,class:['output-socket', output.key],attrs:{"type":"output","socket":output.socket}})}),1):_c('fragment',_vm._l((_vm.outputs()),function(output){return _c('div',{key:output.key,staticClass:"output-line"},[_c('div',{staticClass:"out"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(output.name))]),_c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],class:['output-socket', output.key],attrs:{"type":"output","socket":output.socket}})],1)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }