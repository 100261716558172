<template>
  <b-input-group
    :class="['obit-datepicker', { focus: focus }]"
    v-if="show_input"
  >
    <span v-if="label" class="legend">
      {{ label }}
    </span>
    <b-form-input
      v-model="text_date"
      placeholder="дд.мм.гггг"
      :formatter="date_formatter"
      @focus="set_focus(true)"
      @blur="set_focus(false)"
      :disabled="disabled"
    />
    <b-input-group-append>
      <b-form-datepicker
        v-model="val"
        :disabled="disabled"
        v-bind="config_default"
      >
        <template #button-content>
          <obit-icon icon="calendar" />
          <span class="sr-only"> Выбрать дату </span>
        </template>
      </b-form-datepicker>
    </b-input-group-append>
  </b-input-group>
  <b-form-datepicker
    v-else
    v-model="val"
    :disabled="disabled"
    v-bind="config_button_only"
  >
    <template #button-content>
      <slot>
        <obit-icon icon="calendar" />
        <span class="sr-only"> Выбрать дату </span>
      </slot>
    </template>
  </b-form-datepicker>
</template>

<script>
import ObitIcon from "../../obit/ObitIcon.vue";

const DEFAULT_CONFIG = {
  class: "noboroder",
  right: true,
  "button-variant": "outline-obit",
  locale: "ru-RU",
  "calendar-width": "140px",
  "nav-button-variant": "obit",
  "button-only": true,
  offset: 129,
  "date-format-options": {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
  },
  "hide-header": true,
  "selected-variant": "outline-obitless",
  block: true,
  "start-weekday": 1,
};
const BUTTON_ONLY_CONFIG = Object.assign({}, DEFAULT_CONFIG, {
  class: "border-0 obit-datepicker",
  "button-variant": "outline-obitless",
});

export default {
  name: "DatePicker",
  components: { ObitIcon },
  props: {
    value: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
      required: false,
    },
    show_input: {
      required: false,
      default: true,
      type: Boolean,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
    options: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      n_text_data: "",
      focus: false,
    };
  },
  computed: {
    config_default() {
      return Object.assign({}, DEFAULT_CONFIG, this.options);
    },
    config_button_only() {
      return Object.assign({}, BUTTON_ONLY_CONFIG, this.options);
    },
    val: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", to);
      },
    },
    text_date: {
      get() {
        if (this.val) {
          var ymd = this.val.split("-");
          return ymd.reverse().join(".");
        }
        return null;
      },
      set(to) {
        let date_reg = /[0-9][0-9].[0-9][0-9].[0-9][0-9][0-9][0-9]/;
        if (to.match(date_reg)) {
          var [d, m, y] = to.split(".");
          if (y && m && d) {
            this.val = to.split(".").reverse().join("-");
          }
        } else if (to.match(/[0-9][0-9]/)) {
          to = to + ".";
        } else if (to.match(/[0-9][0-9].[0-9][0-9]/)) {
          to = to + ".";
        }
      },
    },
  },
  methods: {
    set_focus(value) {
      this.focus = value;
    },
    date_formatter(to) {
      if (to.match(/^[0-9][0-9]$/)) {
        to = to + ".";
      } else if (to.match(/^[0-9][0-9].[0-9][0-9]$/)) {
        to = to + ".";
      }
      return to;
    },
  },
};
</script>

<style lang='scss'>
.obit-datepicker {
  flex-wrap: nowrap;
  &.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .legend {
    height: 100%;
    display: table-cell;
    vertical-align: middle;
    padding-top: 0.8em;
  }
  &:hover input,
  &:hover button.btn {
    border-color: var(--primary) !important;
  }
  &.focus {
    button.btn {
      border-color: var(--primary) !important;
    }
  }
  .dropdown-menu {
    margin-top: 10px;
  }
  .cal {
    width: 100%;
    height: 100%;
  }
  .small {
    height: 0;
    border-top: none !important;
  }
  .rounded-circle {
    box-shadow: none !important;
  }
  .font-weight-bold {
    font-weight: 400 !important;
  }
  .b-calendar-grid {
    border: none;
    width: 250px;
    div[aria-label$="(Today)"] {
      .btn {
        color: var(--primary) !important;
      }
    }
    div[aria-selected="true"] {
      .btn {
        color: var(--warning) !important;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
  .border-bottom {
    border-bottom: none !important;
  }
  .noboroder button.btn-outline-obit {
    border: 1px solid #ced4da;
    border-left: none;
    fill: var(--primary);
    padding: 0.375rem 0.75rem;
    &:active {
      border-color: #ced4da;
    }
    &:hover {
      color: var(--primary) !important;
      fill: var(--primary);
    }
  }
  .obit-icon {
    height: 1.2em;
  }
  .input-group {
    flex-wrap: nowrap;
  }
  input {
    border-right: none;
    min-width: 100px !important;
    max-width: 100px;
    padding-right: 0;
  }

  max-width: 160px;
  min-width: 140px;
}
</style>