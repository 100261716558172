<template>
  <form>
    <b-form-group label="Входящие">
      <b-form-radio-group
        v-model="value.inner"
        :options="inner_options"
        buttons
        button-variant="outline-primary"
      />
    </b-form-group>
    <b-form-group label="Исходящие">
      <b-form-radio-group
        v-model="value.outer"
        :options="outer_options"
        buttons
        button-variant="outline-primary"
      />
    </b-form-group>
    <b-form-group label="Клиенты">
      <b-form-select v-model="value.from" :options="persons_options" />
    </b-form-group>
    <b-form-group label="Период">
      <b-form-radio-group
        v-model="value.time"
        :options="time_options"
        buttons
        button-variant="outline-primary"
      />
    </b-form-group>
  </form>
</template>

<script>
import { BFormGroup, BFormRadioGroup, BFormSelect } from "bootstrap-vue";
import { test_address_book as address_book } from "../../test_data";
const person_options = Object.entries(address_book).map(
  ([person_id, person]) => {
    return {
      text: `${person.last_name} ${person.first_name}`,
      value: person_id,
    };
  }
);

export default {
  name: "ComplexFilter",
  props: ["value"],
  components: { BFormGroup, BFormRadioGroup, BFormSelect },
  data() {
    return {
      inner_options: [
        { text: "Все", value: undefined },
        { text: "Принятый", value: 1 },
        { text: "Пропущенный", value: 2 },
      ],
      outer_options: [
        { text: "Все", value: undefined },
        { text: "Отвеченные", value: 1 },
        { text: "Без ответа", value: 2 },
        { text: "Занято", value: 3 },
      ],
      persons_options: person_options,
      time_options: [
        { text: "За всё время", value: undefined },
        { text: "Сегодня", value: "today" },
        { text: "Неделя", value: "week" },
        { text: "Месяц", value: "month" },
        { text: "Выбрать период", value: "period" },
      ],
    };
  },
};
</script>

<style>
</style>