<template>
  <b-button variant="outline" :class="b_classes" @click="toggle"
    >{{ text }} <comp v-if="comp" :is="comp.comp" v-bind="comp.props" />
  </b-button>
</template>

<script>
import { InputMixin } from "../mixins";
export default {
  name: "textBoolInput",
  mixins: [InputMixin],
  props: {
    true_text: { required: false, type: String, default: "Доп настройки" },
    false_text: { required: false, type: String, default: "Скрыть" },
    true_classes: {
      required: false,
      type: Array,
      default: () => ["text-primary"],
    },
    false_classes: {
      required: false,
      type: Array,
      default: () => ["text-primary"],
    },
    true_comp: {
      required: false,
      type: Object,
      default: null,
    },
    false_comp: {
      required: false,
      type: Object,
      default: null,
    },
  },
  computed: {
    text() {
      return this.value ? this.true_text : this.false_text;
    },
    b_classes() {
      return this.value ? this.true_classes : this.false_classes;
    },
    comp() {
      return this.value ? this.true_comp : this.false_comp;
    },
  },
  methods: {
    toggle() {
      this.val = !this.value;
      this.$parent.$forceUpdate();
    },
  },
};
</script>

<style>
</style>