import { callSocket } from "../../sockets"
import Rete from "rete";

export default {
  name: 'Интеграция',
  apiType: 'CRMIntegrations',
  objType: 'integration',
  pack_data: () => ({}),
  mapOutpus: (node) => {

      let output = new Rete.Output('default', 'default', callSocket, false)
      node.addOutput(output)
    
  }
}