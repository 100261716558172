<script>
import { resolveWithPromise } from "../../functions";
export default {
  name: "WithRelated",
  methods: {
    load_depences() {
      return resolveWithPromise([]);
    },
  },
};
</script>