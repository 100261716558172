<template>
  <span class="media-cell spinner-circle">
    <b-button
      size="sm"
      variant="outline-primary"
      class="border-0 media-btn"
      @click="toggle_pause()"
      :disabled="real_item.status != 'ready'"
    >
      <b-overlay :show="loading">
        <obit-icon
          :class="[icon_class, 'spinner-subcircle']"
          v-b-tooltip="icon_tooltip"
          :icon="playing ? 'pause' : 'play'"
        />
      </b-overlay>
    </b-button>
    <div>
      <p
        v-if="item.obj_type == 'play_file'"
        class="play-text"
        v-b-tooltip="
          (item.description.length ||
            item.caption.length ||
            item.obj_name.length) > 25
            ? {
                variant: 'light',
                title: item.description || item.caption || item.obj_name,
                customClass: 'big_tooltip',
              }
            : ''
        "
      >
        {{ item.caption || item.description || item.obj_name, }}
      </p>
      <p
        v-else
        class="play-text"
        v-b-tooltip="
          (real_item.description.length || real_item.obj_name.length) > 25
            ? {
                variant: 'light',
                title: real_item.description || real_item.obj_name,
                customClass: 'big_tooltip',
              }
            : ''
        "
      >
        {{ real_item.description || real_item.obj_name, }}
      </p>
      <!-- <div class="text-muted length" v-if="real_item">
        {{ real_item.duration | time_length }}
      </div> -->
    </div>
  </span>
</template>



<script>
import CellVue from "../../mixins/Cell.vue";
import { timeformatter } from "../../../formatters";
// import { eventBus } from "../..";
// import { bindEvent } from "../../../functions";
import ObitIcon from "../../obit/ObitIcon.vue";
import { bindEvent } from "../../../functions";
import { eventBus } from "../..";
import { as_icon, Icon } from "../../layot";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "MediaNameCell",
  mixins: [CellVue],
  components: { ObitIcon, Icon },
  props: {
    related: {
      type: Boolean,
      default: false,
      requird: false,
    },
    buffer: {
      required: false,
    },
  },
  data() {
    const audio = new Audio();
    return {
      audio,
      src: null,
      currentTime: 0,
      playing: false,
      has_error: false,
      type_media: true,
      loading: false,
      // text_desc: "",
    };
  },
  mounted() {
    // this.text_desc =
    //   this.item.description || this.item.caption || this.item.obj_name;
    this.audio.addEventListener("timeupdate", this.update_time);
    if (this.buffer) {
      this.from_buffer(this.buffer);
    }
    this.$watch(() => this?.real_item?.id, this.reset);
    bindEvent(
      this,
      "play_file",
      (id) => {
        if (this.real_item.id != id && this.playing) {
          this.stop();
        }
      },
      eventBus
    );
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this.update_time);
    this.reset();
  },
  methods: {
    update_time() {
      // this.currentTime = Math.floor(this.audio.currentTime);
      // this.duration = Math.floor(this.audio.duration);
      if (this.audio.currentTime == this.audio.duration) {
        this.reset();
      }
    },
    from_buffer(buffer) {
      let blob = new Blob([buffer], { type: "audio/mp3" });
      let url = URL.createObjectURL(blob);
      this.audio.src = url;
      this.src = url;
    },
    toggle_pause() {
      if (this.playing) {
        this.audio.pause();
        this.playing = false;
      } else this.play();
    },
    play() {
      if (!this.src) {
        this.loading = true;
        this.$store
          .dispatch("media/get_media", this.real_item.id)
          .then((response) => {
            if (response.status == 200 && response.data.byteLength) {
              // отдадим аудиобуфер
              this.from_buffer(response.data);
              eventBus.$emit("play_file", this.real_item.id);
              this.audio.play();
              this.playing = true;
            } else {
              this.$store.commit("add_message", {
                type: "danger",
                message: "Файл не найден",
              });
            }
            this.loading = false;
          });
      } else {
        this.audio.play();
        eventBus.$emit("play_file", this.real_item.id);
        this.playing = true;
      }
    },
    reset() {
      this.stop();
      this.audio.src = null;
      this.src = null;
    },
    stop() {
      if (this.src) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.playing = false;
      }
    },
  },
  computed: {
    icons: () => ({
      dot: as_icon(faCircle),
    }),
    icon_class() {
      let classes = {
        ready: "text-success",
        converting: "text-warning",
        removed: "text-danger",
      };
      return classes[this.real_item.status] || "text-info";
    },
    icon_tooltip() {
      let titles = {
        ready: "доступен",
        converting: "идет конвертация",
        removed: "недоступен",
      };
      //console.log(this.real_item)
      return {
        variant: "light",
        title: titles[this.real_item.status] || "ошибка",
        customClass: "big_tooltip",
        show: 100,
        hide: 400,
      };
    },
    real_item() {
      //  console.log(this.item[this.field_desc.target])
      if (this.field_desc.target) {
        return this.$store.getters["media/object"](
          this.item[this.field_desc.target]
        );
      }
      return this.related
        ? this.$store.getters["media/object"](this.value)
        : this.item;
    },
  },
  filters: {
    time_length: timeformatter,
  },
};
</script>

<style lang='scss'>
.media-cell {
  display: flex;
  align-items: baseline;
  .spinner-border {
    vertical-align: middle;
  }
  .media-btn {
    padding-left: 0;
  }

  .length {
    margin-left: 1em;
    padding-left: 20px;
  }
  .play-text {
    // padding-left: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    width: 190px;
  }
  .text-muted {
    margin: 0;
    padding: 0;
  }
}
</style>