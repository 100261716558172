import { id_field_desc } from "@/functions.js"
import { buildRelatedField } from "../fields"
import RolesInput from '../../shared/RolesInput'
import { SmartButton } from "../../layot"
import { ObitIcon } from "../../obit"
import GroupListView from './GroupListView'
import { uniqBy, merge } from "lodash-es"
import { rebuild_object } from "../../../store/object"
import { eventBus } from "../../index"

const urls = {
  list: 'customer/groups/list',
  add: 'customer/groups/register',
  add_member: 'customer/groups/addMember',
  remove_member: 'customer/groups/removeMember',
  move: 'customer/groups/move'
}

const storage = {
  getters: {
    items: (state, getters) => (group_id = null, recursive = false) => {
      if (!recursive) return getters.objects.filter(
        g => g.parent_id == group_id
      )
      // рекурсивный поиск
      // незачем обходить дерево если это корневая группа
      if (!group_id) return getters.objects.filter(g => g.parent_id != group_id)
      const gr = getters.object(group_id)
      console.time('items')
      let ret = [gr]
      let current_groups = [gr]
      while (current_groups.length) {
        let children = getters.objects.filter(g => current_groups.map(g => g.obj_id).indexOf(g.parent_id) > -1)
        ret = uniqBy(ret.concat(children), 'obj_id')
        current_groups = children
      }
      console.timeLog('items', 'loaded')
      return ret
    },
  },
  mutations: {
    load: (state, objects) => {
      const key = state.description.obj_key
      // const loaded_objects = objects.map(row => {
      //   const r = merge({}, rebuild_object(row, state.description), {
      //     obj_id: `${row[key]}`,
      //     obj_key: `${row[key]}`,
      //     obj_type: state.obj_type
      //   })
      //   uids.push(r.obj_id.toLowerCase())
      //   return r
      // }
      // )
      if (!state.objects) {
        state.objects = [];
      }
      for (const _loaded_obj of objects) {
        let existed_obj = state.objects.find(o => o[key].toLowerCase() == _loaded_obj[key].toLowerCase());
        const created = !existed_obj;
        const row_id = _loaded_obj[key];
        if (created) {
          existed_obj = merge({}, rebuild_object(_loaded_obj, state.description), { obj_id: row_id, obj_key: row_id, obj_type: state.description });
          state.objects.push(existed_obj)
        } else {
          merge(existed_obj, rebuild_object(_loaded_obj, state.description), { obj_id: row_id, obj_key: row_id, obj_type: state.description });
        }

      }
      eventBus.$emit(`updated:${state.description.obj_type}`)
      // state.objects = state.objects.filter(o => uids.indexOf(o[key]?.toLowerCase()) == -1).concat(loaded_objects)
      state.last_update = Date.now()
    },
    update: (state, object) => {
      console.time('update_group', object)
      const obj = rebuild_object(object, state.description)
      const key = state.description.obj_key
      const obj_key = obj[key] || obj.obj_id;
      const old_index = state.objects.findIndex(o => o[key].toLowerCase() === obj_key.toLowerCase())
      const old_obj = old_index != -1 ? state.objects[old_index] : {}

      const upd_obj = merge(
        old_obj,
        obj
      )

      // state.objects = state.objects.filter(
      //   o => o[key].toLowerCase() != obj_key.toLowerCase()
      // ).concat([upd_obj])
      if (old_index == -1) {
        state.objects = state.objects.concat([upd_obj])
      } else {
        // Vue.set(state.objects, old_index, upd_obj)
        // Object.assign(state.objects[old_index], upd_obj)
        //state.objects = [...state.objects]
        state.objects.splice(old_index, 1, upd_obj)
        console.log('ipd', state.objects[old_index], upd_obj)
      }
      state.load_status = 'loaded'
      eventBus.$emit(`updated:${upd_obj.obj_type}:${upd_obj.obj_id}`)
      eventBus.$emit(`updated:group`)
      console.timeLog('update_group', '!')
      return state
    },
  },
  actions: {
    get_roles({ dispatch, getters, commit }, { obj_id, update_group: update_group = true }) {
      let ret = dispatch('requestApi', {
        url: '/roles/list/group',
        data: {
          group_id: obj_id,
          customer_id: getters.object(obj_id).customer_id
        }
      }, { root: true }
      )
      if (update_group) {
        ret = ret.then(
          r => {
            if (r.data.result == 'success') {
              const roles = r.data.payload.map(_r => _r.role_id);
              commit('update', { obj_id: obj_id, group_id: obj_id, roles })
            }
          }
        )
      }
      return ret
    },
    get({ dispatch }, obj_id) {
      return dispatch('get_roles', { obj_id })
    },
    update({ getters, dispatch }, object) {
      const obj_id = object.group_id
      const old_object = getters.object(obj_id)

      let old_members = [].concat(old_object.members || [])
      let old_roles = [].concat(old_object.roles || [])
      let promises = []

      let new_roles = [], new_members = []
      const upd_roles = object.roles;
      const upd_members = object.members;

      if (upd_roles !== undefined) {
        upd_roles.map(
          id => {
            if (old_roles.indexOf(id) == -1) {
              // добавляем на удаление
              new_roles.push(id)
              promises.push(
                dispatch(
                  'requestApi', {
                  url: 'roles/add_role',
                  data: {
                    role_id: id,
                    group_id: object.group_id,
                    customer_id: old_object.customer_id
                  }
                }, { root: true }
                )
              )
            }
            old_roles = old_roles.filter(m => m != id)
          }
        )
        old_roles.map(
          id => promises.push(
            dispatch(
              'requestApi', {
              url: 'roles/remove_role',
              data: {
                role_id: id,
                group_id: object.group_id,
                customer_id: old_object.customer_id
              }
            }, { root: true }
            )
          )
        )
      }

      if (upd_members !== undefined) {
        upd_members.map(
          id => {
            if (old_members.indexOf(id) == -1) {
              new_members.push(id)
              promises.push(
                dispatch(
                  'requestApi', {
                  url: urls.add_member,
                  data: {
                    customer_id: old_object.customer_id,
                    group_id: object.group_id,
                    employee_id: id
                  }
                }, { root: true }
                )
              )
            }
            old_members = old_members.filter(m => m != id)
          }
        )

        old_members.map((id) => {
          promises.push(
            dispatch(
              'requestApi',
              {
                url: urls.remove_member,
                data: {
                  customer_id: old_object.customer_id,
                  group_id: object.group_id,
                  employee_id: id
                }
              },
              { root: true }
            )
          )
        })
      }
      if (old_object.parent_id != object.parent_id) {
        promises.push(dispatch('requestApi', {
          url: urls.move,
          data: {
            customer_id: old_object.customer_id,
            group_id: old_object.group_id,
            from_group_id: old_object.parent_id,
            to_group_id: object.parent_id,
          }
        }, { root: true }))
      }
      return Promise.all(promises).then(
        () => dispatch('list')
      ).then(() => dispatch('get', object.group_id))
    }
  },
}

const test_group_data = undefined
export default {
  verbose: 'Группа',
  plural: 'Группы',
  // objs_comp: GroupListView,
  // obj_comp: GroupView,
  no_create_button: true,
  create_button: SmartButton,
  create_button_props: {
    osnPart: 'Создать новую',
    osnIcon: ObitIcon,
    osnIconProps: { icon: 'plus' },
    secondPart: 'группу'
  },
  urls: urls,
  obj_name: 'title',
  objs_comp: GroupListView,
  base_name: 'group',
  unique_for: 'customer',
  storage: storage,
  list_url: { name: 'settings', query: { page: 'group' } },
  fields: {
    group_id: id_field_desc,
    title: { label: 'Название', isKey: true, required: true },
    parent_id: buildRelatedField('Родительская группа', 'group', { ret_target: 'parent' }),
    children: buildRelatedField('Дочерние группы', 'group', { multiple: true }),
    members: buildRelatedField('Члены группы', 'employee', { multiple: true, options: { required: false } }),
    roles: { label: 'Роли' }
  },
  new_fields: ['title', {
    key: 'parent_id', target: 'parent_id',
    ...buildRelatedField('Родительская группа', 'group', { ret_target: 'parent', required: true })
  }],
  view_fields: ['title', 'parent_id', 'members', {
    label: 'Роли', target: 'roles',
    comp: RolesInput, options: { required: false }
  }],
  edit_fields: ['title', 'parent_id', 'members', {
    label: 'Роли', target: 'roles',
    comp: RolesInput, options: { required: false }
  }],
  list_fields_search: ['title', 'parent_id'],
  list_fields: [
    'title', 'parent_id', 'children', 'members'
  ]
}
export { test_group_data }