<template>
  <div v-if="type == 'slider'" class="inp-range-land" :style="vars">
    <label for="demo-sb"></label>
    <b-form-spinbutton
      id="demo-sb"
      class="input-range"
      v-model="val"
      :min="min"
      :max="max"
    ></b-form-spinbutton>
    <p></p>
  </div>
  <b-input-group v-else class="inp-range-land it-group" :style="vars">
    <div class="inp-range-left">
      <b-button
        class="input-btn-circle"
        @click="val > min ? (val -= step) : (val = min)"
        ><obit-icon icon="ldminus"
      /></b-button>
      <b-input
        class="input-slider"
        type="range"
        :step="step"
        :min="min"
        :max="max"
        v-model="val"
        v-if="input_range"
      />
      <span class="input-values" v-else
        >{{ list_view ? list_view[val] : val }} {{ unit }}</span
      >
      <b-button
        class="input-btn-circle"
        @click="val < max ? (val += step) : (val = max)"
        ><obit-icon icon="lgplus"
      /></b-button>
    </div>
    <div class="inp-range-right it-right">
      <b-button class="input-btn-values it-result" v-if="input_range"
        >{{ list_view ? list_view[val] : val }} {{ unit }}</b-button
      >
    </div>
  </b-input-group>
</template>



<script>
import ObitIcon from "../../components/obit/ObitIcon.vue";
export default {
  name: "inputRangeLanding",
  components: { ObitIcon },
  props: {
    value: {
      required: true,
    },
    type: {
      type: String,
      default: "slider",
    },
    step: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    list_view: {
      type: Array,
      default: undefined,
    },
    unit: {
      type: String,
      default: "",
    },
    input_range: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "black",
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", parseInt(to));
      },
    },
    vars() {
      return {
        "--color": this.color,
      };
    },
  },
};
</script>

<style lang="scss">
.inp-range-land {
  .btn.input-btn-circle {
  }
  input {
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 2px;
    background: #3e3e3e;
  }
  input[type="range"]::-webkit-slider-thumb {
    margin: -6px 0;
    -webkit-appearance: none;
    background: var(--color);
    height: 13px;
    width: 13px;
  }
  input[type="range"] {
  }
  .inp-range-left {
    display: flex;
    justify-content: space-between;
    max-width: 270px;
    width: 100%;
    @media (max-width: 1520px) {
      max-width: 220px;
    }
    // @media (max-width: 1360px) {
    //   max-width: 200px;
    // }
  }
  .input-range {
    background: transparent;
    border: none;
  }
  .input-values {
    font-weight: bold;
    font-size: 16px;
    padding-top: 3px;
  }
  .input-btn-circle {
    position: relative;
    padding: 0;
    margin: 0;
    width: 22px;
    height: 22px;
    background: transparent;
    border: none;
    border-radius: 100%;
    color: var(--color);

    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 22px;
    .btn-plus {
      position: relative;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // height: 30px;
      &::after {
        content: "-";
        position: inherit;
        // position: absolute;
        // top: -12px;
        // left: -3px;
      }
    }
    .btn-minus {
      position: relative;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // height: 30px;
      &::before {
        content: "+";
        position: inherit;
        // position: absolute;

        // top: -12px;
        // left: -4px;
      }
    }
  }
  .input-btn-circle.btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background: transparent;
  }
  .input-slider {
    max-width: 210px;
    background: transparent;
    border: none;
    @media (max-width: 1520px) {
      max-width: 160px;
    }
    // @media (max-width: 1360px) {
    //   max-width: 160px;
    // }
  }
  .input-slider input[type="range"] {
    height: 1px;
  }
  .input-group > .custom-range:focus {
    background: transparent;
    border: none;
    box-shadow: none;
  }
  .input-btn-values {
    background: transparent;
    border: none;
    color: #3e3e3e;
    padding-right: 2px;
    padding-top: 0px;
    padding-left: 4px;
  }
  .it-group {
    justify-content: center;
  }
  .it-right {
  }
  @media (max-width: 480px) {
    .inp-range-left {
      max-width: 180px;
      .input-slider {
        max-width: 120px;
      }
    }
    .inp-range-land {
    }
  }
}
</style>