<template>
  <div>
    <b-form-select
      :options="transfer_options"
      v-model="transfer_value"
      :disabled="disabled"
    />
    <b-form-group
      class="transfer-options"
      v-for="(o, indx) in inputs"
      v-bind:key="indx"
      :label="o.text"
    >
      <b-form-input
        v-model="_obj[o.target]"
        :disabled="disabled"
        @input="save_method(o.target, $event)"
      />
    </b-form-group>
  </div>
</template>

<script>
import { InputMixin } from "../../mixins";
const is_empty = (val) => [null, undefined, ""].indexOf(val) != -1;

export default {
  name: "ForwardInput",
  mixins: [InputMixin],
  props: {
    raw: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  methods: {
    save_method(key, val) {
      const method = this.raw
        ? (key, val) => (this._obj[key] = val)
        : this.parent.update_obj;
      if (this.raw && this._obj["_state"] !== "new") {
        this._obj["_state"] = "edited";
      }
      return method(key, val);
    },
  },
  computed: {
    transfer_options: () => [
      { value: 0, text: "Без переадресации" },
      { value: 1, text: "Безусловная переадресация" },
      { value: 2, text: "Переадресация по-условию" },
    ],
    inputs() {
      return [
        {
          text: "Постоянная переадресация",
          target: "transfer_always",
          val: 1,
        },
        {
          text: "Переадресация при занятой линии",
          target: "transfer_busy",
          val: 2,
        },
        {
          text: "Переадресация при таймауте",
          target: "transfer_timeout",
          val: 2,
        },
      ].filter((r) => this.transfer_value == r.val);
    },
    transfer_value: {
      get() {
        return this.val;
      },
      set(to) {
        const method = this.save_method;
        const tr_alw = ["transfer_always"];
        const tr_other = ["transfer_busy", "transfer_timeout"];
        const tr_all = [...tr_alw, ...tr_other];
        const empty = " ";
        if (to == 0) {
          tr_all.map((k) => method(k, null));
        } else if (to == 1) {
          let new_value = Object.assign({}, this._obj);
          tr_alw.map((k) =>
            method(k, is_empty(new_value[k]) ? empty : new_value[k])
          );
          tr_other.map((k) => method(k, null));
        } else if (to == 2) {
          let new_value = Object.assign({}, this._obj);
          tr_other.map((k) =>
            method(k, is_empty(new_value[k]) ? empty : new_value[k])
          );
          tr_alw.map((k) => method(k, null));
        }
        this.val = to;
        this.$forceUpdate();
      },
    },
  },
};
</script>

<style>
.transfer-options {
  margin-top: 0.5em;
}
</style>