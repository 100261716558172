export default {
  name: 'График работ',
  apiType: 'ScheduleCheck',
  objType: 'shch_check',
  edit_modal: true,
  namedOutputs: true,
  outputs: {
    allow: 'Да',
    reject: 'Нет'
  },
}