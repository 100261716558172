var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group-item',{class:['group-item', `group-item-${_vm.level}`],style:(_vm.styles),attrs:{"data-item":_vm.group_obj.obj_id}},[_c('div',{class:{
      'item-block': true,
      active: _vm.current_group.value == _vm.group_obj.obj_id,
      'text-primary': _vm.current_group.current_group == _vm.group_obj.obj_id,
    }},[_c('icon',{staticClass:"text",attrs:{"icon":_vm.icons.folder}}),_c('span',{class:{
        subitem: _vm.level > 1,
      },on:{"dblclick":_vm.get_members,"click":_vm.show_info}},[_vm._v(" "+_vm._s(_vm.group_obj.obj_name)+" ")]),(_vm.level !== 0)?_c('b-dropdown',{staticClass:"field_actions right margin-right-auto",attrs:{"size":"sm","variant":"outline-obitless","dropright":"","no-caret":"","boundary":"window","popper-opts":{ positionFixed: false }},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('icon',{attrs:{"icon":_vm.icons.actions}})]},proxy:true}],null,false,2308786509)},[_c('b-dropdown-item',{attrs:{"to":{ path: `./group/${_vm.group_obj.obj_id}` }}},[_vm._v("Редактировать")]),(_vm.allow_to_delete)?_c('b-dropdown-item',{attrs:{"variant":"danger"}},[_vm._v("Удалить")]):_vm._e()],1):_vm._e(),(_vm.has_children)?_c('b-button',{attrs:{"variant":"outline-primary ","pill":""},on:{"click":function($event){return _vm.toggle()}}},[_c('icon',{staticClass:"icon",attrs:{"icon":_vm.arrow}})],1):_c('b-button',{attrs:{"disabled":"","variant":"outline-primary","pill":""}},[_c('icon',{staticClass:"icon",attrs:{"icon":_vm.arrow}})],1)],1),_vm._l((_vm.items),function(item){return _c('group-item',{key:item.obj_id,ref:"children",refInFor:true,attrs:{"group_obj":item,"level":_vm.level + 1,"bus":_vm.bus},on:{"member_list":_vm.resend_members}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }