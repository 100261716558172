<template>
  <b-dropdown
    size="xs"
    variant="outline"
    dropleft
    no-caret
    boundary="window"
    class="dropdown-actions"
  >
    <template v-slot:button-content>
      <icon :icon="actions_icon" />
    </template>
    <b-dropdown-item
      v-for="(action, indx) in real_actions"
      v-bind:key="indx"
      v-bind="action"
      @click="click(action)"
    >
      {{ action.text
      }}<component :is="action.comp" v-if="action.comp" v-bind="action" />
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { Icon, as_icon } from "../layot/FontAwesomeIcon";
import CellVue from "../mixins/Cell.vue";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "optionsCell",
  components: { Icon },
  mixins: [CellVue],
  props: {
    icon: {
      type: Object,
      required: false,
      default: () => faEllipsisV,
    },
    actions: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    actions_icon() {
      return as_icon(this.icon);
    },
    real_actions() {
      let actions = [];
      for (const act of this.actions) {
        let new_action = Object.assign({}, act);
        if (act.rule) {
          new_action = Object.assign(new_action, act.rule(this));
        }
        actions.push(new_action);
      }
      return actions;
    },
  },
  methods: {
    click(action) {
      if (action.action) {
        action.action(this.item);
      }
    },
  },
};
</script>

<style>
</style>