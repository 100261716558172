<template>
  <b-button-group
    class="call-player"
    v-if="!has_error && (dsip || call._raw.record_url)"
  >
    <b-button
      class="call-play small-btn"
      variant="outline-primary"
      @click="toggle_pause()"
    >
      <icon :icon="this.playing ? icons.pause : icons.play" />
    </b-button>
    <!-- <b-button class="call-stop" variant="outline-primary" @click="stop()">
      <icon :icon="icons.stop" />
    </b-button> -->
    <b-button variant="outline-primary" @click="download" class="small-btn">
      <icon :icon="icons.download" />
    </b-button>

    <b-button class="call-timer" variant="outline-primary" @click.prevent>
      <!-- 0:00/0:00 -->
      <b-form-input
        v-model="audio.currentTime"
        type="range"
        class="border call-input"
        :max="duration"
        :step="0.1"
      />
      <br />
      <span>
        {{ currentTime | timeformatter }} / {{ duration | timeformatter }}
      </span>
    </b-button>
    <div class="break"></div>
    <b-button
      class="small-btn volume-btn"
      @click="toggle_volume()"
      variant="outline-primary"
    >
      <icon :icon="isMuted ? icons.muted : icons.volume" />
    </b-button>
    <b-dropdown
      no-caret
      variant="primary"
      menu-class="volume-slider"
      class="end"
    >
      <template #button-content>
        <icon :icon="icons.down" />
      </template>
      <b-form-input
        type="range"
        class="border call-input"
        min="0"
        max="100"
        v-model="volume"
      />
    </b-dropdown>
    <b-select
      class="speed"
      v-model="audio.playbackRate"
      :options="ratio_options"
    >
    </b-select>
  </b-button-group>
  <span v-else-if="!call._raw.record_url">Нет файла</span>
  <b-button v-else variant="danger">Ошибка при загрузке файла</b-button>
</template>

<script>
import {
  faChevronDown,
  faDownload,
  faPause,
  faPlay,
  faStop,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { resolveWithPromise } from "../../functions";
import { as_icon, Icon } from "../layot";
import { timeformatter } from "../../formatters";
export default {
  name: "PlayCall",
  components: { Icon },
  filters: {
    timeformatter,
  },
  mounted() {
    this.audio.addEventListener("timeupdate", this.update_time);
    if (this.dsip) {
      this.from_src(this.dsip);
    }
    if (this.buffer) {
      this.from_buffer(this.buffer);
      this.play();
    }
    this.$watch("volume", (to) => {
      const r = parseInt(to);
      this.audio.volume = r / 100;
      this.audio.real_volume = r / 100;
      this.isMuted = r == 0;
    });
    this.$watch("currentTime", (to) => {
      if (to >= this.duration && this.playing) {
        this.playing = false;
      }
    });
  },
  beforeDestroy() {
    this.audio.removeEventListener("timeupdate", this.update_time);
    this.reset();
  },
  props: {
    call_id: {
      type: String,
      required: false,
    },
    buffer: {
      required: false,
    },
    dsip: {
      required: false,
    },
  },
  data() {
    const audio = new Audio();
    return {
      audio,
      src: null,
      volume: 50,
      real_volume: 50,
      playing: false,
      currentTime: 0,
      duration: 0,
      has_error: false,
      isMuted: false,
    };
  },
  computed: {
    ratio_options: () => [
      { value: 0.5, text: "0.5x" },
      { value: 1, text: "1x" },
      { value: 1.5, text: "1.5x" },
      { value: 2, text: "2x" },
    ],
    call() {
      return this.$store.getters["call/object"](this.call_id);
    },
    icons: () => ({
      play: as_icon(faPlay),
      stop: as_icon(faStop),
      volume: as_icon(faVolumeUp),
      muted: as_icon(faVolumeMute),
      pause: as_icon(faPause),
      download: as_icon(faDownload),
      down: as_icon(faChevronDown),
    }),
  },
  methods: {
    toggle_volume() {
      this.isMuted = !this.isMuted;
      if (this.isMuted) {
        this.volume = 0;
      } else {
        this.volume = 50;
      }
    },
    from_src(item) {
      const duration = item.bill_sec;
      const src = item.url;
      this.audio.src = src;
      this.src = src;
      this.duration = duration;
    },
    from_buffer(buffer) {
      let blob = new Blob([buffer], { type: "audio/mp3" });
      let url = URL.createObjectURL(blob);
      this.audio.src = url;
      this.src = url;
    },
    update_time() {
      this.currentTime = Math.floor(this.audio.currentTime);
      if (this.audio.duration !== Infinity && this.audio.duration) {
        this.duration = Math.floor(this.audio.duration);
      }
    },
    download() {
      if (this.src) {
        this.$store.commit("add_message", {
          type: "success",
          message: "Запуск скачивания записи",
        });
        let url = document.createElement("a");
        url.download = "call_id.mp3";
        url.href = this.src;
        document.body.appendChild(url);
        url.click();
        document.body.removeChild(url);
      }
    },
    play() {
      if (!this.src) {
        this.$store
          .dispatch("call/get_audio", this.call_id)
          .then(
            (responce) => {
              if (responce.status == 200 && responce.data.byteLength) {
                // отдадим аудиобуфер
                this.from_buffer(responce.data);
                this.audio.play();
                this.playing = true;
              } else {
                this.$store.commit("add_message", {
                  type: "danger",
                  message: "Запись не найдена",
                });
                this.has_error = true;
                this.$emit("error", "Запись не найдена");
                return resolveWithPromise(false);
              }
            },
            (error) => console.error("error", error)
          )
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.audio.play();
        this.playing = true;
      }
    },
    toggle_pause() {
      if (this.playing) {
        this.audio.pause();
        this.playing = false;
      } else this.play();
    },
    reset() {
      this.stop();
      this.audio.src = null;
      this.src = null;
    },
    stop() {
      if (this.src) {
        this.audio.pause();
        this.audio.currentTime = 0;
        this.playing = false;
      }
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";

.call-player {
  border-radius: 100px;
  background: #54bae7;
  width: 100%;
  height: 100%;
  & > .btn,
  & > select,
  & > btn-group {
    height: 48px !important;
  }
  .break {
    display: none;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    border-radius: 20px;
    .break {
      flex-basis: 100%;
      height: 0;
      display: inline-flex;
    }
    .small-btn.volume-btn {
      margin-left: auto;
    }
  }

  .end {
    .dropdown-toggle {
      border-radius: 20px;
      &[aria-expanded="true"] {
        border-radius: 0px 20px 0px 0px;
      }
    }
  }
  .small-btn {
    max-width: 2em;
    margin: 0;
    padding: 0 auto;
  }
  .speed {
    background: $obit-primary;
    color: white;
    border: none;
    width: 4em;
    border-radius: 40px;
    height: 100%;
    padding-left: 0.4em;
  }
  button.btn,
  label.btn {
    color: white;
    border: none;
    &:hover,
    &:focus {
      color: white !important;
      border: none;
      background-color: $obit-primary;
      .obit-icon,
      svg {
        color: white !important;
      }
    }
    &.call-timer {
      border-left: none;
      border-right: none;
      color: #fff;
      white-space: nowrap;
      .call-input {
        border: none !important;
        border-left: none !important;
        border-right: none !important;
        border-top: 1px solid #54bae7 !important;
        border-bottom: 1px solid #54bae7 !important;
        color: #fff;
        margin-top: -5px;
      }
      span {
        margin-top: -10px;
        display: block;
      }
      &:hover {
        border: none;
        background: #54bae7;
      }
    }
  }
  .volume-slider {
    background: $obit-primary;
    border-color: $obit-primary;
    padding: 0;
    margin: 0;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-top: 10px;
    height: 3em;
    input {
      padding: 0 20px;
      margin: 0;
    }
  }
}
.call-play {
  padding: 0 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-right: none;
  color: #fff;
}
.call-play:hover {
  border: none;
  background: transparent;
}
.call-stop {
  border-left: none;
  border-right: none;
  color: #fff;
}
.call-stop:hover {
  border: none;
  background: #54bae7;
}

.call-volume {
  padding: 0 0 0 20px;
  border-left: none;
  color: #fff;
}
.call-volume:hover {
  border: none;
  background: #54bae7;
  color: #54bae7;
}
.call-input:focus {
  border: 2px solid #fff;
}
.call-input:hover {
  border: none;
  background: #54bae7;
}
input[type="range"]::-webkit-slider-thumb {
  background: #fff;
}
</style>