import { number_formatter } from "../../../formatters"
import { id_field_desc } from "@/functions.js"

const root = '/pbx/services/v2/outgoingRoute'

const urls = {
  list: `${root}/list`
}

export default {
  verbose: 'Исходящая маршрутизация',
  plural: 'Исходящая маршрутизация',
  urls: urls,
  unique_for: ['customer', 'entity'],
  obj_type: 'out_route',
  obj_template: obj => {
    return number_formatter(obj.defaultCID.number)
  },
  storage: {
    getters: {
      calced_options: () => (obj) => ({
        number: obj.defaultCID.number
      })
    }
  },
  fields: {
    service_id: id_field_desc,
    caption: { label: 'Название', isName: true },
    description: 'Комментарий',
  }
}