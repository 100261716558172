export function drag(el, getInitial = () => { }, onDrag = () => { }) {
    let start = null;
    let initial = null;
    el.addEventListener('mousedown', e => {
        initial = getInitial();
        start = [e.pageX, e.pageY];
    });

    el.addEventListener('touchstart', e => {
        initial = getInitial();
        let touch = e.changedTouches[0]
        start = [touch.pageX, touch.pageY]
    })

    window.addEventListener('mousemove', e => {
        if (!start) return;
        let [dx, dy] = [start[0] - e.pageX, start[1] - e.pageY];

        onDrag(dx, dy, initial);
    });

    window.addEventListener('touchmove', e => {
        if (!start) return;
        let touch = e.changedTouches[0]
        let [dx, dy] = [start[0] - touch.pageX, start[1] - touch.pageY];
        onDrag(dx, dy, initial);
    })

    window.addEventListener('mouseup', () => {
        start = null;
    });
    window.addEventListener('touchend', () => {
        start = null;
    })
}