import { buildBooleanField, buildOptionsField, buildRelatedField, instance_field } from '../fields'
import { number_formatter } from '../../../formatters'
import { merge } from 'lodash-es'
// import ListComp from './CityNumberList'
import SmartButton from './ButtonNew.vue'
import { booleanCell } from '../../shared/index'

const SERVICE_TYPE_CITY_LINE = 'city_number'
const root = 'pbx/services/v2/cityNumber'
const EXT_LINKS = {
  //get: `${root}/get`,
  update: `${root}/modify`,
  // add: `${root}/add`,
  list: `${root}/multiservicelist`,
  // link: `${root}/link`,
  // unlink: `${root}/unlink`,

  //delete: `${root}/update`,
}
const regions_list = [
  { regexp: /^812/, name: 'spb', human_name: 'Санкт-Петербург' },
  { regexp: /^495/, name: 'msk', human_name: 'Москва' },
  { regexp: /^843/, name: 'kzn', human_name: 'Казань' },
]
const region_options = regions_list.reduce(
  (r, { name, human_name }) => merge(r, { [name]: human_name }),
  { unknown: 'Неизвестный регион', all: 'Все' }
)

const storage = {
  getters: {
    objects: (state, getters, rootState, rootGetters) => {
      let objs = state.objects
      const key = 'instance_id'
      const un = rootGetters['current_instance']
      objs = objs.filter(o => o[key] == un)
      return objs.map(r => getters.restore_object(r))
    },
    post_data: (state, getters, rootState, rootGetters) => (data, type = 'list') => {
      let for_type = getters[`post_data_${type}`] ? getters[`post_data_${type}`](data) : {}
      // (EXTline) допишем тестовые данные если есть
      let test_data = getters.test_data
      let ret = merge({}, test_data, data, getters.unique_for, for_type, {
        instance_id: rootGetters.current_instance,
        entity_id: rootGetters.current_entity
      })
      delete ret['namespace']
      return ret
    },
    calced_options: (state, getters, rootState, rootGetters) => (item) => {
      let ret = 'unknown'
      for (const { regexp, name } of regions_list) {
        if (regexp.test(item.number)) {
          ret = name
          break
        }
      }
      const out_route = rootGetters['out_route/objects'].filter(o => o.number == item.number).map(o => o.obj_id)
      let calls = rootGetters['internal_line2/objects'].filter(l => out_route.indexOf(l.outgoingRoute) > -1).length > 0
      return { region: ret, status: item.state.availableToUse, allow_calls: calls, entity_id: item.meta.entity_id }
    }
  },
  actions: {
    send_request({ dispatch, getters }, selected) {
      return Promise.all(
        selected.map(({ address_id, address_details, number }) => dispatch(
          'requestApi',
          {
            url: '/pbx/services/v2/cityNumber/request',
            data: {
              ...getters.unique_for,
              address_id, address_details, number
            }
          },
          { root: true }
        ))
      )
    },

    alien({ dispatch, getters }, data) {
      return dispatch(
        'requestApi',
        {
          url: '/pbx/services/v2/cityNumber/alien',
          data: { ...getters.unique_for, ...data }
        },
        { root: true }
      )
    },

    termination({ dispatch, getters }, data) {
      return dispatch(
          'requestApi',
          {
            url: '/pbx/services/v2/sipTermination/create',
            data: { ...getters.unique_for, ...data }
          },
          { root: true }
      )
    }
  }
}

const EXT_DESC = {
  urls: EXT_LINKS,
  obj_key: 'service_id',
  verbose: 'Городской номер',
  plural: 'Городские номера',
  no_create_button: true,
  create_button: SmartButton,
  new_default: {},
  delete_key: 'date_stop',
  no_trash_button: true,
  list_data: {
    service_type: SERVICE_TYPE_CITY_LINE
  },
  unique_for: ['customer', 'entity'],
  page_variant: 'sip',
  obj_name: 'number',

  // objs_comp: ListComp,
  create_modal_props: {
    'footer-bg-variant': 'light'
  },

  storage: storage,
  fields: {
    service_id: {
      label: "ID",
      options: {
        required: true,
        disabled: true
      },
      editable: false
    },
    entity_id: buildRelatedField(
      'Договор', 'entity', { editable: false }
    ),
    instance_id: instance_field,
    caption: 'Комментарий',
    lines: 'Кол-во входящих линий',
    output_lines: 'Кол-во исходящих линий',
    isVirtual: buildBooleanField('Виртуальный', false, { options: { disabled: true } }),
    billing_region: 'Номер региона',
    region: buildOptionsField('Регион', region_options),
    number: {
      label: 'Номер телефона',
      editable: false,
      isKey: true,
      required: true,
      list_options: { // опциональные папаметры для b-table
        sortable: true,
        formatter: number_formatter
      },
      options: {
        type: 'number'
      }
    },
    date_stop: 'Остановлено',
  },
  list_fields: [
    'number',
    'region',
    'caption',
    // { label: 'Количество входящих линий', key: 'lines' },
    { label: 'Исходящая связь', key: 'allow_calls', cell: booleanCell, related: ['internal_line2', 'out_route'] },
    { label: 'Статус', key: 'status', target: 'status', cell: booleanCell }
  ],
  view_fields: ['number', 'caption', 'isVirtual'],
  edit_fields: ['number', 'caption'],
  list_fields_search: ['number'],
}
export { EXT_DESC, EXT_LINKS, SERVICE_TYPE_CITY_LINE }
