<template>
  <b-input-group>
      <b-form-input v-model="password" :type='type' v-bind='$attrs'/>
      <b-input-group-append>
          <b-button variant="outline-primary" @click="show_password = !show_password">
              <icon :icon='icon'/>
          </b-button>
      </b-input-group-append>
  </b-input-group>
</template>

<script>
import { 
    BInputGroup, BInputGroupAppend, BFormInput,
    BButton
} from "bootstrap-vue";
import Icon, {as_icon} from './FontAwesomeIcon'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
export default {
    name: 'passwordInput',
    components: {BInputGroup, BInputGroupAppend, BFormInput, BButton, Icon},
    props: ['value'],
    data() {return {
        show_password: false
    }},
    computed: {
        icon() {
            return !this.show_password ? as_icon(faEye) : as_icon(faEyeSlash)
        },
        type() {
            return this.show_password ? 'text' : 'password'
        },
        password: {
            get() {
                return this.value
            },
            set(to) {
                this.$emit('input', to)
            }
        }
    }
}
</script>

<style>

</style>