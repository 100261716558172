<template>
  <b-input-group>
    <b-form-input v-model="val" :disabled="disabled" />
  </b-input-group>
</template>

<script>
import { type_val } from "../../functions";
import { format } from "date-fns";
export default {
  name: "DateTimePicker",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      required: true,
      type: [String, Number],
    },
    timeFormat: {
      required: false,
      type: String,
      default: "HH:mm:ss",
    },
    dateFormat: {
      required: false,
      type: String,
      default: "yyyy.MM.dd",
    },
    datePosition: {
      required: false,
      type: Number,
      default: 0,
    },
    timePosition: {
      required: false,
      type: Number,
      default: 1,
    },
    splitDateTime: {
      required: false,
      type: String,
      default: " ",
    },
    state: {
      required: false,
      default: null,
      type: Boolean,
    },
  },
  computed: {
    val: {
      get() {
        let d, t;
        if (type_val(this.value) == "String") {
          [d, t] = this.value.split(this.splitDateTime);
        } else {
          const frmt = `${this.dateFormat}${this.splitDateTime}${this.timeFormat}`;
          const from_number = format(this.value, frmt);
          [d, t] = from_number.split(this.splitDateTime);
        }
        return [d, t].join(this.splitDateTime);
      },
    },
  },
};
</script>

<style>
</style>