<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
  >
    <!--- hide title <div class="title">{{node.name}}</div> -->
    <!-- Outputs-->
    <Socket
      v-for="output in inputs()"
      :key="output.key"
      v-socket:output="output"
      type="input"
      :class="['input-socket', output.key]"
      :socket="output.socket"
    />
    {{ get_data(node.data) }}
    <b-card-title class="title"> Входящий звонок </b-card-title>
    <h2 class="name" v-if="service">
      {{ service.obj_name | phone }}
    </h2>
    <Socket
      v-for="output in outputs()"
      :key="output.key"
      v-socket:output="output"
      type="output"
      :class="['output-socket', output.key]"
      :socket="output.socket"
    />
    <b-modal v-model="modal_show" title="настройки">
      <div
        class="control"
        v-for="(control, _i) in controls()"
        :key="_i"
        v-control="control"
      />
    </b-modal>

    <!-- Inputs-->
    <!-- <div class="input" v-for="input in inputs()" :key="input.key">
      <Socket
        v-socket:input="input"
        type="input"
        :socket="input.socket"
      ></Socket>
      <div class="input-title" v-show="!input.showControl()">
        {{ input.name }}
      </div>
      <div
        class="input-control"
        v-show="input.showControl()"
        v-control="input.control"
      ></div>
    </div> -->
  </b-card>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { as_icon, Icon } from "../../../../layot";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { number_formatter } from "@/formatters";
import { eventBus } from "../../../..";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";
export default {
  name: "ExtLineComp",
  mixins: [ComponentMixinVue],
  components: {
    Socket: VueRenderPlugin.Socket,
    Icon,
  },
  filters: {
    phone: number_formatter,
  },
  methods: {
    arrange() {
      eventBus.$emit("rearange", this.node);
    },
  },
  computed: {
    config: () => config,
    icons: () => ({
      gear: as_icon(faCog),
    }),
    real_show() {
      return this.local_data.show;
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.node.city_number-node {
  .name {
    font-size: 2em;
    margin-bottom: 0;

    font-weight: 300;
    .output-socket {
      float: right;
      top: -11px;
      position: relative;
      left: 16px;
    }
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -24px !important;
    }
  }
  .output-socket.output {
    display: inline-block;
    position: relative;
    left: calc(100% - 18px);
    &.limit {
      background-color: $obit-danger !important;
    }
  }
}
</style>