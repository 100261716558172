
export default {
  name: 'Голосовое сообщение',
  apiType: 'PlayFile',
  objType: 'play_file',
  outputs: { 'default': null },
  textSearch: (node, regex) => {
    const service = node.vueContext?.service;
    let text = service?.obj_name || '';
    text += ` ${service?.caption} ${service.description}`
    const mediaIdIobj = service?._related('mediaFileId');
    if (mediaIdIobj) {
      text += `${mediaIdIobj.description} ${mediaIdIobj.title} ${mediaIdIobj.pbx_file_name}`;
    }
    return text && text.search(regex) != -1;
  },
}