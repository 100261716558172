import { dateformatter } from "../../../formatters";
import { buildBooleanField } from "../fields";
import SpecsList from './SpecsList'
import nameCell from './nameCell'
import typeCell from './typeCell'

const SPECS_URLS = {
  list: '/contract/specifications'
}

export default {
  verbose: 'Спецификация',
  plural: 'Спецификации',
  obj_type: 'spec',
  no_create_button: true,
  unique_for: ['customer', 'contract'],
  urls: SPECS_URLS,
  objs_comp: SpecsList,
  obj_name: 'name',
  obj_key: 'date',
  fields: {
    date: { label: 'Дата', editable: false, table_formatter: dateformatter, sortable: true },
    name: { label: 'Название и абонент', isName: true, isKey: true, cell: nameCell, sortable: true },
    active: buildBooleanField('Статус'),
    services: {
      label: 'Сервисы',
      multiple: true
    }
  },
  list_fields: [
    'name', 'date', {
      label: 'Тип',
      key: 'type', field: 'type', target: 'type',
      cell: typeCell
    }, 'active'
  ]
}