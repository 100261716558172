<template>
  <span class="material-icons-outlined">
    <slot />
  </span>
</template>

<script>
export default {
  name: "MDIcon",
};
</script>
