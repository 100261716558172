/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc'
import OidcCallback from '@/views/auth/OidcCallback.vue'
import OidcCallbackError from '@/views/auth/OidcCallbackError.vue'
import License from '@/views/auth/License.vue'
import Obj from '../views/Obj.vue'
import ObjList from '../views/ObjList.vue'
import { OBJ_COMPONENTS } from '@/constants'
import TeleDash from '../views/TeleDash.vue'
import Settings from '../views/Settings.vue'
import NotFound from '../views/404.vue'
import ObitPage from '../views/Page.vue'
import DefObjectsListVue from '../components/objects/DefObjectsList.vue'
import { SIP_MENU } from '@/constants/menu.js'
import { MediaList } from '../components/objects/media'
import { CallsHistory } from '../components/calls'
import { SelectNumber, tarifDetail, tarifView } from '../components/auth'
import ConfirmNumers from '../components/auth/confirm_numbers.vue'
import { RouteList } from '../components/routing'
import InternalLineObj from '../components/objects/InternalLine/InternalLineObj.vue'
import DefObject from '../components/objects/DefObject.vue'
import TariffSettings from '../views/tariff/TariffSettings.vue'
// import IntegrationSetting from '../views/tariff/IntegrationSetting.vue'
// import IncomingCall from '../views/tariff/IncomingCall.vue'
// import RecordingCall from '../views/tariff/RecordingCall.vue'
// import ResponsibleManagerCall from '../views/tariff/ResponsibleManagerCall.vue'
// import OutgoingCall from '../views/tariff/OutgoingCall.vue'
//import integrationPage from '../views/tariff/integrationPage.vue'
import IntegrationSettings from '../components/objects/integration/IntegrationSettings.vue'
import Pbx from '../views/pbx/Pbx.vue'
import AboutLicense from '../views/pbx/AboutLicense.vue'

Vue.use(VueRouter)
const API_OBJ = Object.keys(OBJ_COMPONENTS).join('|')

const default_path = {
  name: 'sipdash',
}

const SIP_SETTINGS_LINKS = [
  { link: ['ivr'], text: 'Голосовое меню' },
  { link: ['shch_ref'], text: 'Расписания' },
  // { link: ['shch_check'], text: '#проверки гр' },
  { link: ['play_file'], text: 'Голосовое сообщение', description: 'Голосовые уведомления, которые вы можете использовать при настройке маршрутизации звонков в вашей виртуальной АТС. Создавайте новые голосовые сообщения и используйте их в разделе маршрутизации.' },
  { link: ['disa'], text: 'Донабор номера', description: 'Дополнительная услуга, которая позволит сообщить звонящему о возможности донабрать внутренний номер вашего сотрудника или группы и перевести на этого сотрудника' },
  // { link: ['hangup'], text: 'HangUP' },
  // { text: 'Тэги' },
  // { link: ['shch'], text: 'Графики работы' },
  { link: ['media'], text: 'Медиафайлы', description: 'Загрузите файл в формате mp3 с голосовым приветствием или мелодией, который будет проигрываться при звонке на ваш внешний номер до ответа или прекращения вызова' },
  // { link: ['hangup'], text: 'HangUP' },
  { link: ['moh'], text: 'Музыка на удержании' },
  // { text: 'Тэги' },
  // { text: 'Безопасность' },
]

if (process.env.VUE_APP_IS_SETTINGS_EMPLOYEE !== `false`) {
  SIP_SETTINGS_LINKS.unshift({
    link: ['employee'],
    text: 'Пользователи',
  })
}

if (process.env.VUE_APP_IS_SETTINGS_PHYSICAL_LINES !== `false`) {
  SIP_SETTINGS_LINKS.push({
    link: ['physical'],
    text: 'Физические линии'
  })
}

// const auth_path = { name: 'auth' }

// const ifNotAuthenticated = (to, from, next) => {
//   next(vm => {
//     const status = vm.$store.getters.auth_status()
//     let ret = null
//     if (status != 'notauth') {
//       ret = default_path
//     }
//     return ret
//   })
// }

// const ifAuthenticated = (to, from, next) => {
//   let path = undefined
//   const status = store.getters.auth_status()
//   store.dispatch('update_user_info')
//   if (status == 'notauth' || !store.getters.is_auth) {
//     path = auth_path
//   }
//   next(path)
// }

const SIP_PART = {
  path: '/sip',
  name: 'Sip',
  component: ObitPage,
  props: { menu: SIP_MENU },
  children: [
    {
      path: '',
      redirect: 'dash',
      name: 'sipdash'
    },
    {
      path: 'dash',
      component: TeleDash
    },
    {
      path: 'call',
      component: Settings,
      props: {
        links: [
          { link: ['external'], text: 'Клиентские' },
          { link: ['internal'], text: 'Внутренние' },
          { link: ['missing'], text: 'Пропущенные' },
          // { text: 'Статистика' },
        ],
        header: 'Звонки'
      },
      children: [
        {
          path: '',
          redirect: '/sip/call/external',
        },
        {
          path: 'external',
          component: CallsHistory,
          props: {
            type: 'external'
          }
        },
        {
          path: 'missing',
          component: CallsHistory,
          props: {
            type: 'external',
            only_status: {
              incoming: ['CANCEL']
            }
          }
        },
        {
          path: 'internal',
          component: CallsHistory,
          props: {
            type: 'internal'
          }
        }
      ]
    },
    {
      path: 'tariff_settings',
      name: 'tariff_settings',
      component: TariffSettings
    },
    {
      path: 'integration/:id',
      name: 'integration_page',
      component: Settings,
      //props: route => route.params,
      props: {
        header: 'Интеграция',
        //title: 'Заголовок',
        links: [
          { text: 'Информация', link: ['info'] },
          { text: 'Таблица интеграции', link: ['users'] },
          { text: 'Настройки', link: ['settings'] }
        ],
      },
      children: [
        {
          path: '',
          redirect: './info'
        },
        {
          path: 'info',
          component: DefObject,
          // props: route => route.params,
          name: 'integration_edit',
          props(route) {
            //console.log('route', route)
            return {
              obj_id: route.params.id,
              obj_type: 'integration',
            }
          }
        },
        {
          path: 'users',
          component: TariffSettings,
          props(route) {
            return { obj_id: route.params.id }
          }
        },
        {
          path: 'settings',
          component: IntegrationSettings,
          props: route => ({
            obj_id: route.params.id
          })
        }
      ],
      beforeEnter: (to, from, next) => {
        if (process.env.VUE_APP_IS_INTEGRATION !== `false`) {
          next()
        } else {
          next(default_path)
        }
      }
    },

    /* {
      path: 'integration_setting',
      name: 'integration_setting',
      component: IntegrationSetting,
      props: {
        header: 'Настройки параметров интеграции',
        links: [
          { text: 'Запись звонка', link: ['recording_call'] },
          { text: 'Входящий звонок', link: ['incoming_call'] },
          { text: 'Ответственный менеджер', link: ['responsible_manager_call'] },
          { text: 'Исходящий звонок', link: ['outgoing_call'] },
          { text: 'new_page', link: ['new_page'] },
        ],

      },
      children:[
        {
          path: '',
          //component: RecordingCall,
          redirect: '/sip/integration_setting/recording_call',
        },
        {
          path: 'recording_call',
          component: RecordingCall,
        },
        {
          path: 'incoming_call',
          component: IncomingCall,
        },
        {
          path: 'responsible_manager_call',
          component: ResponsibleManagerCall,
        },
        {
          path: 'outgoing_call',
          component: OutgoingCall,
        },
        {
          path: 'new_page',
          component: integrationPage,
        }

        // { path: 'ResponsibleManagerCall', component: ResponsibleManagerCall },
      ]

    },
 */

    {
      path: 'routing',
      name: 'editor',
      component: RouteList
    },
    {
      path: 'city_number/new',
      component: SelectNumber,
      name: 'new_number'
    }, {
      path: 'city_number/confirm',
      name: 'confirm_number',
      component: ConfirmNumers,
      props: () => {
        //console.log(route)
        return {
          numbers: []
        }
      }
    },
    {
      path: 'schedule',
      component: DefObjectsListVue,
      props: { obj_type: 'shch' }
    },
    {
      path: 'schedule/:id',
      component: Obj,
      props: route => ({ obj_type: 'shch', obj_id: route.params.id })
    },
    {
      path: 'tariff',
      component: Settings,
      props: {
        header: 'Тариф',
        links: [
          { text: 'Ваш тариф', link: ['info'], description: 'Описание вашего тарифа, текущие начисления за оказанные услуги' },
          { text: 'Детализация звонков', link: ['calls'] },
          //{ text: 'Детализация звонков' },
          { text: 'Детализация услуг', },
        ],
      },
      children: [
        { path: '', redirect: 'info' },
        { path: 'info', component: tarifView },
        { path: 'calls', component: tarifDetail },
        // { path: 'service', component: tarifService }
      ],
      beforeEnter: (to, from, next) => {
        if (process.env.VUE_APP_IS_TARIFF !== `false`) {
          next()
        } else {
          next(default_path)
        }
      }
    },
    {
      path: 'internal_line2',
      component: ObjList,
      name: 'internalLine',
      props: { obj_type: 'internal_line2' }
    },
    {
      path: 'internal_line2/:obj_id',
      component: InternalLineObj,
      name: 'internalLineObj',
      props: (route) => ({ obj_type: 'internal_line2', obj_id: route.params.obj_id })
    },
    {
      path: `:type(${API_OBJ})`,
      component: ObjList,
      name: 'objectList',
    },

    {
      path: `:type(${API_OBJ})/:id`,
      component: Obj,
      name: 'object',
      props: {
        hide_header: false,
        transfer_header: false
      },
      meta: {
        isObj: true
      }
    },
    {
      path: 'settings',
      component: Settings,
      name: 'settings',
      title: 'Настройки',
      props: {
        header: 'Настройки',
        links: SIP_SETTINGS_LINKS
      },
      children: [
        {
          path: '',
          redirect: '/sip/settings/media'
        },
        {
          path: 'media',
          component: MediaList,
        },
        {
          path: `:type(${API_OBJ})`,
          component: DefObjectsListVue,
          props: route => ({ obj_type: route.params.type, show_header: false })
        },
        {
          path: `:type(${API_OBJ})/:id`,
          component: Obj,

          meta: {
            isObj: true
          }
        },
      ]
    },

    {
      path: 'pbx',
      component: Pbx,
      children: [
        {
          path: '',
          redirect: 'about'
        },
        {
          path: 'about',
          component: AboutLicense,
        },
      ],
      beforeEnter: (to, from, next) => {
        if (process.env.VUE_APP_ABOUT_PROGRAMM === `true`) {
          next()
        } else {
          next(default_path)
        }
      }
    }
  ]
}


const routes = [
  {
    path: '/',
    redirect: '/sip'
  },
  SIP_PART,
  {
    path: '/license',
    name: 'license',
    component: License
  },
  {
    path: '/oidc-callback',
    name: 'oidcCallback',
    component: OidcCallback
  },
  {
    path: '/oidc-callback-error',
    name: 'oidcCallbackError',
    component: OidcCallbackError,
    meta: {
      isPublic: true
    }
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  base: process.env.BASE_URL
})

router.beforeEach(vuexOidcCreateRouterMiddleware(store, `oidcStore`))

// router.beforeEach(async (to, from, next) => {
//   // if () {}
//   console.log(to, 'tototototo')
//   next()
// })

export default router
export { router, routes, default_path }