import { merge, uniqBy } from "lodash"
import { id_field_desc } from "@/functions.js"
import { SmartButton } from "../../layot"
import { ObitIcon } from "../../obit"
import { buildRelatedField } from "../fields"

const root = 'roles'
const urls = {
  add: 'create',
  // get: 'info',
  // list: 'list',
  update: 'update',
  list: 'list/customer',
  delete: 'delete',
}
const urls_perm = {
  list: 'permissions',
}

const PERM_DESC = {
  verbose: 'Разрешение',
  plural: 'Разрешения',
  unique_for: 'customer',
  urls: Object.entries(urls_perm)
    .reduce(
      (r, [n, u]) => Object.assign(r, { [n]: `${root}/${u}` }),
      {}
    ),


  storage: {
    actions: {
      list({ dispatch, commit, getters }) {
        return dispatch('requestApi', {
          url: getters.description.urls.list,
          data: getters.pre_data({}, 'list')
        }, { root: true }).then(
          r => {
            if (r.data.result == 'success') {
              const data = r.data.payload
              const to_save = uniqBy(Object.values(data).flat().map(d => getters.post_data(d, 'list')), 'name')
              commit('load', to_save)
              return to_save
            }
          }
        )
      },
      get({ commit, getters, dispatch }, object_id) {
        const get_api = getters.has_api.get

        return dispatch('requestApi', {
          url: get_api,
          data: getters.pre_data({
            [getters.description.obj_key]: object_id
          }, 'get')
        }, { root: true }).then(
          d => {
            if (d.data.result == 'success') {
              commit('update', getters.post_data(d.data.payload, 'get'))
              return d.data.payload
            }
          }
        )
      },
    }
  },
  fields: {
    name: {
      isKey: true,
      label: 'Строка'
    },
    title: {
      isName: true,
      label: 'Название'
    },
  }
}

export default {
  verbose: 'Роль',
  plural: 'Роли',
  unique_for: 'customer',
  no_uniq_get: true,
  no_uniq_update: true,

  no_create_button: true,
  create_button: SmartButton,
  create_button_props: {
    osnPart: 'Создать новую',
    osnIcon: ObitIcon,
    osnIconProps: { icon: 'plus' },
    secondPart: 'роль'
  },

  list_url: { name: 'settings', query: { page: 'role' } },
  storage: {
    actions: {
      list({ dispatch, commit, getters }) {
        return dispatch('requestApi', {
          url: getters.description.urls.list,
          data: getters.pre_data({}, 'list')
        }, { root: true }).then(
          r => {
            if (r.data.result == 'success') {
              const data = r.data.payload
              let to_save = data
              to_save.permissions = to_save.map(
                r => getters.post_data(
                  merge(
                    r,
                    { permissions: r.permissions.map(p => p.name) },
                    getters.unique_for
                  ), 'list'
                )
              )
              commit('load', to_save)
              return to_save
            }
          }
        )
      },
      add({ getters, dispatch }, object) {
        const get_api = getters.has_api.add
        return dispatch(
          'requestApi',
          {
            url: get_api,
            data: getters.pre_data(object, 'add')
          }, { root: true }
        ).then(
          d => {
            if (d.data.result == 'success') {
              return dispatch('list')
            }
            return d.data.payload
          }
        )
      },
      // get({ getters, dispatch }, object_id) {
      //   const get_api = getters.has_api.get
      //   return dispatch('requestApi', {
      //     url: get_api,
      //     data: getters.pre_data({
      //       [getters.description.obj_key]: object_id
      //     }, 'get')
      //   }, { root: true }).then(
      //     d => {
      //       if (d.data.result == 'success') {
      //         return dispatch('list')
      //       }
      //     }
      //   )
      // },
      update({ getters, dispatch }, object) {
        const get_api = getters.has_api.update
        const old_obj = getters.object(object.role_id)
        //debugger; // eslint-disable-line no-debugger
        return dispatch(
          'requestApi',
          {
            url: get_api,
            data: { ...getters.pre_data(object, 'update'), title: old_obj.title }
          }, { root: true }
        ).then(
          d => {
            if (d.data.result == 'success') {
              return dispatch('list')
            }
            return d.data.payload
          }
        )
      }
    },

  },
  urls: Object.entries(urls)
    .reduce(
      (r, [n, u]) => Object.assign(r, { [n]: `${root}/${u}` }),
      {}
    ),
  fields: {
    role_id: id_field_desc,
    title: { label: 'Название', isName: true, isKey: true, required: true },
    permissions: buildRelatedField(
      'Разрешения', 'perm', { multiple: true }
    )
  },
  new_fields: ['title', 'permissions'],
  edit_fields: ['title', 'permissions'],
  list_fields: ['title', 'permissions'],
  view_fields: [
    'title',
    'permissions'
  ],
  list_fields_search: ['title', 'permissions']
}
export { PERM_DESC }