<template>
  <b-form-checkbox v-bind="{ ...$attrs }" :disabled="disabled" v-model="val" />
</template>

<script>
import { InputMixin } from "../mixins";
export default {
  name: "boolInput",
  mixins: [InputMixin],
};
</script>

<style>
</style>