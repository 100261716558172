<template>
  <b-overlay :show="!fetched" blur="5px" opacity="0.4">
    <template #overlay>
      <b-spinner variant="warning" style="width: 10rem; height: 10rem" />
    </template>
    <editor
      class="router_editor"
      ref="editor"
      :data="route_data"
      :additional_data="additional_data"
      v-if="fetched"
      @save="save_data"
      @reload="load_data()"
      @restart="restart"
    />
    <div v-else class="router_editor bg-white"></div>
  </b-overlay>
</template>

<script>
import Editor from "./Editor/Editor.vue";
export default {
  name: "RouteList",
  components: { Editor },
  mounted() {
    this.load_data();
    this.$watch(
      () => this.$store.getters.current_entity,
      () => {
        this.route_data = [];
        this.fetched = false;
        this.has_error = false;
        this.load_data();
      }
    );
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.editor) {
      if (this.$refs.editor.has_changed_data) {
        this.$store.commit("add_message", {
          type: "danger",
          message: "Есть не сохраненные изменения. Нажмите кнопку 'Сохранить'",
        });
        return;
      }
    }
    next();
  },
  data() {
    return {
      route_data: [],
      fetched: false,
      has_error: false,
      additional_data: [],

      do_restart: false,
    };
  },
  methods: {
    restart() {
      this.fetched = false;
      this.$store.commit("add_message", {
        type: "info",
        message: "Перезагрузка",
      });
      setTimeout(() => {
        this.$store.commit("routing/reset");
        this.fetched = true;
      }, 50);
    },
    load_data() {
      this.fetched = false;
      this.load_related_data().then(() =>
        this.$store
          .dispatch("requestApi", {
            url: "/pbx/services/v2/routing/schema",
            data: {
              entity_id: this.$store.getters.current_entity,
              customer_id: this.$store.getters.current_customer,
              instance_id: this.$store.getters.current_instance,
            },
          })
          .then(
            (resp) => {
              if (resp.status == 200) {
                this.route_data = resp.data.payload;
                this.fetched = true;
              } else {
                this.$store.commit("add_message", {
                  type: "danger",
                  message: "Ошибка при загрузке данных",
                });
              }
            }
            // (error) => {
            //   console.error("error", error);
            // }
          )
      );
    },
    load_related_data() {
      const types = {
        internal_line: "internal_line2",
        group: "pbx_group",
        ScheduleCheck: "shch_check",
        IVR: "ivr",
        PlayFile: "play_file",
        Hang: "hangup",
        DISA: "disa",
        CRMIntegrations: "integration",
        SIPTermination: "SIPTermination"
      };
      const existed_groups = [...Object.values(types), "city_number"];
      return Promise.all([
        // ...existed_groups.map((g) => this.$store.dispatch(`${g}/list`)),
        process.env.VUE_APP_IS_EMPLOYEE !== `false` ? this.$store.dispatch("employee/list") : true,
        this.$store
          .dispatch("requestApi", {
            url: "/pbx/services/v2/routing/services",
            data: {
              entity_id: this.$store.getters.current_entity,
              customer_id: this.$store.getters.current_customer,
              instance_id: this.$store.getters.current_instance,
            },
          })
          .then((resp) => {
            let data_to_load = {};
            let unknown_data = [];
            for (const service of resp.data.payload) {
              const t = types[service.service_type] || service.service_type;
              let current_data = data_to_load[t] || [];
              current_data.push(service);
              data_to_load[t] = current_data;
            }
            //this.resp2 = data_to_load;
            // загрузим

            for (const [type, objects] of Object.entries(data_to_load)) {
              if (existed_groups.indexOf(type) > -1) {
                // console.log(type, 'type')
                //
                // this.$store.commit(
                //   `${type}/load`,
                //   objects.map((o) => {
                //
                //
                //     console.log(this.$store.getters[`${type}/post_data`], 'DATA')
                //     console.log(o, '0000000')
                //     console.log(this.$store.getters[`${type}/post_data`](o), 'res')
                //     return this.$store.getters[`${type}/post_data`](o)
                //       }
                //   )
                // );
                this.$store.commit(
                  `${type}/load`,
                  objects.map((o) =>
                    this.$store.getters[`${type}/post_data`](o)
                  )
                );
              } else {
                const objs = objects.map((o) => ({
                  ...o,
                  obj_type: type,
                  obj_id: o.service_id,
                  obj_name: `${o.caption}`,
                }));
                unknown_data = unknown_data.concat(objs);
              }
            }
            this.additional_data = { ...data_to_load, unknown: unknown_data };
          }),
        this.$store.dispatch("shch_ref/list"),
        this.$store.dispatch("media/list"),
      ]);
    },
    save_data(data) {
      this.$store
        .dispatch("requestApi", {
          url: "/pbx/services/v2/routing/modify",
          data: {
            entity_id: this.$store.getters.current_entity,
            instance_id: this.$store.getters.current_instance,
            customer_id: this.$store.getters.current_customer,
            items: data,
          },
        })
        .then((resp) => {
          if (resp.data.result == "success") {
            this.$store.commit("add_message", {
              type: "success",
              message: "Успешно сохранено",
            });
          } else {
            this.$store.commit("add_message", {
              type: "danger",
              message: "Ошибка при сохранении",
            });
          }
        });
    },
  },
};
</script>

<style>
.router_editor {
  height: calc(100vh - 230px) !important;
}
</style>