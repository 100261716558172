const alwaysShow = ["TimeoutAction"]

export default {
  name: 'IVR',
  apiType: 'IVR',
  objType: 'ivr',
  alwaysShow,
  outputs: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '*', '#', "OnHang", ...alwaysShow].reduce(
    (r, v) => Object.assign(r, { [`${v}`]: `${v}` }), {}
  ),
  save_data: (node) => {
    console.log('save ivr')
    let outputs = []
    for (const [name, { connections }] of Object.entries(node.outputs || {})) {
      let connection = { key: name, service_id: null }
      if (connections.length) {
        const c = connections[0]
        connection.service_id = c.node
      }
      outputs.push(connection)
    }
    return outputs
  }

}