

<template>
  <div class="intLineObj">
    <b-row>
      <b-button variant="outline-obitless" :to="link([], -1)">
        <obit-icon icon="back" /> Все внутренние номера
      </b-button>
      <br />
      <br />
      <br />
    </b-row>
    <b-row id="name_row">
      <span id="name" class="col">
        <h3>
          {{ obj.obj_name }}
          
          <is-active
            class="active_comp"
            :item="obj"
            :field="{ target: 'state.availableToUse' }"
            :rule="(v) => (v ? true : false)"
          />
        </h3>
      </span>
    </b-row>
    <b-tabs class="fix-tabs" v-model="tabIndex" v-if="obj_id !== 'new'" lazy>
      <b-tab title="Информация">
        <br />
        <def-object :obj_id="obj_id" obj_type="internal_line2" hide_header />
      </b-tab>
      <b-tab title="Звонки" lazy>
        <div class="internal-call-list">
          <calls-history type="line" :exten="obj.number" />
        </div>
      </b-tab>
      <b-tab v-if="isOperationsHistory" title="История операций">
        <br />
        <obj-list
          obj_type="change_event"
          :addition_filter="events_filter"
          :fields="['datetime', 'user', 'event']"
        />
      </b-tab>
    </b-tabs>
    <component :is="comp" v-else :obj_id="obj_id" :obj_type="obj_type" />
  </div>
</template>

<script>
import ObjList from "../ObjList";
import DefObject from "../DefObject";
import isActive from "../../shared/activeComp";
import { IBox } from "../../layot";
import ObitIcon from "../../obit/ObitIcon.vue";
import CallsHistory from "../../calls/CallsHistory.vue";

const type = "internal_line2";
export default {
  name: "internalLineObj",
  props: ["obj_id"],
  components: { ObjList, isActive, IBox, ObitIcon, CallsHistory, DefObject },
  inject: ["link"],
  data() {
    const indexes = ["info", "calls", "history"].reduce(
      (ret, row, indx) => Object.assign(ret, { [row]: indx }),
      {}
    );
    const position = this.$route.query.page || "info";
    return { tabIndex: indexes[position] || 0 };
  },
  computed: {
    obj_type: () => {
      return type;
    },
    obj() {
      return this.$store.getters[`${type}/object`](this.obj_id) || {};
    },
    comp: () => DefObject,

    isOperationsHistory() {
      return process.env.VUE_APP_INTERNAL_HISTORY_OPERATIONS !== `false`
    }
  },
  methods: {
    events_filter(row) {
      return row.event_type == "internal_line2";
    },
    calls_filter() {
      return {
        ext: this.obj.line_number,
      };
    },
    call_data() {
      return {
        owner: this.obj_id,
      };
    },
  },
};
</script>

<style lang='scss'>
.intLineObj {
  .fix-tabs {
    .nav-tabs {
      flex-wrap: nowrap;
      li {
        white-space: nowrap;
      }
    }
  }
  background-color: white;
  border-radius: 3px;
  .internal-call-list {
    padding-left: 15px;
    .input-group.search-input-group {
      max-width: 250px;
    }
  }
  #name_row {
    padding-left: 1em;
    h3 {
      display: flex;
    flex-flow: column wrap;
      font-weight: 700;
      font-size: 1.25rem;
      .active_comp {
        margin-top: 0.5em;
        font-size: initial;
        vertical-align: middle;
      }
    }
  }
}
</style>