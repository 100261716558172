import Rete from "rete";
import * as Socket from "../../sockets";
import PlayFileComp from './Component'
import Control from './Control'
import config from './config'

const obj_type = 'play_file'

export class CompControl extends Rete.Control {
  constructor(emitter, key, readonly) {
    super(key);
    this.component = Control;
    this.props = { emitter, ikey: key, readonly };
  }

  setValue(val) {
    this.vueContext.value = val;
  }
}

export { config as PlayFile_CONFIG }
export class PlayFile extends Rete.Component {
  constructor() {
    super(obj_type);
    this.data.component = PlayFileComp
    this.data._config = config
  }

  builder(node) {
    const cnf = this.data._config
    const defBuildOutputs = (node) => {
      for (const [out, out_name] of Object.entries(cnf.outputs || {})) {
        let output = new Rete.Output(out, out_name || out, Socket.callSocket, false)
        node.addOutput(output)
      }
    }
    const defBuildInputs = node => {
      var in1 = new Rete.Input("call", "Call", Socket.callSocket, true);
      node.addInput(in1)
    }
    const buildInputs = cnf.mapInputs || defBuildInputs
    const buildOutputs = cnf.mapOutpus || defBuildOutputs


    buildInputs(node)
    buildOutputs(node)

    return node
  }


  async worker(node) {
    // const node_id = node.id
    let current_node = this.editor.nodes.find(n => n.id == node.id)
    for (const conn of node.inputs.call.connections) {
      const n = this.editor.nodes.find(_n => _n.id == conn.node)
      if (!n) continue
      if (n.name == 'city_number') {
        current_node.data.has_root = true
      } else if (n.name != 'start' && n.data.has_root) {
        current_node.data.has_root = true
      } else {
        current_node.data.has_root = false
      }
    }
  }
}
