<template>
  <div class="text-input">
    <b-form-input
        v-model="val"
        class="input text-input"
        :disabled="disabled"
        placeholder="Сотрудник XXX"
        maxlength="50"
    >
    </b-form-input>
  </div>
</template>


<script>
import { InputMixin } from "../../mixins";

export default {
  props: [],
  name: "EmployeeInput",
  mixins: [InputMixin],
};
</script>



<style lang="scss" scoped>
.text-input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.text-block {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  color: #adadad;
}
</style>