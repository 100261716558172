<template>
  <b-card
    :class="[`${config.objType}-node`, 'node']"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
  >
    {{ get_data(node.data) }}
    <b-card-title class="title">
      <Socket
        v-for="input in inputs()"
        :key="input.key"
        v-socket:input="input"
        type="input"
        :class="['input-socket', input.key]"
        :socket="input.socket"
      />
      {{ node.data.raw_data.service_type }}
      <b-badge variant="danger" class="">beta</b-badge>
    </b-card-title>
    <short-text-span
      tag="h2"
      class="name"
      v-if="service"
      :text="service.caption"
    />
    <h2>{{ service.description }}</h2>
    <fragment v-if="!config.namedOutputs">
      <Socket
        v-for="output in outputs()"
        :key="output.key"
        v-socket:input="output"
        type="output"
        :class="['output-socket', output.key]"
        :socket="output.socket"
      />
    </fragment>
    <fragment v-else>
      <div v-for="output in outputs()" :key="output.key" class="output-line">
        <div class="out">
          <span class="name"> {{ output.name }}</span>
          <Socket
            v-socket:input="output"
            type="output"
            :class="['output-socket', output.key]"
            :socket="output.socket"
          />
        </div>
      </div>
    </fragment>
  </b-card>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { Icon, Fragment } from "@/components/layot";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";

export default {
  name: `${config.apiType}Component`,
  mixins: [ComponentMixinVue],
  components: {
    Socket: VueRenderPlugin.Socket,
    Icon,
    Fragment,
  },
  computed: {
    config: () => config,
    service() {
      return this.node.data?.raw_data;
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.unknown-node {
  .name {
    font-size: 2em;
    max-width: calc(100% - 20px);
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
    .beta {
      color: red;
    }
  }
  .output-socket.output {
    position: relative;
    left: calc(100% - 18px);
    &.timeout {
      background-color: $obit-danger !important;
    }
  }
}
</style>