import { merge } from "lodash"
import Rete from 'rete'
import { callSocket } from "../../sockets"

export default {
  name: 'Группа',
  apiType: 'group',
  objType: 'pbx_group',
  outputs: { 'fallback': null },
  load_data: (nodes, node, service) => {
    const outputs = service.outputs.filter(c => c.service_id && c.key == 'fallback').reduce(
      (r, d) => {
        r[d.key] = {
          connections: [
            {
              node: d.service_id,
              input: 'call',
              data: {}
            }
          ]
        }
        return r
      }, {})

    return merge({}, node, { outputs: outputs })
  },
  mapOutpus: node => {
    let outputs = [
      new Rete.Output('fallback', 'Таймаут', callSocket, false),
    ]
    for (let output of outputs) {
      node.addOutput(output)
    }
  },
  textSearch: (node, regex) => {
    const service = node.vueContext?.service;
    let text = service?.obj_name || '';
    text += ` ${service?.caption} ${service.description}`;
    return text && text.search(regex) != -1;
  },
  pack_data: () => ({})
}