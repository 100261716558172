<template>
  <b-nav-item class="customer-top-form" v-if="is_vertical.width > 640">
    <b-form-select
      v-model="customer"
      :options="customer_options"
      value-field="obj_id"
      text-field="obj_name"
      class="select-customer"
      :disabled="customer_options.length <= 1 || disabled"
      plain
    />
    <br />
    <b-form-select
      v-model="contract"
      value-field="obj_id"
      text-field="obj_name"
      :class="['select-contract', 'text-muted']"
      :options="contract_options"
      :disabled="(contract_options.length <= 1 && contract != '') || disabled"
      plain
    >
      <b-form-select-option :value="''" class="no-display" v-if="contract == ''"
        >Договор не выбран</b-form-select-option
      >
    </b-form-select>
  </b-nav-item>
</template>

<script>
import EventBus from "../../EventBus.vue";
import { bindEvent } from "../../../functions";
import { eventBus } from "../..";

export default {
  name: "CustomerForm",
  mixins: [EventBus],
  inject: ["is_vertical"],
  props: {
    could_change: {
      required: false,
      type: Boolean,
      default: true,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.loading = true;
    this.$store.dispatch("customer/list");
    const get_finances = () => this.$store.dispatch("contract/finances");
    get_finances();
    bindEvent(this, "contract_changed", get_finances, eventBus);
    // на случай если сбился contract
    if (this.contract == "") {
      this.$store.dispatch("contract/list").then(() => {
        if (this.contract_options.length) {
          this.contract = this.contract_options[0].obj_id;
        } else {
          this.$router.push({ path: "/dash" });
        }
      });
    }
  },
  computed: {
    customer: {
      get() {
        this.$store.dispatch("contract/list");
        let ret = this.$store.getters.current_customer || "";
        return ret.toUpperCase();
      },
      set(to) {
        let contract = "";
        this.$store.commit("set_current", { customer: to });
        this.$store.dispatch("contract/list").then(() => {
          if (this.$store.getters["contract/objects"].length) {
            contract = this.$store.getters["contract/objects"][0].obj_id;
          } else {
            this.$router.push({ path: "/dash" });
          }
          this.$store.commit("set_current", { contract: contract });
        });
      },
    },
    contract: {
      get() {
        return this.$store.getters.current_contract;
      },
      set(to) {
        this.$store.commit("set_current", { contract: to });
      },
    },
    bus() {
      return this;
    },
    customer_options() {
      return this.$store.getters["customer/objects"];
    },
    contract_options() {
      return this.$store.getters["contract/objects"];
    },
    customer_obj() {
      return this.$store.getters["customer/object"](this.customer) || {};
    },
    contract_obj() {
      return this.$store.getters["contract/object"](this.contract) || {};
    },
  },
};
</script>

<style lang='scss'>
.customer-top-form {
  .select-customer,
  .select-contract {
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0;
    line-height: 1.2em;
    height: 1.1em;
    options: {
      color: var(--dark);
    }
    &:focus {
      box-shadow: none;
    }
    &[disabled],
    &:disabled {
      background-color: transparent;
    }
  }
  .select-customer {
    font-weight: 600;
    margin-bottom: 0.25em;
    font-size: 14px;
  }
  .select-contract {
    font-weight: 400;
    min-width: 100%;
    font-size: 12px;
    line-height: 14.1px;
  }
}
.no-display {
  display: none;
}
</style>
