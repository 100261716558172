<template>
  <div class="row border-bottom" v-if='show'>
    <nav class='navbar' v-bind:class='nav_class' role="navigation" style="margin-bottom: 0">
        <div class="navbar-header">
            <b-button variant='primary' class="navbar-minimalize minimalize-styl-2" @click="toggle_sidebar()">
                <icon :icon="icons.bars" />
            </b-button>
            <slot name='search' />
        </div>
        <ul class="nav navbar-top-links navbar-right">
            <slot></slot>
            <li>
                <a @click="$eventBus.$emit('logout', false)">
                    <icon :icon="icons.sign_out"/> Выход
                </a>
            </li>
        </ul>

    </nav>
</div>
</template>

<script>
import WithBodyClasses from '../mixins/WithBodyClasses'
import { BButton } from "bootstrap-vue";
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Icon , as_icon } from './FontAwesomeIcon.vue';
import { EventBus } from '..';

export default {
    name: 'TopBar',
    mixins: [WithBodyClasses, EventBus],
    components: {BButton, Icon},
    props: {
        fixed: {
            type: Boolean,
            default: true
        },
        fixed_type: {
            type: String,
            default: 'v2'
        },
        show: {
            type: Boolean,
            default: true
        },
    },
    data() {return {
        icons: {
            bars: as_icon(faBars),
            sign_out: as_icon(faSignOutAlt)
        }
    }},
    computed: {
        body_classes() {
            let classes = {}
            classes[[true, 'v1']] = ['fixed-nav']
            classes[[true, 'v2']] = ['fixed-nav', 'fixed-nav-basic']
            return classes[[this.fixed, this.fixed_type]] || []
        },
        nav_class() {
            let classes = {}
            classes[[true, 'v1']] = ['navbar-fixed-top']
            classes[[true, 'v2']] = ['navbar-fixed-top']
            return classes[[this.fixed, this.fixed_type]] || ['navbar-static-top']
        }
    },
    methods: {
        toggle_sidebar() {
            this.$eventBus.$emit('toggleSideBar')
        }
    }
}
</script>

<style>

</style>