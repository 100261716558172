import Rete from "rete";
import SIPTerminationComp from './SIPTerminationComp.vue'
import config from './config'
import { callSocket, startSocket } from "../../sockets";

export { config as SIPTermination_CONFIG }
export class SIPTermination extends Rete.Component {
    constructor() {
        super(config.objType);
        this.data.component = SIPTerminationComp
        this.data._config = config
    }

    builder(node) {
        const cnf = this.data._config
        const defBuildOutputs = (node) => {
            for (const [out, out_name] of Object.entries(cnf.outputs)) {
                let output = new Rete.Output(out, out_name || out, callSocket, false)
                node.addOutput(output)
            }
        }
        const defBuildInputs = node => {
            var in1 = new Rete.Input("start", "start", startSocket, true);
            node.addInput(in1)
        }
        const buildInputs = cnf.mapInputs || defBuildInputs
        const buildOutputs = cnf.mapOutpus || defBuildOutputs


        buildInputs(node)
        buildOutputs(node)

        return node
    }

    async worker(node) {
        // const node_id = node.id
        let current_node = this.editor.nodes.find(n => n.id == node.id)
        let has_root = false
        for (const conn of node.inputs.call.connections) {
            const n = this.editor.nodes.find(_n => _n.id == conn.node)
            if (!n) continue
            if (!has_root) {
                if (n.name == 'city_number' || n.name == 'SIPTermination') {
                    has_root = true
                } else if (n.name != 'start' && n.data.has_root) {
                    has_root = true
                }
            } else continue
        }
        current_node.data.has_root = has_root
        node.data.has_root = has_root
        current_node.update()
        current_node.vueContext.$forceUpdate()
    }
}
