<template>
  <b-button-group size="sm" @click="toggle">
    <b-button variant="outline" class="text-monospace">{{ password }}</b-button>
    <b-button variant="outline-secondary" style="min-width: 3em">
      <icon :icon="icon" />
    </b-button>
  </b-button-group>
</template>

<script>
import { as_icon, Icon } from "../layot/FontAwesomeIcon";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import CellVue from "../mixins/Cell.vue";
export default {
  name: "passwordCell",
  mixins: [CellVue],
  components: { Icon },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    pass() {
      return this.value ? this.value : "_____";
    },
    icon() {
      return as_icon(this.show ? faEyeSlash : faEye);
    },
    password() {
      return this.show ? this.pass : "*".repeat(this.pass.length);
    },
  },
  methods: {
    toggle() {
      this.show = !this.show;
    },
  },
};
</script>

<style>
</style>