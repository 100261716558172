import { merge } from "lodash-es"
import { type_val } from "./functions"
import { build_storage } from "./store/object"

var OBJ_COMPONENTS = {}
var STORAGES = {}
var ROUTES = []
var def_router = []

const register_comp = (name, desc, test_data = []) => {
  const test = test_data.length >= 1
  let additional_data = {}
  const search_keys = {
    obj_key: 'isKey',
    obj_name: 'isName'
  }
  let present_keys = Object.keys(search_keys).reduce((r, key) => Object.assign(r, { [key]: desc[key] || false }), {})
  if (Object.values(present_keys).some(val => !val)) {
    // попробуем найти ключик!
    for (const [field, field_desc] of Object.entries(desc.fields)) {
      // для каждого невалидного ключа
      for (const key of Object.entries(present_keys).filter(([, v]) => !v).map(([k,]) => k)) {
        if (field_desc[search_keys[key]]) {
          additional_data[key] = field
          present_keys[key] = true
        }
      }
      if (Object.values(present_keys).every(e => e)) {
        break
      }
    }
  }

  // обновим поля форм
  const where_to_look = ['new_fields', 'edit_fields', 'view_fields']
  for (const where of where_to_look) {
    const real_fields = desc[where] || []
    const fields = [...real_fields]
    const fields_descriptions = desc.fields || {}
    real_fields.map((field_desc, i) => {
      if (type_val(field_desc) == 'Object' && field_desc.key !== undefined) {
        fields[i] = merge({}, fields_descriptions[field_desc.key] || {}, field_desc)
      }
    })
    additional_data[where] = fields
  }

  const description = merge(
    { obj_type: name, test: test }, additional_data,
    desc
  )
  OBJ_COMPONENTS[name] = description
  STORAGES[name] = build_storage(name, description, test_data, description.storage || {})

  // зарегестрируем роуты

  return OBJ_COMPONENTS
}

const build_default = description => Object.entries(description.fields || {}).reduce(
  (ret, [key, descr]) =>
    Object.assign(ret, {
      [descr.target || key]: descr.multiple ? [] : descr.default || null
    }),
  {}
)

export { register_comp, OBJ_COMPONENTS, build_default, STORAGES, ROUTES, def_router }