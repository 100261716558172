<template>
  <b-dropdown
    size="xs"
    variant="outline-obitless"
    class="field_actions right dropdown-actions"
    dropleft
    no-caret
    boundary="window"
    :popper-opts="{ positionFixed: false }"
  >
    <template v-slot:button-content>
      <icon :icon="icons.actions" />
    </template>
    <b-dropdown-item variant="obit" :to="link_with_params()"
      >Информация</b-dropdown-item
    >
    <b-dropdown-item variant="obit" :to="link_with_params({ tab: 1 })"
      >Внутр. номера</b-dropdown-item
    >
    <obj-modal :obj_id="obj_id" :obj_type="obj_type">
      <template v-slot:default="m">
        <b-dropdown-item variant="obit" @click="m.show_modal()"
          >Редактировать
        </b-dropdown-item>
      </template>
    </obj-modal>
  </b-dropdown>
</template>

<script>
import { Icon, as_icon } from "../../layot";
import CellVue from "../../mixins/Cell.vue";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
// import { eventBus } from "../..";
import ObjModal from "../ObjModal";

export default {
  name: "ActionsEmpl",
  components: { Icon, ObjModal },
  mixins: [CellVue],
  inject: ["link"],
  props: {
    root: {
      type: String,
      required: false,
      default: "./",
    },
  },
  data() {
    return {
      icons: {
        actions: as_icon(faEllipsisV),
      },
    };
  },
  methods: {
    link_with_params(params = {}) {
      let query = Object.entries(params).reduce(
        (ret, [key, val]) => (ret += `${key}=${val}&`),
        ""
      );
      let new_link = `${this.link([this.item.obj_id])}?${query}`;
      return new_link;
    },
  },
};
</script>

<style lang='scss'>
.dropdown.field_actions {
  float: right;
  button {
    padding-right: 2px;
    padding-left: 2px;
  }
  ul {
    padding: 20px 0px;
    margin-top: 10px;
    margin-right: 7px;
    a {
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      float: right;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.right {
  float: right;
}
</style>