<template>
  <b-form-input
    v-model="val"
    class="input text-input"
    :disabled="_obj.status == 'active' || _obj.status == 'disabled'"
    :placeholder="placeholder"
  />
</template>

<script>
import { InputMixin } from "../../mixins";

export default {
  props: ["placeholder"],
  name: "EmailInput",
  mixins: [InputMixin],
  // mounted() {
  //   this.val = this._obj.email;
  // },
};
</script>