<template>
  <div class="empty_calls">
    <icon :icon="icon" size="8x" rotation="180" />
    <h1 class="middle">У вас пока нет ни одного звонка</h1>
  </div>
</template>

<script>
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { as_icon, Icon } from "../layot";
export default {
  name: "EmptyComp",
  components: { Icon },
  computed: {
    icon: () => as_icon(faSmile),
  },
};
</script>

<style>
.empty_calls {
  text-align: center;
}
</style>