<template>
  <div class="text-input">
    <div class="text-block">
      Используйте любую комбинацию из 3-5 цифр, которая не начинается с 1 и 0
    </div>
    <b-form-input
      v-model="val"
      class="input text-input"
      :disabled="disabled"
      :placeholder="placeholder"
      maxlength="5"
    >
    </b-form-input>
    <!-- <obit-icon icon="question" v-b-tooltip.v-light="'Для звонков внутри Вашей Виртуальной АТС, переадресации звонков и распределения вызовов'"/> -->
  </div>
</template>


<script>
import { InputMixin } from "../../mixins";
import ObitIcon from "../../obit/ObitIcon";

export default {
  components: { ObitIcon },
  props: ["placeholder"],
  name: "TextInput",
  mixins: [InputMixin],
};
</script>



<style lang="scss" scoped>
.text-input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .text-input {
    width: 65px;
    text-align: center;
    padding: 5px;
  }
}
.text-block {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  color: #adadad;
}
</style>