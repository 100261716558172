<script>
import { library } from '@fortawesome/fontawesome-svg-core'
// import {  } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'

const inlineMixin = {
  name: 'inlineMixin',
  props: {
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes() {
      return {inline: this.inline}
    }
  }
}

const IconStack = {
  name: 'IconStack',
  mixins: [FontAwesomeLayers, inlineMixin],
}
const Icon = {
    name: 'Icon',
    mixins: [FontAwesomeIcon, inlineMixin],
}
const as_icon = (icon, register=true) => {
  if (register) {
    library.add(icon)
  }
  return [icon.prefix, icon.iconName]
}
export default Icon
export {library, IconStack, Icon, as_icon}
</script>