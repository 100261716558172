import { id_field_desc } from "@/functions.js"
import { buildOptionsField, buildRelatedField, description_field } from "../fields"
import { BFormRadioGroup } from 'bootstrap-vue';
import { first } from "lodash";

const root = '/pbx/services/v2/virtual/callRecords'

const LINKS = {
  //get: `${root}/info`,
  update: `${root}/modify`,
  add: `${root}/create`,
  delete: `${root}/delete`,
  list: `${root}/list`
  // list: 'user/list',
}
const CALL_PRICE_VARIANTS = {
  30: 100,
  60: 150,
  180: 200
}

const OBJ = {
  urls: LINKS, // ссылки 
  obj_key: 'service_id', // ключевой параметр
  obj_name: 'caption', // ключ для репрезентации
  verbose: 'Запись звонков', // название 
  plural: 'Запись звонков', // название мн. число
  unique_for: ['customer', 'entity'],
  hide_modal_header: true,
  obj_template: (val) => `Запись и хранение звонков ${val.depth} дней`,
  // next_new_url: '/user/', // ссылка после создания
  //next_edit_url: NaN,
  //new_default: {}, // значение по-умолчанию.
  //delete_key: 'deleted',
  save_method(comp) {
    if (comp.mode == 'new' && comp.$refs.form.validate()) {
      const days = comp.$refs.form.object.depth
      const price = CALL_PRICE_VARIANTS[days] ?? '---';
      comp.$bvModal.msgBoxConfirm(`Вы действительно хотите подключить услугу "Запись и хранение звонков ${days} дней". Стоимость ${price} руб. за каждый внутренний номер.`, {
        okVariant: 'obit',
        okTitle: 'Подключить',
        cancelTitle: 'Отмена',
        hideHeaderClose: false,
        centered: true
      }).then(
        ok => {
          if (ok) comp.$emit('save')
        }
      )
    } else {
      comp.$emit('save')
    }
  },

  obj_type: 'callRecords',
  fields: { // описание полей
    service_id: id_field_desc,
    caption: {
      label: 'Название',
    },
    customer_id: buildRelatedField('Клиент', 'customer'),
    entity_id: buildRelatedField('Док', 'entity'),
    description: description_field,
    depth: buildOptionsField(
      'Глубина хранения записей звонков',
      Object.entries(CALL_PRICE_VARIANTS).map(([days, price]) => ({ text: `${days} дней - ${price} руб.`, value: days })),
      {
        comp: BFormRadioGroup,
        default: first(Object.keys(CALL_PRICE_VARIANTS)),
        options: {
          stacked: true
        }
      })
  },
  view_fields: [
    'depth'
  ],
  edit_fields: [
    'depth'
  ],
  new_fields: [
    'depth'
  ]
}
export { OBJ, CALL_PRICE_VARIANTS }