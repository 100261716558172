<template>
  <!-- <b-form-input :value="val" @change="val = $event" :disabled="disabled" /> -->

  <b-input-group>
    <multiselect
      v-model="val"
      placeholder="Выберите"
      :options="options_test"
      :disabled="disabled || (val && val.includes('0.0.0.0/0'))"
      :multiple="true"
      :taggable="true"
      @tag="addTag($event)"
      class="select2"
    ></multiselect>
    <b-input-group-append v-if="!disabled">
      <b-form-checkbox
        :disabled="disabled"
        v-model="all"
        button-variant="outline-primary"
        button
        >Все</b-form-checkbox
      >
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { InputMixin } from "../../mixins";
import Multiselect from "vue-multiselect";

export default {
  name: "AllowIPInput",
  components: { Multiselect },
  mixins: [InputMixin],
  props: {},
  data() {
    return { options_test: [] };
  },
  methods: {
    addTag(val) {
      this.options_test.push(val);
      this.val.push(val);
    },
  },
  created() {
    if (this.val.length == 0) {
      this.val.push("0.0.0.0/0");
    }
  },
  computed: {
    all: {
      get() {
        return (this.val || []).includes("0.0.0.0/0");
      },
      set(isCheck) {
        this.val = isCheck ? ["0.0.0.0/0"] : [];
      },
    },
  },
};
</script>

<style>
</style>