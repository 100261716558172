<template>
  <div class="empty_calls">
    <!-- <icon :icon="icon" size="8x" rotation="180" /> -->
    <h1 class="middle">Данные отсутствуют</h1>
  </div>
</template>

<script>
export default {
  name: "EmptyComp",
};
</script>

<style>
.empty_calls {
  text-align: center;
  margin-top: 10px;
}
</style>