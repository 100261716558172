<template>
  <div class="tarif_view" id="tarif_view">
    <br />
    <b-row class="row_padding">
      <b-col>
        <b-form class="subform">
          <h4>Условия тарифов</h4>
          <b-form-group label="Базовая конфигурация" label-cols="3">
            <b>1000 ₽ / мес </b>
            <b-link href="https://static.lobby.obit.ru/lkul/Tariffs_VPBX.pdf"
              >Стандартный</b-link
            >
          </b-form-group>
          <b-form-group label="Стоимость внутреннего номера" label-cols="3"
            >200 ₽ / мес <b-link>Внутр. номера</b-link>
          </b-form-group>
          <b-form-group
            label="Стоимость подключения номера другого оператора"
            label-cols="3"
            >330 ₽ / мес <b-link>Внешних номера</b-link>
          </b-form-group>
          <b-form-group
            v-for="c in $store.getters['callRecords/objects']"
            :key="c.obj_id"
            :label="c.obj_name"
            label-cols="3"
          >
            <template #label>
              {{ c.obj_name }}
              <br />
              <b-form-text> на один внутренний номер </b-form-text>
            </template>
            {{ callRecordsOptions[c.depth] || "---" }} ₽ / мес
            <b-button variant="link" @click="show_edit(c.obj_id)"
              >Управлять</b-button
            >
          </b-form-group>
        </b-form>
        <b-form class="subform" v-if="additional_add.length">
          <h4>Подключаемые компоненты</h4>
          <b-form-group
            v-for="(item, index) in additional_add"
            :key="index"
            label-cols="3"
            :label="item.label"
          >
            {{ item.text }}
            <b-button
              v-parent_class="{ classes: ['form-block'] }"
              variant="obit"
              pill
              @click="send(item.action)"
              :disabled="item.disabled"
              >Подключить</b-button
            >
          </b-form-group>
        </b-form>
        <b-form class="subform" v-if="enabled_tariffs.length">
          <h4>Подключенные</h4>
          <b-form-group
            v-for="item in enabled_tariffs"
            :key="item.obj_id"
            label-cols="3"
            :label="`Интеграция c Битрикс24`"
          >
            500 ₽/мес
            <b-button
              v-parent_class="{ classes: ['form-block'] }"
              pill
              variant="obit-orange"
              :to="{ name: 'integration_edit', params: { id: item.obj_id } }"
              :disabled="integraions_disabled"
              >Изменить</b-button
            >
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <manager-modal ref="man_modal" />
    <IntegrationModal ref="int_modal" />

    <obj-modal
      v-if="!!edit_records_id"
      ref="callRecordEdit"
      :obj_id="edit_records_id"
      obj_type="callRecords"
      modalOnly
      mode="edit"
      :deleteHeaderText="delete_callrecord_header"
      :modalProps="{
        title: 'Редактирование услуги \'Запись и хранение звонков\'',
      }"
    >
      <template #form>
        <i class="text-muted"
          >Стоимость услуги указана за один внутренний номер</i
        >
      </template></obj-modal
    >
    <obj-modal
      v-if="!!edit_integration_id"
      ref="editIntegration"
      :obj_id="edit_integration_id"
      obj_type="integration"
      modalOnly
      mode="edit"
    />
    <obj-modal
      ref="callRecords"
      obj_type="callRecords"
      obj_id="new"
      mode="new"
      modalOnly
      :modalProps="{
        title: 'Подключение услуги \'Запись и хранение звонков\'',
      }"
    >
      <template #btnContent> Подключить </template>
      <template #form>
        <i class="text-muted"
          >Стоимость услуги указана за один внутренний номер</i
        >
      </template>
    </obj-modal>
  </div>
</template>

<script>
import parent_class from "../../directives/parent_class";
import ManagerModal from "../obit/ManagerModal";
import IntegrationModal from "../obit/IntegrationModal";
import ObjModal from "../objects/ObjModal.vue";
import { CALLRECORDS_DESC, CALL_PRICE_VARIANTS } from "../objects/callRecords";
import { INTEGRATION_DISABLED } from "../objects/integration/description";
export default {
  name: "tarifView",
  components: { IntegrationModal, ManagerModal, ObjModal },
  directives: { parent_class: parent_class },
  mounted() {
    Promise.all([
      this.$store.dispatch("integration/list"),
      this.$store.dispatch("callRecords/list"),
    ]);
  },
  data() {
    return {
      show_modal: false,
      edit_records_id: null,
      show_edit_records: false,
      edit_integration_id: null,
      show_edit_integraion: false,
    };
  },
  computed: {
    integraions_disabled() {
      return INTEGRATION_DISABLED;
    },
    enabled_tariffs() {
      return this.$store.getters["integration/objects"];
    },
    additional_add() {
      let ret = [];
      // { label: "Доп. внешний канал", text: "от 800 ₽" },
      // { label: "Аудиоконференции", text: "от 200 ₽/мес" },
      // { label: "Очередь звонков", text: "от 400 ₽/мес" },
      // { label: "Речевая аналитика", text: "от 400 ₽/мес" },
      // { label: "Контакт-платформа", text: "от 400 ₽/мес" },

      // { label: "Интеграция с колл-трекингом", text: "от 400 ₽/мес" },
      if (this.$store.getters["callRecords/objects"].length == 0) {
        ret = [].concat(
          [
            {
              label: "Запись и хранение звонков",
              text: "от 100 ₽/мес",
              action: "call_record",
            },
          ],
          ret
        );
      }
      if (this.$store.getters["integration/objects"].length == 0) {
        ret.push({
          label: "Интеграция с Битрикс24",
          text: "500 ₽/мес",
          // action: "crm_integration",
          action: "CRM1",
          disabled: INTEGRATION_DISABLED,
        });
      }
      ret.push({
        label: "Интеграция с другими CRM",
        text: "от 500 ₽/мес",
        action: "crm_integration",
        //action: "CRM",
      });
      return ret;
    },
    rec_options: () => CALLRECORDS_DESC.fields.depth.options.options,
    callRecordsOptions: () => CALL_PRICE_VARIANTS,
  },
  methods: {
    delete_callrecord_header: () =>
      'Удалить услугу "Запись и хранение звонков"',
    show_edit($id) {
      this.edit_records_id = $id;
      this.show_edit_records = true;
      this.$nextTick(() => this.$refs.callRecordEdit.show_modal());
    },
    show_integration_modal($id) {
      this.edit_integration_id = $id;
      this.show_edit_integraion = true;
      this.$nextTick(() => this.$refs.editIntegration.show_modal());
    },
    send(action) {
      if (action) {
        switch (action) {
          case "call_record":
            //this.show_modal = true;
            this.$refs.callRecords.show_modal();
            break;
          case "CRM1":
            this.$refs["int_modal"].show(action);
            break;
          default:
            this.$refs["man_modal"].show(action);
        }
        //this.$refs["man_modal"].show(action);
        // this.$store.dispatch(`CRM/${action}`);
      } else {
        this.show_modal = true;
        // this.$store.commit("add_message", {
        //   type: "danger",
        //   message: "Нечего подключать",
        // });
      }
    },
  },
};
</script>

<style lang='scss'>
#tarif_view {
  .row_padding {
    padding-left: 30px;
  }
  .subform {
    margin: 20px 0;

    fieldset {
      padding-left: 5px;
      font-size: 16px;
    }
  }
  .subform:not(:last-child) {
    border-bottom: 1px solid var(--light);
  }

  .form-row {
    align-items: center;
    @media (max-width: 576px) {
      padding: 5px 0;
      justify-content: space-between;
      width: 100%;
      margin: 0;
    }
  }
  .form-text {
    min-width: 50%;
  }
  .form-block {
    max-width: 300px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
}
</style>