import { date_reformat, texttimeformatter, apiDateFormatter } from '../../formatters'
import { buildRelatedField, buildOptionsField, buildDateTimeField } from '../objects/fields'
import { id_field_desc, resolveWithPromise } from '@/functions.js'
import CallStatus from './CallStatus'
import PersonCell from './PersonCell'
import template from '../../template'
// import { register_test_data } from '../../test_data'
import CallsListPC from './CallsListPC'
import LogCell from './LogCell'
import PlayCell from './PlayCell'
import CallsFilter from './CallsFilter'
import { build_default } from '../../object'
import { BFormCheckboxGroup, BFormDatepicker, BFormRadioGroup } from 'bootstrap-vue'
import { formattedROInput } from '../shared'
import inputPlayer from './inputPlayer'
import EmptyComp from './EmptyComp'
import { Select2 } from '../layot'
import { isEqual, merge } from 'lodash-es'
import CallTime from './CallTime'
import { rebuild_object } from '../../store/object'


const length_formatter = (value, key, item) => {
  return texttimeformatter(item.len || 0)
}

function get_date_formatter(add_info) {
  var today = new Date();
  if (add_info.filter.date_range == '1') {
    add_info.begin = apiDateFormatter(Date.now()) + ' 00:00:00'
  } else if (add_info.filter.date_range == '2') {
    let nextWeek = new Date()
    add_info.begin = apiDateFormatter(nextWeek.setDate(nextWeek.getDate() - 7)) + ' 00:00:00'
  } else if (add_info.filter.date_range == '3') {
    var month = today.getMonth().length == 2 ? today.getMonth() : '0' + today.getMonth()
    add_info.begin = today.getFullYear() + '-' + month + '-01 00:00:00'
  }
  if (add_info.filter.begin) {
    add_info.begin = add_info.filter.begin + ' 00:00:00'
    delete add_info.filter['begin']
  }
  if (add_info.filter.end) {
    add_info.end = add_info.filter.end + ' 00:00:00'
    delete add_info.filter['end']
  }
  delete add_info.filter.date_range
  return add_info
}

const address_book_description = {
  verbose: 'Персона',
  plural: 'Персоны',
  obj_name: 'email',
  obj_key: 'person_id',
  obj_template: template`${'first_name'} ${'last_name'}`,
  fields: {
    person_id: id_field_desc,
    first_name: 'Имя',
    last_name: 'Фамилия',
    email: 'Почта',
    company: 'Компания',
    site: {
      label: 'Сайты',
      multiple: true
    },
    numbers: {
      label: 'Номера',
      multiple: true
    }
  }
}
const HOMESTATICS_URL = {
  calls: '/statistics/home/calls', // звонки
  response: '/statistics/home/response', // отвеченные
  duration: '/statistics/home/duration', // длительность
  missed: '/statistics/home/missed', // пропущенные
}

// const TEST_ANSV = {
//   "result": "success",
//   "code": 0,
//   "text": null,
//   "exception": null,
//   "payload": {
//     "contract_id": "077d5d09-a540-4fbd-b2a5-3bb1e2165f11",
//     "date_start": "2022-04-01 11:21:04",
//     "date_end": "2022-04-15 11:21:04",
//     "mode": "json",
//     "from": 0,
//     "count": 10,
//     "items": [
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "88123258825",
//         "zone": null,
//         "direction_group": "(L) Петербург",
//         "direction": "Санкт-Петербург",
//         "duration": 1,
//         "price": 0.9
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "88555371388",
//         "zone": null,
//         "direction_group": "(D) Европейская часть России",
//         "direction": "Татарстан Набережные Челны",
//         "duration": 6,
//         "price": 14.98
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89213909364",
//         "zone": null,
//         "direction_group": "(L) Петербург (мобильные DEF)",
//         "direction": "Санкт-Петербург, (mob) - Мегафон",
//         "duration": 2,
//         "price": 4.2
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89112952525",
//         "zone": null,
//         "direction_group": "(L) Петербург (мобильные DEF)",
//         "direction": "Санкт-Петербург, (mob) - МТС",
//         "duration": 2,
//         "price": 4.2
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89817499724",
//         "zone": null,
//         "direction_group": "(L) Петербург (мобильные DEF)",
//         "direction": "Санкт-Петербург, (mob) - МТС",
//         "duration": 1,
//         "price": 2.1
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89817499724",
//         "zone": null,
//         "direction_group": "(L) Петербург (мобильные DEF)",
//         "direction": "Санкт-Петербург, (mob) - МТС",
//         "duration": 7,
//         "price": 14.7
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89823313169",
//         "zone": null,
//         "direction_group": "(D) Федеральные коды DEF",
//         "direction": "Другие коды DEF",
//         "duration": 5,
//         "price": 22.5
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89117048061",
//         "zone": null,
//         "direction_group": "(L) Петербург (мобильные DEF)",
//         "direction": "Санкт-Петербург, (mob) - МТС",
//         "duration": 1,
//         "price": 2.1
//       },
//       {
//         "type": "out",
//         "from": "8124188430",
//         "to": "89531795525",
//         "zone": null,
//         "direction_group": "(L) Петербург (мобильные DEF)",
//         "direction": "Санкт-Петербург, (mob) - TELE2",
//         "duration": 1,
//         "price": 2.1
//       }
//     ],
//     "total": 52
//   }
// };

const storage = {
  getters: {
    as_dict: (state, getters, rootState, rootGetters) => rootGetters['internal_line2/objects'].reduce(
      (r, l) => merge(r, { [l.line_number]: l.obj_id }),
      {}
    ),
    pre_data_list: (state, getters, rootState, rootGetters) => (data) => ({
      customer_id: rootGetters.current_customer,
      instance_id: rootGetters.current_instance,
      order: "date",
      direction: "desc",
      ...data
    }),
    rebuild_call: (state, getters) => (call, log_type, line = null) => {
      let ret = {
        call_id: call.call_id,
        date: date_reformat(call.date),
        from: call.num_a,
        to: call.num_b,
        service_id: call.service_id,
        direction: call.direction,
        tags: [],
        log_id: call.call_id,
        operator: null,
        len: call.billsec,
        exten: call.exten,
        _description: getters.description,
        obj_id: call.call_id,
        obj_type: 'call',
        _raw: call
      }
      if (log_type == 'line') {
        ret['direction'] = call.num_a == line ? 'outgoing' : 'internal'
      }
      return ret
    },
    rebuild_log: () => call => {
      let ret = {
        log_id: call.call_id,
        time: call.duration,
        link: 'https://file-examples-com.github.io/uploads/2017/11/file_example_WAV_1MG.wav'
      }
      return ret
    }
  },
  actions: {
    list({ getters, commit, dispatch, rootGetters }, data = {}) {
      const config = data || {}
      const force = config.force || false
      const list_api = config.url || 'pbx/reports/callhistory'
      const raw = config.raw || false
      let add_info = config.data || {}
      const instance = rootGetters.current_instance || ''
      const log_type = config.log_type || 'internal'
      const line = add_info.ext || null
      if (add_info?.filter?.date) {
        add_info = get_date_formatter(add_info)
      }
      if (isEqual([], add_info?.filter) || isEqual({}, add_info?.filter)) {
        delete add_info['filter']
      }
      if (add_info?.begin === null) {
        delete add_info['begin']
      }
      if (add_info?.end === null) {
        delete add_info['end']
      }
      commit('load_status', 'loading')
      if (!force && Date.now() - getters.last_update < 5000 || !instance) {
        commit('load_status', 'success')
        return resolveWithPromise(getters.objects)
      } else {
        return dispatch('requestApi', { url: list_api, data: getters.pre_data(add_info, 'list') }, { root: true }).then(
          d => {
            let ret = d.data?.payload
            if (d.data?.result == 'success') {
              commit('load_status', 'success')
              let calls = d.data.payload.items.map(
                r => getters.post_data(getters.rebuild_call(r, log_type, line), 'list')
              )
              commit('load', calls)
              ret = {
                items: calls,
                total: d.data.payload.total
              }
              // commit('log/load', d.data.payload.items.map(
              //   r => getters.rebuild_log(r)
              // ), { root: true })
              let r = raw ? ret : d.data.payload;

              return r
            }
            commit('load_status', 'error')
            return ret
          }
        )
      }
    },
    tarif_list({ dispatch, rootGetters }, { data }) {
      let send_data = { ...data, ...rootGetters.current('customer', 'entity', 'contract') }
      delete send_data.instance_id
      return dispatch(
        'requestApi',
        {
          data: send_data,
          url: '/pbx/reports/tariff_calls',
          //test_answer: TEST_ANSV
        },
        { root: true }
      ).then(d => {
        let ret = d.data.payload;
        return ret
      })
    },
    get_by_ext({ dispatch }, data = {}) {
      const url = '/pbx/reports/exthistory'
      data['url'] = url
      data['log_type'] = 'line'
      return dispatch('list', data)
    },
    get_log({ dispatch, rootGetters }, log_id) {
      let ret = []
      const url = '/pbx/reports/calldetails'
      return dispatch('requestApi', {
        data: {
          call_id: log_id,
          bind: rootGetters.current_namespace,
          customer_id: rootGetters.current_customer
        },
        url: url
      }, { root: true }).then(
        resp => resp.data.payload, () => ret
      )
    },

    get_audio({ dispatch, getters }, call_id) {
      const obj = getters.object(call_id);
      //const bind = rootGetters.current_namespace
      const url = obj._raw.record_url;
      return dispatch(
        'requestApi',
        {
          url: url,
          method: 'GET',
          additional_config: {
            responseType: 'arraybuffer'
          }
        }, { root: true }
      )
    },
    get_home_statistics({ dispatch, getters }, { type, data }) {
      const send_data = merge(data, getters.unique_for)
      return dispatch(
        'requestApi',
        {
          url: HOMESTATICS_URL[type],
          data: send_data
        },
        { root: true }
      )
    },
  }
}

const call_description = {
  verbose: 'Звонки',
  plural: 'Звонки',
  obj_name: 'call_id',
  obj_key: 'call_id',
  list_post_comp: CallsListPC,
  list_filter_comp: CallsFilter,
  empty_comp: EmptyComp,
  page_variant: 'sip',
  no_create_button: true,
  storage: storage,
  unique_for: ['customer', 'instance'],
  fields: {
    call_id: id_field_desc,
    date: buildDateTimeField('Дата', { cell: CallTime, sortable: true }),
    operator: buildRelatedField('Оператор', 'employee'),
    log_id: buildRelatedField('Лог', 'log'),
    from: buildRelatedField('Кто', 'address_book', { cell: PersonCell, sortable: true, cell_options: { apiKey: 'num_a' } }),
    to: buildRelatedField('Кому', 'address_book', { cell: PersonCell, sortable: true, cell_options: { apiKey: 'num_b' } }),
    tags: buildRelatedField('Тэги', 'tag', { multiple: true, default: [] })
  },
  list_fields: [
    { label: 'Тип', key: 'icon', cell: CallStatus, apiKey: 'dialstatus', sortable: true },
    'date',
    'from', 'to',
    {
      label: 'Длительность', key: 'len', formatter: length_formatter, sortable: true,
      apiKey: 'billsec'
    },
    { label: 'Запись', key: 'record', cell: PlayCell },
    { label: 'Лог', key: 'log', cell: LogCell },
  ],
  view_fields: [
    'date',
    {
      label: 'Длительность',
      target: 'log_id',
      comp: formattedROInput,
      options: {
        formatter: length_formatter
      }
    },
    'from', 'to', 'operator',
    { label: 'Аудиозапись', comp: inputPlayer, target: 'log_id', key: 'log_id' },
    'tags'
  ],
}
const client_call_descrption = merge(
  {}, call_description, {
  storage: {
    mutations: {
      load: (state, objects) => {
        state.last_update = Date.now()
        let uids = []
        const loaded_objects = objects.filter(row => {
          return row.direction !== 'local'
        }).map(row => {
          const r = merge({}, rebuild_object(row, state.description), {
            obj_id: row[state.description.obj_key],
            obj_key: row[state.description.obj_key],
            obj_type: state.obj_type
          })
          uids.push(r.obj_id)
          return r
        }
        )
        state.objects = state.objects.filter(o => uids.indexOf(o[state.description.obj_key]) == -1).concat(loaded_objects)
      },
    }
  }
})
const local_call_descrption = merge(
  {}, call_description, {
  storage: {
    mutations: {
      load: (state, objects) => {
        state.last_update = Date.now()
        let uids = []
        const loaded_objects = objects.filter(row => {
          return row.direction === 'local'
        }).map(row => {
          const r = merge({}, rebuild_object(row, state.description), {
            obj_id: row[state.description.obj_key],
            obj_key: row[state.description.obj_key],
            obj_type: state.obj_type
          })
          uids.push(r.obj_id)
          return r
        }
        )
        state.objects = state.objects.filter(o => uids.indexOf(o[state.description.obj_key]) == -1).concat(loaded_objects)
      },
    }
  }
})

const log_description = {
  verbose: 'Лог звонка',
  plural: 'Логи звонков',
  obj_name: 'time',
  obj_key: 'log_id',
  fields: {
    log_id: id_field_desc,
    time: 'Время',
    link: 'Ссылка на аудио',
  },
}

const calls_inner = {
  1: 'принятые',
  2: 'пропущенные',
  3: 'отменённые',
}

const calls_outer = {
  1: 'отвеченные',
  2: 'без ответа',
  3: 'занято',
}

const calls_regions = {
  1: 'Городские',
  2: 'Мобильные',
  3: 'Междугородние',
  4: 'Международные'
}

const call_types = {
  inner: calls_inner,
  outer: calls_outer
}
const options_multiple = {
  multiple: true, custom_multiple: true,
  comp: BFormCheckboxGroup,
  options: {
    buttons: true,
    'button-variant': 'outline-primary'
  }
}

const buildFilterRule = (field_descr, rule = () => true) => Object.assign(field_descr, {
  filter_rule: rule
})

const complex_filter_description = {
  verbose: 'Фильтр',
  fields: {
    inner: buildFilterRule(buildOptionsField(
      'Входящие', calls_inner, options_multiple

    )),
    outer: buildFilterRule(buildOptionsField(
      'Исходящие', calls_outer, options_multiple
    )),
    persons: buildFilterRule(buildRelatedField(
      'Кто', 'address_book', { multiple: true }
    ), (row, value) => value.indexOf(row.from) + value.indexOf(row.to) >= -1),
    numbers: buildFilterRule(buildRelatedField(
      'Доп. номера', 'internal_line2',
      { multiple: true, custom_multiple: true, comp: Select2, options: { search: true } }
    )),
    groups: buildFilterRule(buildRelatedField(
      'Группы', 'group',
      { multiple: true }
    )),
    clients: buildFilterRule(buildRelatedField(
      'Клиенты', 'customer',
      { multiple: true }
    )),
    regions: buildFilterRule(buildOptionsField(
      'Регионы', calls_regions, options_multiple
    )),
    time: buildFilterRule(buildOptionsField(
      'Период', [
      { text: 'За всё время', value: undefined },
      { text: 'Сегодня', value: 'today' },
      { text: 'Неделя', value: 'week' },
      { text: 'Месяц', value: 'month' },
      { text: 'Выбрать период', value: 'period' },
    ], {
      multiple: false,
      comp: BFormRadioGroup,
      options: {
        buttons: true,
        'button-variant': 'outline-primary'
      }
    }
    )),
    period_start: buildFilterRule(
      { label: 'С', comp: BFormDatepicker, rule: (obj) => obj.time == 'period' },
    ),
    period_end: buildFilterRule(
      { label: 'По', comp: BFormDatepicker, rule: (obj) => obj.time == 'period' },
    ),
    tags: buildFilterRule(
      buildRelatedField(
        'Тэги', 'tag', { multiple: true }
      )
    )
  }
}
const change_events_description = {
  verbose: 'История операций',
  obj_key: 'chev_id',
  obj_name: 'event',
  fields: {
    chev_id: id_field_desc,
    datetime: buildDateTimeField('Дата'),
    user: 'Пользователь',
    event_type: 'Тип',
    type: 'Раздел',
    event: 'Событие'
  },
  list_fields: ['datetime', 'type', 'event']
}
const chev_test_data = [

].map((row, index) => Object.assign({
  [change_events_description.obj_key]: index + 1,
  datetime: '2020.07.01 14:15:00'
}, row))

// register_test_data('change_event', chev_test_data)
const default_complex = () => build_default(complex_filter_description)
export {
  call_description, change_events_description, default_complex, call_types,
  log_description, address_book_description, complex_filter_description, chev_test_data,
  client_call_descrption, local_call_descrption
}