<template>
  <b-form inline class="calls-filter">
    <b-form-row v-if="description.obj_type != 'local_call'">
      <b-form-radio-group
        buttons
        :options="types_options"
        v-model="filter.type"
        button-variant="outline"
      />
    </b-form-row>
    <b-form-row>
      <b-form-input :placeholder="filter_string" v-model="filter.input" />
    </b-form-row>
    <b-form-row class="export ml-auto">
      <b-button
        @click="$eventBus.$emit('show-complex-form')"
        variant="outline-primary"
      >
        <obit-icon icon="filter" />
        Фильтры
        <b-badge v-if="complex_filter_count">{{
          complex_filter_count
        }}</b-badge>
      </b-button>
      <b-button
        variant="outline-danger"
        v-if="complex_filter_count"
        @click="reset"
      >
        <icon :icon="icons.clear" />
      </b-button>
      <b-button variant="outline-export">
        <obit-icon icon="xls" />&nbsp;&nbsp;&nbsp;Экспорт
      </b-button>
    </b-form-row>
    <b-modal v-if="show_additionfilter"> ABKMNH </b-modal>
  </b-form>
</template>

<script>
import FilterMixinVue from "../mixins/FilterMixin.vue";
import { Icon, as_icon } from "../layot";
import {
  BFormRadioGroup,
  BFormInput,
  BButton,
  BBadge,
  BForm,
  BFormRow,
} from "bootstrap-vue";
import { faFilter, faTimes } from "@fortawesome/free-solid-svg-icons";
import EventBusVue from "../EventBus.vue";
import { complex_filter_description, default_complex } from "./description";
import { bindEvent } from "../../functions";
import isEqual from "lodash/isEqual";
import { eventBus } from "..";
import ObitIcon from "../obit/ObitIcon.vue";
const default_value = () =>
  Object.assign(
    {},
    {
      input: "",
      type: undefined,
      complex: default_complex(),
    }
  );

export default {
  name: "callsFilter",
  mixins: [FilterMixinVue, EventBusVue],
  components: {
    Icon,
    BBadge,
    BFormRadioGroup,
    BFormInput,
    BButton,
    BForm,
    BFormRow,
    ObitIcon,
  },
  mounted() {
    bindEvent(this, "new-call-filter", (filter) => {
      this.filter.complex = filter;
      this.apply_filter();
    }),
      eventBus;
  },
  computed: {
    types_options: () => [
      { text: "Все", value: undefined },
      { text: "Входящие", value: "incoming" },
      { text: "Исходящие", value: "outgoing" },
    ],
    icons: () =>
      Object.assign(
        {},
        {
          filter: as_icon(faFilter),
          clear: as_icon(faTimes),
        }
      ),
    df() {
      return this.default_filter();
    },
    complex_filter_count() {
      const def_filter = this.default_filter().complex;
      let count = 0;
      Object.entries(this.filter.complex || {}).map(([key, val]) => {
        if (!isEqual(val, def_filter[key])) {
          count += 1;
        }
      });
      return count;
    },
  },
  methods: {
    default_filter: () => Object.assign({}, default_value()),
    reset() {
      this.filter = this.default_filter();
      this.$eventBus.$emit("reset-complex-filter");
    },
    rule(row) {
      // фильтр по типу
      let ret = false;
      let filter_simple = false;
      let filter_complex = false;
      let filter_input = false;
      const def_filter = this.default_filter();
      if (this.filter.type) {
        filter_simple = row.direction == this.filter.type;
      } else {
        filter_simple = true;
      }
      if (this.filter.input) {
        const imp = this.filter.input;
        const to = row._related("to")?.obj_name || row.to;
        const fr = row._related("from")?.obj_name || row.from;
        filter_input = fr.indexOf(imp) != -1 || to.indexOf(imp) != -1;
      } else {
        filter_input = true;
      }
      // пойдем по сложному фильтру
      if (this.complex_filter_count) {
        Object.entries(this.filter.complex)
          .filter(([key, val]) => !isEqual(val, def_filter.complex[key]))
          .map(([key, val]) => {
            const field_descr = complex_filter_description.fields[key];
            const rule = field_descr.filter_rule;
            filter_complex |= rule(row, val, this.filter.complex);
          });
      } else {
        filter_complex = true;
      }
      ret = filter_simple && filter_complex && filter_input;
      return ret;
    },
  },
};
</script>

<style lang='scss'>
.calls-filter {
  max-width: 930px;
  .form-row {
    margin-right: 3em;
    &.filter,
    &.export {
      .btn {
        font-weight: 700;
        font-size: 11px;
        line-height: 13px;
        text-transform: uppercase;
        padding: 10px;
        vertical-align: middle;
        margin-left: 2em;
      }
      &.export .btn-outline-success {
        color: #40cc5b;
        border-color: #40cc5b;
      }
    }
    .btn.btn-outline {
      &.active {
        background-color: var(--primary);
        color: white;
        border: none;
      }
      border: 1px solid lightgrey;
    }
  }
}
</style>