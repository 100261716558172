<template>
  <b-input-group>
    <b-form-input v-model="val" :disabled="disabled" />
  </b-input-group>
</template>

<script>
import { BFormInput, BInputGroup } from "bootstrap-vue";
import { type_val } from "../../functions";
import { format } from "date-fns";
export default {
  name: "MonthPicker",
  components: { BInputGroup, BFormInput },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      required: true,
      type: [String, Number],
    },
    dateFormat: {
      required: false,
      type: String,
      default: "yyyy.MM",
    },
    state: {
      required: false,
      default: null,
      type: Boolean,
    },
  },
  computed: {
    val: {
      get() {
        let d, year, month;
        const months = {
          1: "Январь",
          2: "Февраль",
          3: "Март",
          4: "Апрель",
          5: "Май",
          6: "Июнь",
          7: "Июль",
          8: "Август",
          9: "Сентябрь",
          10: "Октябрь",
          11: "Ноябрь",
          12: "Декабрь",
        };
        if (type_val(this.value) == "String") {
          d = this.value;
        } else {
          const frmt = `${this.dateFormat}`;
          d = format(this.value, frmt);
        }
        [year, month] = d.split(".");
        d = [months[parseInt(month)], year].join(" ");
        return d;
      },
    },
  },
};
</script>

<style>
</style>