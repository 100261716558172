import { id_field_desc } from '@/functions.js'
import { BFormSelect, BFormDatepicker, BFormCheckbox, BFormRadio, BFormTextarea, BFormRadioGroup, BFormTimepicker } from 'bootstrap-vue'
import RouteList from './RouteList'
// import { register_test_data } from '../../test_data'


const route_description = {
    verbose: 'Маршрут',
    plural: 'Маршруты',
    obj_name: 'name',
    objs_comp: RouteList,
    fields: {
        route_id: id_field_desc,
        name: { label: 'Название правила', required: true, isKey: true },
        commentary: { label: 'Комментарий', comp: BFormTextarea },
        ext_number: { label: 'Номер', table_formatter: value => value.replace(/[^\d]/g, '') },
        default: { label: 'Маршрут по-умолчанию', comp: BFormCheckbox },
        rules: { label: 'Правила', multiple: true }
    },
    list_fields: ['name', 'ext_number', 'default', 'commentary'],
    new_fields: ['name', 'commentary', 'default'],
    edit_fields: ['name', 'commentary', 'rules'],
    list_fields_search: [],
}
const worktimes = [
    { text: 'Круглосуточно', value: 'all' },
]
const rule_description = {
    verbose: 'Правило',
    plural: 'Правила',
    fields: {
        rule_id: id_field_desc,
        tag: { label: 'Тэги', multiple: true },
        ext_number: { label: 'Входящий номер', },
        schedule: { label: 'Расписание', related: 'schedule', multiple: true },
        media: { label: 'Файл', related: 'schedule', multiple: true },
        action: { label: 'Действия', related: 'schedule', multiple: true },
        worktime: { label: 'Время работы', comp: BFormRadioGroup, options: { options: worktimes } },
    }
}
const rule_default = {
    rule_id: 0,
    tag: undefined,
    ext_number: undefined,
    schedule: 1,
    media: [2],
    action: [1]
}
const test_rules = [
    {
        route_id: 1,
        name: 'Базовое правило', ext_number: '7981810989', default: true,
        commentary: 'Это правило по умолчанию присваивается каждому новому номеру', rules: [rule_default]
    },
    {
        route_id: 2,
        name: 'Склад в Репино', ext_number: '8 921 333-22-31',
        commentary: 'Менеджер склада в репино, рекламный трафик', rules: []
    },
    {
        route_id: 3, name: 'Техподдержка', ext_number: '8 800 217-21-32',
        rules: [rule_default]
    },
]



const shchtype_def = 'allday'
const shchtype_options = [
    { text: 'Круглосуточно', value: shchtype_def },
    { text: 'По расписанию', value: 'shch' }
]
const shchteduler_description = {
    verbose: 'Расписание',
    plural: 'Расписания',
    fields: {
        shch_id: id_field_desc,
        name: 'Название',
        timezone: { label: 'Часовой пояс', comp: BFormSelect },
        type: { label: 'Время работы', comp: BFormRadio, default: shchtype_def, options: { buttons: true, options: shchtype_options } },
        // varints: {label: 'Варианты', default: ['Рабочее время', 'Выходные'], multiple: true},
        days: { label: 'Дни и время работы', multiple: true }, // TODO: должен быть кастомный компонент для работы с
        exclude: { label: 'Выходные и праздники', multiple: true, comp: BFormDatepicker }
    }
}
const tags_description = {
    verbose: 'Тэг',
    plural: 'Tэги',
    obj_key: 'tag_id',
    obj_name: 'name',
    fields: {
        tag_id: id_field_desc,
        name: 'Название'
    }
}
const test_tags = [
    { tag_id: 1, name: 'Белый список' },
    { tag_id: 2, name: 'Черный список' },
    { tag_id: 3, name: 'Третий список' },
    { tag_id: 4, name: 'Несписок' },
]


const aval_types = [
    { text: 'Пользователь', value: 'user' },
    { text: 'Группа', value: 'group' },
    { text: 'Внешний номер', value: 'ext_number' },
    { text: 'IVR', value: 'ivr' },
    { text: 'Голосовое сообщение', value: 'voice_mail' },
    { text: 'Обрыв соединения', value: 'disconnect' }
]
const action_desctription = {
    verbose: 'Действие',
    plural: 'Действия',
    fields: {
        action_id: id_field_desc,
        type: { label: 'Тип события', comp: BFormSelect, options: { options: aval_types } },
        user: {
            label: 'Сотрудник',
            comp: BFormSelect,
            related: 'user',
            rule: obj => ['user', 'voice_mail'].indexOf(obj.type) !== -1,
            //options: {options: {buttons: true}},
        },
        group: {
            label: 'Группа',
            comp: BFormSelect, related: 'group',
            options: { options: [] },
            rule: obj => obj.type == 'group'
        },
        call_time: {
            label: 'Время дозвона',
            comp: BFormTimepicker,
            rule: obj => ['user', 'group'].indexOf(obj.type) !== -1
        },
        ivr: {
            label: 'Меню',
            comp: BFormSelect,
            related() { return [] },
            rule: obj => obj.type == 'ivr'
        },
    },
}


const test_actions = [
    { action_id: 1, type: 'user', user: '59ED54AE-B832-40A8-8A23-7791BABD9FF0', }
]
const test_shchtedulers = [
    { shch_id: 1, name: 'По-умолчанию', type: shchtype_def }
]
// register_test_data('action', test_actions)
// register_test_data('shchteduler',  test_shchtedulers)
// register_test_data('tag', test_tags)
//  // register_test_data('media', test_medias)

export {
    route_description, rule_description,
    shchteduler_description, action_desctription,
    test_rules, test_actions, test_tags, tags_description,
    test_shchtedulers
}