<template>
	<div>
	</div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
	name: 'OidcCallback',
	methods: {
		...mapActions('oidcStore', [
			'oidcSignInCallback'
		])
	},
	created () {
		this.oidcSignInCallback()
				.then((redirectPath) => {
					this.$router.push(redirectPath)
				})
				.catch((err) => {
					console.error(err)
					this.$router.push({name: `oidcCallbackError`}) // Handle errors any way you want
				})
	}
}
</script>
