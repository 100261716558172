const modal_default_settings = {
  "headerBorderVariant": "bottom-0",
  "footerBorderVariant": "top-0",
  "headerClasses": ["header"],
  "modalClasses": ["edit-modal"],
  "footerClasses": ["modal-buttons"],
  size: "lg",
  cancelVariant: "outline-obit",
  cancelTitle: "Отмена",
  okTitle: "Сохранить",
  okVariant: "obit",
  noCloseOnBackdrop: true,
  'no-close-on-backdrop': true
}
const VueCustomConfig = {
  BModal: modal_default_settings,
  BFormTimepicker: {
    labelCloseButton: 'Закрыть',
    labelHours: 'Часы',
    labelMinutes: 'Минуты',
    labelNoTimeSelected: 'Время не выбрано',
  },
  BTime: {
    labelHours: 'Часы',
    labelMinutes: 'Минуты',
    labelNoTimeSelected: 'Время не выбрано',
  }
}
export default VueCustomConfig