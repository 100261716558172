import ActionCell from './components/objects/ActionsCell.vue'
import { eventBus } from './components'


const resolveWithPromise = ret => new Promise(resolve => resolve(ret), reject => reject(ret))
const obj_template = function (obj, obj_type_prior = undefined) {
  const type = obj.obj_type || undefined
  if (type) {
    const description = obj._description ? obj._description : obj_type_prior
    const fields = description.fields
    const fields_list = Object.keys(fields)
    const obj_key = description.obj_name
    const templ = description.obj_template || undefined
    // старый вариант
    var ret
    if (fields_list.indexOf(obj_key) !== -1 && !templ) {
      ret = obj[obj_key] || obj.obj_id
    } else if (templ) {
      ret = templ(obj)
    }
    return ret
  } else {
    return
  }
}

const build_options_from_dict = function (obj) {
  return Object.keys(obj).map(key => {
    return { value: key, text: obj[key] }
  })
}
const type_val = val => Object.prototype.toString.call(val).slice(8, -1)
const restore_from_options = function (options, value, as_text = true) {
  let ret = value
  let opt = options.filter(row => row.value == value)
  if (opt.length) {
    ret = as_text ? opt[0].text : opt[0]
  }
  return ret
}
const build_options_from_list = (iterable, key, name) => iterable.map(obj => {
  return {
    text: type_val(name) == 'String' ? obj[name] : name(obj),
    value: obj[key]
  }
})

const actions_field = {
  key: 'actions',
  label: 'Действия',
  cell: ActionCell
}
const id_field_desc = {
  label: 'id',
  readonly: true,
  isKey: true
}
const obj_is_deleted = obj => obj[obj._description.delete_key || 'isActive']

const PromiseWithTimeout = function (promise, timeout = 100, default_value = {}) {
  let timeoutPromise = new Promise(resolve => {
    let id = setTimeout(() => {
      clearTimeout(id);
      resolve(type_val(default_value) == 'Function' ? default_value() : default_value)
    }, timeout)
  })
  // Returns a race between our timeout and the passed in promise
  return Promise.race([
    promise,
    timeoutPromise
  ])
}
const waitForEventWithTimeout = function (emitter, eventName, timeout, default_value = []) {
  return new Promise(resolve => {
    let timer;

    function listener(data) {
      clearTimeout(timer);
      emitter.$off(eventName, listener);
      resolve(data);
    }
    function get_value() {
      return type_val(default_value) == 'Function' ? default_value() : default_value
    }
    emitter.$on(eventName, listener);
    timer = setTimeout(() => {
      emitter.$off(eventName, listener);
      resolve(get_value);
    }, timeout);
  });
}

const bindEvent = (t, event, listner, bus = eventBus, other_unmount = []) => {
  bus.$on(event, listner)
  const unmount = () => {
    bus.$off(event, listner)
  }
  t.$once("hook:beforeDestroy", unmount)
  for (const ou of other_unmount) {
    t.$once(ou, unmount)
  }
}


const bindNativeEvent = (component, target, event, handler = () => { }) => {
  target.addEventListener(event, handler);
  const unmount = () => {
    target.removeEventListener(event, handler);
  }
  component.$once('hook:beforeDestroy', unmount);
}

const uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const gpw = () => Math.random().toString(36).slice(2)

const drop_first = (a, v) => {
  let ret = [...a]
  const findedIndex = ret.indexOf(v)
  return ret.filter((_, i) => i != findedIndex)
}
const drop_list = (a, b) => {
  let ret = [...a]
  b.map(
    v => {
      const findedIndex = ret.indexOf(v)
      ret = ret.filter((_, i) => i != findedIndex)
    }
  )
  return ret
}
const download_from_responce = (response, file_name = null) => {
  if (file_name) {
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file_name); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  }

}

const download_responce_ab = (response, file_name = null) => {
  if (file_name) {
    const blob = new Blob([new Uint8Array(response.data)]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file_name); //or any other extension
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link)
  }

}

const compare_id = (id1, id2) => {
  const id1_ = type_val(id1) == 'String' ? id1.toLowerCase() : id1
  const id2_ = type_val(id2) == 'String' ? id2.toLowerCase() : id2

  return id1_ == id2_
}

function getCoords(elem, prefix = '') { // кроме IE8-
  var box = elem.getBoundingClientRect();

  return {
    [`${prefix}y`]: box.top + scrollY,
    [`${prefix}x`]: box.left + scrollX
  };

}

export {
  type_val,
  obj_template, restore_from_options, build_options_from_dict,
  actions_field, id_field_desc, build_options_from_list, PromiseWithTimeout,
  obj_is_deleted, bindEvent, resolveWithPromise,
  waitForEventWithTimeout, uuidv4, gpw, drop_first, drop_list,
  download_from_responce, compare_id, download_responce_ab,
  bindNativeEvent, getCoords
}