<template>
  <div class="spec-name">
    <p>{{ item.name | spec_name }}</p>
    {{ item.address }}
  </div>
</template>

<script>
import CellVue from "../../mixins/Cell.vue";
export default {
  name: "nameCell",
  mixins: [CellVue],

  filters: {
    spec_name: (val) => {
      let ret = val.split("(")[0];
      return ret;
    },
  },
  computed: {
    customer() {
      const obj_type = "customer";
      return this.$store.getters[`${obj_type}/object`](
        this.$store.getters[`current_${obj_type}`]
      );
    },
  },
};
</script>

<style lang='scss'>
.spec-name {
  p {
    margin-bottom: 0.5em;
  }
}
</style>