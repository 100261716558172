<template>
  <div class="related-cell">
    <router-link :to="link" v-if="isPk">{{ first_value }}</router-link>
    <span v-else>{{ first_value }}</span>
    <fragment v-for="(val, indx) in other_values" v-bind:key="indx">
      <br />
      <span class="text-muted line">
        {{ val }}
      </span>
    </fragment>
  </div>
</template>

<script>
import { Fragment } from "vue-fragment";
import CellVue from "../mixins/Cell.vue";
export default {
  name: "MultiCell",
  props: {
    isPk: {
      required: false,
      type: Boolean,
      default: false,
    },
    keys: {
      required: true,
      type: Array,
    },
  },
  mixins: [CellVue],
  components: { Fragment },
  computed: {
    link() {
      return `./${this.item.obj_type}/${this.item.obj_id}`;
    },
    first_value() {
      return this.item[this.keys[0]];
    },
    other_values() {
      return this.keys
        .slice(1)
        .map((k) => this.item[k])
        .filter((i) => i);
    },
  },
};
</script>

<style>
.line {
  width: 100%;
  display: inline-block;
}
</style>