// import { BFormSpinbutton } from "bootstrap-vue"
import { merge } from "lodash"
import { id_field_desc } from "@/functions.js"
import { InputMixin } from "../../mixins/index"
import { break_form_field, buildRelatedField } from "../fields"
import { MediaCell } from "../media"

const root = '/pbx/services/v2/ivr'
const IVR_URLS = {
  list: `${root}/list`,
  update: `${root}/modify`,
  add: `${root}/create`,
  delete: `${root}/delete`
}
const media_option = { cell: MediaCell, cell_options: { related: true }, required: true }
const media_option_edit = {
  cell: MediaCell, cell_options: { related: true }, required: true, row_props: {
    'label-cols': 6
  }
}

const edit_field = [{
  target: 'caption',
  key: 'caption',
  label: 'Название', isKey: true, isName: true, row_props: {
    'label-cols': 6
  }
},
{
  label: 'Комментарий',
  key: 'description',
  target: 'description', row_props: {
    'label-cols': 6
  }
},
{
  label: 'Время ожидания, сек.',
  target: 'timeout', key: 'timeout', comp: InputMixin, options: { convert_type: parseInt, type: 'number', min: "0" }, row_props: {
    'label-cols': 6
  }
},
  break_form_field,
{
  target: 'repeatCount',
  key: 'repeatCount',
  label: 'Количество повторов', comp: InputMixin, options: { convert_type: parseInt, type: 'number', min: "0" }, row_props: {
    'label-cols': 6
  }
},
buildRelatedField('Голосовое приветствие', 'media', merge(media_option_edit, {
  target: 'hello',
  key: 'hello'
})),
buildRelatedField('Уведомление при ошибке', 'media', merge(media_option_edit, {
  target: 'prompt',
  key: 'prompt'
})),
{
  target: 'notification',
  key: 'notification',
  label: 'Настройка активных кнопок в режиме редактирования в разделе маршрутизации', editable: false, row_props: {
    'label-cols': 11
  }, options: { disabled: true }
}]



const IVR_DESC = {
  verbose: 'Голосовое меню',
  plural: 'Голосовые меню',
  urls: IVR_URLS,
  unique_for: ['customer', 'entity'],
  obj_key: 'service_id',
  fields: {
    service_id: id_field_desc,
    caption: {
      label: 'Название', isKey: true, isName: true
    },
    description: {
      label: 'Комментарий'
    },
    // timeout: {
    //   label: 'Время ожидания, сек.',
    //   comp: BFormSpinbutton,
    //   default: 0,
    //   options: {
    //     min: 5,
    //     max: 90
    //   }
    // },
    //limit: { label: 'Лимит', options: { type: 'number', default: 0 } },
    timeout: {
      label: 'Время ожидания, сек.', comp: InputMixin, options: { convert_type: parseInt, type: 'number', min: "0" }
    },
    repeatCount: {
      label: 'Количество повторов', comp: InputMixin, options: { convert_type: parseInt, type: 'number', min: "0" }
    },
    number: {
      label: 'Номер', editable: true,
    },
    hello: buildRelatedField('Голосовое приветствие', 'media', merge(media_option, { target: 'hello' })),
    prompt: buildRelatedField('Уведомление при ошибке', 'media', merge(media_option, { target: 'prompt' })),
    notification: {
      label: 'Настройка активных кнопок в режиме редактирования в разделе маршрутизации', editable: false, row_props: {
        'label-cols': 11
      }, options: { disabled: true }
    }
  },
  list_fields: ['caption', 'description', 'hello', 'prompt', 'repeatCount'],
  view_fields: ['caption', 'description', 'hello', 'timeout', break_form_field, 'repeatCount', 'prompt'],
  new_fields: ['caption', 'description', 'hello', 'timeout', break_form_field, 'repeatCount', 'prompt', 'notification'],
  edit_fields: edit_field,
}


const test_actions_ivr = [
  { action_id: 1, action: 'employee', destination: '20E7E47F-E776-42E2-8CAC-858DEFEABCC8', timeout: 5 },
  { action_id: 2, action: 'group', destination: 1, timeout: 10 }
]

const test_data_ivr = [
  { ivr_id: 1, name: 'Главное меню', media_id: 1, error_media_id: 2, wait: 2, actions: test_actions_ivr }
]

export { IVR_DESC, test_data_ivr, test_actions_ivr }
export { IVR_ACTION_DESC } from './actions_description'