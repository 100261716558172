var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.real_show),expression:"real_show"}],class:[
    `${_vm.config.objType}-node`,
    'node',
    {
      'node-hover': _vm.hover,
      'node-new': _vm.node_new > 0,
      'node-search': _vm.node_search,
    },
  ],attrs:{"no-body":""},on:{"mouseover":function($event){return _vm.isHover(true)},"mouseleave":function($event){return _vm.isHover(false)}}},[_vm._v(" "+_vm._s(_vm.get_data(_vm.node.data))+" "),_c('b-card-title',{staticClass:"title"},[_vm._l((_vm.inputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],key:output.key,class:['input-socket', output.key],attrs:{"type":"input","socket":output.socket}})}),_vm._v("Перевод на менеджера "),_c('obj-modal',{staticClass:"settings",attrs:{"buttonProps":{
        variant: 'outline',
        class: 'border-0',
      },"obj_id":_vm.service.obj_id,"obj_type":"integration","fields":['caption', 'description'],"noDelete":""},on:{"click":function($event){_vm.modal_show = true}},scopedSlots:_vm._u([{key:"btnContent",fn:function(){return [_c('icon',{attrs:{"icon":_vm.icons.gear}})]},proxy:true}])})],2),(_vm.service)?_c('short-text-span',{staticClass:"name",attrs:{"tag":"h2","text":_vm.service.caption || 'Неизвестно'}}):_vm._e(),_vm._l((_vm.outputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],key:output.key,class:['output-socket', output.key],attrs:{"type":"output","socket":output.socket}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }