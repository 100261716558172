<template>
  <div>
    <b-modal
      v-model="show_person"
      @close="hide"
      @hide="hide"
      @cancel="hide"
      hide-footer
      no-close-on-backdrop
    >
      <obj-form
        v-model="person_id"
        :description="person_id._description"
        mode="view"
        v-if="person_id"
      />
      <client-log v-if="log_id" :call_id="log_id" />
      <call-player
        v-if="audio_id"
        ref="player"
        :call_id="audio_id"
        :buffer="buffer_cache"
      />
    </b-modal>
    <b-modal
      v-model="show_complex_filter"
      @ok="emit_complex_filter"
      ok-title="Применить"
      cancel-title="Закрыть"
      modal-class="responsive-modal"
    >
      <obj-form
        v-model="complex_filter"
        :description="filter_descr"
        mode="edit"
      />
      <template v-slot:modal-footer="{ ok, hide }">
        <b-button-group>
          <b-button size="sm" @click="ok()" variant="success">
            Применить фильтры
          </b-button>
          <b-button size="sm" variant="outline-secondary" @click="hide()">
            Отмена
          </b-button>
          <b-button size="sm" variant="outline" @click="reset_complex_filter()">
            Сбросить фильтры
          </b-button>
        </b-button-group>
        <!-- Button with custom close trigger value -->
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BButton, BButtonGroup } from "bootstrap-vue";
import { eventBus, ObjForm } from "..";
import { bindEvent } from "../../functions";
import { ClientLog } from "../calls";
import { complex_filter_description, default_complex } from "./description";
import { as_icon, Icon } from "../layot";
import { faPlay, faStop, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import CallPlayer from "./CallPlayer.vue";
export default {
  name: "CallsListPost",
  components: {
    BModal,
    ObjForm,
    ClientLog,
    BButton,
    BButtonGroup,
    Icon,
    CallPlayer,
  },
  mounted() {
    bindEvent(this, "show-person", this.show, eventBus);
    bindEvent(this, "show-log", this.show_log, eventBus);
    bindEvent(this, "show-log-media", this.show_audio, eventBus);
    bindEvent(this, "show-complex-form", () => {
      this.show_complex_filter = true;
    });
    bindEvent(this, "reset-complex-filter", () => {
      this.complex_filter = this.build_complex_filter();
    });
    //this.$watch('complex_filter', (to) => //console.log('filter:', to), {deep: true})
    //this.$watch('complex_filter.persons', (to) => //console.log('filter:', to), {deep: true})
  },
  data() {
    return {
      show_person: false,
      log_id: null,
      person_id: null,
      audio_id: null,
      show_complex_filter: false,
      buffer_cache: null,
      complex_filter: this.build_complex_filter(),
      filter_descr: complex_filter_description,
    };
  },
  computed: {
    icons: () => ({
      play: as_icon(faPlay),
      stop: as_icon(faStop),
      volume: as_icon(faVolumeUp),
    }),
  },
  methods: {
    build_complex_filter: () => default_complex(),
    reset_complex_filter() {
      this.complex_filter = this.build_complex_filter();
    },
    emit_complex_filter() {
      this.$eventBus.$emit(
        "new-call-filter",
        Object.assign({}, this.complex_filter)
      );
    },

    show_log(call_id) {
      //this.show_person = true;
      this.log_id = call_id;
    },
    show(person_id) {
      (this.show_person = true),
        //this.person = this.$store.getters.get_object('address_book', person_id)
        (this.person_id = person_id);
    },
    // show_audio(log_id) {
    //   this.show_person = true;
    //   this.audio_id = `${API_SERVER}${log_id}`;
    // },
    play_audio() {
      this.audio_id.start();
    },
    stop_audio() {
      this.audio_id.stop();
    },
    show_audio(call_id) {
      this.$store.dispatch("call/get_audio", call_id).then((responce) => {
        if (responce.status == "200" && responce.data.byteLength) {
          this.buffer_cache = responce.data;
          this.audio_id = call_id;
          this.show_person = true;
        } else {
          this.$store.commit("add_message", {
            type: "danger",
            message: "Запись не найдена",
          });
        }
        eventBus.$emit("show-log-media-loaded", call_id);
      });
    },
    hide() {
      this.show_person = false;
      this.person_id = null;
      this.log_id = null;
      if (this.audio_id) {
        this.$refs.player.reset();
        this.audio_id = null;
        this.buffer_cache = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-outline-primary:hover {
  border: none;
}

.responsive-modal div.modal-dialog {
  max-width: fit-content;
}
.responsive-modal legend {
  min-width: 160px;
}
</style>