import { faChevronDown, faChevronUp } from "../../../../node_modules/@fortawesome/free-solid-svg-icons/index";
import { id_field_desc } from "@/functions.js";
import { as_icon, Icon } from "../../layot/index";
import { textBoolInput } from "../../shared/index";
import { buildBooleanField, buildOptionsField, buildRelatedField } from "../fields";
import { internalLineLengthValidator, internalLineValidator, isNumberValidator, positiveValue, subValidator, Validator } from "../../../validators"
import GroupObj from './GroupObj'
import MaxLength from './MaxLength'
import ActionsEmpl from './ActionsEmpl'
import TextInput from '../InternalLine/TextInput'
import storage from '../../../store'


const test_data = [
]
const fields_list = ['caption', 'description', { label: 'Номер', target: 'number' }, 'strategy', buildRelatedField('Музыка на удержании', 'moh', {
  target: 'MOH'
}),
  {
    label: 'Максимальное время дозвона, сек.', target: 'timeout'
  },
  {
    label: 'Максимальная длина очереди', target: 'maxLength',
  }
]
const new_fields = ['caption', 'description', 'number', 'strategy',
  'additional_settings',
  // 'media_beep', 
  'MOH', 'timeout',
  'wrapUpTime', 'maxLength'
]
const edit_field = ['caption', 'description', { label: 'Номер', target: 'number', editable: false, }, 'strategy', 'additional_settings',
  // 'media_beep', 
  'MOH', 'timeout',
  'wrapUpTime', 'maxLength']

const shevrones = {
  down: as_icon(faChevronDown),
  up: as_icon(faChevronUp)
}

const CALL_STRAT = {
  ringall: 'Звонить всем',
  rrmemory: 'Последовательно',
  linear: 'Последовательно с первого'
}

const root = 'pbx/services/v2/group'
const URLS = {
  add: `${root}/create`,
  list: `${root}/list`,
  delete: `${root}/delete`,
  update: `${root}/modify`
}
const store = {
  getters: {
    unique_for: (state, getters, rootState, rootGetters) => {
      return {
        customer_id: rootGetters.current_customer,
        instance_id: rootGetters.current_instance,
        entity_id: rootGetters.current_entity,
      }
    },
    calced_options: () => obj => ({
      lines_count: obj.members ? obj.members.filter(m => !m.service.state.deleted).length : 0,
      additional_settings: true
    })
  },
  actions: {
    get_members({ dispatch, getters }, object_id) {
      return dispatch(
        'requestApi',
        {
          url: `${root}/members/list`,
          data: {
            ...getters.unique_for,
            service_id: object_id
          }
        }, { root: true }
      )
    },
    add_members({ dispatch, getters }, { object_id, items }) {
      return dispatch(
        'requestApi',
        {
          url: `${root}/members/add`,
          data: {
            ...getters.unique_for,
            service_id: object_id,
            items: items.map(
              e => ({ member_service_id: e, member_service_type: 'internal_line' })
            )
          }
        },
        { root: true }
      )
    },
    delete_members({ dispatch, getters }, { object_id, items }) {
      return dispatch(
        'requestApi',
        {
          url: `${root}/members/remove`,
          data: {
            ...getters.unique_for,
            service_id: object_id,
            items: items.map(
              e => ({ member_service_id: e, member_service_type: 'internal_line' })
            )
          }
        },
        { root: true }
      )
    }
  }
}

const numberExist = number => {
  const message = 'Номер занят';
  const numbers = storage.getters['pbx_group/objects'].map(i => i.number) + storage.getters['internal_line2/objects'].map(i => i.number);
  return subValidator(
    numbers.indexOf(number) > -1, message
  )
}



export default {
  verbose: 'Группу',
  plural: 'Группы',
  obj_name: 'number',
  update_text: 'Группа успешно обновлена',
  error_text: (d) => {
    if (d.data.code == 1006) {
      return d.data.text
    }
    return `Ошибка при создании Группы`
  },
  obj_comp: GroupObj,
  urls: URLS,
  storage: store,
  fields: {
    service_id: id_field_desc,
    service_type: {
      test_val: 'group',
      label: 'Тип',
      editable: false
    },
    entity_id: buildRelatedField('Договор', 'entity'),
    instance_id: buildRelatedField('Инстанс', 'intstance'),
    caption: { label: 'Название', isKey: true },
    description: { label: 'Описание' },
    strategy: buildOptionsField('Алгоритм вызова', CALL_STRAT),
    // number: 'Номер',
    number: {
      label: 'Номер',
      required: true,
      comp: TextInput,
      validator: (val, obj) => Validator(
        val, obj, isNumberValidator, internalLineLengthValidator, internalLineValidator, positiveValue,
        numberExist
      ),
      // description: 'Не может начинаться с единицы',
      options: {
        placeholder: 'XXX',
      },
    },
    email: { label: 'E-mail', description: 'Отчет о пропущенных вызовов на E-mail' },
    lines_count: 'Внутр. номеров',
    additional_settings: {
      label: 'Расширенные настройки',
      default: true,
      row_props: {
        'label-class': 'font-weight-bold',
        'label-cols': 4
      },
      comp: textBoolInput,
      options: {
        false_text: 'Развернуть',
        true_text: 'Свернуть',
        false_comp: { comp: Icon, props: { icon: shevrones.down } },
        true_comp: { comp: Icon, props: { icon: shevrones.up } }
      }
    },
    // группа медиа файлов
    media_beep: buildRelatedField('Голосовое приветствие', 'media', {
      rule: obj => obj.additional_settings,
      editable: false,
    }),
    MOH: buildRelatedField('Музыка на удержании', 'moh', {
      rule: obj => obj.additional_settings,
      options: { defaultFirstAvailable: true }
    }),

    timeout: {
      label: 'Максимальное время дозвона, сек.',
      default: 60,
      rule: obj => obj.additional_settings,
      options: {
        type: 'number',
        min: 2,
      }
    },
    wrapUpTime: {
      label: 'Время отдыха оператора после вызова, сек.',
      default: 5,
      rule: obj => obj.additional_settings,
      description: 'Время следующего вызова вашего сотрудника в группе после завершения предыдущего звонка.',
    },
    maxLength: {
      label: 'Максимальная длина очереди',
      default: 5,
      comp: MaxLength,
      rule: obj => obj.additional_settings,
    },
    timeout_exit: buildBooleanField('Выход при неответе/занятости', false, {
      rule: obj => obj.additional_settings
    }),
    timeout_event: buildBooleanField('Уведомление при неответе/занятости', false, {
      rule: obj => obj.additional_settings
    }),
    interception_group: buildRelatedField('Группа перехвата звонков', 'pbx_group', {
      rule: obj => obj.additional_settings
    }),

    employees: buildRelatedField('Сотрудники', 'internal_line2', { multiple: true }),
    admins: buildRelatedField('Админы', 'employee', { multiple: true }),
  },
  unique_for: ['customer', 'instance'],
  list_fields: [
    'caption', 'number',
    {
      label: 'Внутр. номеров',
      key: 'lines_count',
    },
    {
      label: 'Статус',
      key: 'status'
    },
    {
      label: '', key: 'act2',
      cell: ActionsEmpl
    }
  ],
  new_fields: new_fields,
  view_fields: fields_list,
  edit_fields: edit_field,
  list_fields_search: ['caption', 'number'],
}
export { test_data as PBXGroupTestData, CALL_STRAT }