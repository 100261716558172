<template>
  <div class="object-form empl">
    <portal to="header">
      <div class="header empl">
        <b-button variant="outline-obitless" to="." class="back">
          <obit-icon icon="back" /> Все пользователи
        </b-button>
        <h1 class="header">
          {{ obj.obj_name }} <employee-status :obj="obj" />
        </h1>
      </div>
    </portal>
    <b-tabs v-model="tabIndex" lazy>
      <b-tab title="Профиль и доступы">
        <def-object :obj_id="obj_id" obj_type="employee" no_head />
      </b-tab>
      <b-tab v-if="obj_id !== 'new'" title="История операций" lazy>
        <b-row>
          <b-col class="object">
            <base-list :description="event_description" :objects="events" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ObjForm from "../../ObjForm";
import ObitIcon from "../../obit/ObitIcon";
import EmployeeStatus from "./EmployeeStatus";
import { bindEvent } from "../../../functions";
import { Portal } from "portal-vue";
import BaseList from "../../BaseList.vue";
import { datetimeformatter } from "../../../formatters";
import ObjModal from "../ObjModal";
import DefObject from "../DefObject.vue";

export default {
  name: "employeeView",
  props: ["obj_id"],
  components: {
    ObjForm,
    EmployeeStatus,
    ObitIcon,
    Portal,
    BaseList,
    ObjModal,
    DefObject,
  },
  mounted() {
    bindEvent(
      this,
      "valid",
      (is_valid) => {
        if (is_valid) {
          this.edit_modal = false;
        }
      },
      this.bus
    );
    this.get_events();
  },
  data() {
    const indexes = ["profile", "access", "role", "history"].reduce(
      (ret, row, indx) => Object.assign(ret, { [row]: indx }),
      {}
    );
    const position = this.$route.query.position || "profile";
    return {
      tabIndex: indexes[position] || 0,
      edit_modal: false,
      roles: [],
      events: [],
    };
  },
  computed: {
    tabsIndexes: () =>
      ["profile", "access", "role", "history"].reduce(
        (ret, row, indx) => Object.assign(ret, { [row]: indx }),
        {}
      ),

    nextDelete() {
      return this.$route.path.split("/").slice(0, -1).join("/");
    },
    obj_type: () => "employee",
    obj() {
      return this.$store.getters["employee/object"](this.obj_id) || {};
    },
    bus() {
      return this;
    },
    event_description: () => ({
      verbose: "Действия",
      fields: {
        date: { label: "Дата", table_formatter: datetimeformatter },
        action: "Дейтвие",
      },
      list_fields: ["date", "message"],
    }),
  },
  methods: {
    events_filter(row) {
      return row.event_type == "employee";
    },
    save_obj() {
      this.$emit("save");
    },
    get_events() {
      this.$store
        .dispatch("employee/get_actions", {
          obj_id: this.obj_id,
        })
        .then((events) => {
          if (events) {
            this.events = events;
          }
        });
    },
  },
};
</script>

<style lang='scss'>
.object-form.empl {
#name_row {
  margin-bottom: 1rem;
}
#name * {
  display: inline;
}
.empl.header {
  .back {
    padding-top: 0px;
    padding-left: 0px;
  }
  h1.header {
    padding-bottom: 0px;

    padding-top: 0px;
    padding-left: 0px;
  }
}
.empl {
  .row.back,
  .row.header {
    padding: 15px 10px;
    margin: 0;
  }
  .object {
    margin: 15px 30px;
  }
}
.btn-success {
  order: 2;
}
.btn-trash {
  order: -2;
}
}
</style>