<template>
  <div class="person_cell">
    <span class="text-primary phone">
      {{ (person.obj_name || value) | phone }}
    </span>
    <span v-if="show_exten && has_exten" class="exten text-muted">
      {{ item.exten }}
    </span>
  </div>
</template>

<script>
import CellVue from "../mixins/Cell.vue";
import { EventBus } from "..";
import { number_formatter } from "../../formatters";
export default {
  name: "PersonCell",
  mixins: [CellVue, EventBus],
  computed: {
    person() {
      return this.$store.getters["internal_line2/object"](this.value) || {};
    },
    has_exten() {
      return this.item.exten ? true : false;
    },
    show_exten() {
      const item = this.item;
      const key = this.field.key;
      const ret =
        (item.direction == "incoming" && key == "to") ||
        (item.direction == "outgoing" && key == "from");
      return ret;
    },
  },
  filters: {
    phone: number_formatter,
  },
};
</script>

<style lang='scss'>
.person_cell {
  span {
    width: 100%;
    display: inline-block;
    &.phone {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      white-space: nowrap;
    }
    &.exten {
      font-size: 12px;
      line-height: 14.4px;
      margin-top: 5px;
    }
  }
}
</style>