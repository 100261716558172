<script>
import {type_val} from '../../functions'
export default {
    name: 'WithBodyClasses',
    mounted() {
        this.toggle_body_classes(true)
    },
    beforeDestroy() {
            this.toggle_body_classes(false)
    },
    computed: {
        body_classes() {
            return []
        }
    },
    methods: {
        toggle_body_classes(force, classes) {
            const _classes = classes || this.body_classes
            if (type_val(_classes) === 'Array') {
                _classes.forEach(element => {
                    document.body.classList.toggle(element, force)
                })
            } else if (type_val(_classes) === 'String') {
                document.body.classList.toggle(_classes, force)
            } else {
                Object.entries(_classes).map((row) => {
                    const [_cls, tgl] = row
                    document.body.classList.toggle(_cls, tgl)
                })
            }
        }
    }
}
</script>