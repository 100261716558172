<template>
  <def-objects-list obj_type="media" :show_header="false">
    <template v-slot:create_modal="scope">
      <!-- <b-tabs>
        <b-tab title="Синтез речи" disabled />
        <b-tab title="Файл с диска">
          <br />
          <obj-form mode="new" v-model="scope.obj" v-bind="scope" />
        </b-tab>
      </b-tabs> -->
      <obj-form mode="new" v-model="scope.obj" v-bind="scope" />
    </template>
  </def-objects-list>
</template>

<script>
import { BFormRadioGroup, BFormSelect, BFormTextarea } from "bootstrap-vue";
import ObjForm from "../../ObjForm.vue";
import DefObjectsList from "../DefObjectsList.vue";
import fileUpload from "./fileUpload";

const sintez_description = {
  fields: {
    message: {
      label: "Текст сообшения",
      comp: BFormTextarea,
      options: {
        cols: 4,
      },
    },
    voice: {
      label: "Голос",
      comp: BFormRadioGroup,
      options: {
        stacked: true,
        options: [
          { value: "f", text: "Женский" },
          { value: "m", text: "Мужской" },
        ],
      },
    },
    speed: {
      label: "Скорость",
      comp: BFormSelect,
      options: {
        options: [
          { value: 1, text: "Быстро" },
          { value: 2, text: "Средняя" },
          { value: 3, text: "Медлено" },
        ],
      },
    },
    title: "Название",
    type: {
      label: "Тип",
      comp: BFormSelect,
      options: {
        options: [
          { value: 1, text: "Голосове сообщения" },
          { value: 2, text: "Музыка на удержания" },
        ],
      },
    },
  },
};
const file_description = {
  basename: "media",
  fields: {
    file: {
      label: " ",
      row_props: { "label-cols": 0 },
      comp: fileUpload,
      target: "file",
    },
  },
};
export default {
  name: "MediaList",
  components: { DefObjectsList, ObjForm },
  computed: {
    descriptions: () => ({
      sintez: sintez_description,
      file: file_description,
    }),
  },
};
</script>

<style>
.objects-list.media {
  display: block;
}
</style>