<script>
import { type_val } from '../../functions'

export default {
    name: 'ListWithFilter',
    data() {return {
        filter: ''
    }},
    computed: {
        search_fields() {
            return this.description.list_fields_search || []
        },
        show_filter() {
            return this.search_fields.length
        },
        filtered_objects() {
            var objs = this.objects
            if (this.show_filter) {
                const filters = this.search_fields
                objs = objs.filter(
                    row => filters.some(filter => {
                        if (type_val(filter) == 'String') {
                            return row[filter] == this.filter
                        }
                        if (type_val(filter) == 'Function') {
                            return filter(row)
                        }
                    })
                )
            }
            return objs
        }
    }
}
</script>