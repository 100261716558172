<template>
  <div class="hr-line-dashed" />
</template>

<script>
export default {
  name: "BreakForm",
};
</script>

<style lang='scss'>
@import "../../../static/SCSS/obit-colors";
.hr-line-dashed {
  border-top: 1px solid $obit-gray;
  margin-bottom: 1em;
}
</style>