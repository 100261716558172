<template>
  <call-player v-if="value" :call_id="value" />
</template>

<script>
import { InputMixin } from "../mixins";
import CallPlayer from "./CallPlayer.vue";
export default {
  components: { CallPlayer },
  mixins: [InputMixin],
};
</script>

<style>
</style>