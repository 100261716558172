<script>
import ObjList from '../ObjList'
import EventBus from '../../EventBus'
import InternalLineTableVue from './InternalLineTable.vue'
export default {
    name: 'IntextList',
    mixins: [ObjList, EventBus],
    computed: {
        obj_type: () => 'internal_line',
        table_comp: () => InternalLineTableVue
    },
}
</script>