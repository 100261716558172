<template>
  <div v-if="!description.no_search && objects_in.length" class="search_filter">
    <b-form
      inline
      @keyup.enter.stop.prevent="apply_filter"
      @keydown.enter.stop.prevent="apply_filter"
    >
      <b-form-row class="default_filter">
        <b-input-group>
          <slot name="presearch" />
          <b-form-input
            :placeholder="input_placeholder"
            v-model="filter.input"
            v-if="_filter_fields.length > 0"
            :class="{ 'border-right-0': filter.input }"
            @focus="focus = true"
            @blur="focus = false"
          />
          <template v-slot:append v-if="filter.input">
            <b-button
              variant="outline"
              :class="[
                'border',
                'border-left-0',
                'text-primary',
                'clear-input',
                { focus: focus },
              ]"
              @click="filter.input = ''"
              ><obit-icon icon="cancel"
            /></b-button>
          </template>
          <slot name="postsearch" />
          <slot />
        </b-input-group>
        <portal-target
          name="create_button"
          class="ml-auto create-button"
          v-if="is_vertical.value"
        />
      </b-form-row>
    </b-form>
  </div>
</template>

<script>
import { type_val } from "../../functions";
import ObitIcon from "../obit/ObitIcon.vue";
import { PortalTarget } from "portal-vue";

export default {
  name: "FilterMixin",
  components: {
    ObitIcon,
    PortalTarget,
  },
  props: {
    objects_in: {
      required: true,
      type: Array,
    },
    description: {
      required: false,
      type: Object,
      default: null,
    },
    value: {
      required: true,
      type: Array,
    },
    filter_string: {
      required: false,
      type: String,
      default: "Поиск по",
    },
  },
  mounted() {
    this.filter = this.default_filter();
    this.apply_filter();
    // будем следить за всем входящим в дефолтное значение TODO: сделать рекурсивным?
    //
    this.$watch(() => this.objects_in.length, this.apply_filter);
    this.$watch(
      () => JSON.stringify(this.filter),
      () => {
        this.apply_filter();
      },
      { deep: true }
    );
  },
  inject: ["is_vertical"],
  methods: {
    apply_filter() {
      this.$emit("filter");
      let obj_list = this.objects_in.filter(this.rule);
      this.objects = obj_list.map((o) => o.obj_id);
    },
    default_rule(row) {
      const store = this.$store;
      const search = ([field, value]) => {
        let ret = false;
        if (this._filter_fields.indexOf(field) >= 0) {
          const field_descr = this.descr.fields[field] || {};
          const related = field_descr.related;
          if (!value) return false;
          if (type_val(value) !== "String") {
            if (type_val(value) == "Number") {
              value = `${value}`;
            }
          }
          if (!related) {
            ret =
              value.toLowerCase().indexOf(this.filter.input.toLowerCase()) >= 0;
          } else {
            if (!field_descr.multiple) {
              const val = store.getters[`${related}/object`](value);
              if (val) {
                ret =
                  val.obj_name
                    .toLowerCase()
                    .indexOf(this.filter.input.toLowerCase()) >= 0;
              }
            } else {
              const val = store.getters[`${related}/objects`].filter(
                (r) => value.indexOf(r.obj_id) >= 0
              );
              if ((val || []).length) {
                ret = val.some(
                  (r) =>
                    r.obj_name
                      .toLowerCase()
                      .indexOf(this.filter.input.toLowerCase()) >= 0
                );
              }
            }
          }
        }
        return ret;
      };
      if (this.filter.input.length == 0) return true;
      return Object.entries(row).some(search);
    },
    rule(row) {
      return this.default_rule(row);
    },
    default_filter() {
      return {
        input: "",
      };
    },
  },
  computed: {
    descr() {
      return this.description || {};
    },

    _filter_fields() {
      let ret = [];
      for (const [field, descr] of Object.entries(this.descr.fields)) {
        if (descr.search) {
          ret.push(field);
        }
      }
      for (const field of this.descr.list_fields_search || []) {
        if (ret.indexOf(field) < 0) {
          ret.push(field);
        }
      }
      return ret;
    },
    objects: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", to);
      },
    },

    input_placeholder() {
      return this.description.search_placeholder ?? this.filter_string;
    },
  },
  data() {
    return {
      filter: this.default_filter(),
      focus: false,
    };
  },
  watch: {
    //'filter.input'() {this.apply_filter()},
    // objects_in() {
    //   this.filter = this.default_filter();
    //   this.apply_filter();
    // },
  },
};
</script>

<style lang='scss'>
.search_filter {
  .form-row.default_filter {
    body.vertical & {
      margin-left: 0;
      margin-right: 0;
      button.btn.add-item {
        padding-left: 5px;
        padding-right: 5px;
        font-size: 14px;
        vertical-align: middle;
      }
    }
    .input-group {
      width: 286px !important;
      body.vertical & {
        width: 50% !important;
      }
      .focus {
        border-color: var(--primary) !important;
      }
      .clear-input {
        max-height: 38px;
      }
    }
    &:first-child {
      margin-left: 1em;
      flex: 2;
    }
    .export {
      margin-right: 1em;
      vertical-align: middle;
      font-weight: 500;
      body.vertical & {
        display: none;
      }
    }
  }
}
</style>