<template>
  <span>{{ value }}</span>
</template>

<script>
const not_req_props = [
  "unformatted",
  "field",
  "detailsShowing",
  "toggleDetails",
  "rowSelected",
  "unselectRow",
  "value",
  "formatted",
];
const additional_props = not_req_props.reduce(
  (ret, f) => Object.assign(ret, { [f]: { required: false } }),
  {}
);
export default {
  name: "CellMixin",
  props: {
    item: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    related: {
      type: Boolean,
      required: false,
      default: false,
    },
    isKey: {
      type: Boolean,
      default: false,
      required: false,
    },
    _table: {
      type: Object,
      required: false,
    },
    ...additional_props,
  },
  mounted() {
    if (this.field) {
      this.$el.parentElement.classList.add(`field_${this.field?.key}`);
    }
  },
  computed: {
    obj_type() {
      return this.item?.obj_type;
    },
    description() {
      return this.item?._description;
    },
    obj_id() {
      return this.item?.obj_id;
    },
    field_desc() {
      return this.description?.fields[this.field?.key] ?? {};
    },
  },
};
</script>