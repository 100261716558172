<template>
  <span v-if="status" :class="['employee-status', `status-${status.status}`]">
    <p class="mb-0">{{ status.text }}</p>
  </span>
</template>

<script>
export default {
  name: "EmployeeStatus",
  props: {
    _obj: {
      required: false,
    },
    item: {
      required: false,
    },
  },
  computed: {
    object() {
      const ret = this.item || this._obj;
      return ret;
    },
    status() {
      let ret = null;
      if (this.object)
        ret = { status: this.object.status, text: this.object.status_human };
      return ret;
    },
  },
};
</script>

<style lang='scss'>
@import "../../../../static/SCSS/obit-colors";
.employee-status {
  &.status-active {
    color: $obit-success;
  }
  &.status-deleted {
    color: $obit-danger;
  }
  &.status-not-approved {
    color: $obit-warning;
  }
  &.status-not-active {
    color: $obit-danger;
  }
}
</style>