<template>
  <div class="group-list-view">
    <portal to="create_button">
      <b-button
        v-if="!descr.no_create_button"
        variant="outline-obitless"
        class="add-item"
        @click="show_create"
      >
        <obit-icon icon="plus" />
        {{ descr.create_button_text || `Создать ${descr.verbose}` }}
      </b-button>
      <component
        v-else-if="descr.create_button"
        :is="descr.create_button"
        v-bind="descr.create_button_props"
        variant="outline-obitless"
        class="add-item"
        @click="show_create"
      />
    </portal>
    <b-overlay :show="loading" class="group-view" bg-color="white">
      <b-list-group flush class="group-items">
        <group-item
          v-for="item in items"
          :key="item.obj_id"
          :group_obj="item"
          @member_list="update_members_list"
          :sub="all_subempl"
          @show_info="update_current_info"
          :bus="bus"
        />
      </b-list-group>

      <div v-if="current_info" class="group-info">
        <h3>
          <icon class="icon text-primary" :icon="icons.group" />
          {{ group_info.obj_name }}
        </h3>
        <b-overlay :show="roles_loading">
          <p class="roles-list">
            Роли:
            <span v-if="roles.length == 0">Нет</span
            ><b-badge
              v-else
              v-for="role in roles"
              :key="role.role_id"
              variant="primary"
              >{{ role.title }}</b-badge
            >
          </p>
        </b-overlay>
        <p>Количество пользователей: {{ group_info.members.length }}</p>
        <p v-if="group_info.parent_id">
          Родительская группа: {{ get_group_name(group_info.parent_id) }}
        </p>
        <b-list-group
          v-if="current_info == current_group"
          flush
          class="group-employees"
        >
          <b-list-group-item v-if="employees_list.length == 0 && group">
            Пусто
          </b-list-group-item>
          <b-list-group-item
            v-for="item in employees_list"
            :key="item.obj_id"
            @click="show_modal(item.obj_id)"
          >
            <div class="icon-block">
              <icon
                :class="['icon', item.deleted ? 'text-danger' : 'text-primary']"
                :icon="icons.user"
              />
            </div>
            <div>{{ item.obj_name }}</div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-overlay>
    <obj-modal
      obj_type="employee"
      :obj_id="employee_id"
      modalOnly
      ref="edit_empl"
    />
    <b-modal
      :title="create_title"
      v-model="show_create_modal"
      v-bind="descr.create_modal_props || {}"
      @ok.prevent="save_method"
      no-close-on-backdrop
    >
      <slot
        name="create_modal"
        v-bind="{
          obj: new_object,
          description: descr,
          obj_type: descr.obj_type,
          eventBus: bus,
          value: new_object,
        }"
      >
        <obj-form
          v-model="new_object"
          :description="descr"
          mode="new"
          :eventBus="bus"
          :line_props="{ 'label-cols': 4 }"
          :obj_type="descr.obj_type"
          ref="new_form"
        />
      </slot>
      <template v-slot:modal-footer="m">
        <b-button class="def-save" variant="obit" @click="m.ok()"
          >Сохранить</b-button
        >
        <b-button variant="outline-obit" @click="m.cancel()">Отмена</b-button>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { as_icon, Icon, Portal } from "../../layot";
import GroupItem from "./GroupItem.vue";
import { flatten, uniq } from "lodash-es";
import ObjModal from "../ObjModal.vue";
import Vue from "vue";
import { ObjForm } from "../..";
import { bindEvent } from "../../../functions";

const group_obs = Vue.observable({
  value: null,
  current_group: null,
});
export default {
  components: { GroupItem, Icon, ObjModal, Portal, ObjForm },
  name: "GroupListView",
  provide() {
    return {
      current_group: group_obs,
    };
  },
  data: () => ({
    loading: false,
    all_subempl: false,
    show_deleted: false,
    current_group: null,
    employees: [],
    employee_id: null,
    current_info: null,
    show_create_modal: false,
    new_object: {},
    roles_loading: false,
    roles: [],
  }),
  async mounted() {
    this.load_data();
    group_obs.value = null;
    this.$watch("current_group", (to) => {
      group_obs.value = to;
    });
    bindEvent(this, "updated:group", () => {
      this.$forceUpdate();
    });

    bindEvent(
      this,
      "show_info",
      async (to) => {
        if (to != this.current_info) {
          this.update_current_info(to);
          if (this.group_info.roles === undefined) {
            this.roles_loading = true;
            let resp = await this.$store.dispatch("group/get_roles", {
              obj_id: to,
              update_group: false,
            });
            this.roles_loading = false;
            if (resp.data.result == "success") {
              this.roles = resp.data.payload;
            } else {
              this.roles = [];
            }

            //
          } else {
            let current = this.group_info.roles;
            this.roles = this.$store.getters["role/objects"].filter(
              (r) => current.indexOf(r.role_id) != -1
            );
          }
        }
      },
      this
    );
    bindEvent(
      this,
      `added:${this.obj_type}`,
      () => (this.show_create_modal = false)
    );
    bindEvent(
      this,
      "valid",
      (is_valid) => {
        if (is_valid) {
          this.show_create_modal = false;
        }
      },
      this
    );
  },
  methods: {
    get_role_name(role_id) {
      return this.$store.getters["role/object"](role_id)?.obj_name || "---";
    },
    get_group_name(group_id) {
      return this.$store.getters["group/object"](group_id)?.obj_name || "---";
    },
    update_current_info(obj_id) {
      this.current_info = obj_id;
      group_obs.current_group = obj_id;
      //this.employees = this.group.members.map((o) => o.toLowerCase());
    },
    show_create() {
      this.show_create_modal = true;
    },
    save_method() {
      const def_save = () => this.$emit("save");
      const method = this.descr.save_method || def_save;
      method(this);
    },
    async load_data() {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch("group/list"),
        process.env.VUE_APP_IS_EMPLOYEE !== `false` ? this.$store.dispatch("employee/list") : true,
        this.$store.dispatch("role/list"),
      ]);
      this.loading = false;
    },
    update_members_list(obj_id) {
      this.current_group = obj_id;
      this.employees = this.group.members.map((o) => o.toLowerCase());
    },
    show_modal(id) {
      this.employee_id = id;
      this.$refs.edit_empl.$emit("show");
    },
  },
  computed: {
    bus() {
      return this;
    },
    create_title() {
      return this.descr.create_title || `Создать ${this.descr.verbose}`;
    },
    descr() {
      return this.$store.getters["group/description"];
    },
    group() {
      return this.current_group
        ? this.$store.getters["group/object"](this.current_group)
        : null;
    },
    group_info() {
      return this.current_info
        ? this.$store.getters["group/object"](this.current_info)
        : null;
    },
    icons: () => ({
      user: as_icon(faUser),
      group: as_icon(faUsers),
    }),
    items() {
      return this.$store.getters["group/items"]();
    },
    employees_list() {
      let ret;
      if (this.group && this.group.parent_id == null)
        ret = this.$store.getters["employee/objects"];
      else if (this.all_subempl) {
        const groups = this.$store.getters["group/items"](
          this.current_group,
          true
        );
        const employees = uniq(flatten(groups.map((g) => g.members))).map((e) =>
          e.toLowerCase()
        );
        ret = this.$store.getters["employee/objects"].filter(
          (e) => employees.indexOf(e.obj_id.toLowerCase()) > -1
        );
      } else
        ret = this.$store.getters["employee/objects"].filter(
          (item) => this.employees.indexOf(item.obj_id.toLowerCase()) != -1
        );
      if (!this.show_deleted) {
        ret = ret.filter((o) => !o.deleted);
      }
      return ret;
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.group-list-view {
  max-width: 930px;
  .custom-control {
    padding: 1rem 1rem 1rem 1.5rem;
    border-bottom: 2px solid rgba(0, 0, 0, 0.125);
  }
  p.roles-list {
    .badge {
      margin-right: 0.5em;
    }
  }
  .group-view {
    display: flex;
    flex-direction: row;
    align-items: stretch !important;
    padding-bottom: 2em;
    &.b-overlay-wrap {
      // border: 1px solid rgba(0, 0, 0, 0.125);
      align-items: baseline;
      .list-group {
        width: 100%;
      }
    }
    .group-items {
      max-width: 350px;
      width: 100%;
      min-height: 20em;
      padding-left: 18px;
      padding-top: 1em;
    }
    .icon-block {
      padding: 0 7px 0 0;
    }
    .group-info {
      border-left: 1px solid $obit-lightgray;
      height: auto;
      padding-left: 2em;
      padding-top: 1em;
      align-self: stretch;
    }
  }
}
</style>