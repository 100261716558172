<script>
import Vue from 'vue'

const eventBus = new Vue()

Vue.prototype.$eventBus = eventBus

export default {
    name: 'EventBus',
    mounted() {
        this.$eventBus.$on('refresh', this._refresh)
    },
    beforeDestroy() {
        this.$eventBus.$off('refresh')
    },
    methods: {
        '_refresh': function() {
            this.$forceUpdate()
        }
    }
}
export {eventBus}
</script>