<template>
  <div class="bg-white spec-list">
    <div class="title">
      <h1 class="head">Договор {{ contract.obj_name }}</h1>
      <h1 class="description">Перечень документов</h1>
    </div>
    <obj-list obj_type="spec" disable_search />
  </div>
</template>

<script>
import ObjList from "../ObjList.vue";
export default {
  components: { ObjList },
  name: "SpecsList",
  computed: {
    contract() {
      return this.$store.getters["contract/object"](
        this.$store.getters.current_contract
      );
    },
    customer() {
      return this.$store.getters["customer/object"](
        this.$store.getters.current_customer
      );
    },
  },
};
</script>

<style lang='scss'>
.spec-list {
  padding: 15px;
  .title {
    padding: 15px 0px;
    .head {
      $size: 30px;
      font-size: $size;
      line-height: $size + 2.2px;
      text-transform: initial;
    }
  }
}
</style>