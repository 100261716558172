<template>
   <div class="activity-stream">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ActivityStream',
    computed: {
        length: () => 0
    }
}
</script>

<style>

</style>