<template>
  <div class="blur-table">
    <b-table
      :items="objects"
      :fields="fields"
      v-if="objects.length"
      v-bind="{ ...table_props, ...$attrs }"
      v-b-visible="showPost"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      responsive
      ref="table"
      show-empty
      @update:sortBy="$emit('sorted')"
      @update:sortDesc="$emit('sorted')"
      :no-local-sorting="noLocalSorting"
      :class="['objects-table', `objects-${description.obj_type}`]"
    >
      <template
        v-for="(field, indx) in fields"
        :slot="'head(' + field.key + ')'"
        slot-scope="data"
      >
        <div :key="indx" v-parent_classes="{ classes: [`field_${field.key}`] }">
          {{ data.label }}
        </div>
      </template>
      <template
        v-for="(field, indx) in fields"
        :slot="'cell(' + field.key + ')'"
        slot-scope="obj"
      >
        <component
          v-if="field.cell"
          :is="field.cell"
          v-bind="cell_data(obj, field)"
          :index="indx"
          :key="indx"
        />
        <div
          v-else
          :key="indx"
          v-parent_classes="{ classes: [`field_${field.key}`] }"
        >
          {{ obj.value }}
        </div>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData" />
      </template>
      />
    </b-table>

    <div v-else>
      <slot name="empty">
        <component :is="empty_component" v-if="empty_component" />
        <h2 v-else>{{ real_empty_msg }}</h2>
      </slot>
    </div>

    <slot name="post_component">
      <component :is="post_comp" v-if="post_comp && post_is_visible" />
    </slot>
    <portal to="paginator" v-if="use_paginator">
      <b-row v-if="showPaginator" class="paginator-row">
        <b-col xl="4" cols="6"
          ><b-pagination
            v-model="page"
            class="obit-paginator"
            :total-rows="objects.length"
            :per-page="currentPerPage"
            size="sm"
        /></b-col>
        <b-col>
          <ul class="pagination obit-paginator b-pagination pagination-sm">
            <li class="text">Показать пo</li>
            <li
              v-for="(v, i) in per_pages_variants"
              :key="i"
              :class="['page-item', { active: per_page == v }]"
            >
              <button class="page-link" @click="per_page = v">{{ v }}</button>
            </li>
          </ul></b-col
        >
        <!-- <b-col></b-col> -->
      </b-row>
    </portal>
  </div>
</template>

<script>
import { VBVisible } from "bootstrap-vue";
import EventBus from "./EventBus";
import { METHODS_MIX, COMPUTED_MIX } from "./mixins/WithDescription";
import { type_val } from "../functions";
import parent_classes from "../directives/parent_class";
import { Portal } from "portal-vue";
import { pick } from "lodash-es";
import Scrollable from "simplebar-vue";

export default {
  name: "BaseList",
  directives: { "b-visible": VBVisible, parent_classes: parent_classes },
  props: {
    objects: {
      required: true,
      type: Array,
    },
    description: {
      required: true,
      type: Object,
    },
    perPage: {
      default: 7,
      type: Number,
    },
    replace_fields: {
      default: null,
      required: false,
    },
    maxDivergence: {
      default: 3,
      type: Number,
    },
    empty_comp: {
      required: false,
      default: null,
    },
    empty_msg: {
      required: false,
      default: "Ничего не найдено",
      type: String,
    },
    noLocalSorting: {
      default: false,
      required: false,
      type: Boolean,
    },
    per_pages_variants: {
      required: false,
      type: Array,
      default: () => [10, 30, 100],
    },
    use_paginator: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  components: { Portal, Scrollable },
  mixins: [EventBus],
  mounted() {
    this.$watch(
      () => this.description.obj_type,
      () => this.$forceUpdate()
    );
  },
  data() {
    return {
      page: 1,
      post_is_visible: true,
      per_page: 10,
      sortBy: null,
      sortDesc: false,
    };
  },
  computed: {
    real_empty_msg() {
      return this.description?.empty_msg ?? this.empty_msg;
    },
    ...COMPUTED_MIX,
    other_cells() {
      this.fields;
      // получим список кастомных клеток
      return this.fields.filter((f) => f.cell).map((f) => [f, f.cell]);
    },
    mode: () => "list",
    showPaginator() {
      return (
        this.objects.length >=
        Math.min(...this.per_pages_variants) + this.maxDivergence
      );
    },
    currentPerPage() {
      return this.showPaginator
        ? this.per_page
        : this.per_page + this.maxDivergence;
    },
    empty_component() {
      const comp = this.description.list_empty_component
        ? this.description.list_empty_component
        : this.empty_comp;
      return comp;
    },
    post_comp() {
      const pc = this.description.list_post_comp || null;
      return pc;
    },
    table_props() {
      let ret = {};
      if (this.use_paginator) {
        ret["per-page"] = this.currentPerPage;
        ret["current-page"] = this.page;
      }
      return ret;
    },
    sortOptions() {
      const keys = ["sortBy", "sortDesc"];
      const table = this.$refs["table"];
      return pick(table?.context || {}, keys);
    },
  },
  methods: {
    reset_page() {
      this.page = 1;
    },
    ...METHODS_MIX,
    showPost(vis) {
      this.post_is_visible = vis;
    },
    cell_data(obj, field) {
      const field_descr = field;
      const cell_options = field_descr.cell_options;
      let addtitional_data = {};
      if (type_val(cell_options) == "Function") {
        addtitional_data = cell_options(obj, this);
      } else if (type_val(cell_options) == "Object") {
        addtitional_data = cell_options;
      }
      return Object.assign(
        { field_desc: field, _table: this },
        obj,
        addtitional_data
      );
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.objects-table {
  thead {
    th {
      border-top: none !important;
      color: $obit-darkgray;
      line-height: 14.4px;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
    }
  }
  tbody {
    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
      font-style: normal;
      vertical-align: inherit;
      // vertical-align: bottom;
      // vertical-align: baseline;
      .text-muted {
        font-size: 12px;
        line-height: 14.4px;
      }
    }
  }
}
.paginator-row {
  margin-top: 2em !important;
  margin-left: 25px !important;
  @media (max-width: 480px) {
    margin-left: 0 !important;
  }
}

.pagination .text {
  height: 2.5em;
  line-height: 1.5;
  margin: 0.5em 0.5em;
}

.blur-table::after {
  content: "";
  position: absolute;
  top: 134px;
  right: 0px;
  width: 20px;
  // height: 100%;
  background: linear-gradient(
    90deg,
    #ffffff 7.06%,
    rgba(255, 255, 255, 0) 118.33%
  ); /* transparent keyword is broken in Safari */
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
</style>