<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
  >
    {{ get_data(node.data) }}
    <b-card-title class="title">
      <Socket
        v-for="input in inputs()"
        :key="input.key"
        v-socket:input="input"
        type="input"
        :class="['input-socket', input.key]"
        :socket="input.socket"
      />
      {{ config.name }}
      <obj-modal
        v-if="service && has_edit_modal"
        :obj_id="service.service_id"
        :obj_type="service.obj_type"
        noDelete
      >
        <template #default="{ show_modal }">
          <b-button pill variant="outline" sm @click="show_modal">
            <icon :icon="icons.gear" />
          </b-button>
        </template>
      </obj-modal>
    </b-card-title>
    <short-text-span
      tag="h2"
      class="name"
      v-if="service"
      :text="service.obj_name || ''"
    />
    <fragment v-if="!config.namedOutputs">
      <Socket
        v-for="output in outputs()"
        :key="output.key"
        v-socket:input="output"
        type="output"
        :class="['output-socket', output.key]"
        :socket="output.socket"
      />
    </fragment>
    <fragment v-else>
      <div v-for="output in outputs()" :key="output.key" class="output-line">
        <div class="out">
          <span class="name"> {{ output.name }}</span>
          <Socket
            v-socket:input="output"
            type="output"
            :class="['output-socket', output.key]"
            :socket="output.socket"
          />
        </div>
      </div>
    </fragment>
  </b-card>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { as_icon, Icon, Fragment } from "@/components/layot";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { bindEvent } from "../../../../functions";
import { eventBus } from "../../..";
import ShortTextSpan from "../../../shared/shortTextSpan.vue";
import ObjModal from "../../../objects/ObjModal.vue";

import AreaPlugin from "rete-area-plugin";

export default {
  name: "ComponentMixin",
  mixins: [VueRenderPlugin.mixin],
  components: {
    Socket: VueRenderPlugin.Socket,
    Icon,
    Fragment,
    ShortTextSpan,
    ObjModal,
  },
  data: () => ({
    modal_show: false,
    service_id: null,
    local_data: {},
    hover: false,
    members: [],
    node_new: 0,
    node_search: false,
    wait_add_click: false,
    added: false,
  }),
  methods: {
    searchNode(goto = false) {
      this.node_new = 10;
      let timerId = setInterval(() => {
        if (this.node_new > 0) {
          this.node_new -= 1;
        } else {
          clearInterval(timerId);
        }
      }, 1000);
      if (goto) {
        AreaPlugin.zoomAt(this.editor, [this.node]);
      }
    },
    hightlightNode(val) {
      this.node_search = val;
    },
    get_data(data) {
      this.local_data = data;
      this.service_id = data.service_id || null;
      return null;
    },
    isHover(val) {
      this.hover = val;
      // if (val) {
      //   this.$el.parentNode.classList.add("node-hover");
      // } else {
      //   this.$el.parentNode.classList.remove("node-hover");
      // }
    },
    show_settings() {
      this.modal_show = true;
    },
    action_show_node() {
      this.node.data.show = true;
      this.node.update();
      let node = this.node;
      this.editor.view.updateConnections({ node });
    },
    show_node() {
      this.wait_add_click = true;
    },
  },
  computed: {
    config: () => ({}),
    has_edit_modal() {
      return this.config.edit_modal || false;
    },
    icons: () => ({
      gear: as_icon(faCog),
    }),
    service() {
      if (!this.config.objType) return;
      return this.service_id
        ? this.$store.getters[`${this.config.objType}/object`](this.service_id)
        : null;
    },
    real_show() {
      return this.local_data.show || this.local_data.has_root;
    },
  },
  mounted() {
    bindEvent(
      this,
      `show_node_${this.node.id}`,
      () => {
        this.show_node();
        // AreaPlugin.zoomAt(this.editor, [node]);
      },
      eventBus
    );
    bindEvent(
      this,
      `search_node_${this.node.id}`,
      (val) => {
        this.hightlightNode(val);
        this.node.update();
        this.$forceUpdate();
      },
      eventBus
    );
    this.$watch(
      () => this.local_data.show,
      () => {
        let node = this.node;
        this.editor.view.updateConnections({ node });
        this.node.update();
        this.$forceUpdate();
        this.searchNode();
      }
    );

    bindEvent(
      this,
      "click_with_coor",
      () => {
        if (this.wait_add_click) {
          this.wait_add_click = false;
          //const { x, y, offsetX, offsetY } = e;
          this.action_show_node();
          setTimeout(() => {
            const node = this.editor.view.nodes.get(this.node);
            const el = node?.el;
            const { x, y } = this.editor.view.area.mouse;
            const { clientWidth, clientHeight } = el;
            node.translate(x - clientWidth / 2, y - clientHeight / 2);
            this.added = true;
            // очистим сторадж
            this.$store.commit("routing/reset_created");
          }, 2);
          // this.editor.view.nodes.get(this.node).translate(x, y);
        }
      },
      eventBus
    );
    // отложено покажем ноду
    // setTimeout(() => {
    //   if (this.node.data.new) this.show_node();
    // }, 100);
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.node {
  border: none;
  box-shadow: 0px 28px 64px -27px #c1c1c1;
  font-weight: 300;
  max-width: 250px;
  z-index: 80 !important;
  .additional-info {
    z-index: 10000;
  }
  &.node-hover {
    z-index: 8000 !important;
  }
  &.node-new {
    border: 2px solid $obit-export;
    box-shadow: 0px 28px 64px -27px $obit-export;
  }
  &.node-search {
    border: 2px solid $obit-danger;
    box-shadow: 0px 28px 64px -27px $obit-danger;
  }
  .edit & {
    :not([title="call"]):hover {
      cursor: move;
    }
    &:hover .btn {
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
  .name {
    font-size: 2em;
    line-height: 1.2em;
    padding-left: 0.5em;
    max-width: calc(100% - 20px);
    font-weight: 300;
  }
  .title {
    padding: 6px 12px;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    line-height: 37px;
    color: #fff;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
      float: left;
    }
    .object-modal {
      width: fit-content;
      display: inline;
      float: right;
      background-color: transparent;
      & > button.btn {
        color: white;
      }
    }
  }
  .output-line {
    margin-right: -4px;
    .out {
      border: 2px solid var(--primary);
      border-radius: 50px;
      max-width: 98%;
      width: fit-content;
      height: 32px;
      margin-bottom: 5px;
      float: right;
      .name {
        display: block;
        font-size: 24px;
        line-height: 1.2em;
        padding-left: 0.8em;
        padding-right: 30px;
        max-width: fit-content;
        white-space: nowrap;
      }
      .output-socket.output {
        position: relative;
        left: calc(100% - 31px);
        top: -32px;
        &.timeout {
          background-color: $obit-danger !important;
        }
      }
    }
  }
  .output-socket.output {
    position: relative;
    left: calc(100% - 18px);
    &.timeout {
      background-color: $obit-danger !important;
    }
  }
}
</style>