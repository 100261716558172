<template>
  <div>
    <b-row>
      <rule-col
        v-for="(rule, i) in obl_value"
        v-bind:key="i"
        v-model="obl_value[i]"
        @clone="clone"
        @remove="remove"
        @input="$emit('input', value)"
        :index="i"
      />
      <b-col cols="1">
        <b-button variant="outline-primary" pill @click="obl_value.push({})">
          <icon icon="plus" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { Icon, library } from "../layot";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RuleCol from "./RuleCol";
import { cloneDeep } from "lodash-es";

library.add(faPlus);

export default {
  name: "RuleInput",
  props: {
    value: {
      required: true,
    },
  },
  components: { Icon, RuleCol },
  computed: {
    obl_value() {
      return this.value;
    },
  },
  methods: {
    clone(row) {
      let new_value = this.obl_value;
      new_value.push(cloneDeep(row));
      this.$emit("input", new_value);
    },
    remove(index) {
      let new_value = this.obl_value.filter((row, i) => {
        return i !== index;
      });
      this.$store.commit("add_message", {
        type: "danger",
        message: [index, new_value],
      });
      this.$emit("input", new_value);
    },
  },
};
</script>

<style>
</style>