import { render, staticRenderFns } from "./typeCell.vue?vue&type=template&id=8476f940"
import script from "./typeCell.vue?vue&type=script&lang=js"
export * from "./typeCell.vue?vue&type=script&lang=js"
import style0 from "./typeCell.vue?vue&type=style&index=0&id=8476f940&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports