<template>
  <span>
    {{ item.mobile_phone | phone }}<br v-if="item.mobile_phone" />
    <span v-if="item.email" class="text-muted">{{ item.email }}</span>
  </span>
</template>

<script>
import CellVue from "../../mixins/Cell.vue";
import { number_formatter } from "../../../formatters";
export default {
  name: "ContactsCell",
  mixins: [CellVue],
  filters: {
    phone: number_formatter,
  },
};
</script>

<style>
</style>