import Rete from "rete";
import * as Socket from "../../sockets";
import Component from './Component'


export class Start extends Rete.Component {
  constructor() {
    super("start");
    this.data.component = Component
  }

  builder(node) {
    var out = new Rete.Output('start', 'start', Socket.startSocket)
    return node.addOutput(out)
  }


  async worker() {
    return {}
  }
}
