var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"media-cell spinner-circle"},[_c('b-button',{staticClass:"border-0 media-btn",attrs:{"size":"sm","variant":"outline-primary","disabled":_vm.real_item.status != 'ready'},on:{"click":function($event){return _vm.toggle_pause()}}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('obit-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(_vm.icon_tooltip),expression:"icon_tooltip"}],class:[_vm.icon_class, 'spinner-subcircle'],attrs:{"icon":_vm.playing ? 'pause' : 'play'}})],1)],1),_c('div',[(_vm.item.obj_type == 'play_file')?_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(
        (_vm.item.description.length ||
          _vm.item.caption.length ||
          _vm.item.obj_name.length) > 25
          ? {
              variant: 'light',
              title: _vm.item.description || _vm.item.caption || _vm.item.obj_name,
              customClass: 'big_tooltip',
            }
          : ''
      ),expression:"\n        (item.description.length ||\n          item.caption.length ||\n          item.obj_name.length) > 25\n          ? {\n              variant: 'light',\n              title: item.description || item.caption || item.obj_name,\n              customClass: 'big_tooltip',\n            }\n          : ''\n      "}],staticClass:"play-text"},[_vm._v(" "+_vm._s(_vm.item.caption || _vm.item.description || _vm.item.obj_name,)+" ")]):_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(
        (_vm.real_item.description.length || _vm.real_item.obj_name.length) > 25
          ? {
              variant: 'light',
              title: _vm.real_item.description || _vm.real_item.obj_name,
              customClass: 'big_tooltip',
            }
          : ''
      ),expression:"\n        (real_item.description.length || real_item.obj_name.length) > 25\n          ? {\n              variant: 'light',\n              title: real_item.description || real_item.obj_name,\n              customClass: 'big_tooltip',\n            }\n          : ''\n      "}],staticClass:"play-text"},[_vm._v(" "+_vm._s(_vm.real_item.description || _vm.real_item.obj_name,)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }