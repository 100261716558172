<script>
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";
export default {
  name: "HangComponent",
  mixins: [ComponentMixinVue],
  computed: {
    config: () => config,
  },
};
</script>