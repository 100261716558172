<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-button disabled v-if="pre_text" variant="outline">
        {{ pre_text }}
      </b-button>
    </b-input-group-prepend>
    <b-form-input v-model="val" v-bind="$attrs" />
    <b-button disabled v-if="post_text">
      {{ post_text }}
    </b-button>
  </b-input-group>
</template>

<script>
export default {
  name: "customWith",
  props: {
    pre_text: {
      type: String,
      default: "",
    },
    post_text: {
      type: String,
      default: String,
    },
    value: {
      required: true,
    },
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(to) {
        this.$emit("input", to);
      },
    },
  },
};
</script>

<style>
</style>