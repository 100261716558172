<template>
  <span v-if="rel_obj" class="related-cell">
    <span v-if="is_multiple && !with_links" class="related-cell">
      {{ list_of_objects.map((o) => o[related_target]).join(", ") }}
      <b-badge v-if="other_part_length" @click="toggle_all()">{{
        open_all ? "скрыть" : max_count_text(other_part_length)
      }}</b-badge>
    </span>
    <span v-else-if="is_multiple && with_links" class="related-cell">
      <b-link
        v-for="(o, indx) in list_of_objects"
        v-bind:key="indx"
        :to="link(o)"
      >
        {{ rel_obj[related_target] }}
      </b-link>
      <b-badge v-if="other_part_length" @click="toggle_all()">
        {{ open_all ? "скрыть" : max_count_text(other_part_length) }}
      </b-badge>
    </span>
    <span v-else>
      <b-link v-if="with_links" :to="link(rel_obj)">{{
        rel_obj[related_target]
      }}</b-link>
      <span v-else>{{ rel_obj[related_target] }}</span>
    </span>
  </span>
</template>

<script>
import CellVue from "../mixins/Cell.vue";
export default {
  name: "relatedField",
  mixins: [CellVue],
  props: {
    with_links: {
      required: false,
      type: Boolean,
      default: false,
    },
    max_count: {
      required: false,
      type: Number,
      default: 3,
    },
    max_count_text: {
      required: false,
      type: Function,
      default: (value) => `+${value}`,
    },
    related_target: {
      required: false,
      type: String,
      default: "obj_name",
    },
  },
  mounted() {
    this.$watch("value", () => {
      this.$forceUpdate();
    });
  },
  methods: {
    link() {
      let ret = `./${this.item.obj_type}/${this.item.obj_key}`;
      if (this.field.field_desc.root) {
        ret = `${this.field.field_desc.root}/${this.value}`;
      }
      return ret;
    },
    toggle_all() {
      this.open_all = !this.open_all;
      this.$forceUpdate();
    },
  },
  data: () => ({
    open_all: false,
  }),
  computed: {
    rel_obj() {
      const obj_type = this.field.field_desc.related;
      if (this.is_multiple) {
        return this.$store.getters[`${obj_type}/objects`].filter(
          (o) => this.value.indexOf(o.obj_id) != -1
        );
      } else {
        const obj_id = this.value;

        return this.$store.getters[`${obj_type}/object`](obj_id);
      }
    },
    is_multiple() {
      return this.field_desc.multiple || false;
    },
    list_of_objects() {
      let ret = [];
      if (this.is_multiple) {
        ret = this.rel_obj ? [...this.rel_obj] : [];
        if (ret.length > this.max_count + 1 && !this.open_all) {
          ret = ret.slice(0, this.max_count);
        }
      }
      return ret;
    },
    other_part_length() {
      let ret = 0;
      if (this.is_multiple) {
        ret = this.rel_obj?.length - this.max_count;
      }
      return ret > 1 ? ret : 0;
    },
  },
};
</script>

<style lang='scss'>
.related-cell {
  a:hover {
    color: var(--warning);
    text-decoration: none;
  }
}
</style>