<template>
  <div class="bg-white select-number" v-if="step === 1">
    <h3>Приобретение городского номера</h3>
    <h2>
      Обратите внимание, город должен соответствовать адресу подключения
      <b-link>Поменять тип номера</b-link>
    </h2>
    <b-form inline>
      <b-form-group label="Город" label-cols="4">
        <b-select>
          <b-select-option value="all">Все</b-select-option>
        </b-select>
      </b-form-group>
      <b-form-group label="Часть номера" label-cols="2">
        <b-form-input v-model="search_number" />
      </b-form-group>
      <b-form-group label=" ">
        <b-button variant="obit">найти</b-button>
        <!--b-button variant='obit' @click="reset()">Сбросить</b-button-->
      </b-form-group>
    </b-form>
    <b-tabs>
      <b-tab v-for="(objs, name, i) in aval_groups" :key="i" :title="name">
        <template #title>
          {{ name }} <span class="text-muted">{{ objs.length }}</span>
        </template>
        <h2>
          Санкт-Петербург (812)
          <b-link @click.prevent="choose_random()"
            >Выбрать случайный номер</b-link
          >
        </h2>
        <b-table
          :items="objs"
          :fields="columns"
          class="numbers-aval-list"
          responsive
        >
          <template v-slot:head(select)>
            <b-form-checkbox />
          </template>
          <template v-slot:cell(select)="obj">
            <b-form-checkbox v-model="selected" :value="obj.item" />
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
    <div class="bottom">
      <b-button variant="obit" @click="show_modal_add = true"
        >Заказать</b-button
      >
      <b-button variant="outline-obit">Показать все</b-button>
    </div>
    <b-modal
      v-model="show_modal_add"
      ok-title="продолжить"
      @ok="confirm"
      no-close-on-backdrop
    >
      <p>
        <b>Выбранные номера: </b>
        <span v-for="item in selected" :key="item.number"
          >{{ item.number | number_formatter }}
          <b-button
            variant="outline-primary"
            class="border-0"
            size="sm"
            @click="delete_selected(item.number)"
            ><obit-icon icon="close" /></b-button
        ></span>
      </p>
      <p>
        <b>Подключение </b>
        {{ selected.reduce((r, i) => (r += i.connect_price), 0) | price }}
      </p>
      <p>
        <b>Абонентская плата </b>
        {{ selected.reduce((r, i) => (r += i.price), 0) | price_per_month }}
      </p>
    </b-modal>
  </div>
  <div class="bg-white select-number" v-else-if="step === 2">
    <h2>Приобретение городского номера</h2>
    <b-form>
      <b-form-group label="Выбранные номера" label-cols="2">
        <span v-for="item in selected" :key="item.number">
          {{ item.number | number_formatter }}
          <b-button variant="outline-primary" class="border-0" size="sm"
            ><obit-icon icon="close" /></b-button
        ></span>
      </b-form-group>
      <b-form-group label-cols="2" label="Подключение"
        >{{ selected.reduce((r, i) => (r += i.connect_price), 0) | price }}
      </b-form-group>
      <b-form-group label-cols="2" label="Абонентская плата">
        {{ selected.reduce((r, i) => (r += i.price), 0) | price_per_month }}
      </b-form-group>
    </b-form>
    <h2>Укажите адрес подключения номера</h2>
    <p>Обратите внимание, город должен соответствовать адресу подключения</p>
    <b-row class="address" v-for="item in selected" :key="item.number">
      <b-col>
        <p>Для номера {{ item.number | number_formatter }}</p>
        <b-form>
          <b-form-group label="Город" label-cols="2">
            <b-input placeholder="Санкт-Петерург" disabled />
          </b-form-group>
          <b-form-group label="Адрес" label-cols="2">
            <b-form-select
              v-model="item.address_id"
              :options="address_list"
              @input="(to) => (item.address_details = address_list[to])"
            />
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button variant="obit" @click="step = 3"> Подтвердить </b-button>
        <b-button variant="outline-obit" @click="step = 1"> Назад </b-button>
      </b-col>
    </b-row>
  </div>
  <div class="bg-white select-number" v-else-if="step === 3">
    <h3>Подтверждение данных</h3>
    <b-table
      :items="selected"
      :fields="confirm_columns"
      class="numbers-aval-list"
      responsive
    >
    </b-table>
    <b-row>
      <b-button variant="obit" @click="last_confirm()"> Подтвердить </b-button>
      <b-button variant="outline-obit" @click="step = 2"> Назад </b-button>
    </b-row>
    <b-modal
      v-model="show_last_confirm"
      ok-title="Продолжить"
      @ok="send_request()"
      no-close-on-backdrop
    >
      <p>
        Номер ХХХ-ХХ-ХХ успешно забронирован. Вы сможете сразу настроить
        маршрутизацию звонков и совершать исходящие вызовы, используя данный
        номер.
      </p>
      <p>
        Мы подготовили дополнительное соглашение к Вашему Договору %ХХХХХ% для
        оформления номера в соответствии с требованиями законодательства. Наш
        менеджер свяжется с Вами в ближайшее время для передачи документов.
      </p>
      <p>
        В случае непредставления подписанных документов в срок до ХХ.ХХ.ХХ
        абонент обязан оплатить услугу бронирования в размере %ХХХХХ% руб. При
        отсутствии подписанных с Вашей стороны документов в указанный срок номер
        будет переведен в резерв без возможности повторного подключения в
        течение 6 месяцев и стоимость услуги бронирования подлежит оплате в
        полном объеме.
      </p>
      <p>
        Нажимая "Продолжить" вы принимаете условия. При выборе - "Отменить"
        номер будет разбронирован.
      </p>
    </b-modal>
    <b-modal
      no-close-on-backdrop
      v-model="show_ok_modal"
      @ok="$router.push({ path: '/sip/city_number' })"
    >
      <p>
        Номер ХХХ-ХХ-ХХ успешно забронирован. Вы сможете сразу настроить
        маршрутизацию звонков и совершать исходящие вызовы, используя данный
        номер.
      </p>
      <p>
        Мы подготовили дополнительное соглашение к Вашему Договору %ХХХХХ% для
        оформления номера в соответствии с требованиями законодательства.
      </p>
      <p>
        Наш менеджер свяжется с Вами в ближайшее время для передачи документов.
      </p>
      <p>
        В случае непредставления подписанных документов в срок до ХХ.ХХ.ХХ
        абонент обязан оплатить услугу бронирования в размере %ХХХХХ% руб.
      </p>
      <p>
        При отсутствии подписанных с Вашей стороны документов в указанный срок
        номер будет переведен в резерв без возможности повторного подключения в
        течение 6 месяцев и стоимость услуги бронирования подлежит оплате в
        полном объеме.
      </p>
    </b-modal>
  </div>
</template>

<script>
import { sample } from "lodash-es";
import { build_options_from_dict } from "../../functions";
import { number_formatter, price_per_month, price } from "../../formatters";
import ObitIcon from "../obit/ObitIcon.vue";

const statuses = {
  all: "Все",
  regular: "Обычный",
  silver: "Серебрянный",
  gold: "Золотой",
  platinum: "Платиновый",
};
const columns = [
  { label: "", key: "select" },
  { label: "Номер", key: "number", formatter: number_formatter },
  { label: "Цена", key: "price", formatter: price_per_month },
  { label: "Цена подключения", key: "connect_price", formatter: price },
  { label: "Комментарий", key: "commentary" },
];
const confirm_columns = [
  { label: "Номер", key: "number", formatter: number_formatter },
  { label: "Адрес", key: "address_details" },
  { label: "Цена", key: "price", formatter: price_per_month },
  { label: "Цена подключения", key: "connect_price", formatter: price },
  { label: "Тариф", key: "tarrif" },
];

export default {
  name: "SelectNumber",
  components: { ObitIcon },
  filters: {
    number_formatter,
    price,
    price_per_month,
  },
  data() {
    return {
      numbers: [],
      statuses: statuses,
      confirm_columns,
      columns,
      selected: [],
      search_number: "",
      status_selected: "all",
      show_modal_add: false,
      show_last_confirm: false,
      confirm_step: 1,
      step: 1,
      show_ok_modal: false,
      address_list: {
        "a3294d1b-3950-4212-a951-00ca1bfeab86":
          "г Санкт-Петербург, ул Заставская, д 33",
      },
    };
  },
  mounted() {
    this.get_data();
  },
  computed: {
    filtered_objs() {
      return this.numbers.filter((row) => {
        let res = row.number.indexOf(this.search_number) != -1;
        let selected = this.selected.indexOf(row.number) != -1;
        const row_status = row.status || "regular";
        let status_filter =
          this.status_selected === "all" || this.status_selected == row_status;
        return (res || selected) && status_filter;
      });
    },
    aval_groups() {
      var ret = {};
      this.filtered_objs.map((row) => {
        if (!ret[row.group]) {
          ret[row.group] = [];
        }
        ret[row.group] = ret[row.group].concat([row]);
      });
      return ret;
    },
    status_options() {
      return build_options_from_dict(statuses);
    },
  },
  methods: {
    confirm() {
      this.step = 2;
    },
    send_request() {
      this.$store
        .dispatch("city_number/send_request", this.selected)
        .then(() => {
          this.show_ok_modal = true;
        });
    },
    last_confirm() {
      this.show_last_confirm = true;
    },
    get_data() {
      this.$store
        .dispatch("requestApi", {
          url: "/pbx/services/v2/cityNumber/available",
          data: {
            customer_id: this.$store.getters["current_customer"],
            entity_id: this.$store.getters["current_entity"],
            category: 10,
          },
        })
        .then((resp) => {
          if (resp.data.result == "success") {
            this.numbers = resp.data.payload.map((n) => ({
              number: n,
              price: 1200,
              connect_price: 2100,
              group: "Все",
            }));
          }
        });
    },
    choose_random() {
      this.selected.push(
        sample(
          this.filtered_objs.filter((row) => {
            return this.selected.map((n) => n.number).indexOf(row.number) == -1;
          })
        )
      );
    },
    reset() {
      this.selected = [];
      this.filter = "";
      this.status_selected = "all";
    },
    delete_selected(item) {
      this.selected = this.selected.filter((row) => row.number != item);
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.select-number {
  h3 {
    padding: 30px 30px;
    padding-bottom: 0px;
    text-transform: uppercase;
  }
  & > h2 {
    padding: 5px 30px;
  }
  form {
    padding-left: 30px;
  }
  .numbers-aval-list {
    padding: 15px 30px;
    th {
      color: $obit-muted;
    }
  }
  .bottom {
    padding: 30px;
    padding-top: 5px;
    button {
      margin-right: 15px;
    }
  }
}
</style>