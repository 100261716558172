<template>
  <div class="bg-white pbx-group-list">
    <b-button variant="outline-obitless" :to="link([], -1)">
      <obit-icon icon="back" /> Все группы
    </b-button>
    <h3 v-if="obj" class="title">{{ obj.obj_name }} - {{ obj.caption }}</h3>
    <b-tabs v-model="tabIndex">
      <b-tab title="Информация">
        <def-object :obj_type="'pbx_group'" :obj_id="obj_id" hide_header />
      </b-tab>
      <b-tab>
        <template #title>
          Состав группы <b-badge>{{ lines_count }}</b-badge>
        </template>
        <obj-list
          :fields="lines_fields"
          obj_type="internal_line2"
          :items="lines"
          class="group_members"
        >
          <template v-slot:cell(remove_id)="slot">
            <b-form-checkbox
              v-model="delete_list"
              :value="slot.item.service_id"
            />
          </template>
        </obj-list>
        <b-button
          variant="outline-obit"
          class="border-0"
          @click="show_modal_add = true"
        >
          <obit-icon icon="plus" /> Добавить
        </b-button>
        <b-button
          variant="outline-obit"
          class="border-0"
          @click="delete_members()"
          v-if="delete_list.length"
          >Удалить
        </b-button>
      </b-tab>
      <!-- <b-tab title="История звонков"></b-tab> -->
    </b-tabs>
    <b-modal v-model="show_modal_add" title="Добавить" @ok="add_members">
      <obj-list
        :items="lines_aval"
        :fields="lines_aval_fields"
        obj_type="internal_line2"
        :use_paginator="false"
        class="pbx-members-add"
      >
        <template v-slot:head(add_line)>
          <b-form-checkbox
            v-model="lines_aval_all"
            :indeterminate="lines_aval_ime"
          />
        </template>
        <template v-slot:cell(add_line)="slot">
          <b-form-checkbox v-model="add_list" :value="slot.item.service_id" />
        </template>
      </obj-list>
      <p v-if="add_list.length">Добавить номера {{ add_numbers.join(", ") }}</p>
    </b-modal>
  </div>
</template>

<script>
import ObitIcon from "../../obit/ObitIcon.vue";
import DefObject from "../DefObject.vue";
import { buildRelatedField } from "../fields";
import ObjList from "../ObjList.vue";
import isActive from "../../shared/activeComp";

export default {
  components: {
    DefObject,
    ObitIcon,
    ObjList,
  },
  name: "GroupObj",
  props: {
    obj_id: {
      type: String,
      required: true,
    },
  },
  inject: ["link"],
  mounted() {
    this.get_members();
  },
  data() {
    return {
      lines_shadow_list: [],
      show_modal_add: false,
      add_list: [],
      add_all_list: false,
      delete_list: [],
      tabIndex: this.$route.query.tab ? Number(this.$route.query.tab) : 0,
    };
  },
  computed: {
    members_obj: {
      get() {
        return {
          add: this.add_list,
          delete: this.delete_list,
        };
      },
      set(to) {
        const { add } = to;
        this.add_list = add;
      },
    },
    members_desc() {
      return {
        fields: {
          add: buildRelatedField("Линии", "internal_line2", { multiple: true }),
        },
      };
    },
    obj() {
      return this.$store.getters["pbx_group/object"](this.obj_id);
    },
    lines() {
      const lines_ids =
        this.lines_shadow_list?.map((o) => o.service.service_id) || [];
      return this.$store.getters["internal_line2/objects"].filter(
        (l) => lines_ids.indexOf(l.service_id) > -1
      );
    },
    add_numbers() {
      const ids = this.add_list;
      return this.$store.getters["internal_line2/objects"]
        .filter((l) => ids.indexOf(l.service_id) > -1)
        .map((l) => l.number);
    },
    lines_aval_all: {
      get() {
        return this.add_list.length ? true : false;
      },
      set(to) {
        if (!to) {
          this.add_list = [];
        } else {
          if (!this.add_list.length) {
            this.add_list = this.lines_aval.map((s) => s.service_id);
          }
        }
      },
    },
    lines_aval_ime: {
      get() {
        let ret = false;
        if (
          this.lines_aval_all &&
          this.add_list.length != this.lines_aval.length
        ) {
          ret = true;
        }
        return ret;
      },
      set(to) {
        console.log(to);
      },
    },
    lines_aval_fields() {
      return [
        { label: " ", key: "add_line" },
        "number",
        "employee",
        {
          label: "Cтатус",
          key: "isActive",
          cell: isActive,
          target: "state.availableToUse",
          cell_options: { rule: (v) => (v ? true : false) },
        },
      ];
    },
    lines_aval() {
      const lines_ids =
        this.lines_shadow_list?.map((o) => o.service.service_id) || [];
      return this.$store.getters["internal_line2/objects"].filter(
        (l) => lines_ids.indexOf(l.service_id) == -1
      );
    },
    lines_fields() {
      const list_fields = this.$store.getters["internal_line2/description"]
        .list_fields;
      return [
        {
          label: " ",
          key: "remove_id",
          field: "remove_id",
          target: "remove_id",
        },
      ].concat(list_fields);
    },
    lines_count() {
      return this.lines.length;
    },
  },
  methods: {
    get_members() {
      this.$store
        .dispatch("pbx_group/get_members", this.obj_id)
        .then((resp) => (this.lines_shadow_list = resp.data.payload));
    },
    add_members() {
      this.$store
        .dispatch("pbx_group/add_members", {
          object_id: this.obj_id,
          items: this.add_list,
        })
        .then(() => {
          this.add_list = [];
          this.get_members();
        });
    },
    delete_members() {
      this.$store
        .dispatch("pbx_group/delete_members", {
          object_id: this.obj_id,
          items: this.delete_list,
        })
        .then(() => {
          this.delete_list = [];
          this.get_members();
        });
    },
    member(type, id) {
      return this.$store.getters[`${type}/object`](id);
    },
  },
};
</script>

<style lang='scss'>
.object-form-pbx_group {
  max-width: 930px;
}
.password-data-form {
  max-width: 930px;
}
.pbx-group-list {
  h3.title {
    padding-top: 15px;
    margin-left: 30px;
  }
  .group_members {
    margin-top: 15px;
  }
}
.pbx-members-add {
  .table-responsive.objects-table.objects-internal_line2 {
    max-height: calc(100vh - 270px);
  }
}
</style>