<script>
import Obj from '../Obj'
export default {
    name: 'Intext',
    mixins: [Obj],
    computed: {
        obj_type: () => {return 'internal_line'},
        use_instance_id: () => {return true},
    },
}
</script>
