<template>
  <div id="periodInput">
    <slot></slot>

    <b-button-group>
      <b-button
        v-for="(v, key) in dt_variants"
        :key="key"
        :variant="key == filter_type ? 'obit' : 'outline-obit'"
        @click="set_filter_type(key)"
      >
        {{ v }}
      </b-button>
    </b-button-group>

    <div class="d-flex additional-filter">  
      <date-picker
        label="С"
        :options="{ max: max_start, min: min_start }"        
        @input="debounceEmit"
        v-model="date_start"        
      />
      <date-picker
        label="По"        
        :options="{ min: min_end, max: max_end }"
        @input="debounceEmit"
        v-model="date_end"
      />
    </div>
  </div>
</template>

<script>
import { endOfMonth, parseISO, startOfMonth, subMonths } from "date-fns";
import { dateformatter_standart } from "../../formatters";
import { min } from "date-fns/esm";
import DatePicker from "../objects/Bill/DatePicker.vue";
import { debounce } from 'lodash';

const DATEFILTER_VARIANTS = {
  // yestoday: "Вчера",
  // week: "Текущая неделя",
  last_month: "Предыдущий месяц",
  month: "Текущий месяц",
  // period: "Период",
};

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    key_start: {
      type: String,
      required: false,
      default: "date_start",
    },
    key_end: {
      type: String,
      required: false,
      default: "date_end",
    },
    def_period: {
      type: String,
      required: false,
      default: null,
    },
  },
  components: { DatePicker },
  data() {
    const calced_period = this.calc_period();
    return {
      filter_type: calced_period,
    };
  },
  methods: {
    calc_period() {
      const [start, end] = [
        this.value[this.key_start],
        this.value[this.key_end],
      ];
      let ret = "period";
      var pdate = subMonths(new Date(), 1);
      const [month_start, month_end, pmonth_start, pmonth_end] = [
        dateformatter_standart(startOfMonth(new Date())),
        dateformatter_standart(endOfMonth(new Date())),
        dateformatter_standart(startOfMonth(pdate)),
        dateformatter_standart(endOfMonth(pdate)),
      ];
      if (start == month_start && end == month_end) {
        ret = "month";
      } else if (start == pmonth_start && end == pmonth_end) {
        ret = "last_month";
      }
      return ret;
    },
    set_filter_type(value) {
      this.filter_type = value;
      switch (value) {       
        case "month":
          this.$emit("input", {
            ...this.value,
            [this.key_start]: dateformatter_standart(startOfMonth(new Date())),
            [this.key_end]: dateformatter_standart(endOfMonth(new Date())),
          });
          this.$emit("fetchData")
          break;
        case "last_month":
          var date = subMonths(new Date(), 1);
          this.$emit("input", {
            ...this.value,
            [this.key_start]: dateformatter_standart(startOfMonth(date)),
            [this.key_end]: dateformatter_standart(endOfMonth(date)),
          });
          this.$emit("fetchData")
          break;
      }
    },

    debounceEmit: debounce(function() {      
      this.$emit("dateChange")
    }, 10000)

  },
  computed: {
    dt_variants: () => DATEFILTER_VARIANTS,
    date_start: {
      get() {
        return this.value[this.key_start];
      },
      set(to) {        
        let new_val = { ...this.value, [this.key_start]: to };
        this.$emit("input", new_val);
      },
    },
    date_end: {
      get() {
        return this.value[this.key_end];
      },
      set(to) {
        let new_val = { ...this.value, [this.key_end]: to };
        this.$emit("input", new_val);
      },
    },
    min_end() {
      let today = new Date();
      let ret = today;
      if (this.date_start) {
        let current_max = parseISO(this.date_start);
        ret = min([today, current_max]);
      }
      return dateformatter_standart(ret);
    },
    max_end() {
      return dateformatter_standart(new Date());
    },
    min_start() {
      let ret = null;
      return ret;
    },
    max_start() {
      let _max = new Date();
      if (this.date_end) {
        let current_max = parseISO(this.date_end);

        _max = min([_max, current_max]);
      }
      let ret;
      try {
        ret = dateformatter_standart(_max);
      } catch (e) {
        console.error("error in max_start", e);
      }

      return ret;
    },
  },
};
</script>

<style lang="scss">
#periodInput {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;

  .obit-datepicker:not(:last-child) {
    margin-right: 17px;
  }

  .legend {
    margin-right: 7px;
  }
}
</style>
