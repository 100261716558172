<template>
  <b-modal title="Выйти?" v-model="show_logout" @ok="logout">
    Вы действительно хотите выйти<span v-if="full_logout"> из всех сессий</span
    >?
  </b-modal>
</template>

<script>
import store from "../../store";
import { eventBus } from "../EventBus";
import { BModal } from "bootstrap-vue";
export default {
  name: "LogoutModal",
  components: { BModal },
  mounted() {
    eventBus.$on("logout", this.showLogout);
  },
  beforeDestroy() {
    eventBus.$off("logout");
  },
  data() {
    return {
      show_logout: false,
      full_logout: false,
      user: store.state.user,
    };
  },
  methods: {
    showLogout(full) {
      if (full == !undefined) {
        full = false;
      }
      this.show_logout = true;
      this.full_logout = full;
    },
    closeLogout() {
      this.show_logout = false;
      this.full_logout = false;
    },
    logout() {
      this.$store.dispatch("logout");
      this.closeLogout();
    },
  },
};
</script>

<style>
</style>