<template>
  <div>
    <obj-form v-model="call" mode="view" :description="call._description" />
    <b-table :items="events" :fields="format_table" />
  </div>
</template>

<script>
import { BTable } from "bootstrap-vue";
import { timestamp, timeformatter } from "../../formatters";
import ObjForm from "../ObjForm.vue";

export default {
  name: "ClientLog",
  components: { BTable, ObjForm },
  props: {
    call_id: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      format_table: [
        { label: "Время", key: "time", formatter: timeformatter },
        { label: "Событие", key: "event" },
        { label: "Обработчик", key: "obr" },
      ],
    };
  },
  computed: {
    call() {
      return this.$store.getters.get_object("call", this.call_id);
    },
    call_descr: () => ({
      fields: {},
    }),
    log() {
      return this.call._related("log_id");
    },
    events() {
      return this.log.events;
    },
    time() {
      return timestamp(this.call.time);
    },
  },
};
</script>

<style>
</style>