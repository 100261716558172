/* eslint-disable */
import Axios from 'axios';
import { SERVER } from '@/constants'
import { type_val } from '@/functions.js';

const OAuthStore = {
  state: () => ({
    user: null,
    roles: [],

    user_id: null,
    license: null,

    //@Todo remove?
    user_info: {}
  }),
  getters: {
    roles: (state) => state.roles,

    user: (state) => state.user,
    user_id: (state) => () => state.user_id,
    license: (state) => () => state.license,
  },
  mutations: {
    saveUserData(state, user) {
      state.user = user;
    },

    saveUserId(state, id) {
      state.user_id = id;
    },

    saveLicense(state, license) {
      state.license = license;
    },

    saveRoles(state, roles) {
      state.roles = roles;
    },

    clear: (state) => {
      state.user_id = null
      state.user = null
      localStorage.clear()
    },

  },

  actions: {
    logout: ({ commit, dispatch }) => {
      return dispatch(`oidcStore/signOutOidc`).then(() => {
        dispatch(`oidcStore/removeOidcUser`)
        commit('clear')
        dispatch('resetObjects', null, { root: true })
      }).catch(() => dispatch(`oidcStore/removeOidcUser`))
    },

    requestApi(
      { commit, getters, dispatch },
      {
        url, data: data = {}, use_token: use_token = true,
        method: method = 'POST',
        base_url: base_url = SERVER,
        content_type: content_type = 'application/json;charset=UTF-8',
        additional_config: additional_config = {},
        withFiles: withFiles = false,
        ignore_messages: ignore_messages = true,
        test_answer: test_answer = null,
        test_answer_code: test_answer_code = 200
      }
    ) {
      if (test_answer) {
        return {
          data: test_answer,
          status: test_answer_code
        }
      }
      const ct = withFiles ? 'multipart/form-data' : content_type
      const headers = {
        'Content-Type': ct,
      }
      const req = Axios.create()
      const msg = m => ignore_messages ? null : commit('add_message', m, { root: true })
      let resp_int = (resp) => {
        if (resp instanceof Error) {
          if (resp?.response?.data?.result == 'error') {
            msg({ type: 'danger', message: resp?.response?.data?.text || 'Неизвестная ошибка' })

            // токен прострочен
            if (resp?.response?.data?.code === 401) {
              dispatch(`oidcStore/authenticateOidc`)
            }
          }
        }
        return resp
      }
      if (use_token) {
        headers['Authorization'] = `Bearer ${getters['user'].access_token}`;
      }
      req.interceptors.response.use(
        resp_int, resp_int
      )
      let req_data
      if (withFiles) {
        let fd = new FormData()
        Object.entries(data).map(([key, val]) => {
          if (type_val(val) == 'FileList') {
            Array.from(val).map(
              f => fd.append(`${key}`, f, f.name)
            )
          } else {
            fd.append(key, val)
          }
        })
        req_data = fd
      } else {
        req_data = JSON.stringify(data)
      }
      return req(
        {
          baseURL: base_url,
          url: url,
          data: req_data,
          method: method,
          headers: headers,
          crossDomain: true,
          //dataType: 'json',
          ...additional_config
        }
      )
    }
  }
}
export default OAuthStore