var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.real_show),expression:"real_show"}],class:[
    `${_vm.config.objType}-node`,
    'node',
    {
      'node-hover': _vm.hover,
      'node-new': _vm.node_new > 0,
      'node-search': _vm.node_search,
    },
  ],attrs:{"no-body":""},on:{"mouseover":function($event){return _vm.isHover(true)},"mouseleave":function($event){return _vm.isHover(false)}}},[_vm._v(" "+_vm._s(_vm.get_data(_vm.node.data))+" "),_c('b-card-title',{staticClass:"title"},[_vm._l((_vm.inputs()),function(input){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(input),expression:"input",arg:"input"}],key:input.key,class:['input-socket', input.key],attrs:{"type":"input","socket":input.socket}})}),_vm._v(" "+_vm._s(_vm.config.name)+" "),(_vm.service)?_c('obj-modal',{staticClass:"node-edit",attrs:{"obj_type":"play_file","obj_id":_vm.service_id,"noDelete":""},scopedSlots:_vm._u([{key:"default",fn:function(m){return [_c('b-button',{attrs:{"pill":"","variant":"outline","sm":""},on:{"click":function($event){return m.show_modal()}}},[_c('icon',{staticClass:"title-icon",attrs:{"icon":_vm.icons.gear}})],1)]}}],null,false,3556821975)}):_vm._e()],2),_c('b-modal',{attrs:{"title":"настройки"},model:{value:(_vm.modal_show),callback:function ($$v) {_vm.modal_show=$$v},expression:"modal_show"}},[(_vm.service)?_c('p',[_vm._v(_vm._s(_vm.service.obj_id))]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.node.data.raw))]),_vm._l((_vm.controls()),function(control,_i){return _c('div',{directives:[{name:"control",rawName:"v-control",value:(control),expression:"control"}],key:_i,staticClass:"control"})})],2),_c('h2',{staticClass:"name"},[(_vm.service.mediaFileId)?_c('media-name-cell-vue',{attrs:{"item":_vm.service,"related":"","value":_vm.service.mediaFileId,"field":{
        key: 'mediaFileId',
        field_desc: {
          target: 'mediaFileId',
        },
      },"index":0}}):_vm._e()],1),_c('fragment',_vm._l((_vm.outputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],key:output.key,class:['output-socket', output.key],attrs:{"type":"output","socket":output.socket}})}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }