import Rete from "rete";
import ExtLineComp from './ExtLineComp'
import Control from './Control'
import config from './config'
import { callSocket, startSocket } from "../../sockets";

export { config as ExternalLine_CONFIG }

export class ExternalLineControl extends Rete.Control {
  constructor(emitter, key, readonly) {
    super(key);
    this.render = 'vue'
    this.component = Control;
    this.props = { emitter, ikey: key, readonly };
  }

  setValue(val) {
    this.vueContext.value = val;
  }
}

export class ExternalLine extends Rete.Component {
  constructor() {
    super(config.objType);
    this.data.render = 'vue'
    this.data.component = ExtLineComp
    this.data._config = config
  }

  builder(node) {
    const cnf = this.data._config
    const defBuildOutputs = (node) => {
      for (const [out, out_name] of Object.entries(cnf.outputs)) {
        let output = new Rete.Output(out, out_name || out, callSocket, false)
        node.addOutput(output)
      }
    }
    const defBuildInputs = node => {
      var in1 = new Rete.Input("start", "start", startSocket, true);
      node.addInput(in1)
    }
    const buildInputs = cnf.mapInputs || defBuildInputs
    const buildOutputs = cnf.mapOutpus || defBuildOutputs


    buildInputs(node)
    buildOutputs(node)

    return node
  }




  async worker() {

  }
}
