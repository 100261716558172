<template>
  <div class="background">
    <div class="pad">
      <span class="ftitle">Финансы</span>
    </div>
    <b-nav tabs>
      <b-nav-item
        v-for="(l, indx) in links"
        v-bind:key="indx"
        v-bind="nav_bind(l.link)"
      >
        {{ l.text }}
      </b-nav-item>
    </b-nav>

    <router-view />
  </div>
</template>

<script>
import Obj from "../DefObject";
import { eventBus } from "../../";
import { FILTER_DESC } from "./filter_description";
import { uniqBy } from "lodash-es";
import { compareAsc, parseISO } from "date-fns";
import { money_format } from "../../../formatters";

export default {
  name: "FinObj",
  props: {
    links: {
      required: false,
      default: () => [
        { link: ["main"], text: "Основное" },
        { link: ["bill"], text: "Документы" },
        { link: ["calcs"], text: "Взаиморасчёты" },
        { link: ["acts"], text: "Акты сверки" },
      ],
    },
  },
  inject: ["link"],
  mounted() {
    this.gravity = true;
  },
  filters: {
    money: money_format,
  },
  watch: {
    doc_types(to) {
      eventBus.$emit("doc_type_pass", to);
    },
  },
  computed: {
    obj() {
      return this.$store.getters["bill/object"](this.obj_id);
    },
    comp: () => Obj,
    service_fields: {
      get() {
        return [
          { key: "name", label: "Сервис" },
          { key: "sum", label: "Цена" },
        ];
      },
    },
    filter_data: {
      get() {
        return {
          filter_descr: FILTER_DESC,
          date_start: null,
          date_end: null,
        };
      },
    },
    finances_data: {
      get() {
        return this.$store.getters.finances || {};
      },
    },
    doc_types() {
      var ret_types = [];
      if (this.gravity && this.$refs.docs) {
        ret_types = uniqBy(
          this.$refs.docs.objs.map((val) => ({
            value: val.type,
            text: val.typeTitle,
          })),
          "value"
        );
        ret_types.unshift({
          value: null,
          text: "Все",
        });
      }
      return ret_types;
    },
    debt_label_data() {
      const today = new Date();
      var payday = null;
      var ret = {
        style: "",
        text: "",
        sum: Math.abs(this.finances_data.balance),
      };
      if (this.finances_data.expire) {
        payday = parseISO(this.finances_data.expire.split(" ")[0]);
      } else {
        ret.text = "К оплате";
        return ret;
      }
      const comparison = compareAsc(payday, today);
      if (this.finances_data.balance < 0) {
        if (comparison < 0) {
          ret.style = "color: red;";
          ret.text = "Задолженность";
        } else {
          ret.text = "К оплате";
        }
      } else {
        ret.text = "К оплате";
        ret.sum = 0;
      }
      return ret;
    },
    expire() {
      if (this.finances_data.expire) {
        var date = this.finances_data.expire.split(" ")[0];
        var ymd = date.split("-");
        return ymd.reverse().join(".");
      }
      return null;
    },
  },
  data() {
    return {
      gravity: false,
      act_modal: false,
      act_date_start: null,
      act_date_end: null,
      tab: this.$route.meta.page,
      loading: false,
    };
  },
  methods: {
    route_replacer(route) {
      switch (route) {
        case "main":
          if (this.$route.name !== "FinancesMain") {
            this.$router.replace({ name: "FinancesMain" });
          }
          break;
        case "bill":
          if (this.$route.name !== "FinancesDocs") {
            this.$router.replace({ name: "FinancesDocs" });
          }
          break;
        case "calc":
          if (this.$route.name !== "FinancesCalculations") {
            this.$router.replace({ name: "FinancesCalculations" });
          }
          break;
        case "act":
          if (this.$route.name !== "FinancesActs") {
            this.$router.replace({ name: "FinancesActs" });
          }
          break;
      }
    },
    nav_bind(to) {
      if (to) {
        let link = this.link(to, -1);
        return { to: link, active: this.$route.path.startsWith(link) };
      } else {
        return { disabled: true };
      }
    },
  },
};
</script>

<style>
.margarin {
  margin-top: 8px;
}

.act-button {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
}

.big-bottom {
  margin-bottom: 20px;
}
.left-right-margins {
  margin-left: 25px;
  margin-right: 25px;
}
.debt-window {
  background: rgba(236, 105, 105, 0.2);
  border-radius: 6px;
  padding: 20px;
  margin-top: 40px;
}
.background {
  background-color: white;
  padding-top: 10px;
  border-radius: 4px;
  padding-bottom: 10px;
}
.ftitle {
  font-size: 16px;
  text-transform: uppercase;
  line-height: 16.8px;
  color: #3e3e3e;
  fill: #3e3e3e;
  font-weight: 700;
}
.pad {
  padding: 20px;
  padding-left: 50px;
}
</style>
