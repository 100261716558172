<template>
  <fragment>
    <div class="obit-logo" :class="{'_service': isNotObitLogo}" />
    <span v-if="!isNotObitLogo" class="text-muted varitant-text">Бизнес-телефония</span>
  </fragment>
</template>

<script>
import { Fragment } from "vue-fragment";

export default {
  name: "Logo",

  components: {
    Fragment
  },

  props: {},

  computed: {
    isNotObitLogo() {
      return process.env.VUE_APP_IS_OBIT_LOGO === `false`
    }
  }
}
</script>