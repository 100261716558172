<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
    v-parent-class="{ 'active-node': show_additional }"
  >
    {{ get_data(node.data) }}
    <b-card-title class="title">
      <Socket
        v-for="input in inputs()"
        :key="input.key"
        v-socket:input="input"
        type="input"
        :class="['input-socket', input.key]"
        :socket="input.socket"
      />
      {{ config.name }}

      <b-button-group>
        <obj-modal
          v-if="service && has_edit_modal"
          :obj_id="service.service_id"
          :obj_type="service.obj_type"
          noDelete
        >
          <template #default="{ show_modal }">
            <b-button pill variant="outline" sm @click="show_modal">
              <icon :icon="icons.gear" />
            </b-button>
          </template>
        </obj-modal>
        <b-dropdown
          dropright
          variant="outline"
          toggle-class="border-0"
          no-caret
          class="additional-info"
          @shown="show_additional = true"
          @hidden="show_additional = false"
        >
          <template #button-content>
            <icon class="title-icon" :icon="icons.bars" />
          </template>
          <b-dropdown-group v-if="shch_ref">
            <b-dropdown-text v-for="(r, i) in shch_ref.rules" :key="i">
              <nobr>
                <span class="day" v-if="r.dayOfWeek.fullRange">Ежедневно</span>
                <span class="day" v-else
                  >{{ r.dayOfWeek.fromDay | day }} -
                  {{ r.dayOfWeek.toDay | day }}</span
                >
                <span class="hours" v-if="r.timeRange.fullRange">
                  Круглосуточно
                </span>
                <span class="hours" v-else>
                  с {{ r | hourFrom }} до {{ r | hourTo }}</span
                >
              </nobr>
            </b-dropdown-text>
          </b-dropdown-group>
        </b-dropdown>
      </b-button-group>
    </b-card-title>
    <short-text-span
      tag="h2"
      class="name"
      v-if="service"
      :text="service.obj_name || ''"
    />
    <fragment v-if="!config.namedOutputs">
      <Socket
        v-for="output in outputs()"
        :key="output.key"
        v-socket:input="output"
        type="output"
        :class="['output-socket', output.key]"
        :socket="output.socket"
      />
    </fragment>
    <fragment v-else>
      <div v-for="output in outputs()" :key="output.key" class="output-line">
        <div class="out">
          <span class="name"> {{ output.name }}</span>
          <Socket
            v-socket:input="output"
            type="output"
            :class="['output-socket', output.key]"
            :socket="output.socket"
          />
        </div>
      </div>
    </fragment>
  </b-card>
</template>

<script>
import { faBars, faCog } from "@fortawesome/free-solid-svg-icons";
import { as_icon, Icon } from "../../../../layot";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";
import { timeformatter } from "../../../../../formatters";
import parent_class from "../../../../../directives/parent_class";

const item_time_format = (item, direction = "from") =>
  timeformatter(
    item.timeRange[`${direction}Hour`] * 60 * 60 +
      item.timeRange[`${direction}Minute`] * 60
  );

export default {
  name: "ShchCheckComponent",
  mixins: [ComponentMixinVue],
  directives: { "parent-class": parent_class },
  components: { Icon },
  data() {
    return {
      show_additional: false,
    };
  },
  filters: {
    day: (val) => ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"][val] || "",
    hourFrom: (val) => item_time_format(val, "from").slice(0, 5),
    hourTo: (val) => item_time_format(val, "to").slice(0, 5),
  },
  computed: {
    config: () => config,
    icons: () => ({
      bars: as_icon(faBars),
      gear: as_icon(faCog),
    }),
    shch_ref() {
      const vm = this;
      if (this.service && vm) {
        return vm.service._related("scheduleRef");
      }
      return null;
    },
  },
};
</script>

<style lang='scss'>
.node.shch_check-node {
  .out {
    width: 30%;
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    font-weight: 300;
    background: #54bae7;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
}
</style>