<template>
  <b-row>
    <b-col>
      <h3 class="col">Уведомления</h3>
      <b-form-group
        label="Показывать только непрочитанное"
        label-cols-sm="4"
        label-cols-lg="3"
        class="float"
      >
        <b-form-checkbox v-model="not_all" />
      </b-form-group>
      <b-button variant="outline-secondary" class="float-right">
        Отметить все как прочитанные
      </b-button>
    </b-col>

    <b-card
      v-for="(message, indx) in messages"
      v-bind:key="indx"
      :title="format_datetime(message.date)"
      :class="{ 'text-muted': message.readed }"
    >
      <b-button class="float-right" variant="outline-secondary" pill
        >Ok</b-button
      >
      <h4>{{ message.title }}</h4>
      <br />
      {{ message.message }}
    </b-card>
  </b-row>
</template>

<script>
import { timestamp } from "../../../formatters";
export default {
  name: "NotificationsList",
  data() {
    return {
      not_all: false,
    };
  },
  computed: {
    messages() {
      let all_messages = this.$store.getters.get_objects("notification");
      if (this.not_all) {
        all_messages = all_messages.filter((row) => row.readed);
      }
      return all_messages;
    },
  },
  methods: {
    format_datetime: timestamp,
  },
};
</script>

<style>
</style>