<template>
  <div
    id="page"
    :style="css_vars"
    :class="{ manager_transfered: manager_transfered }"
  >
    <slot name="topbar">
      <top-bar class="topbar" />
    </slot>

    <div class="menu" v-if="isAuth">
      <slot name="side-menu">
        <side-menu />
      </slot>
    </div>
    <scrollable id="page-wrapper">
      <div :class="['page', ...classes]">
        <slot />
      </div>

      <portal-target name="manager" slim ref="manager-portal" />
      <b-footer
        v-if="calc_settings('show_footer')"
        :fixed="calc_settings('fixed_footer')"
      />
    </scrollable>
  </div>
</template>

<script>
import Menu from "./Menu";
import Footer from "./Footer";
import TopBar from "./TopBar";
import Scrollable from "simplebar-vue";
import { PortalTarget } from "portal-vue";
export default {
  name: "Page",
  props: {
    position: {
      default: undefined,
      type: [String, Array],
    },
    title: {
      default: undefined,
      type: String,
    },
    classes: {
      required: false,
      type: Array,
      default: () => [],
    },
    menu: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      settings: {},
      mnt: false,
    };
  },
  components: {
    "side-menu": Menu,
    "b-footer": Footer,
    TopBar,
    Scrollable,
    PortalTarget,
  },
  computed: {
    isAuth() {
      return this.$store.getters['oidcStore/oidcIsAuthenticated']
    },
    css_vars() {
      return {
        "--page-size": "span 1"
      };
    },
    manager_transfered() {
      let ret = false;
      if (this.mnt) {
        ret = this.$refs["manager-portal"].passengers.length;
      }
      return ret;
    },
  },
  methods: {
    set_title() {
      if (this.title) {
        document.title = this.title;
      }
    },
    calc_settings(name, def = true) {
      return this.settings[name] === undefined ? def : this.settings[name];
    },
  },
  mounted() {
    this.set_title();
    this.mnt = true;
  },
};
</script>

<style lang='scss'>
#page {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: 90px 70px 1fr;
  grid-template-areas:
    "top"
    "side"
    "page";
  background-color: var(--light);
  @media screen and (min-width: 801px) {
    grid-template-rows: 90px 1fr;
    grid-template-columns: 240px 1fr;
    grid-template-areas:
      "top top"
      "side page";
  }

  #page-wrapper {
    width: calc(100vw - 240px);
    grid-area: page;
    height: calc(100vh - 90px);
    overflow: auto;
    .page {
      min-height: calc(100vh - 90px - 90px);
      padding: 30px;
      padding-bottom: 0;
    }
    @media screen and (max-width: 800px) {
      width: 100vw;
      height: 100%;
      .page {
        min-height: calc(100vh - 90px - 90px - 70px);
        padding: 12px 0;
        .row {
          margin: 0;
        }
        .col {
          padding: 0;
        }
      }
    }
  }
  &.manager_transfered {
    #page-wrapper {
      @media screen and (max-width: 800px) {
        .page {
          min-height: calc(100vh - 90px - 90px - 70px - 162px) !important;
        }
      }
    }
  }
  .menu {
    height: 100%;
    grid-area: side;
  }
  .topbar {
    grid-column: span 2;
    grid-area: top;
    height: fit-content !important;
    padding-left: 30px;
  }
  nav.footer {
    width: 100%;
    height: 90px !important;
    margin: auto;
    padding-left: 30px;
    padding-right: 30px;
    @media (max-width: 801px) {
      height: auto !important;
    }
    @media (max-width: 480px) {
      padding-right: 0;
    }
  }

  @media (max-width: 640px) {
    .footer {
      height: auto !important;
    }
  }
  // @media (max-width: 480px) {
  //   .footer {
  //     height: 150px !important;
  //   }
  // }
}
</style>