<template>
  <b-form-input v-model="val" disabled />
</template>

<script>
import { get } from "shvl";
export default {
  name: "formattedROInput",
  props: {
    value: {
      required: false,
    },
    formatter: {
      required: false,
      type: Function,
      default(value) {
        return value;
      },
    },
    _obj: {
      type: Object,
      required: true,
    },
    target: {
      type: [String, Array],
      required: false,
      default: null,
    },
  },
  computed: {
    val: {
      get() {
        const value =
          this.target !== null ? get(this._obj, this.target) : this.value;
        return this.formatter(value, null, this._obj);
      },
    },
  },
};
</script>

<style>
</style>