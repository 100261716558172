const default_root = '/pbx/services/v2/crm'
const crm_root = '/contract/deal'
const roots = {
  default_root,
  crm_root
}
const unique_for = ['customer', 'entity']

export default {
  namespaced: true,
  getters: {
    unique_for: (state, getters, rootState, rootGetters) => ({ ...rootGetters.current(...unique_for) }),
    unique_for_var: (state, getters, rootState, rootGetters) => (variant) => {
      let ret = {}
      if (variant == 'default') {
        Object.assign(ret, rootGetters.current('customer', 'entity'))
      } else {
        Object.assign(ret, rootGetters.current('customer', 'contract'))
      }
      return ret
    }
  },
  actions: {
    send_request: ({ dispatch, getters }, { url, data, type: type = 'default' }) => {
      const root = roots[`${type}_root`]
      const send_data = { url: `${root}/${url}`, data: { ...data, ...getters.unique_for_var(type) } }
      return dispatch('requestApi', send_data, { root: true })
    },
    crm_integration: ({ dispatch, commit }, data = {}) => dispatch('send_request', {
      url: 'request',
      type: 'default',
      data
    }).then(resp => {
      let ret = false
      if (resp?.data?.result == 'success') {
        commit('add_message', { type: 'success', message: 'Заявка на подключение успешно отправлена' }, { root: true })
        ret = true
      } else {
        commit('add_message', { type: 'danger', message: 'Ошибка при отправке заявки' }, { root: true })
      }
      return ret
    }),
    crm_task: ({ dispatch, commit }, { subject, description, priority: priority = 'low' }) => dispatch(
      'send_request', {
      url: 'crm_task',
      data: { subject, description, priority }
    }).then(resp => {
      let ret = false
      if (resp?.data?.result == 'success') {
        ret = true
        commit('add_message', { type: 'success', message: 'Заявка успешно отправлена' }, { root: true })
      } else {
        commit('add_message', { type: 'danger', message: 'Ошибка при отправке заявки' }, { root: true })
      }
      return ret
    }),
    deal: ({ dispatch, commit }, { title, comments, type: type = 'default' }) => dispatch(
      'send_request', {
      url: 'deal',
      data: { title, comments },
      type
    }).then(resp => {
      let ret = false
      if (resp?.data?.result == 'success') {
        commit('add_message', { type: 'success', message: 'Заявка успешно отправлена' }, { root: true })
        ret = true
      } else {
        const msg = resp.response?.data.text ?? 'Ошибка при отправке заявки'
        commit('add_message', { type: 'danger', message: msg }, { root: true })
      }
      return ret
    })
  }
}