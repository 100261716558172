const root = '/contract'
const unique_for = ['customer', 'contract']

export default {
    namespaced: true,
    getters: {
        unique_for: (state, getters, rootState, rootGetters) => ({ ...rootGetters.current(...unique_for) })
    },
    actions: {
        send_request: ({ dispatch, getters }, { url, data }) => {
            const send_data = { url: `${root}/${url}`, data: { ...data, ...getters.unique_for } }
            return dispatch('requestApi', send_data, { root: true })
        },
        ask_manager: ({ dispatch, commit }, data) => dispatch(
            'send_request', {
            url: 'askManager',
            data
        }).then(resp => {
            if (resp.data.result == 'success') {
                commit('add_message', { type: 'success', message: 'Заявка успешно отправлена' }, { root: true })
            } else {
                commit('add_message', { type: 'danger', message: 'Ошибка при отправке заявки' }, { root: true })
            }
        }),
        deal: ({ dispatch, commit }, { subject, description, phone, email, variant }) => {
            const convert_variants = {
                vps1: 'vps',
                vps2: 'energy',
            }
            const real_variant = convert_variants[variant] ?? variant
            return dispatch(
                'send_request', {
                url: 'deal/' + real_variant,
                data: { subject, description, phone, email }
            }).then(resp => {
                let ret = false
                if (resp.data?.result == 'success') {
                    commit('add_message', { type: 'success', message: 'Заявка успешно отправлена' }, { root: true })
                    ret = true
                } else {
                    let msg = resp.response?.data?.text ?? 'Ошибка при отправке заявки'
                    commit('add_message', { type: 'danger', message: msg }, { root: true })
                }
                return ret
            }, (error_resp) => {
                console.log('error resp', error_resp)
            })
        }
    }
}