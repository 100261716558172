<template>
  <b-col>
    <b-row class="rule-row">
      <b-col class="widget bg-white">
        <h3>
          <span v-if="get('tag').length === 0">Любой внешний номер</span>
          <span v-else>
            <b-button-group
              variant="outline"
              v-for="(t, i) in get('tag')"
              v-bind:key="t"
            >
              <b-button variant="outline">{{
                get_object("tag", t).name
              }}</b-button>
              <b-button variant="outline-danger" @click="remove_tag(t)">
                <icon icon="trash" />
              </b-button>
              <b-button
                v-b-tooltip="'очистить'"
                variant="outline-danger"
                v-if="i == get('tag').length - 1"
                @click="clear_tags()"
              >
                <icon icon="times" />
              </b-button>
            </b-button-group>
          </span>
          <b-button
            :id="`popover-row-${index}`"
            variant="outline"
            @click="close()"
          >
            <icon icon="cog" />
          </b-button>
          <b-popover :target="`popover-row-${index}`" :show.sync="showPopover">
            <template v-slot:title>
              <b-button
                class="close"
                aria-label="Close"
                @click="showPopover = false"
              >
                <icon icon="times" />
              </b-button>
              Действия
            </template>
            <b-form-row>
              <b-button
                size="sm"
                variant="outline-danger"
                @click="
                  $emit('remove', index);
                  showPopover = false;
                "
                block
                :disabled="index == 0"
                >Удалить
              </b-button>
            </b-form-row>
            <b-form-row>
              <b-button
                size="sm"
                variant="outline-success"
                block
                @click="
                  $emit('clone', value);
                  showPopover = false;
                "
                >Клонировать
              </b-button>
            </b-form-row>
            <b-form-row v-if="index !== 0">
              <b-button
                size="sm"
                block
                v-if="tag_mode == false"
                @click="tag_mode = true"
              >
                Добавить тэг
              </b-button>
              <b-form-select
                v-else
                v-bind="get_aval_options()"
                size="sm"
                @change="add_tag"
              />
            </b-form-row>
          </b-popover>
        </h3>
        <section v-if="get('tag').length === 0">
          <p>Настройка будет работать на любые номера клиентов:</p>
          <ul>
            <li>без тегов</li>
            <li>
              с любыми тегами, если для них не установлены доп. правила в рамках
              этого маршрута.
            </li>
          </ul>
        </section>
      </b-col>
    </b-row>
    <b-row class="rule-row">
      <b-col
        class="widget bg-white"
        v-for="(name, i) in shch_list()"
        v-bind:key="i"
      >
        <section>
          <h3>
            {{ name }}
            <b-button variant="outline" @click="showShchModal">
              <icon icon="cog" />
            </b-button>
          </h3>
        </section>
      </b-col>
    </b-row>
    <b-row class="rule-row">
      <b-col
        class="widget bg-white"
        v-for="(_, i) in shch_list()"
        v-bind:key="i"
      >
        <section v-if="get_object('media', get('media')[i])">
          <h3>
            {{ get_object("media", get("media")[i]).name }}
          </h3>
          {{ timeformat(get_object("media", get("media")[i]).file_length) }}
          <b-button variant="outline" @click="showMediaModal(i)"
            ><icon icon="edit"
          /></b-button>
        </section>
        <section v-else>
          <h4>Без приветствия</h4>
          <b-button variant="outline" @click="showMediaModal(i)">
            <icon icon="plus" />
          </b-button>
        </section>
      </b-col>
    </b-row>
    <b-row class="rule-row">
      <b-col
        class="widget bg-white"
        v-for="(_, i) in shch_list()"
        v-bind:key="i"
      >
        <section>
          <h3>
            Перевод на IVR-меню {{ get_object("action", get("action")[i]) }}
          </h3>
          Главное меню
          <b-button @click="showActionModal(i)">
            <icon icon="cog" />
          </b-button>
        </section>
      </b-col>
    </b-row>
    <b-modal
      :title="modal_title"
      v-model="show_modal"
      @close="closeModal"
      @ok="closeModal"
    >
      <media-select
        v-if="media_select !== undefined"
        v-model="value.media[media_select]"
      />
      <b-form-select
        v-if="shch_select"
        v-bind="get_aval_options('shch')"
        v-model="shch"
      >
        <b-form-select-option :value="undefined">
          Круглосуточно
        </b-form-select-option>
      </b-form-select>
      <obj-form
        v-if="action_select !== undefined"
        v-model="action[action_select]"
        mode="edit"
        :description="action_desc"
      />
    </b-modal>
  </b-col>
</template>

<script>
import { Icon, library } from "../layot";
import ObjForm from "../ObjForm";
import { timeformatter } from "../../formatters";
import { MediaSelect } from "../objects/media";
import { faCog, faPlus, faEdit } from "@fortawesome/free-solid-svg-icons";
import {
  test_tags,
  test_actions,
  test_shchtedulers,
  action_desctription,
} from "./description";
import { set } from "shvl";
import cloneDeep from "lodash/cloneDeep";
import { type_val } from "../../functions";

library.add(faCog, faPlus, faEdit);

export default {
  name: "RuleCol",
  props: {
    value: {
      required: true,
    },
    index: {
      required: true,
    },
  },
  data() {
    return {
      showPopover: false,
      tag_mode: false,
      new_tag: undefined,
      show_modal: false,
      shch_select: false,
      action_select: undefined,
      media_select: undefined,
      modal_title: "",
    };
  },
  computed: {
    tags_aval() {
      return test_tags.filter(
        (row) => (row = this.tag.indexOf(row.tag_id) === -1)
      );
    },
    shch: {
      get() {
        return this.value.schedule;
      },
      set(value) {
        this.upgrade_val({ schedule: value });
      },
    },
    media: {
      get() {
        return this.value.media;
      },
      set(value) {
        this.upgrade_val({ media: value });
      },
    },
    action: {
      get() {
        return this.value.action || [];
      },
      set(value) {
        this.upgrade_val({ action: value });
      },
    },
    action_desc: () => action_desctription,
  },
  methods: {
    catch_event() {
      //console.warn(event, value)
    },
    shch_list() {
      return (this.shch === undefined) | (this.shch === null)
        ? ["Круглосуточно"]
        : ["Рабочее время", "Выходные"];
    },
    closeModal() {
      this.media_select = undefined;
      this.shch_select = false;
      this.show_modal = false;
      this.action_select = undefined;
    },
    showMediaModal(indx) {
      this.modal_title = "Выбор медиафайла";
      this.media_select = indx;
      this.show_modal = true;
      this.$forceUpdate();
    },
    showShchModal() {
      this.modal_title = "Расписание";
      this.show_modal = true;
      this.shch_select = true;
    },
    showActionModal(indx) {
      var actions = this.value.action || []; // fix action
      if (type_val(actions[indx]) == "Number") {
        this.action[indx] =
          this.get_object("action", this.get("action")[indx]) || {};
      } else if (actions[indx] === undefined) {
        this.action[indx] = {};
      }
      this.modal_title = "Действие";
      this.action_select = indx;
      this.show_modal = true;
    },
    get(type = "tag") {
      if (["action", "tag", "media"].indexOf(type) > -1) {
        return this.value[type] || [];
      }
      return [];
    },
    timeformat(val) {
      return timeformatter(val);
    },
    close() {
      this.tag_mode = false;
      this.showPopover = false;
    },
    update_val(value) {
      this.$emit("input", value);
      this.$forceUpdate();
    },
    upgrade_val(options = {}) {
      let v = cloneDeep(this.value);
      Object.entries(options).map(([target, val]) => {
        set(v, target, val);
      });
      this.$emit("input", v);
      this.$forceUpdate();
    },
    get_aval_options(type = "tags") {
      const data = {
        tags: test_tags,
        actions: test_actions,
        media: this.$store.getters.get_objects("media"),
        shch: test_shchtedulers,
      };
      const add_options = {
        tags: { "text-field": "name", "value-field": "tag_id" },
        media: { "text-field": "name", "value-field": "media" },
        shch: { "text-field": "name", "value-field": "shch_id" },
      };
      let options = {
        options: data[type] || [],
      };
      options = Object.assign({}, options, add_options[type] || {});
      return options;
    },
    get_object(type = "tag", value = undefined) {
      if (value === undefined) {
        return undefined;
      }
      const data = {
        tag: test_tags,
        action: test_actions,
        media: this.$store.getters.get_objects("media"),
        shch: test_shchtedulers,
      };
      const keys = {
        tag: "tag_id",
        action: "action_id",
        media: "media",
        shch: "shch_id",
      };
      const dt = data[type] || [];
      const d = dt.filter((row) => row[keys[type]] == value);
      return d.length ? d[0] : undefined;
    },
    add_tag(tag) {
      let value = this.value;
      let tags = this.get("tag");
      tags.push(tag);
      value.tag = tags;
      this.update_val(value);
      this.tag_mode = false;
      this.showPopover = false;
      //this.$store.commit('add_message', {type: 'success', message: tag})
    },
    get_tag_name(indx) {
      const tags = test_tags.filter((row) => row.tag_id == indx) || [];
      return tags.length ? tags[0] : undefined;
    },
    clear_tags() {
      let value = this.value;
      value.tag = [];
      this.update_val(value);
    },
    remove_tag(indx) {
      const tags = this.get("tag");
      let value = this.value;
      value.tag = tags.filter((row) => row != indx);
      this.update_val(value);
    },
  },
  components: {
    Icon,
    ObjForm,
    MediaSelect,
  },
};
</script>

<style>
.rule-row {
  padding-left: 1rem;
}
.rule-row .widget {
  margin-right: 1rem;
}
</style>