<script>
import ObjList from '../ObjList'
import UTable from './UTable'
export default {
    name: 'UserList',
    mixins: [ObjList],
    computed: {
        obj_type: () => {return 'user'},
        table_comp: () => {return UTable},
    }
}
</script>