<template>
  <b-form-input
    v-model.number="val"
    :disabled="disabled"
    :placeholder="placeholder"
    type="number"
  >
  </b-form-input>
</template>


<script>
import { InputMixin } from "../../mixins";
import ObitIcon from "../../obit/ObitIcon";

export default {
  components: { ObitIcon },
  props: ["placeholder"],
  name: "NumberInput",
  mixins: [InputMixin],
};
</script>



<style lang="scss" scoped>
</style>