<template>
  <div disabled="disabled">
    <span :class="classes">{{ message }}</span>
  </div>
</template>

<script>
import { get } from "shvl";
export default {
  name: "isActive",
  props: {
    active_message: {
      type: String,
      required: false,
      default: "Активен",
    },
    active_class: {
      type: String,
      required: false,
      default: "text-success",
    },
    not_active_class: {
      type: String,
      required: false,
      default: "text-danger",
    },
    not_active_message: {
      type: String,
      required: false,
      default: "Не активен",
    },
    rule: {
      required: false,
      default: undefined,
    },
    value: {
      required: false,
    },
    item: {
      required: false,
    },
    field: {
      required: false,
    },
  },
  computed: {
    obj() {
      let target = this.field?.target ?? this.field?.key;
      if (this.item && target) {
        return get(this.item, target);
      }
      return this.value;
    },
    _rule: function () {
      return this.rule ? this.rule : (value) => (!value ? true : undefined);
    },
    message() {
      return this._rule(this.obj)
        ? this.active_message
        : this.not_active_message;
    },
    classes() {
      return [
        this._rule(this.obj) ? this.active_class : this.not_active_class,
        "act-status",
      ];
    },
  },
};
</script>

<style>
.form-control[disabled] {
  border: none !important;
  padding-left: 0px;
}
</style>