<template>
  <div>
    <b-nav-item
      :class="{ active: isActive(value) || active, ...classes }"
      v-bind="link"
      v-b-toggle:[toggle_target]
    >
      <component :is="icon_comp" :icon="value.icon" v-if="value.icon" />
      <span class="nav-label"> {{ value.name }}</span>
      <icon
        v-if="value.items.length"
        :icon="value.opened || isActive() ? arrow_up : arrow_down"
        class="right"
      />
    </b-nav-item>
    <b-collapse :id="toggle_target">
      <menu-item
        v-for="(sl_item, sl_i) in value.items"
        v-bind:key="sl_i"
        :level="next_level"
        :value="sl_item"
        :indx="sl_i"
        :position="pos"
      />
    </b-collapse>
  </div>
</template>

<script>
import {
  faChevronLeft,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { Icon, as_icon } from "./FontAwesomeIcon.vue";
import { BLink, BNavItem, BCollapse } from "bootstrap-vue";
import EventBus from "../EventBus";
import { type_val, bindEvent } from "../../functions";
export default {
  name: "menuItem",
  components: { Icon, BLink, BNavItem, BCollapse },
  mixins: [EventBus],
  mounted() {
    bindEvent(this, "menuToggle", this.catchEvent);
    bindEvent(this, "togglePosition", this.catchToggle);
  },
  props: {
    indx: {
      required: false,
      type: Number,
      default: 0,
    },
    position: {
      required: false,
      type: Object,
      default() {
        return {};
      },
    },
    level: {
      default: 1,
      type: Number,
    },
    arrow_up: {
      type: [String, Array],
      default: () => as_icon(faChevronLeft),
    },
    arrow_down: {
      type: [String, Array],
      default: () => as_icon(faChevronDown),
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    catchEvent(payload) {
      if (this._uid != payload.id && this.level == payload.level) {
        this.active = false;
      }
    },
    catchToggle(payload) {
      if (this.pos_val !== payload[this.level]) {
        this.value.opened = false;
      }
    },
    toggleItem() {
      this.value.opened = !this.value.opened || false;
      this.$forceUpdate();
    },
    togglePosition() {
      if (!this.link.disabled) {
        this.$eventBus.$emit("togglePosition", this.pos);
        this.$store.commit("set_any_value", {
          key: "position",
          value: this.pos,
        });
      }
    },
    toggleButton() {
      this.$eventBus.$emit("menuToggle", this.ids_to_ignore);
    },
    isActive() {
      return this.pos[this.level] == this.$store.getters.position[this.level];
    },
  },
  computed: {
    toggle_target() {
      return `menu_${this.level}_${this.indx}`;
    },
    link() {
      let ret = {};
      if (this.value.url) {
        ret["to"] = this.value.url;
        //ret["is"] = BLink;
      } else {
        //ret["is"] = "a";
        ret["disabled"] = true;
        ret["active"] = false;
      }
      return ret;
    },
    classes() {
      var ret = {};
      Object.entries(this.value.classes).map(([key, val]) => {
        ret[key] = type_val(val) !== "Function" ? val : val();
      });
      return ret;
    },
    icon_comp() {
      return this.value.icon_comp || Icon;
    },
    ids_to_ignore() {
      return { level: this.level, id: this._uid };
    },
    my_level_class() {
      const classes = {
        2: "nav-second-level",
        3: "nav-third-level",
      };
      let ret = classes[this.next_level] || "";
      return ret;
    },
    has_next_level() {
      return this.level <= 2;
    },
    next_level() {
      return this.has_next_level ? this.level + 1 : undefined;
    },
    pos_val() {
      return this.value.position !== undefined
        ? this.value.position
        : this.indx;
    },
    pos() {
      return { ...this.position, [this.level]: this.pos_val };
    },
  },
};
</script>

<style>
.right {
  float: right;
  line-height: 1.42857;
}
body.mini-navbar .right {
  display: none;
}
body.mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 160px;
  height: auto;
}
.mini-navbar .nav .nav-second-level {
  position: absolute;
  left: 70px;
  top: 0;
  display: block;
  background-color: #2f4050;
  padding: 10px 10px 10px 10px;
  font-size: 12px;
}
body.fixed-sidebar-v2.mini-navbar .nav li:hover > .nav-second-level,
.mini-navbar .nav li:focus > .nav-second-level {
  display: block;
  border-radius: 0 2px 2px 0;
  min-width: 160px;
  height: auto;
}
#side-menu :not(.right) svg:not(.right) {
  margin-right: 0.5em;
}
</style>