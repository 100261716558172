import Rete from "rete";
import { callSocket } from "../../sockets";
import Comp from './Component'

import UNKNOWN_CONFIG from './config'

const obj_type = UNKNOWN_CONFIG.objType

export { UNKNOWN_CONFIG }
export class UNKNOWN extends Rete.Component {
  constructor() {
    super(obj_type);
    this.data.component = Comp
    this.data._config = UNKNOWN_CONFIG
  }

  builder(node) {
    const cnf = this.data._config
    const defBuildOutputs = (node) => {
      for (const _output of node.data.raw.outputs ?? []) {
        let output = new Rete.Output(_output.key, _output.key, callSocket, false)
        node.addOutput(output)
      }
    }
    const defBuildInputs = node => {
      var in1 = new Rete.Input("call", "Call", callSocket, true);
      node.addInput(in1)
    }
    const buildInputs = cnf.mapInputs ?? defBuildInputs
    const buildOutputs = cnf.mapOutpus ?? defBuildOutputs


    buildInputs(node)
    buildOutputs(node)

    return node
  }


  async worker(node) {
    // const node_id = node.id
    let current_node = this.editor.nodes.find(n => n.id == node.id)
    let has_root = false
    for (const conn of node.inputs.call.connections) {
      const n = this.editor.nodes.find(_n => _n.id == conn.node)
      if (!n) continue
      if (!has_root) {
        if (n.name == 'city_number') {
          has_root = true
        } else if (n.name != 'start' && n.data.has_root) {
          has_root = true
        }
      } else continue
    }
    current_node.data.has_root = has_root
    node.data.has_root = has_root
    current_node.update()
    current_node.vueContext.$forceUpdate()
  }
}
