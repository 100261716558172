<script>
import ObjList from '../ObjList'
import { default_promise } from '../../../constants'
//import {get_objects} from './functions'

export default {
    name: 'ServiceList',
    mixins: [ObjList],
    computed: {
        obj_type: () => 'service',
    },
    methods: {
        fetch_data() {
            return default_promise
        },
        // fetch_data: function(){
        //     return get_objects()
        // }
    }
}
</script>