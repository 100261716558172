import $ from 'jquery'
import slimscroll from '../../static/js/plugins/slimscroll/jquery.slimscroll'
// import '../../static/css/plugins/iCheck/custom.css'
slimscroll($)


export default {
    name: 'scrollable',
    inserted(el, binding) {
        const default_options = {}
        const opts = Object.assign({},
            default_options, binding.value || {}
        )
        $(el).slimScroll(opts)
    },
    unbind(el) {
        $(el).slimScroll({ destroy: true })
    },
}