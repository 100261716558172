const numbers = [  // доступные номера
    { number: '78006001671', price: 1200, connect_price: 2100, status: 'gold', group: 'вся Россия' },
    { number: '78006001672', price: 1200, connect_price: 2100, status: 'gold', group: 'вся Россия' },
    { number: '78006001673', price: 1200, connect_price: 2100, group: 'вся Россия' },
    { number: '78006001674', price: 1200, connect_price: 2100, group: 'Санкт-Петербург' },
    { number: '78006001675', price: 1200, connect_price: 2100, group: 'Санкт-Петербург', commentary: 'Только для юрлиц' },
    { number: '78006001676', price: 1200, connect_price: 2100, group: 'вся Россия' },
    { number: '78006001677', price: 1200, connect_price: 2100, status: 'silver', group: 'вся Россия' },
    { number: '78006001697', price: 1200, connect_price: 2100, status: 'silver', group: 'вся Россия' },
    { number: '78006001687', price: 1200, connect_price: 2100, status: 'silver', group: 'вся Россия' },
    { number: '78006001777', price: 1200, connect_price: 2100, status: 'platinum', group: 'вся Россия' },
    { number: '78006001678', price: 1200, connect_price: 2100, group: 'Санкт-Петербург' },
    { number: '78006001679', price: 1200, connect_price: 2100, group: 'Санкт-Петербург' },
]

const test_file = 'https://file-examples-com.github.io/uploads/2017/11/file_example_WAV_1MG.wav'

const calls_info = [ // лог
    {
        log_id: 1, time: '2020.08.20 14:15:00', link: test_file, events: [
            { time: 0, event: 'Вызов на входящую линию', obr: '+78126791277' },
            { time: 1, event: 'Исходящий вызов' },
            { time: 0, event: 'Исходящий вызов' },
            { time: 7, event: 'Соединение завершено', obr: '+74956791277' },
        ]
    },
    {
        log_id: 2,
        time: '2020.08.20 14:15:00', link: test_file, events: [
            { time: 2, event: 'Вызов на входящую линию', obr: '+78126791277' },
            { time: 0, event: 'Вызов на входящую линию' },
            { time: 4, event: 'Вызов на входящую линию' },
            { time: 7, event: 'Соединение завершено', obr: '+74956791277' },
        ]
    },
    {
        log_id: 3,
        time: '2020.08.20 14:15:00', link: test_file, events: [
            { time: 0, event: 'Вызов на входящую линию', obr: '+78126791277' },
            { time: 2, event: 'Вызов на входящую линию' },
            { time: 20, event: 'Вызов на входящую линию' },
            { time: 70, event: 'Соединение завершено', obr: '+74956791277' },
        ]
    },
]

const test_address_book = [ // адрес
    {
        person_id: 1,
        first_name: 'Константин', last_name: 'Константинопольский',
        email: 'ekamelev@yandex.ru', site: ['projectorat.ru'],
        numbers: ['79520000001', '79520000002', '79520000003'],
        company: 'Обит'
    },
    {
        person_id: 2,
        first_name: 'Иван', last_name: 'Иванов',
        email: 'ekamelev@yandex.ru', position: '', site: ['projectorat.ru'],
        numbers: ['79529900001', '79529900099']
    },
    {
        person_id: 3,
        first_name: 'Эвтанайзер', last_name: 'Эвтанайзеров',
        email: 'ekamelev@yandex.ru', position: '', site: ['projectorat.ru'],
        numbers: ['79529900401']
    },
    {
        person_id: 4,
        first_name: 'BVz', last_name: 'afvbkbnz',
        email: 'amil@yandex.ru', position: 'Кто-то', site: ['projectorat.ru'],
    }
]

const calls_list = [
    {
        call_id: 1,
        time: '2020.08.20 14:15:00',
        type: 'inner',
        type_additional: 1,
        log_id: 1,
        from: 1, to: 2,
        tags: [1, 2],
    },
    {
        call_id: 2,
        time: '2020.08.20 14:15:00',
        type: 'inner', type_additional: 2, log_id: 2, from: 2, to: 1,
        tags: []
    },
    { call_id: 3, time: '2020.08.20 14:15:00', type: 'inner', type_additional: 3, log_id: 3, from: 3, to: 2, tags: [] },
    { call_id: 4, time: '2020.08.20 14:15:00', type: 'outer', type_additional: 1, log_id: 1, from: 3, to: 1, tags: [] },
    { call_id: 5, time: '2020.08.20 14:15:00', type: 'outer', type_additional: 2, log_id: 1, from: 1, to: 2, tags: [] },
    { call_id: 6, time: '2020.08.20 14:15:00', type: 'outer', type_additional: 3, log_id: 1, from: 2, to: 1, tags: [] },
]

var test_data = {}
const register_test_data = (name, data = []) => {
    test_data[name] = data
}


const optionItem1 = {
    systemName: 'field_1',
    title: 'Название поля 1',
    type: 'int',
    defaultValue: 0,
    currentValue: 1
}
const optionItem2 = {
    systemName: 'field_2',
    title: 'Название поля 2',
    type: 'string',
    defaultValue: 'по умолчанию',
    currentValue: 'string value текущее значение'
}
const optionItem3 = {
    systemName: 'field_3',
    title: 'Название поля 3',
    type: 'optionsList',
    defaultValue: 0,
    currentValue: 1,
    options: [
        { value: 0, text: 'Значение 0' },
        { value: 1, text: 'Значение 1' },
        { value: 2, text: 'Значение 2' },
    ]
}


const optionItem4 = {
    systemName: 'field_4',
    title: 'Название поля 4 (чекбокс)',
    type: 'checkbox',
    defaultValue: "false",
    currentValue: "true"
}

const optionItem5 = {
    systemName: 'field_5',
    title: 'Название поля (список чекбоксов)',
    type: 'checkList',
    defaultValue: [],
    currentValue: [1],
    options: [
        { value: 0, text: 'Значение 0' },
        { value: 1, text: 'Значение 1' },
        { value: 2, text: 'Значение 2' },
    ]
}

/*
var object = {
    customer_id: 'customer_id',
    entity_id: 'entity_id',
    integration_id: 'integration_id',
    
    [optionItem1.systemName]: optionItem1.currentValue,
    [optionItem2.systemName]: optionItem2.currentValue,
    [optionItem3.systemName]: optionItem3.currentValue,
}
*/

// съедобный объект-образец для objForm
/* var integration_description = {
    verbose: 'integration',
    fieds: {
        [optionItem1.systemName]: {
            label: optionItem1.title,
        },
        [optionItem2.systemName]: {
            label: optionItem2.title,
        },
        [optionItem3.systemName]: {
            label: optionItem3.title,
            comp: BFormSelect,
            options: {
                options: optionItem3.options
            }
        }
    }
} */

const test_cust_id = "C9E9B1B3-48E8-4936-96B5-805111DB67BC"
const test_ent_id = "9B778597-7C37-4565-9ABC-94C88D4C469A"
const resp = {
    customer_id: test_cust_id,
    entity_id: test_ent_id,
    integration_id: 'integration_id',
    title: 'Первая',
    options: [
        optionItem1,
        optionItem2,
        optionItem3,
        optionItem4,
        optionItem5,
    ]
}
const resp2 = {
    customer_id: test_cust_id,
    entity_id: test_ent_id,
    integration_id: 'integration_id1',
    title: 'Вторая',
    options: [
        optionItem2,
        optionItem5,
    ]
}
const resp3 = {
    customer_id: test_cust_id,
    entity_id: test_ent_id,
    integration_id: 'integration_id2',
    title: 'Третья',
    options: [
        optionItem1,

    ]
}

const list_test_resp = [
    resp, resp2, resp3

]
//export { optionItem1, optionItem2, optionItem3 }

export { resp as TEST_INTEGRATION_RESPONSE, list_test_resp as TEST_INTEGRATION_RESP_LIST }

export { numbers, calls_info, calls_list, test_address_book, test_data, register_test_data }