<script>
import Obj from '../Obj'
// import { build_depences } from '../../../object'
// import { get_objects } from './functions'

export default {
    name: 'ServiceObj',
    mixins: [Obj],
    computed: {
        obj_type: () => 'service',
    },
}
</script>