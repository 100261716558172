<template>
  <div class="call_type">
    <b>{{ label_text }}</b
    ><br /><br />
    <b-form-checkbox-group
      v-model="val"
      :options="options_list"
      buttons
      button-variant="outline-obit"
    ></b-form-checkbox-group>
  </div>
</template>

<script>
import { InputMixin } from "../mixins";

export default {
  name: "CallsTypeField",
  mixins: [InputMixin],
  props: {
    options_list: {
      type: Array,
      required: true,
    },
    label_text: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {},
};
</script>