<template>
  <div class="about-licese">
    <div class="row header">
      <div class="col">
        <span class="title">Информация о лицензии</span>

        <b-button
          variant="outline-obitless"
          class="add-item"
          type="button"
          @click="$router.push({name: 'license'})"
        >
          <obit-icon icon="plus" />
          Обновить лицензию
        </b-button>
      </div>
    </div>

    <div class="row items">
      <div class="col">
        <b-table  class="objects-table" empty-text="Нет информации" stacked :items="items" :fields="fields">
          <template #cell(expired)="data">
            <b class="text-info">{{ parseDate(data.value) }}</b>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import {fromUnixTime,  format } from "date-fns";
import {DATETIME_FORMAT} from '@/formatters.js'
import ObitIcon from "@/components/obit/ObitIcon.vue";

export default {
  name: "AboutLicense",
  components: {ObitIcon},

  data() {
    return {
      fields: [
        // {
        //   key: `customer_id`,
        //   label: ``
        // },
        // {
        //   key: `entity_id`,
        //   label: ``
        // },
        {
          key: `entity_name`,
          label: `Номер договора`
        },
        {
          key: `customer_name`,
          label: `Клиент`
        },
        {
          key: `expired`,
          label: `Дата окончания`
        }
      ]
    }
  },

  computed: {
    license() {
      return this.$store.getters['license']()
    },

    items() {
      return this.license ? [this.license] : []
    },
  },

  methods: {
    parseDate(ms) {
      const date = fromUnixTime(ms)

      try {
        return format(date, DATETIME_FORMAT)
      } catch (err) {
        return `Invalid date`
      }
    }
  },
};
</script>

<style  lang='scss'>
.table.b-table.b-table-stacked > tbody > tr > :first-child {
  border-top-width: 0;
}
</style>