import { actions_field, id_field_desc } from '@/functions.js'
import { optionsCell } from '../../shared/index';
import { buildBooleanField, buildRelatedField, description_field } from "../fields";


//import totalCheckbox from './totalCheckbox';
/* const root = 'pbx/'
const URLS = {
    add: `${root}/register`,
    get: `${root}/info`,
    update: `${root}/update`,
    delete: `${root}/close`,
    list: `${root}/list`,
} */
const storage = {

}

// NOTE: здесь (от/в)ключается инетграции
const INTEGRATION_DISABLED = false;


let _build_field = (label, key) => ({
  label, key, target: key, rule: (item) => !!item[key] && item[key] != ' '
});
const dynamycFields = [_build_field('Токен', 'token'), _build_field('APP KEY', 'app_key'), _build_field('Адрес сервера', 'address')]

let dymamicIntegration = {
  verbose: 'Интеграция',
  plural: 'Интерации',  // каким образом определяется, что будет использовано, множ или единств число?
  // urls: URLS, 
  // obj_key: `integratop_id`,
  no_create_button: true,
  //no_search: true,
  search_placeholder: 'Поиск по интеграциям',
  obj_name: 'title',  // зачем? в каком поле искать имя этого объекта
  obj_type: 'integration', // patch для этой компоненты берётся отсюда для. Что ещё обозначает ключ obj_type ?
  //create_button_text: 'Новая интеграция',  // текст для кнопки справа наверху
  storage, // зачем? если хранилище должно работать нестандартным образом (к Илье)
  fields: {
    integration_id: id_field_desc,
    entity_id: buildRelatedField('entity', 'entity'),
    customer_id: buildRelatedField('customer_id', 'customer'),
    title: { label: 'Название интеграции', isName: true, isKey: true },
    token: { label: "Токен" },
    app_key: { label: "APP KEY" },
    address: { label: "Адрес сервера" }
  },
  list_fields: ['title', 'address', actions_field],
  list_fields_search: ['title',],
  new_fields: ['title', 'address', 'token', 'app_key'],
  edit_fields: ['title', ...dynamycFields],
  view_fields: ['title', ...dynamycFields],
}


const link_with_params = (item, where) => {

  return `/sip/${item.obj_type}/${item.obj_id}/${where}`
}


const broot = 'pbx/services/v2/crmIntegrations';
const bitrix_new_fields = ['caption', 'domain', 'client_id', 'client_secret', 'description'];
const bitrix_edit_views_fields = ['caption', 'description', _build_field('Адрес сервера', 'domain'), _build_field('Код приложения (client_id)', 'client_id'), _build_field('Ключ приложения (client_secret)', 'client_secret')];

let bitrixData = {
  verbose: 'Интеграция',
  plural: 'Интеграции',
  newParam: true,
  urls: {
    add: `${broot}/createBitrix`,  // pbx/services/v2/crmIntegrations/
    get: `${broot}/get`,
    update: `${broot}/modify`,
    delete: `${broot}/delete`,
    list: `${broot}/list`,
    users: `${broot}/users`,
    companyStructure: `${broot}/companyStructure`,
  },
  obj_key: `service_id`,
  no_create_button: true,
  //no_search: true,
  unique_for: ['customer', 'entity'],
  search_placeholder: 'Поиск по интеграциям',
  nextDelete: '/sip/integration',
  obj_name: 'caption',
  obj_type: 'integration',
  empty_msg: 'У вас нет подключенных Интеграций. Подключение через раздел "Тариф"',
  //create_button_text: 'Новая интеграция',  
  storage,
  fields: {
    service_id: id_field_desc,
    entity_id: buildRelatedField('entity_id', 'entity'),
    customer_id: buildRelatedField('customer_id', 'customer'),
    caption: { label: 'Название интеграции', isName: true, isKey: true },
    description: description_field,
    client_secret: { label: "Ключ приложения (client_secret)" },
    client_id: { label: "Код приложения (client_id)" },
    domain: { label: "Адрес сервера" },
    welcomeSound: buildRelatedField('Приветствие', 'media'),
    errorSound: buildRelatedField('Сообщение об ошибке', 'media', { description: 'Сообщение при ошибке связи с CRM' }),
    timeout: {
      label: 'Включено',
      description: 'Время ожидания перехода (сек.)',
      //comp: totalCheckbox
    },
    awaitWelcomeSoundEnds: buildBooleanField('Проигрывать приветсвие до конца?')
  },
  list_fields: ['caption', 'description',
    {
      label: '', key: 'actions2', cell: optionsCell, cell_options: {
        actions: [
          { text: 'Информация', rule: comp => { return { to: link_with_params(comp.item, 'info') } } },
          { text: 'Таблица интеграции', rule: comp => { return { to: link_with_params(comp.item, 'users') } } },
          { text: 'Настройки', rule: comp => { return { to: link_with_params(comp.item, 'settings') } } },
        ]
      }
    }
  ],
  list_fields_search: ['caption', 'description'],
  new_fields: bitrix_new_fields,
  edit_fields: bitrix_edit_views_fields,
  view_fields: bitrix_edit_views_fields,
}

//console.log("list_fields", bitrixData.list_fields)

export { dymamicIntegration, bitrixData, INTEGRATION_DISABLED }

