import { BFormDatepicker } from "bootstrap-vue";

const buildFilterRule = (field_descr, rule = () => true) => Object.assign(field_descr, {
  filter_rule: rule
})

const FILTER_DESC = {
  verbose: 'Фильтр',
  fields: {
      period_start: buildFilterRule(
          { label: 'С', comp: BFormDatepicker },
          () => true
      ),
      period_end: buildFilterRule(
          { label: 'По', comp: BFormDatepicker },
          () => true
      ),
  }
}

export { FILTER_DESC }
