<template>
  <body id="app" :class="{ vertical: is_vertical.value }">
    <div class="messages">
      <b-alert
          dismissible
          :show="alert.time"
          fade
          :variant="alert.type"
          v-for="alert in messages"
          v-bind:key="alert.key"
          :slot="alert.options"
      >
        <component :is="alert.header_tag || 'h6'" v-if="alert.header">{{
            alert.header
          }}</component>
        <br v-if="alert.header" />
        <p v-if="alert.message">{{ alert.message }}</p>
        <p v-for="(m, i) in alert.messages" :key="i">{{ m }}</p>
      </b-alert>
    </div>
    <router-view />
  </body>
</template>

<script>
import Vue from "vue";
import BootstrapVue, {
  VBModal,
  VBTooltip,
  VBHover,
  VBToggle,
  BAlert,
} from "bootstrap-vue";
import "../static/css/vue-fixes.css";
import config from "./vue-config.js";
// import { updateToken } from "@/functions.js";

Vue.directive("b-modal", VBModal);
Vue.directive("b-tooltip", VBTooltip);
Vue.directive("b-hover", VBHover);
Vue.directive("b-toggle", VBToggle);

Vue.use(BootstrapVue, config);

const break_point = 800;

const isVertical = Vue.observable({
  value: false,
  width: window.innerWidth,
  height: window.innerHeight,
});
const is_vertical = () => window.innerWidth <= break_point;
const set_isVertical = () => {
  isVertical.value = is_vertical();
  isVertical.width = window.innerWidth;
  isVertical.height = window.innerHeight;
};

export default {
  components: {BAlert},
  data() {
    return {
      messages: [],
      is_vertical: isVertical,
    };
  },
  provide() {
    return {
      is_vertical: isVertical,
    };
  },
  methods: {
    // userLoaded: function (e) {
    //   // console.log('I am listening to the user loaded event in vuex-oidc', e.detail)
    // },
    oidcError: function (e) {
      console.log('I am listening to the oidc oidcError event in vuex-oidc', e.detail)
    },
    automaticSilentRenewError: function (e) {
      console.log('I am listening to the automaticSilentRenewError event in vuex-oidc', e.detail)
    },
  },

  mounted() {
    // window.addEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.addEventListener('vuexoidc:oidcError', this.oidcError)
    window.addEventListener('vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError)

    set_isVertical();
    window.addEventListener("resize", set_isVertical);
  },
  beforeDestroy() {
    window.removeEventListener("resize", set_isVertical);
    // window.removeEventListener('vuexoidc:userLoaded', this.userLoaded)
    window.removeEventListener('vuexoidc:oidcError', this.oidcError)
    window.removeEventListener('vuexoidc:automaticSilentRenewError', this.automaticSilentRenewError)
  },
  watch: {
    "$store.state.messages": function (from, to) {
      if (to.length) {
        this.$store
            .dispatch("messages")
            .then((m) => (this.messages = this.messages.concat(m)));
      }
    },

    // $route() {
    // updateToken()
    // }
  },
};
</script>

<style lang='scss'>
@import "../static/SCSS/obit.scss";
@import "~bootstrap-vue/src/index.scss";
@import "~simplebar/dist/simplebar.min.css";
// @import "../static/SCSS/style.scss";

body {
  overflow: hidden;
}

.messages {
  width: 30vw;
  position: absolute;
  z-index: 3000;
  right: 1%;
  top: 1%;
  height: fit-content;
}

.hidden {
  display: none !important;
}

#app {
  height: auto;
}

svg.inline {
  vertical-align: bottom;
  display: inline-block;
}

.loading {
  position: absolute;
  color: whitesmoke;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.loading > * {
  display: inline-flex;
  width: 4rem;
  height: 4rem;
  margin: 0.5rem;
}
</style>
