<template>
  <b-link :to="url" class="number-cell">
    {{ value }}
  </b-link>
</template>

<script>
import CellVue from "../mixins/Cell.vue";
export default {
  name: "keyCell",
  mixins: [CellVue],
  computed: {
    url() {
      let ret = `./${this.item.obj_type}/${this.item.obj_key}`;
      if (this.field_desc.list_options?.root !== undefined) {
        ret = `${this.field_desc.list_options?.root}/${this.item.obj_key}`;
      }
      return ret;
    },
  },
};
</script>

<style>
.number-cell {
  white-space: nowrap;
}
</style>