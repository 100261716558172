<template>
  <li class="manager-menu" v-if="manager.ident">
    <h5 ref="header">Ваш менеджер</h5>
    <b-avatar :src="manager.img"></b-avatar>
    <h4>
      {{ manager.name }}
    </h4>
    <b-button
      variant="obit"
      block
      @click="goModal"
      ref="button"
      class="btn-manager"
    >
      Связаться с менеджером
    </b-button>
    <manager-modal ref="man_modal" />
  </li>
</template>

<script>
// import { bindEvent } from "../../functions";
import ObjForm from "../ObjForm.vue";
import ManagerModal from "./ManagerModal";
// import fileInputVue from "../layot/fileInput.vue";

export default {
  components: { ObjForm, ManagerModal },
  name: "Manager",
  mounted() {
    // let store = this.$store;
    // const manager = store.getters.get_manager || {};
    // const upd_manger = () =>
    //   this.$store
    //     .dispatch("customer/get", store.getters.current_customer)
    //     .then((data) => {
    //       store.commit("update_manager", data.manager);
    //     });
    // if (store.getters.current_customer && !manager.ident) {
    //   upd_manger();
    // }
    // bindEvent(this, "customer_changed", upd_manger);
  },
  data() {
    return {
      file: null,
    };
  },
  computed: {
    manager() {
      return this.$store.getters.get_manager;
    },
  },
  methods: {
    goModal() {
      this.$refs["man_modal"].show("");
    },
  },
};
</script>

<style lang='scss'>
@import "../../../static/SCSS/obit-colors.scss";
.manager-menu {
  padding: 0.5em 2em;
  background-color: $obit-darker;
  padding: 30px;
  margin-top: auto;
  display: block;
  .side-menu & {
    display: list-item;
  }
  h5 {
    line-height: 14.4px;
    font-size: 12px;
    color: $obit-dark;
  }
  h4 {
    font-size: 14px;
    line-height: 16.8px;
    float: right;
    padding: 0.25em;
    min-height: 3em;
    width: calc(100% - 3.5em);
  }
  .btn {
    padding: 1em 2em;
    font-size: 9px;
    line-height: 10.8px;
    max-width: 267px;
    body.vertical & {
      font-size: 14px;
      line-height: 16.8px;
      padding: 12px 24px;
    }
    &.btn-manager {
      &:focus {
        background-color: var(--primary) !important;
        border-color: var(--primary) !important;
      }
      &:hover {
        background-color: var(--warning) !important;
        border-color: var(--warning) !important;
      }
    }
  }
  .manager-modal-footer {
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}
</style>