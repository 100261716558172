import { merge } from "lodash-es";
import { INTEGRATION_DISABLED } from "../components/objects/integration/description";

const def_exact_options = {
  "exact-active-class": "active",
};
const blank_options = { disabled: true };
const build_prop = (link, props = {}) => merge({
  to: link,
  active: route => route.path.startsWith(link)
}, props)

const OSN_MENU = [
  {
    title: "Все сервисы",
    props: Object.assign({ to: { name: "dash" } }, def_exact_options),
  },
  {
    title: "Интернет",
    props: build_prop('/internet'),
    menu_id: '429ece26-4fe9-11e8-afb4-003048c6b4ab'
  },
  {
    title: 'Цифровая телефония',
    menu_id: '429ece27-4fe9-11e8-afb4-003048c6b4ab',
    props: build_prop('/dsip')
  },
  {
    title: "ВАТС",
    menu_id: 'bd67a09f-42f8-11ed-9c59-005056b57a2d',
    props: Object.assign(
      {
        to: "/sip",
      },
      def_exact_options
    ),
  },
  // { title: "Интернет", props: blank_options },
  // { title: "Wi-Fi", props: blank_options },
  // { title: "Виртуальные серверы", props: blank_options },
  // { title: "Видеонаблюдение", props: blank_options },

  {
    title: "Настройки",
    props: build_prop("/settings"),
  },
  {
    title: "Финансы",
    props: {
      to: { name: "finances" },
      active: ($route) => $route.path.startsWith("/finances"),
    },
  },
  { title: "Договор", props: build_prop('/spec') },
];

const SIP_MENU = [
  {
    title: "Звонки",
    props: build_prop("/sip/call"),
    descr: 'История входящих и исходящих, клиентских и внутренних вызовов; прослушивание записи разговоров'
  },
  {
    title: "Внутренние номера",
    props: build_prop("/sip/internal_line2"),
    descr: 'Короткие номера ваших сотрудников'
  },
  {
    title: "Внeшние номера",
    props: build_prop("/sip/city_number"),
    descr: 'Телефонные номера для входящих и исходящих внешних вызовов.'
  },
  {
    title: "Маршрутизация",
    descr: 'Сценарии обработки входящих вызовов, поступивших на ваши внешние номера',
    props: build_prop('/sip/routing')
  },
  {
    title: "Группы",
    props: build_prop('/sip/pbx_group'),
    descr: 'Объединение сотрудников под общим номером для обработки входящих вызовов'
  },
  // {
  //   title: "Адресная книга",
  //   props: blank_options,
  // },
  {
    title: " ",
    props: blank_options,
  },
  {
    title: "Настройки",
    props: build_prop("/sip/settings")
  },
  // {
  //   title: "Журнал действий",
  //   props: blank_options,
  // },
];

if (process.env.VUE_APP_IS_TARIFF !== `false`) {
  SIP_MENU.push(
    {
      title: "Тариф",
      props: build_prop('/sip/tariff'),
      descr: 'Описание вашего тарифа, текущие начисления за оказанные услуги, детализация звонков и услуг, сервисы, доступные к подключению'
    }
  )
}

if (process.env.VUE_APP_IS_INTEGRATION !== `false`) {
  SIP_MENU.push(
    {
      title: "Интеграции",
      props: build_prop('/sip/integration', { disabled: INTEGRATION_DISABLED }),
      descr: 'Настройки параметров интеграции'
    }
  )
}

if (process.env.VUE_APP_ABOUT_PROGRAMM === `true`) {
  SIP_MENU.push(
    {
      title: "О программе",
      props: build_prop('/sip/pbx/about'),
    }
  )
}

const USER_MENU = [
  { title: 'Личные данные' },
  { title: 'Пароль' },
  { title: 'Безопасность' },
  { title: 'Уведомления' },
]


export { OSN_MENU, SIP_MENU, USER_MENU }