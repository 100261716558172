<template>
  <b-row>
    <b-col>
      <component v-bind:is="comp" :obj_type="type" />
    </b-col>
  </b-row>
</template>

<script>
// @ is an alias to /src
import { OBJ_COMPONENTS } from "../constants";
import DefObjectsListVue from "../components/objects/DefObjectsList.vue";
export default {
  name: "ObjList",
  props: {
    variant: {
      type: String,
      default: "default",
    },
    obj_type: {
      required: false,
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    type() {
      return this.$route.params.type || this.obj_type || "user";
    },
    descr() {
      return OBJ_COMPONENTS[this.type];
    },
    page_variant() {
      return this.descr.page_variant || this.variant;
    },
    comp() {
      const comps = this.descr.objs_comp || DefObjectsListVue;
      return comps;
    },
    params() {
      return this.$route.params;
    },
    title() {
      return this.descr.plural || undefined;
    },
  },
};
</script>
