import { merge } from 'lodash'
import Rete from 'rete'
import { startSocket } from '../../sockets'

const outputs = {
    default: null,
}

export default {
    name: 'SIP Входящий звонок',
    apiType: 'SIPTermination',
    objType: 'SIPTermination',
    outputs: outputs,
    mapInputs: (node) => {
        let empt = new Rete.Input('start', 'start', startSocket)
        node.addInput(empt)
    },
    load_data: (nodes, node, service) => {
        nodes["start"]["outputs"]["start"]["connections"].push({
            node: service.service_id,
            input: "start",
            data: {},
        });

        let default_inputs = service.outputs.filter(
            o => o.key == 'default' && o.service_id
        ).map(
            o => ({ input: 'call', node: o.service_id, data: {} })
        )
        return merge({}, node, {
            outputs: {
                default: {
                    connections: default_inputs
                }
            },
        })
    },
    pack_data: () => ({})
}