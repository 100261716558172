<template>
  <div class="side-menu" v-scrollable="{ height: 'auto' }">
    <b-nav
      v-for="({ menu, header, classes }, indx) in menu"
      v-bind:key="indx"
      :class="classes"
      vertical
    >
      <b-nav-text v-if="header">{{ header }}</b-nav-text>
      <menu-item
        v-for="(menu_item, index) in menu"
        v-bind:key="index"
        :value="menu_item"
        :indx="index"
      />
    </b-nav>
  </div>
</template>

<script>
import store from "../../store";
import WithBodyClasses from "../mixins/WithBodyClasses";

import { BNav, BNavText } from "bootstrap-vue";
import { USER_OBJ } from "../objects/User/description";
//import {get, set} from 'shvl'
import { cloneDeep } from "lodash-es";
import menuItem from "./menuItem";
import scrollable_jquery from "../../directives/scrollable_jquery";
import { bindEvent } from "../../functions";

export default {
  name: "Menu",
  mixins: [WithBodyClasses],
  directives: {
    scrollable: scrollable_jquery,
  },
  components: {
    BNav,
    BNavText,
    menuItem,
  },
  mounted() {
    //$('#side-menu').metisMenu()
    bindEvent(this, "toggleSideBar", this.toggle_sidebar, this.$eventBus);
  },
  props: {
    position: {
      type: [Object, String, Array],
      default: undefined,
    },
    fixed: {
      type: Boolean,
      default: true,
    },
    arrow_up: {
      type: String,
      default: "chevron-left",
    },
    arrow_down: {
      type: String,
      default: "chevron-down",
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    body_classes() {
      var ret = {};
      if (this.fixed) {
        ret["fixed-sidebar-v2"] = this.collapsed;
        ret["fixed-sidebar"] = !this.collapsed;
      }
      return ret;
    },
  },
  data() {
    return {
      user: store.state.user,
      user_desc: USER_OBJ,
      show_logout: false,
      full_logout: false,
      collapsed: false,
      show_user_info: false,
      current_menu: cloneDeep(this.menu),
    };
  },
  methods: {
    isOpen(item) {
      let ret = item.items && item.opened == true;
      return ret;
    },
    showLogout(full) {
      if (full == !undefined) {
        full = false;
      }
      this.show_logout = true;
      this.full_logout = full;
    },
    closeLogout() {
      this.show_logout = false;
      this.full_logout = false;
    },
    toggle_item(item) {
      let o = item.opened || false;
      item.opened = !o;
      this.$forceUpdate();
    },
    toggle_sidebar() {
      this.collapsed = !this.collapsed;
      this.toggle_body_classes(this.collapsed, ["mini-navbar"]);
      this.toggle_body_classes(this.collapsed);
    },
    isActive(item, level) {
      if (level !== undefined) {
        level = 1;
      }
      let ret = false;
      let pos = this.positions.first;
      if (level === 2) {
        pos = this.positions.second;
      } else if (level === 3) {
        pos = this.positions.third;
      }
      ret = item.position && pos && pos === item.position;
      return ret;
    },
  },
};
</script>

<style scoped>
.sidebar-collapse {
  overflow: hidden;
  width: auto;
  height: fit-content;
}
.side-menu {
  width: 15rem !important;
  height: auto;
  margin-top: 3rem;
  background-color: var(--light);
}
</style>