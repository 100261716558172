<script>
import ObjComp from "./mixins/ObjComp";
import { WithRelated } from "./mixins";
import WithApi from "./mixins/WithApi.vue";
import {
  OBJECT_MODES,
  COMPUTED_MIX,
  METHODS_MIX,
} from "./mixins/WithDescription";
import { bindEvent, type_val } from "../functions";
import { isEqual } from "lodash-es";
import Vue from "vue";

export default {
  name: "ObjForm",
  mixins: [WithRelated, ObjComp, WithApi],
  props: {
    value: {
      required: true,
      default: () => ({}),
    },
    eventBus: {
      required: false,
    },
    eventPrefix: {
      required: false,
      type: String,
      default: "",
    },
    description: {
      required: false,
    },
    mode: {
      required: false,
      default: "view",
      validator: (value) => OBJECT_MODES.indexOf(value) >= 0,
    },
    withoutLabels: {
      required: false,
      type: Boolean,
    },
    inline: {
      required: false,
      type: Boolean,
    },
    classes: {
      required: false,
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      errors: {},
      edited_obj: {},
      with_validators: {},
      validated: false,
    };
  },
  computed: {
    ...COMPUTED_MIX,
    desc() {
      return this.description || this.object._description;
    },
    has_changes() {
      return JSON.stringify(this.value) != JSON.stringify(this.edited_obj);
    },
    bus() {
      return this.eventBus || this;
    },
  },
  mounted() {
    this.eventBus;
    const bus = this.bus;
    if (this.eventBus) {
      bindEvent(
        this,
        `${this.eventPrefix}validate`,
        () => {
          this.validate();
        },
        bus
      );
      bindEvent(this, `${this.eventPrefix}save`, this.update, bus);
    }
    let obs = this.fields.reduce((r, f) => {
      const descr = this.field_description(f);
      const key = descr.target || descr.key || f;
      if (key !== "__break__") {
        r[key] = this.value[key] || " ";
      }
      return r;
    }, {});
    let d = Vue.observable(obs);
    this.edited_obj = d;
    this.$watch(() => JSON.stringify(this.object), this.$forceUpdate);
  },
  methods: {
    ...METHODS_MIX,
    validate() {
      // сброс ошибок
      this.errors = {};
      for (const field of this.fields) {
        this.validate_field(field);
      }
      this.validated = true;
      const is_valid = Object.values(this.errors).flat().length === 0;
      this.$forceUpdate();
      return is_valid;
    },
    update() {
      const valid = this.validate();
      this.bus.$emit("valid", valid);
    },
    validate_field(field) {
      this.description;
      const field_description = this.field_description(field);
      let f = this.field_key(field);
      let field_errors = [];
      // обработка обязательных
      if (
        field_description.required &&
        (this.object[f] == null ||
          this.object[f] == "" ||
          isEqual(this.object[f], []))
      ) {
        if (!field_description.multiple) {
          field_errors.push("Поле обязательно");
          this.with_validators[f] = true;
        }
      }
      if (field_description.validator) {
        let res = field_description.validator(this.object[f], this.object);
        if (res) {
          field_errors = field_errors.concat(res);
          this.with_validators[f] = true;
        }
      }
      if (this.$refs[`field[${f}]`]) {
        let ref = this.$refs[`field[${f}]`];
        if (ref.length && ref[0].validation) {
          let field_has_eroros = ref[0].validation();
          if (field_has_eroros) {
            field_errors.push("Есть ошибки");
            this.with_validators[f] = true;
          }
        }
      }

      if (field_errors.length) this.errors[f] = field_errors;
      return field_errors;
    },
    field_key(field) {
      this.description;
      const field_description = this.field_description(field);
      let f =
        type_val(field_description) == "String"
          ? field
          : field_description.target || field_description.key || field;

      return f;
    },
    get_errors(field) {
      return this.errors[this.field_key(field)] || [];
    },
    add_error(field, text) {
      if (this.errors[field] !== undefined) {
        this.errors[field].push(text);
      } else {
        this.errors[field] = [text];
      }
      this.with_validators[field] = true;
      this.$forceUpdate();
    },
    clear_errors(field) {
      if (this.errors[field] !== undefined) {
        this.errors[field].length = 0;
      }
    },
    has_errors(field) {
      const errors = this.get_errors(field);
      return this.with_validators[this.field_key(field)]
        ? errors.length == 0
        : null;
    },
  },
};
</script>

<style>
form.object-form-internal_line2.mode-view {
  max-width: 930px;
}
form.object-form-ivr.mode-view {
  max-width: 930px;
}
</style>