import {id_field_desc} from "@/functions";

export default {
    verbose: 'SIP Входящий звонок',
    plural: 'SIP Входящий звонок',
    obj_type: 'SIPTermination',
    obj_name: 'number',
    unique_for: ['customer', 'entity'],
    obj_template: (obj) => `${obj.number}`,

    storage: {
    },


    fields: {
        service_id: id_field_desc,
        // caption: { label: 'Название', isName: true },
        // description: 'Комментарий',
        number: {
            label: 'Номер телефона',
            editable: false,
            isKey: true,
            required: true,
            options: {
                type: 'number'
            }
        },
    },
}