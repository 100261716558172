<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
  >
    {{ get_data(node.data) }}
    <b-card-title class="title">
      <Socket
        v-for="output in inputs()"
        :key="output.key"
        v-socket:input="output"
        type="input"
        :class="['input-socket', output.key]"
        :socket="output.socket"
      />Перевод на менеджера
      <obj-modal
        class="settings"
        :buttonProps="{
          variant: 'outline',
          class: 'border-0',
        }"
        @click="modal_show = true"
        :obj_id="service.obj_id"
        obj_type="integration"
        :fields="['caption', 'description']"
        noDelete
      >
        <template #btnContent>
          <icon :icon="icons.gear" />
        </template>
      </obj-modal>
    </b-card-title>
    <short-text-span
      tag="h2"
      class="name"
      v-if="service"
      :text="service.caption || 'Неизвестно'"
    />
    <Socket
      v-for="output in outputs()"
      :key="output.key"
      v-socket:input="output"
      type="output"
      :class="['output-socket', output.key]"
      :socket="output.socket"
    />
  </b-card>
</template>
<script>
import { faCog, faPlus } from "@fortawesome/free-solid-svg-icons";
import ComponentMixinVue from "../ComponentMixin.vue";
import { as_icon } from "@/components/layot";
import config from "./config";
import ObjModal from "../../../../objects/ObjModal.vue";
export default {
  name: "IntegrationComponent",
  mixins: [ComponentMixinVue],
  components: { ObjModal },
  computed: {
    config: () => {
      return config;
    },
    icons: () => ({
      gear: as_icon(faCog),
      plus: as_icon(faPlus),
    }),
  },
};
</script>
<style lans='scss'>
#rete .card.integration-node.node {
  min-width: 360px;
}
</style>