/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import { difference, merge } from 'lodash';

import {router} from '@/router'
import { DEFAULT_MESSAGE_TIME, OBJ_COMPONENTS, SERVER } from '@/constants'
import { type_val, uuidv4 } from '@/functions.js';
import { eventBus } from '@/components'
import {oidcSettings} from '@/auth.js'
import OAuthStore from './oauth.js';
import CRM from './crm.js'
import routing from './routing.js'
import { STORAGES } from '../object.js'
import CONTRACT from './contract.js'

// debugger // eslint-disable-line no-debugger

Vue.use(Vuex);

const OBJ_STATUSES = {
  wait: 'wait',
  default: undefined
}

const unique = ['instance', 'customer', 'entity', 'contract', 'namespace']
const build_unique = unique.reduce((acc, inst) => {
  acc[`current_${inst}`] = (state) => state[`current_${inst}`] || ''
  return acc
}, {})

const def_manager = {
  shortName: '',
  longName: '',
  ident: ''
}

const store = new Vuex.Store({
  state: {
    instance_id: localStorage.getItem('instance_id') || '',
    user: JSON.parse(localStorage.getItem('user')) || {},
    current_customer: localStorage.getItem('customer') || '',
    current_entity: localStorage.getItem('entity') || '',
    current_instance: localStorage.getItem('instance') || '',
    current_contract: localStorage.getItem('contract') || '',
    current_namespace: localStorage.getItem('namespace') || '',
    show_develop_panel: false,
    messages: [],
    is_admin: false,
    position: {},
    manager: Object.assign({}, def_manager),
    finances: {}
  },
  mutations: {
    finances(state, data) {
      state.finances = data
    },
    update_manager(state, manager) {
      state.manager = manager
    },
    update_develop_panel(state, payload) {
      // обновление статуса дев панели
      state.show_develop_panel = payload
    },
    // работа с отдельном объекте
    // принимают {type: 'object base name', data: {}, obj_key}
    add_message(state, message) {
      if (type_val(message) == 'String') {
        message = { type: 'info', message: message }
      }
      if (!message.time) {
        message.time = DEFAULT_MESSAGE_TIME
      }
      message.key = uuidv4()
      state.messages.push(message)
    },
    clear_messages(state) {
      state.messages = []
    },
    set_current(state, payload) {
      let set_to = Object.assign({}, payload)
      if (set_to.customer && Object.keys(set_to).length == 1) {
        ['instance', 'contract', 'entity', 'namespace'].map(k => set_to[k] = '')
      } else if (set_to.customer && set_to.contract !== undefined) {
        set_to.contract = ''
      }
      const def_function = (data, key) => {
        Object.entries(OBJ_COMPONENTS).map(
            ([rel, desc]) => {
              if (key == desc.unique_for) {
                // state.objects_statuses[rel] = 0
                this.commit(`${rel}/outdate`)
                // state.objects[rel] = []
              }
            }
        )
        if (state[`current_${key}`] != data) {
          state[`current_${key}`] = data || ''
          localStorage.setItem(key, data || '')
          eventBus.$emit(`${key}_changed`)
        }
      }
      const keys = {
        entity: def_function,
        contract: def_function,
        customer: (data, key) => {
          if (data) {
            def_function(data, key)
          }
        },
        instance: def_function,
        namespace: (data, key) => {
          if (state[`current_${key}`] != data) {
            state[`current_${key}`] = data || ''
            localStorage.setItem(key, data || '')
            eventBus.$emit(`${key}_changed`)
          }
        }
      }
      // const { entity, customer, instance } = payload
      Object.entries(set_to).map(
          ([key, value]) => keys[key](value, key)
      )

      eventBus.$emit('refresh')
    },
    set_customer(state, id) {
      state.customer = id
      localStorage.setItem('customer', id)
      eventBus.$emit('refresh')
    },
    set_entity(state, id) {
      state.entity = id
      localStorage.setItem('entity', id)
      eventBus.$emit('refresh')
    },
  },
  getters: {
    is_admin: state => state.is_admin,
    position: state => state.position,
    finances: state => state.finances,
    messages: state => state.messages,
    ...build_unique,
    current_user: state => state.user,
    current: state => (...keys) => {
      const filter_keys = keys.filter(k => unique.indexOf(k) > -1)
      const real_keys = filter_keys.length ? filter_keys : ['customer']
      return real_keys.reduce(
          (r, key) => merge(r, { [`${key}_id`]: state[`current_${key}`] }),
          {}
      )
    },
    available_customers: () => [],
    available_instances: () => [],
    get_manager(state) {
      return state.manager ? Object.assign({
        img: `${SERVER}customer/manager/image/${state.manager.ident}`,
        name: `${state.manager.longName}`,
      }, state.manager) : {}
    }
  },
  actions: {
    add_message({ commit }, data) {
      commit('add_message', data)
    },
    messages({ commit, getters }) {
      let messages = getters.messages
      commit('clear_messages')
      return messages
    },
    resetObjects({ commit }, ignore = []) {
      const list_to_reset = difference(Object.keys(STORAGES), ignore);
      list_to_reset.map(
          name => commit(`${name}/reset`)
      )
    }
  },
  modules: {
    oidcStore: vuexOidcCreateStoreModule(
        oidcSettings,
        {
          namespaced: true,
          dispatchEventsOnWindow: true
        },
        {
          userLoaded: async (user) => {
            // console.log('OIDC user is loaded:', user)
            store.commit(`saveUserData`, user)
            store.commit(`saveUserId`, user.profile.sub)

            const resp = await store.dispatch('requestApi', {
              url: '/license/get',
              method: `GET`,
            }, {root: true})

              const resData = resp.data?.payload
              if (resData) {
                store.commit(`saveLicense`, resData)
                const { customer_id } = resData
                await store.commit("set_current", {customer: customer_id, contract: customer_id});
                await store.dispatch("entity/list");
                const entId = store.getters["entity/objects"][0].obj_id;
                const ent = store.getters["entity/object"](entId);

                if (ent) {
                  const inst = store.getters["instance/object"](ent.instance_id);
                  await store.commit("set_current", {
                    entity: entId,
                    instance: ent.instance_id,
                    namespace: inst.namespace,
                  });
                }

              } else {
                await router.push({ path: "/license" })
              }
          },
          userUnloaded: () => {
            console.log('OIDC user is unloaded')
          },
          accessTokenExpiring: () => {
            // console.log('Access token will expire')

            store.dispatch(`oidcStore/authenticateOidcSilent`).catch(() => store.dispatch(`oidcStore/removeOidcUser`))
          },
          accessTokenExpired: () => {
            // console.log('Access token did expire')
          },
          silentRenewError: () => {
            // console.log('OIDC user is unloaded')
            store.dispatch(`oidcStore/authenticateOidc`)
          },
          userSignedOut: () => {
            // console.log('OIDC user is signed out')
          },
          oidcError: (payload) => {
            // console.log('OIDC error', payload)
          },
          automaticSilentRenewError: (payload) => {
            // console.log('OIDC automaticSilentRenewError', payload)
            store.dispatch(`oidcStore/authenticateOidc`)
          }
        }
    ),
    OAuthStore,
    ...STORAGES,
    CRM, routing,
    CONTRACT
  }
})

export default store;
export { OBJ_STATUSES }