<template>
  <b-button variant="outline-primary" class="border-0" @click="download_file"
    >Скачать</b-button
  >
</template>

<script>
import { Cell } from "../../mixins";
import { pick } from "lodash-es";
export default {
  name: "LinkComp",
  mixins: [Cell],
  methods: {
    download_file() {
      let data_to_load = pick(this.item, ["customer_id", "contract_id"]);
      data_to_load["document_id"] = this.item.DocumentID;
      this.$store.dispatch("bill/fetch_document", {
        data: data_to_load,
        name: this.item.number,
      });
    },
  },
};
</script>
