<template>
  <fragment>
    <div class="form-control border-0" :style="options">
      {{ _obj.account }} <span v-if="status" id="dot" />
    </div>

    <div v-if="status" class="form-control border-0">
      <small>
        IP-адрес: {{status.remoteIP}}, expiry: {{status.regExpiry}}
      </small>
    </div>
  </fragment>
</template>

<script>
import {Fragment} from "vue-fragment";
import { InputMixin } from "../../mixins";
export default {
  name: "endpointStatus",

  mixins: [InputMixin],

  components: {Fragment},

  computed: {
    status() {
      return this._obj.status
    },

    statusColor() {
      let ret = "danger";
      if (this.status?.online) {
        ret = "success";
      }
      return ret;
    },

    options() {
      return {
        "--dot-color": `var(--${this.statusColor})`,
      };
    },
  },
};
</script>

<style>
#dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--dot-color);
  display: inline-block;
}
</style>