<template>
  <b-list-group-item
    :class="['group-item', `group-item-${level}`]"
    :data-item="group_obj.obj_id"
    :style="styles"
  >
    <div
      :class="{
        'item-block': true,
        active: current_group.value == group_obj.obj_id,
        'text-primary': current_group.current_group == group_obj.obj_id,
      }"
    >
      <icon :icon="icons.folder" class="text" />
      <span
        :class="{
          subitem: level > 1,
        }"
        @dblclick="get_members"
        @click="show_info"
      >
        {{ group_obj.obj_name }}
      </span>

      <b-dropdown
        size="sm"
        variant="outline-obitless"
        class="field_actions right margin-right-auto"
        dropright
        no-caret
        boundary="window"
        v-if="level !== 0"
        :popper-opts="{ positionFixed: false }"
      >
        <template v-slot:button-content>
          <icon :icon="icons.actions" />
        </template>
        <b-dropdown-item :to="{ path: `./group/${group_obj.obj_id}` }"
          >Редактировать</b-dropdown-item
        >
        <b-dropdown-item variant="danger" v-if="allow_to_delete"
          >Удалить</b-dropdown-item
        >
      </b-dropdown>

      <b-button
        @click="toggle()"
        v-if="has_children"
        variant="outline-primary "
        pill
      >
        <icon class="icon" :icon="arrow" />
      </b-button>
      <b-button disabled v-else variant="outline-primary" pill>
        <icon class="icon" :icon="arrow" />
      </b-button>
    </div>

    <group-item
      v-for="item in items"
      :key="item.obj_id"
      :group_obj="item"
      :level="level + 1"
      ref="children"
      :bus="bus"
      @member_list="resend_members"
    />
  </b-list-group-item>
</template>

<script>
import {
  faChevronDown,
  faChevronUp,
  faEllipsisV,
  faFolder,
  faFolderMinus,
  faFolderPlus,
} from "@fortawesome/free-solid-svg-icons";
import { bindEvent } from "../../../functions";
import { as_icon, Icon } from "../../layot";

export default {
  components: { Icon },
  name: "GroupItem",
  inject: ["current_group"],
  props: {
    group_obj: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
      required: false,
    },
    bus: {
      required: true,
    },
  },
  mounted() {
    bindEvent(this, "close", this.close, this);
    bindEvent(this, "open", () => (this.show_children = true), this);
  },
  data: () => ({
    show_children: false,
  }),
  methods: {
    close() {
      if (this.$refs.children) {
        this.$refs.children.map((n) => n.close());
      }
      this.show_children = false;
    },
    toggle() {
      this.$emit(this.show_children ? "close" : "open");
    },
    get_members() {
      this.$emit("member_list", this.group_obj.obj_id);
    },
    resend_members(obj_id) {
      this.$emit("member_list", obj_id);
    },
    show_info() {
      this.bus.$emit("show_info", this.group_obj.obj_id);
    },
  },
  computed: {
    icons() {
      let folder = faFolder;
      if (this.has_children) {
        folder = this.show_children ? faFolderMinus : faFolderPlus;
      }
      return {
        folder: as_icon(this.has_children ? folder : faFolder),
        actions: as_icon(faEllipsisV),
      };
    },
    styles() {
      return {
        "--left-padding": `${this.level * 20}px`,
      };
    },
    allow_to_delete() {
      return this.children.length + this.group_obj.members.length == 0;
    },
    arrow() {
      return as_icon(this.show_children ? faChevronUp : faChevronDown);
    },
    children() {
      return this.$store.getters["group/items"](this.group_obj.obj_id);
    },
    has_children() {
      return this.children.length > 0;
    },
    items() {
      return this.show_children ? this.children : [];
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.group-item {
  display: flex;
  flex-direction: column !important;
  border: none;
  padding: 0;
  .margin-right-auto {
    margin-left: auto;
  }
  .item-block {
    padding: 2px 5px;
    padding-left: calc(var(--left-padding) + 1em);
    display: flex !important;
    align-items: center;
    &.active {
      background-color: $obit-primary;
      border-radius: 5px;
      color: white;
      & > * {
        color: white;
      }
      &.text-primary {
        color: white;
      }
      .btn {
        color: white;
        &[disabled] {
          color: lighten($obit-primary, 20%);
        }
      }
    }
    .text {
      margin-right: 1em;
    }

    .subitem {
      padding: 2px 5px;
    }
    .btn {
      padding: 2px 10px;
      border: none;
    }
  }
  .group-item-1 {
    padding: 5px;
  }
  &.list-group-item {
    // padding: 17px 0;
    display: flex;
    flex-direction: row;
  }
}

.group-employees {
  width: 50%;
}
</style>