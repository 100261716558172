<script>
import ObjList from '../ObjList'
import { EventBus } from '../..'
export default {
    name: 'EmployeeList',
    mixins: [ObjList, EventBus],
    computed: {
        obj_type: () => 'employee',
    },
    mounted() {
        this.$eventBus.$on('customer_changed', () => this.fetch_data())
    }
}
</script>