<template>
  <icon :icon='icon' :class="classes" />
</template>

<script>
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import { as_icon } from '../layot'
import Icon from '../layot/FontAwesomeIcon.vue'
import CellVue from '../mixins/Cell.vue'
export default {
    name: 'BooleanCell',
    mixins: [CellVue],
    props: {
        yes_icon: {
            type: Object,
            default: () => faCheck,
        },
        no_icon: {
            type: Object,
            default: () => faTimes
        },
        yes_class: {
            type: [String, Array, Object],
            default: 'text-success'
        },
        no_class: {
            type: [String, Array, Object],
            default: 'text-danger'
        },
        reverse: {
            type: Boolean,
            default: false
        }
    },
    components: { Icon },
    computed: {
        val() {
            return this.reverse ^ this.value
        },
        icon() {
            const icon = this.val ? this.yes_icon : this.no_icon
            return as_icon(icon)
        },
        classes() {
            return this.val ? this.yes_class : this.no_class
        }
    }
}
</script>

<style>

</style>