import { type_val } from '../../functions.js'
import { BFormInput } from 'bootstrap-vue'
import { break_form_field } from '../objects/fields'
import keyCell from '../shared/keyCell'
import { pickBy } from 'lodash'
const OBJECT_MODES = ['view', 'edit', 'new']
const OBJECTS_MODES = ['list']
const MODES = [
  ...OBJECT_MODES, ...OBJECTS_MODES
]
const DATA_MIX = {
  '_avalModes': MODES
}
const COMPUTED_MIX = {
  object: {
    get() {
      const edited_obj = pickBy(this.edited_obj, val => val !== ' ')
      return { ...this.value, ...edited_obj };
    },
    set(to) {
      this.edited_obj = to;
    },
  },
  fields() {
    // поля в зависиости от режима
    const limit = this.limitFields || []
    const replace_fields = this.replace_fields || []
    const description = this.description || {}
    var fields
    if (replace_fields.length) {
      fields = replace_fields
    } else {
      fields = description[`${this.mode}_fields`] || description['new_fields'] || Object.keys(description.fields || {})
    }
    if (limit.length) {
      fields = fields.filter(f => limit.indexOf(f) !== -1)
    }
    if (OBJECTS_MODES.indexOf(this.mode) !== -1) {
      fields = fields.map((value) => {
        if (type_val(value) == 'String') {
          const desc = this.field_description(value)
          let line = {
            key: value,
            sortable: desc.sortable || undefined
          }
          let label = value
          if (type_val(desc) == 'String') {
            label = desc
          } else if (desc.label) {
            label = desc.label
          }
          if (desc.table_formatter) {
            line['formatter'] = desc.table_formatter
          }
          if (desc.list_options) {
            line = Object.assign({}, line, this.description.fields[value].list_options)
          }
          if (desc.isKey) {
            line['cell'] = keyCell
          }
          if (desc.cell) {
            line['cell'] = desc.cell
            line['cell_options'] = desc.cell_options
            line['field_desc'] = desc
          }
          line['label'] = label
          return line
        } else {
          return value
        }
      })
    }
    return fields
  },
  urls() {
    return this.description.urls || {}
  },
  related_objects() {
    this.description
    const fields_desc = this.fields.map(this.field_description).filter(
      row => row.related
    ).map(row => row.related)
    return fields_desc
  },
}
const METHODS_MIX = {
  _obj_get() {
    return { ...this.value, ...this.edited_obj };
  },
  field_description(field) { // описание поля
    this.description
    let desc = type_val(field) == 'Object' ? field : this.description.fields[field] || {}
    if (type_val(desc) == 'String') {
      desc = {
        label: desc
      }
    }
    // добавим вачисляемые настройки
    if (desc.func && type_val(desc.func) == 'Function') {
      desc = Object.assign({}, desc, desc.func(this.object, this.mode))
    }
    // вычисляемы настройки
    const opts = desc.options
    if (type_val(opts) == 'Function') {
      desc.options = opts(this.object, this.mode)
    }
    return desc
  },
  get_label(field) { // получаем назвавние поля
    const desc = this.field_description(field) || {}
    return desc.label || field
  },
  calc_options() { return {} }, //вычисляемы опции для поля
  field_options(field) { //вычисляем опции для поля
    const desc = this.field_description(field)
    const options = desc.options || {}
    let ret = { '_obj': this.object, field_desc: desc }
    let obj = Object.assign({}, this.object)
    if (type_val(options) == 'Object') {
      ret = Object.assign({}, ret, options)
    }
    // if (desc.related) {
    //     ret['options'] = build_options(desc.related)
    // }
    let calc_options = {}
    if (desc.calc_options) {
      calc_options = desc.calc_options(obj)
    }
    ret = Object.assign({}, ret, this.calc_options(field), calc_options)
    if (this.mode == 'view' || (this.mode == 'edit' && desc.editable === false)) {
      ret['disabled'] = true
    }
    // добавит форму
    ret['parent'] = this
    return ret
  },
  get_component(field) {
    const desc = this.field_description(field)
    var comp = undefined
    if (OBJECT_MODES.indexOf(this.mode) !== -1) {
      comp = desc[`${this.mode}_comp`] || desc.comp || BFormInput
    }
    return comp
  },
  get_target(field) {
    const res = this.field_description(field)
    const target = res.target || field
    return target
  },
  show_field(field) {
    this.object
    if (type_val(field) === 'String' && field !== break_form_field) {
      const res = this.field_description(field)
      var ret = res.show || true
      if (res.rule) {
        ret = res.rule(this.object)
      }
      return ret
    } else if (field === break_form_field) {
      return true
    } else {
      const rule = field.rule
      if (rule) {
        let r = rule(this._obj_get())
        return r
      }
      return true
    }
  }
}
export default {
  name: 'withDescription',
  methods: { ...METHODS_MIX },
  computed: { ...COMPUTED_MIX }
}
export {
  OBJECT_MODES, OBJECTS_MODES, MODES,
  DATA_MIX, COMPUTED_MIX, METHODS_MIX
}