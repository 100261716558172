<template>
  <select2
    :disabled="disabled"
    :options="options"
    multiple
    v-model="val"
    list_key="obj_id"
    list_text="obj_name"
    related="role"
    :required="false"
  />
</template>

<script>
import { Select2 } from "../layot";
import { InputMixin } from "../mixins";

export default {
  name: "rolesInput",
  mixins: [InputMixin],
  components: { Select2 },
  computed: {
    options() {
      return this.$store.getters["role/objects"];
    },
  },
};
</script>

<style>
</style>