<template>
    <span>{{get_full_login()}}</span>
</template>

<script>
import CellVue from '../../mixins/Cell.vue'
import store from '../../../store'
export default {
    name: 'LoginCell',
    mixins: [CellVue],
    mounted() {
        this.$eventBus.$on(['loaded:entity', 'loaded:instance'], () => this.$forceUpdate())
    },
    beforeDestroy() {
        this.$eventBus.$off(['loaded:entity', 'loaded:instance'])
    },
    methods: {
        get_full_login() {
            const entity = this.item._related('entity_id')
            let name  = this.item.login
            if (entity &&  entity._ready) {
                const instance = entity._related('instance_id')
                if (instance._ready) {
                    name = `${instance.namespace}+${this.item.login}`
                }
            }
            return name
        },
        loaded: () => store.getters.get_objects('entiiy').length >= 1
    }
}
</script>

<style>

</style>