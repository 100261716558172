<template>
  <div class="devices-input">
    <legend class="col-form-label devices-title">
      Устройства для приема звонков
    </legend>
    <!-- <h2 v-if="devices_count > 1" class="text">
      {{ devices_count }}
      <text-bool-input
        v-if="devices_count"
        v-model="show_info"
        v-bind="_show_info_settings"
      />
    </h2> -->
    <draggable
      :list="devices"
      :disabled="draggable_disabled"
      handle=".handle"
      @end="onEnd"
    >
      <div
        v-for="(device, indx) in devices"
        v-bind:key="indx"
        class="device-subform"
        v-show="device._state != 'deleted'"
      >
        <b-form-group label="Тип устройства" v-bind="row_props">
          <b-input-group class="">
            <b-form-checkbox
              :disabled="disabled"
              v-if="device.block_type_change"
              v-model="device.disabled"
              @input="device_modified(indx)"
            />
            <b-form-select
              :options="current_options(device.type)"
              :value="device.type"
              :disabled="disabled || device.block_type_change"
              @input="change_device_type($event, indx)"
            />
            <b-input-group
              size="sm"
              prepend="Время вызова"
              append="сек"
              class="group-order-timeout"
              v-bind="row_props"
            >
              <b-form-input
                :disabled="disabled"
                v-model.number="device.orderTimeout"
                @input="device_modified(indx)"
                class="order-timeout"
                @keypress="restrictChars($event)"
                onkeyup="if(parseInt(this.value)>60){this.value=60; return false; } else if(parseInt(this.value)<0){this.value=0; return false}"
              />
            </b-input-group>
            <!-- <span>sec</span> -->
            <!-- <timeout-input v-if="_obj.strategy == 'S'"  /> -->
            <b-button
              v-if="devices.length > 1 && !disabled"
              variant="outline"
              class="text-primary"
              :disabled="device.type == 'analog' && device._state != 'new'"
              @click="remove(indx)"
            >
              <obit-icon icon="trash" />
            </b-button>
            <b-button
              size="sm"
              variant="outline"
              class="border-0 handle"
              v-show="!draggable_disabled"
              v-b-tooltip.v-light="'Изменить порядок вызова устройств '"
              ><icon :icon="icons.sort"
            /></b-button>
          </b-input-group>
          <b-form-text v-if="device.type == 'sip'">
            <fragment>
              Потребуется приложение или SIP-телефон. Можете ознакомиться с
              <b-link>нашей подборкой</b-link>
              <br />
              <b-link
                @click="toggle_settings(indx)"
                v-if="!device.block_type_change"
              >
                {{ device.show ? "Скрыть" : "Показать" }} настройки устройства
              </b-link>
            </fragment>
          </b-form-text>
          <b-form-text
            v-if="device.type == 'analog' && !device.block_type_change"
          >
            Заявка на подключение физической линии будет отправлена на
            проработку
          </b-form-text>
        </b-form-group>

        <b-form-group
          label="Мобильный телефон"
          v-if="device.type == 'mobile'"
          v-bind="row_props"
        >
          <b-form-input
            v-model="device.target"
            :disabled="disabled"
            @input="device_modified(indx)"
          />
          <b-form-invalid-feedback
            :state="field_has_error(indx, 'target') ? false : true"
          >
            {{ field_has_error(indx, "target") }}
          </b-form-invalid-feedback>
          <b-form-text>
            <fragment>
              Прием звонка на внешний номер тарифицируется в соответствии с
              вашими тарифами на исходящую связь
            </fragment>
          </b-form-text>
        </b-form-group>

        <div v-if="device.type == 'sip'">
          <b-form-group
            :label="s.label"
            v-bind="row_props"
            v-for="(s, i) in filter_sip_settings(indx)"
            v-bind:key="i"
          >
            <comp
              :is="s.comp || 'b-form-input'"
              v-model="device[s.target]"
              :disabled="disabled"
              v-bind="s.props || {}"
              :_obj="device"
              :ref="`inp_${indx}_${s.target}`"
              @input="device_modified(indx)"
              autocomplete="new-password"
            />
            <b-link @click="toggle_settings(indx)" v-if="s.target == 'account'">
              {{ device.show ? "Скрыть" : "Показать" }} настройки устройства
            </b-link>
            <b-form-text v-if="s.target == 'password' && !disabled">
              <b-link @click="gen_pass(indx)">Сгенерировать новый</b-link>
            </b-form-text>
            <b-form-invalid-feedback
              :state="field_has_error(indx, s.target) ? false : true"
            >
              {{ field_has_error(indx, s.target) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <b-form-group
          v-if="device.type == 'analog'"
          label="Название линии"
          v-bind="row_props"
        >
          <b-form-input
            v-model="device.caption"
            :disabled="disabled"
            :placeholder="analog_name_placeholder"
          />
        </b-form-group>
      </div>
    </draggable>
    <b-button
      variant="outline-primary"
      v-if="!disabled && aval_devices.length"
      @click="add_new"
      pill
      class="uppercase p-obit-mitddle text-button"
    >
      Добавить устройство
    </b-button>
    <b-modal
      title="Оформление заявки"
      v-model="show_add_line"
      ok-title="Отправить"
    >
      <p>
        Чтобы подключить внутр. номер к цифровому или аналоговому телефону,
        требуется подключение физической линии.
      </p>
      <p>
        После отправки этой заявки мы перезвоним вам и расскажем подробнее о
        подключении.
      </p>
      <p>
        Пожалуйста, укажите адрес, по которому вы собираетесь подключить
        устройство.
      </p>
    </b-modal>
  </div>
</template>

<script>
import { InputMixin } from "../../mixins";
import ObitIcon from "../../obit/ObitIcon.vue";
import { Fragment } from "vue-fragment";
import TextBoolInput from "../../shared/textBoolInput.vue";
import ForwardInputVue from "./ForwardInput.vue";
import { drop_first, gpw } from "../../../functions";
import { cloneDeep } from "lodash-es";
import { as_icon, Icon, passwordInput } from "../../layot";
import EndpointStatusVue from "./EndpointStatus.vue";
import draggable from "vuedraggable";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import AllowIPInput from "./AllowIPInput";
import inputWithPrefixVue from "../../shared/inputWithPrefix.vue";
import LoginSIPInputVue from "./LoginSIPInput.vue";
import { DEFAULT_LINE_PROPS } from "../../../constants";
// import TimeoutInput from "./TimeoutInput.vue";

export default {
  name: "devicesInput",
  mixins: [InputMixin],
  components: {
    ObitIcon,
    TextBoolInput,
    Fragment,
    draggable,
    Icon,
  },
  mounted() {
    if (this.parent.mode == "new") {
      this.show_info = true;
    }
    this.$watch(
      () => this._obj.call_perms,
      (to) => {
        let d = cloneDeep(this.devices);
        for (const i in d) {
          d[i].callLevel = to;
        }
        this.devices = d;
      }
    );
    this.$watch(
      () => this._obj.orderTimeout,
      (to) => {
        let d = cloneDeep(this.devices);
        for (const i in d) {
          if (d[i]._state == "existed") {
            d[i]._state = "edited";
          }
          if (d[i]._state != "deleted") {
            d[i].orderTimeout = Number(to);
          }
        }
        this.devices = d;
      }
    );
  },
  data() {
    return {
      show_info: true,
      show_add_line: false,
      errors: {},
    };
  },
  created() {
    this.devices.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  },
  computed: {
    row_props: () => DEFAULT_LINE_PROPS,
    icons: () => ({
      sort: as_icon(faSort),
    }),
    draggable_disabled() {
      let ret = this.disabled ? true : false;
      if (ret === false) {
        if (this.devices_count <= 1) {
          ret = true;
        } else {
          if (this._obj.strategy == "P") {
            ret = true;
          }
        }
      }
      return ret;
    },
    _show_info_settings: () => ({
      false_text: "Показать настройки",
      true_text: "Скрыть настройки",
    }),
    dev_options(d) {
      let ret = [
        { text: "Мобильный телефон", value: "mobile" },
        { text: "Софтфон / SIP-телефон", value: "sip", max_count: 2 },
        // { text: "Цифровой / аналоговый телефон", value: "analog" },
      ];
      for (let device of d.devices) {
        if (device.type == "analog") {
          ret.push({ text: "Цифровой / аналоговый телефон", value: "analog" });
        }
      }
      return ret;
    },
    aval_devices() {
      // соберем список
      let ret = this.dev_options
        .map((r) => Array(r.max_count || 1).fill(r.value))
        .flat();
      this.devices.map((d) => (ret = drop_first(ret, d.type)));
      return ret;
    },
    devices: {
      get() {
        const def_val =
          this.parent.mode == "new"
            ? [
                {
                  type: "mobile",
                  target: "",
                  forward: 0,
                  show: true,
                  _state: "new",
                  callLevel: this._obj.call_perms,
                  orderTimeout: this._obj?.orderTimeout || 20,
                },
              ]
            : [];
        const devices = this.value || def_val;
        let ret = [];
        if (this.devices_count == 1) {
          ret = devices;
        } else if (this.show_info) {
          ret = devices;
        }
        return ret;
      },
      set(to) {
        this.parent.update_obj("devices_count", to.length);
        this.$emit("input", to || []);
      },
    },
    devices_count() {
      return (this.value || []).filter((d) => d._state != "deleted").length;
    },
    analog_name_placeholder() {
      return `Физическая линия ${this._obj.number || "XXX"}`;
    },
    sip_settings() {
      const fields_variations = {
        edit: [
          {
            label: "SIP-аккаунт",
            target: "account",
            comp: EndpointStatusVue,
            always: true,
            modes: ["view", "edit"],
          },
          {
            label: "SIP-логин",
            target: "login",
            comp: this.disabled ? LoginSIPInputVue : inputWithPrefixVue,
            //comp: LoginSIPInput,
            props: {
              pre_text: `${this.$store.getters.current_namespace}+`,
            },
            modes: "all",
          },
          {
            label: "SIP-пароль",
            target: "password",
            modes: "all",
            comp: passwordInput,
          },
          {
            label: "Переадресация",
            target: "forward",
            props: { parent: this, raw: true },
            modes: "all",
            comp: ForwardInputVue,
          },
          {
            label: "IP адрес подключения",
            target: "allowIP",
            props: { parent: this, raw: true },
            default: ["0.0.0.0/0"],
            comp: AllowIPInput,
            modes: "all",
          },
        ],
      };
      return fields_variations["edit"];
    },
  },
  methods: {
    restrictChars(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onEnd() {
      let new_value = cloneDeep(this.devices);
      for (var i in new_value) {
        if (new_value[i]._state != "deleted") {
          new_value[i].order = Number(i) + 1;
          new_value[i]._state = "edited";
        }
      }
      this.devices = new_value;
    },
    field_has_error(index, field) {
      let ret = null;
      if (this.errors[index] && this.errors[index][field]) {
        ret = this.errors[index][field];
      }
      return ret;
    },
    validation() {
      let has_errors = false;
      // если есть внешний номер
      this.errors = {};
      for (const indx in this.devices) {
        let device = this.devices[indx];
        let device_errors = this.errors[indx] || {};
        if (device.type == "mobile") {
          if (!device.target) {
            has_errors = true;
            device_errors["target"] = "Не заполнен номер";
          }
        }
        if (device.type == "sip") {
          if (!device.login) {
            has_errors = true;
            device_errors["login"] = "Обязательный параметр";
          }
          if (!device.password) {
            has_errors = true;
            device_errors["password"] = "Обязательный параметр";
          }
          if (!device.allowIP) {
            has_errors = true;
            device_errors["allowIP"] = "Обязательный параметр";
          } else {
            const rg =
              /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
            for (var ip of device.allowIP) {
              if (!rg.test(ip.split("/")[0])) {
                has_errors = true;
                device_errors["allowIP"] = "Невалидный IP";
                break;
              }
            }
          }
        }
        if (Object.keys(device_errors).length) {
          this.errors[indx] = device_errors;
        }
      }
      return has_errors;
    },
    current_options(current_opt) {
      const filter_options = (opt) => {
        let ret = false;
        ret =
          opt.value == current_opt ||
          this.aval_devices.indexOf(opt.value) != -1;
        return ret;
      };
      return this.dev_options.filter(filter_options);
    },
    change_device_type(event, indx) {
      let dev = cloneDeep(this.devices);
      dev[indx].type = event;
      if (dev[indx].type == "sip" && dev[indx]._state == "new") {
        if (!dev[indx].password) {
          dev[indx].password = gpw();
        }
        dev[indx].login = this.generate_new_sip_login();
        dev[indx].allowIP = ["0.0.0.0/0"];
      }
      this.devices = dev;
    },
    generate_new_sip_login() {
      const pattern = new RegExp(`^user${this._obj.number}s\\d+$`);
      const n = this.devices
        .filter((d) => d.type == "sip" && pattern.test(d.login || ""))
        .reduce((r, d) => {
          let spl = d.login.split("s");
          if (spl.length) {
            let part = parseInt(spl[spl.length - 1]);
            r = Math.max(r, part + 1);
          }
          return r;
        }, 0);
      return `user${this._obj.number}s${n}`;
    },
    filter_sip_settings(indx) {
      const mode = this.parent.mode;
      const def_modes = ["view", "edit"];
      const device = this.devices[indx];
      const rule = (row) => {
        const row_modes = row.modes || def_modes;
        if (row.target == "account") {
          return device.block_type_change || false;
        }
        let ret = row_modes == "all" || row_modes.indexOf(mode) != -1;
        ret &= row.always || device.show;
        return ret;
      };

      return this.sip_settings.filter(rule);
    },

    toggle_settings(indx) {
      let dev = cloneDeep(this.devices);
      let d = !!dev[indx]?.show;
      dev[indx]["show"] = !d;
      this.devices = dev;

      this.parent.$forceUpdate();
    },

    add_new() {
      const type = this.aval_devices[0];
      let add_data = {
        transfer: 0,
      };
      if (type == "sip") {
        add_data["namespace"] = this.$store.getters.current_namespace;
        add_data["login"] = this.generate_new_sip_login();
        add_data["allowIP"] = ["0.0.0.0/0"];
        add_data["password"] = gpw();
        add_data["forward"] = 0;
      }
      this.devices = this.devices.concat([
        {
          type: type,
          number: "",
          show: true,
          _state: "new",
          callLevel: this._obj.call_perms,
          orderTimeout: this._obj?.orderTimeout || 20,
          ...add_data,
        },
      ]);
    },
    remove(indx) {
      let devices = cloneDeep(this.devices);
      const device = devices[indx];
      if (device._state != "new") {
        devices[indx]._state = "deleted";
      } else {
        devices = devices.filter((d, i) => i != indx);
      }
      this.devices = devices;
    },
    device_modified(indx) {
      let devices = this.devices;
      if (devices[indx]._state != "new") {
        devices[indx]._state = "edited";
      }
      this.devices = devices;
    },
    gen_pass(indx) {
      let devices = cloneDeep(this.devices);
      const new_pass = gpw();
      devices[indx]._state = "edited";
      devices[indx].password = new_pass;
      //devices[indx].password = gpw();
      this.devices = devices;
      //this.device_modified(indx);
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.devices-input {
  h2.text {
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 0;
  }
  .device-subform:not(:last-child) {
    border-bottom: 1px solid $obit-lightgray;
    margin-bottom: 1em;
  }
  label.devices-title {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .group-order-timeout {
    .order-timeout {
      font-size: 1rem;
      height: auto;
      border-right: 0px;
      padding-right: 0px;
    }
    .order-timeout:focus + .input-group-append .input-group-text {
      border-color: var(--primary) !important;
    }
    .input-group-append {
      .input-group-text {
        padding: 4px 7px 4px 0px;
        background-color: transparent;
      }
    }
  }
}
.mode-edit,
.mode-new {
  .devices-input {
    .group-order-timeout {
      width: 15%;
      @media (max-width: 650px) {
        width: 100%;
      }
      .order-timeout {
        max-width: 50%;
        @media (max-width: 650px) {
          max-width: 15%;
        }
      }
      .input-group-prepend {
        display: none;
      }
      .input-group-append {
        max-width: 50%;
      }
    }
  }
}
.mode-view {
  .devices-input .group-order-timeout {
    // width: 30%;
    // min-width: 160px;
    width: 50%;
    min-width: 160px;
    @media (max-width: 990px) {
      width: 100%;
    }
    .order-timeout {
      max-width: 40px;
      width: 30px;
    }
    .input-group-append,
    .input-group-prepend {
      .input-group-text {
        background-color: transparent;
        font-size: 1rem;
        height: auto;
        border: 0px;
        padding: 0px;
        padding-right: 7px;
      }
    }
  }
}
</style>/