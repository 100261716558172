import { id_field_desc, restore_from_options } from "@/functions.js"
import { buildOptionsField, buildRelatedField } from "../fields"

const actions_list = [
    { value: 'employee', text: 'Перевод на сотрудника', related: true, destination_label: 'Имя сотрудника', timeout: true },
    { value: 'group', text: 'Перевод на группу', related: true, destination_label: 'Группа', timeout: true },
    { value: 'media', text: 'Проиграть медиафайл', related: true, destination_label: 'Медиафайл', },
    { value: 'ext_line', text: 'Перевод на внешний номер', destination_label: 'Внешний номер', timeout: true },
    { value: 'voice_mail', text: 'Оставить голосовое сообщение', destination_label: 'E-mail для получения' },
    { value: 'ivr', text: 'Другое голосовое меню', destination_label: 'Голосовое меню', related: true },
    { value: 'repeat', text: 'Повторить меню' },
    { value: '_call', text: 'Донабор внутреннего пользователя' },
    { value: 'disconect', text: 'Разъеденить' }
]

const IVR_ACTION_DESC = {
    verbose: 'Действие',
    plural: 'Действия',
    fields: {
        action_id: id_field_desc,
        action: buildOptionsField('Действие', actions_list),
        destination: {
            label: 'Назначение',
            func(obj) {
                const option = restore_from_options(actions_list, obj.action, false)
                if (option.related) {
                    return buildRelatedField(option.destination_label, option.value, {
                        rule() { return true }
                    })
                }
                return {
                    label: option.destination_label || 'Назначение',
                    rule() { return option.destination_label !== undefined }
                }
            }
        },
        timeout: {
            label: 'Время дозвона',
            rule(obj) {
                return actions_list.filter(row => row.timeout && obj.action == row.value).length > 0
            },
        }
    },
    edit_fields: ['action', 'destination', 'timeout'],
    view_fields: ['action', 'destination', 'timeout']
}
export { IVR_ACTION_DESC }