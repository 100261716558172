<template>
  <b-navbar v-bind:class="['footer', { fixed: fixed }]">
    <div class="landing-footer-bar">
      <b-navbar-nav
        class="item"
        v-for="(link, indx) in links"
        v-bind:key="indx"
      >
        <b-link
          v-bind="link.props || {}"
          :size="size"
          variant="link"
          class="item-link"
          ><nobr>{{ link.title }}</nobr></b-link
        >
      </b-navbar-nav>
    </div>
    <b-navbar-brand v-if="!isOnPrem" class="brand text-muted"> © {{ year }} </b-navbar-brand>
  </b-navbar>
</template>

<script>
import { BNavbar, BLink, BNavbarNav, BNavbarBrand } from "bootstrap-vue";
BNavbarBrand;
export default {
  name: "Footer",
  components: { BNavbar, BLink, BNavbarNav, BNavbarBrand },
  props: {
    year: {
      default: 2020,
      type: Number,
    },
    fixed: {
      default: true,
      type: Boolean,
    },
  },
  data: () => {
    return {
      size: "sm",
      is_fixed: true,
    };
  },
  computed: {
    isOnPrem() {
      return process.env.VUE_APP_FOOTER_LINKS === `false`
    },

    links() {
      if (this.isOnPrem) {
        const supportLink = `https://1c-service.com`
        return [
          {
            title: "Помощь",
            props: { href: supportLink, target: "_blank" },
          },

          {
            title: "Напишите нам",
            props: { href: supportLink, target: "_blank" }
          },
        ]
      } else {
        const support_link = this.$route.path.startsWith("/sip")
            ? "https://vats.obit.ru/faq/help/"
            : "https://www.obit.ru/support/";

        return [
          {
            title: "Реквизиты",
            props: { href: "https://www.obit.ru/pdf/Requisites.pdf" },
          },
          {
            title: "Помощь",
            props: { href: support_link, target: "_blank" },
          },
          {
            title: "Новости",
            props: {
              to: "/views/news",
              active: this.$route.path.startsWith("/views/news"),
            },
          },
          { title: "Акции" },
          { title: "Напишите нам" },
          {
            title: "Политика конфиденциальности",
            props: {
              href: "https://www.obit.ru/pdf/Privacy_policy.pdf",
              target: "_blank",
            },
          },
          {
            title: "Условия использования",
            props: {
              href: "https://www.obit.ru/pdf/User_agreement.pdf",
              target: "_blank",
            },
          },
        ];
      }

    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";

.footer.navbar-expand {
  // flex-flow: wrap;
  // justify-content: start;
  justify-content: center;
  align-items: baseline;
  align-content: center;
  display: flex;
  .landing-footer-bar {
    display: flex;
    flex-flow: wrap;
    align-items: center;
    max-width: 1380px;
    padding: 0px 0 0 15px;
    .text-muted {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      margin: 10px 0 0 0;
    }
    @media (max-width: 801px) {
      // padding-right: 75px;
    }
    @media (max-width: 640px) {
      flex-flow: wrap;
      padding: 0;
    }
    a:hover {
      color: #fd6300; /* Цвет ссылки при наведении на нее курсора мыши */
    }
  }
  .item,
  .brand {
    margin-right: 30px;
    margin-top: 10px;

    a {
      font-size: 14px;
      line-height: 15.8px;
      font-weight: 400;
      color: $obit-footerlink;
      text-decoration: underline;
    }
    .active {
      color: #fd6300;
    }

    @media (max-width: 1280px) {
      margin-right: 15px;
    }
    @media (max-width: 801px) {
      margin-right: 30px;
    }
    @media (max-width: 500px) {
      margin-right: 20px;
    }
    @media (max-width: 404px) {
      margin-right: 13px;
    }
  }

  @media screen and (min-width: 1230px) {
    .navbar-brand.brand {
      // margin-left: auto;
    }
  }

  @media (max-width: 481px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.center {
  text-align: center;
}
.right {
  text-align: right;
}
strong {
  line-height: 1.8rem;
}

@media (max-width: 640px) {
  .footer.navbar-expand {
    // .item {
    //   padding-bottom: 20px;
    // }
    .landing-footer-bar {
      .text-muted {
        width: auto;
        justify-content: end;
      }
    }
  }
}

@media (max-width: 480px) {
  .footer.navbar-expand {
    .landing-footer-bar {
      padding-bottom: 5px;
      width: auto;
      .text-muted {
      }
    }
  }
}
</style>
