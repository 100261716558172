import Rete from "rete";
import * as Socket from "../../sockets";
import PbxGroupComp from './Component'
import Control from './Control'
import config from './config'

export { config as PBXGroup_CONFIG }

export class CompControl extends Rete.Control {
  constructor(emitter, key, readonly) {
    super(key);
    this.component = Control;
    this.props = { emitter, ikey: key, readonly };
  }

  setValue(val) {
    this.vueContext.value = val;
  }
}

export class PbxGroup extends Rete.Component {
  constructor() {
    super("pbx_group");
    this.data.component = PbxGroupComp
    this.data._config = config
  }

  builder(node) {
    const cnf = this.data._config
    const defBuildOutputs = (node) => {
      for (const [out, out_name] of Object.entries(cnf.outputs || {})) {
        let output = new Rete.Output(out, out_name || out, Socket.callSocket, false)
        node.addOutput(output)
      }
    }
    const defBuildInputs = node => {
      var in1 = new Rete.Input("call", "Call", Socket.callSocket, true);
      node.addInput(in1)
      node.addInput(new Rete.Input('start', 'start', Socket.startSocket))
    }
    const buildInputs = cnf.mapInputs || defBuildInputs
    const buildOutputs = cnf.mapOutpus || defBuildOutputs


    buildInputs(node)
    buildOutputs(node)

    return node
  }

  async worker(node) {
    // const node_id = node.id
    let current_node = this.editor.nodes.find(n => n.id == node.id)
    let has_root = false
    for (const conn of node.inputs.call.connections) {
      const n = this.editor.nodes.find(_n => _n.id == conn.node)
      if (!n) continue
      if (!has_root) {
        if (n.name == 'city_number') {
          has_root = true
        } else if (n.name != 'start' && n.data.has_root) {
          has_root = true
        }
      } else continue
    }
    current_node.data.has_root = has_root
    node.data.has_root = has_root
    current_node.update()
    current_node.vueContext.$forceUpdate()
  }
}
