<template>
    <div class="ibox ">
        <div class="ibox-title" v-if='header'>
            <h5>{{ header }}</h5>
            <div class="ibox-tools">
                <slot name='tools'></slot>
                <a class="collapse-link" @click="toggle_collapse()" v-if='collapse'>
                    <icon :icon="collapse_shevron" />
                </a>
                <a class="close-link" v-if='close'>
                    <icon icon="times" />
                </a>
            </div>
        </div>
        <div class="ibox-content" v-show="collapsed_">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { Icon, library } from './FontAwesomeIcon'
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons'
library.add(faChevronUp, faChevronDown)
//import { BOverlay } from 'bootstrap-vue'
export default {
    name: 'IBox',
    components: {Icon},
    props: {
        header: {
            default: undefined,
            type: String
        },
        close: {
            type: Boolean
        },
        collapse: {
            type: [Boolean, Number]
        },
        collapsed: {
            type: Boolean
        }
    },
    data() {return {
        collapsed_: ! this.collapsed
    }},
    computed: {
        collapse_shevron() {
            let icon = 'chevron-'
            icon += this.collapsed_ ? 'up' : 'down'
            return icon
        }
    },
    methods: {
        toggle_collapse() {
            this.collapsed_ = ! this.collapsed_
        }
    }
}
</script>
