<template>
  <page :title="title" :classes="page_classes" v-if="isAuth">
    <template v-slot:topbar>
      <obit-top
          :variant="page_variant"
          :logo_text="logo_text"
      >
      </obit-top>
    </template>
    <template v-slot:side-menu>
      <side-menu :variant="page_variant" :menu="menu" />
    </template>
    <router-view v-if="show_data" />
    <slot v-if="show_data" />
    <portal-target name="paginator" slim />
  </page>
</template>

<script>
// @ is an alias to /src
import { Page } from "../components/layot";
import { TopBar, SideMenu } from "../components/obit";
import { PortalTarget } from "portal-vue";

export default {
  name: "ObitPage",
  components: {
    Page,
    "obit-top": TopBar,
    SideMenu,
    PortalTarget,
  },
  props: {
    variant: {
      type: String,
      default: "default",
    },
    menu: {
      type: Array,
      required: false,
      default: () => [],
    },
    logo_text: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    isAuth() {
      return this.$store.getters['oidcStore/oidcIsAuthenticated']
    },
    page_classes() {
      return this.$route.meta.page_classes || [];
    },
    page_variant() {
      return this.$route.meta.page_variant || this.variant;
    },
    title() {
      return this.$route.title;
    },
    show_data() {
      let ret = !this.loading;
      if (this.variant == "sip") {
        const keys = ["instance", "entity", "namespace"];
        const show = keys
            .map((k) => this.$store.getters[`current_${k}`])
            .every((k) => k);
        ret = show;
      }
      return ret;
    },
  },
  provide() {
    let page_variant = {};
    Object.defineProperty(page_variant, "type", {
      enumerable: true,
      get: () => this.page_variant,
    });
    return {
      link: this.build_relative_link,
      page_context: page_variant,
    };
  },

  methods: {
    build_relative_link(args, end = null) {
      let path = this.$route.path.split("/");
      if (end) {
        path = path.slice(0, end);
      }
      return [...path, ...args].join("/");
    },
  },
};
</script>
