<template>
  <base-list
    :objects="objects"
    :description="description"
    :replace_fields="fields"
    :use_paginator="use_paginator"
    v-bind="$attrs"
    v-if="!groups"
    ref="list"
  >
    <!--  -->
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
      ><slot :name="name" v-bind="slotData" />
    </template>
  </base-list>
  <div v-else>
    <div v-for="([hdr, objs], indx) in groups(objects)" v-bind:key="indx">
      <h2>{{ hdr }}</h2>
      <base-list
        :objects="objs"
        :description="description"
        :replace_fields="fields"
        :header="hdr"
      >
        <!--  -->
        <template
          v-for="(_, name) in $scopedSlots"
          :slot="name"
          slot-scope="slotData"
          ><slot :name="name" v-bind="slotData" />
        </template>
      </base-list>
    </div>
  </div>
</template>
<script>
import BaseList from "../BaseList";

export default {
  name: "ObjectsList",
  components: { BaseList },
  props: {
    objects: {
      type: Array,
      required: true,
    },
    description: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: false,
      default: undefined,
    },
    group_function: {
      // функция для группировки должна возврящать [
      //     [text, [objects]],
      // ]
      type: Function,
      required: false,
      default: undefined,
    },
    use_paginator: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  computed: {
    groups() {
      return this.group_function || this.description.list_group_func;
    },
  },
  methods: {
    reset_page() {
      this.$refs["list"].reset_page();
    },
  },
};
</script>