<template>
  <div class="page-404">
    <div class="center middle">
      <h1>Нет такой страницы</h1>
      <b-link to="/">В начало</b-link>
      <b-link @click="back">Назад</b-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "404",
  methods: {
    back() {
      window.history.back();
    },
  },
};
</script>

<style  lang='scss'>
.page-404 {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  .middle {
    margin: auto;
    display: flex;
    flex-direction: column;
    h1,
    a {
      margin-top: 1em;
      font-size: 2em;
    }
  }
}
</style>