<script>
import ObjTable from "../ObjTable";
import { as_icon, Icon } from "../../layot";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
// import { get_related } from '../../../functions'

export default {
  name: "InternalLineTable",
  mixins: [ObjTable],
  components: { Icon },
  data() {
    return {
      loaded: false,
      icons: {
        show: as_icon(faEye),
        hide: as_icon(faEyeSlash),
      },
    };
  },
  methods: {
    get_related_instance(obj) {
      const entity = obj._related("entity_id");
      const instance = entity._related("instance_id");
      return instance;
    },
  },
  mounted() {
    this.$eventBus.$on("loaded:entity", () => (this.loaded = true));
  },
  beforeDestroy() {
    this.$eventBus.$off("loaded:entity");
  },
};
</script>
