<template>
  <div :class="['objects-list', obj_type]">
    <b-row class="header" v-if="show_header">
      <b-col>
        <span class="title">{{ header }}</span>
        <b-button
          v-if="!descr.no_create_button"
          variant="outline-obitless"
          class="add-item"
          @click="show_create_modal = true"
        >
          <obit-icon icon="plus" />
          {{ descr.create_button_text || `Создать ${descr.verbose}` }}
        </b-button>
        <component
          v-else-if="descr.create_button"
          :is="descr.create_button"
          v-bind="descr.create_button_props"
          variant="outline-obitless"
          class="add-item"
          @click="show_create"
        />
      </b-col>
    </b-row>
    <portal to="create_button" v-else>
      <b-button
        v-if="!descr.no_create_button"
        variant="outline-obitless"
        class="add-item"
        @click="show_create"
      >
        <obit-icon icon="plus" />
        {{ descr.create_button_text || `Создать ${descr.verbose}` }}
      </b-button>
      <component
        v-else-if="descr.create_button"
        :is="descr.create_button"
        v-bind="descr.create_button_props"
        variant="outline-obitless"
        class="add-item"
        @click="show_create"
      />
    </portal>
    <b-row class="items">
      <b-col>
        <obj-list :obj_type="obj_type" />
      </b-col>
    </b-row>
    <b-modal
      :title="create_title"
      v-model="show_create_modal"
      v-bind="descr.create_modal_props || {}"
      @ok.prevent="save_method"
      no-close-on-backdrop
    >
      <slot
        name="create_modal"
        v-bind="{
          obj: new_object,
          description: descr,
          obj_type: obj_type,
          eventBus: bus,
          value: new_object,
        }"
      >
        <obj-form
          v-model="new_object"
          :description="descr"
          mode="new"
          :eventBus="bus"
          :line_props="{ 'label-cols': 4 }"
          :obj_type="obj_type"
          ref="new_form"
        />
      </slot>
      <template v-slot:modal-footer="m">
        <b-button class="def-save" variant="obit" @click="m.ok()"
          >Сохранить</b-button
        >
        <b-button variant="outline-obit" @click="m.cancel()">Отмена</b-button>
      </template>
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData" />
      </template>
    </b-modal>
  </div>
</template>

<script>
import { IBox } from "../layot";
import { OBJ_COMPONENTS } from "../../constants";
import ObjList from "./ObjList";
import ObitIcon from "../obit/ObitIcon";
import ObjForm from "../ObjForm";
import { bindEvent } from "../../functions";
import { Portal } from "portal-vue";
import { Fragment } from "vue-fragment";

export default {
  name: "DefObjectsList",
  props: {
    obj_type: {
      required: true,
      type: String,
    },
    show_header: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  components: {
    ObitIcon,
    ObjForm,
    "obj-list": ObjList,
    Portal,
    Fragment,
  },
  mounted() {
    bindEvent(
      this,
      `added:${this.obj_type}`,
      () => (this.show_create_modal = false)
    );
    bindEvent(
      this,
      "valid",
      (is_valid) => {
        if (is_valid) {
          this.show_create_modal = false;
        }
      },
      this
    );
    bindEvent(this, `remove:${this.obj_type}`, (id) => {
      this.$store.dispatch(`${this.obj_type}/delete`, id);
    });

    this.$watch("obj_type", this.$forceUpdate);
  },
  inject: ["is_vertical"],
  provide() {
    return { objsComp: this };
  },
  data() {
    return {
      show_create_modal: false,
      new_object: {},
    };
  },
  computed: {
    bus() {
      return this;
    },
    arounded_comp: () => IBox,

    descr() {
      return OBJ_COMPONENTS[this.obj_type] || {};
    },
    header() {
      return this.descr.plural || `Список ${this.obj_type}`;
    },
    create_title() {
      return this.descr.create_title || `Создать ${this.descr.verbose}`;
    },
    arounded_bind() {
      const bind = {
        header: this.header,
      };
      return bind;
    },
  },
  methods: {
    show_create() {
      this.show_create_modal = true;
    },
    save_method() {
      const def_save = () => this.$emit("save");
      const method = this.descr.save_method || def_save;
      method(this);
    },
  },
};
</script>

<style lang='scss'>
.def-save {
  order: 1;
}
@media (max-width: 800px) {
  .internal_line2,
  .city_number {
    .header {
      .title,
      .add-item {
        font-size: 14px !important;
      }
    }
  }
}
</style>