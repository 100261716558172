import {OBJ_COMPONENTS} from './constants'
import {obj_template, restore_from_options} from './functions'
import store from './store'

const template = function (strings, ...keys) {
    return (function (obj) {
        if (!obj) return ''
        var result = [strings[0]];
        const desc = obj._description ? obj._description : OBJ_COMPONENTS[obj.obj_type]
        keys.forEach(function (key, i) {
            var value
            if (desc.fields[key].related) {
                let sub_obj = store.getters.get_object(desc.fields[key].related, obj[key])
                value = obj_template(sub_obj)
            } else if (desc.fields[key] && desc.fields[key].options && desc.fields[key].options.options) {
                value = restore_from_options(desc.fields[key].options.options, obj[key])
            } else {
                value = obj[key]
            }
            result.push(value, strings[i + 1]);
        });
        return result.join('');
    });
}
export default template