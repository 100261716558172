<template>
  <obj-form
    v-model="object"
    :description="description"
    :mode="mode"
    :eventBus="bus"
    :obj_id="obj_id"
    :obj_type="obj_type"
  >
    <slot />
  </obj-form>
</template>

<script>
import { OBJ_COMPONENTS } from "../../constants";
import { obj_template, bindEvent } from "../../functions";
import EventBus from "../EventBus";
import ObjForm from "../ObjForm";
import { WithRelated } from "../mixins";
import WithApi from "../mixins/WithApi.vue";

export default {
  name: "Obj",
  props: {
    obj_id: {
      type: [String, Number],
      required: true,
    },
    obj_type: {
      type: String,
    },
    eventBus: {
      required: false,
    },
    eventPrefix: {
      required: false,
      type: String,
      default: "",
    },
    mode: {
      required: false,
      default: "view",
      type: String,
    },
  },
  components: { ObjForm },
  mixins: [EventBus, WithApi, WithRelated],
  data: function () {
    return {
      old_object: {},
      object: {},
    };
  },
  watch: {
    obj_id() {
      // следим за obj_id чтобы перерисовывать
      this.set_title();
      this.restore_obj();
    },
  },
  mounted() {
    bindEvent(
      this,
      `${this.eventPrefix}valid`,
      (is_valid) => {
        if (is_valid) this.update_data();
      },
      this.bus
    );
    bindEvent(this, `${this.eventPrefix}reset`, this.reset, this.bus);
    bindEvent(this, `${this.eventPrefix}refresh`, this.load_data, this.bus);
    this.object =
      this.$store.getters[`${this.obj_type}/object`](this.obj_id) || {};
    this.$watch(
      () => this.$store.getters[`${this.obj_type}/object`](this.obj_id),
      (to) => (this.object = to)
    );
  },
  computed: {
    bus() {
      return this.eventBus || this;
    },
    use_instance_id() {
      return false;
    },
    additional_fields() {
      return [];
    }, // дополнительые поля на случай если что-то нужно добавить
    deleted() {
      // является ли объект удаленным
      let ret = this.old_object[this.description.delete_key];
      return ret;
    },
    obj_key() {
      // ключ
      this.description;
      return this.description.obj_key;
    },
    obj_name() {
      // имя объекта
      let name =
        this.object[this.description.obj_name] ||
        this.object[this.description.obj_key];
      if (this.object.obj_type) {
        name = obj_template(this.object);
      }
      if (this.mode == "new" && !name) {
        name = "Новый " + this.description.verbose;
      }
      return name;
    },
    default_value() {
      // значение по-умолчани
      let def = Object.assign({}, this.description?.new_default ?? {});
      return def;
    },
    description() {
      // описание объекта
      this.obj_type;
      return OBJ_COMPONENTS[this.obj_type];
    },
  },
  methods: {
    validate() {
      this.$emit("validate");
    },
    update() {
      this.$emit("update");
    },
    load() {
      // загрузка даных
      return this.load_depences()
        .then(this.restore_obj())
        .then(() => {
          this.$eventBus.$emit("refresh");
        });
    },
    set_title() {
      // задаем заголовок
      document.title = this.description.verbose;
    },
  },
};
</script>