import { description_field, buildRelatedField } from "../fields"
import { BFormCheckbox } from "bootstrap-vue"
import { actions_field, id_field_desc } from "@/functions.js"
import store from "../../../store"

const root = 'pbx/instance'
const URLS = {
  add: `${root}/create`,
  get: `${root}/get`,
  update: `${root}/update`,
  delete: `${root}/close`,
  list: `${root}/list`,
}

export default {
  verbose: 'Экземпляр телефонии',
  plural: 'Экземпляры телефонии',
  urls: URLS,
  obj_key: 'instance_id',
  obj_name: 'name',
  delete_key: 'date_stop',
  add_new_data() {
    return {
      customer_id: store.getters.customer
    }
  },
  unique_for: 'customer',
  //fake_unique: true,
  page_variant: 'sip',
  no_create_button: true,
  fields: {
    instance_id: id_field_desc,
    namespace: "Namespace",
    name: "Название",
    description: description_field,
    customer_id: buildRelatedField('Клиент', 'customer'),
    active: {
      label: 'Активность',
      comp: BFormCheckbox
    },
    suspend: {
      label: 'Приостановлен',
      comp: BFormCheckbox
    },
  },
  list_fields: ['namespace', 'name', 'description', actions_field],
  list_fields_search: ['namespace', 'name'],
  new_fields: ['namespace', 'name', 'description'],
  edit_fields: ['namespace', 'name', 'description', 'active', 'suspend']
}