import { id_field_desc } from "@/functions.js"
import { buildRelatedField, buildOptionsField } from "../fields"
import ELObj from './ELObj'
import isActive from '../../shared/activeComp'

const status_options = [
  'Рассмотрение заявки',
  'Заявка принята, проверка документов',
  'Заявка отклонена',
  'Активен до',
  'Доступен, требуется оплата'
]
const fields = {
  line_id: id_field_desc,
  number: { label: 'Номер телефона', isKey: true },
  operator: 'Оператор',
  caption: 'Комментарий',
  region: 'Регион',
  status: buildOptionsField('Статус', status_options),
  lines: 'Количество входящих линий',
  route_id: 'Маршрутизация',
  tag: buildRelatedField('Тэг', 'tag'),
  price: 'Абонентская плата'
}
const external_line_groups = objects => {
  let _groups = {}
  for (const obj of objects) {
    let sub_group = _groups[obj.operator] || []
    sub_group.push(obj)
    _groups[obj.operator] = sub_group
  }
  return Object.entries(_groups)
}
// const regions = {
//   spb: /8812/,
//   msk: /8945/
// }
const storage = {
  getters: {
    calced_options: () => () => {
      return { region: 'spb' }
    }
  }
}
const description = {
  verbose: 'Внешняя линия',
  plural: 'Внешние линии',
  obj_key: 'line_id',
  obj_name: 'number',
  obj_comp: ELObj,
  fields: fields,
  storage: storage,
  list_group_func: external_line_groups,
  list_fields: ['number', 'region', 'caption', 'lines', { label: 'Пием звонков', key: 'allow_calls' }, 'status'],
  view_fields: [
    { label: 'Статус', target: 'status', comp: isActive },
    'route_id',
    'tag'
  ]
}
export { description }