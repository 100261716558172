<template>
  <span class="start">
    <Socket
      v-for="output in outputs()"
      :key="output.key"
      v-socket:input="output"
      type="input"
      class="input-socket"
      :socket="output.socket"
    />
  </span>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
// import { eventBus } from "../../../..";
export default {
  name: "Component",
  mixins: [VueRenderPlugin.mixin],
  components: {
    Socket: VueRenderPlugin.Socket,
  },
  mounted() {
    // если не отсортировано
    // if (!this.$store.getters["routing/has_positions"]) {
    //   setTimeout(() => {
    //     if (this.node.data.name == "start") {
    //       console.log("mounted", this.node);
    //       eventBus.$emit("rearange", this.node);
    //     }
    //   }, 100);
    // }
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.start {
  width: 0;
  height: 0;
}
</style>