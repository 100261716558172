import { id_field_desc } from "@/functions.js"
import { keyCell, } from "../../shared/index"
import { buildRelatedField } from "../fields"
import ForwardInput from "./ForwardInput.vue";
import isActive from '../../shared/activeComp'
import { cloneDeep, } from "lodash-es"
import ActionsPhys from "./ActionsPhys"

const root = '/pbx/services/v2/internalLine/physical'

const PHYSICAL_URLS = {
  list: `${root}/list`,
  update: `${root}/modify`,
}

const PHYSICAL_DESC = {
  verbose: 'Линию',
  urls: PHYSICAL_URLS,
  obj_type: 'physical',
  unique_for: ['customer', 'entity'],
  obj_template: obj => obj.caption || `Физическая линия ${obj.lineNumber}`,
  no_create_button: true,
  no_trash_button: true,
  storage: {
    getters: {
      calced_options: () => obj => {
        const internal_line_service_id = obj.internalNumberId;
        const get_forward_status = (data) => {
          let ret = 0;
          if (data.always) { ret = 1 }
          else if (data.onBusy || data.onTimeout) { ret = 2 }
          return ret
        }
        let ret = {
          status: obj.state.availableToUse,
          callLevel: obj.restrictions.callLevel,
          forward: get_forward_status(obj.transfer),
          transfer_always: obj.transfer.always || null,
          transfer_busy: obj.transfer.onBusy || null,
          transfer_timeout: obj.transfer.onTimeout || null,
          internal_line_service_id
        }
        const line = obj._related('internalNumberId')
        if (line) {
          const empl = line.employee
          ret['employee'] = empl.toUpperCase()
        }
        return ret
      }
    },
    actions: {
      update({ getters, commit, dispatch }, object) {
        const get_api = getters.has_api.update
        const old_data = getters.object(object.service_id)
        const data = cloneDeep(getters.pre_data(object, 'update'))
        const fields_map = {
          transfer_always: 'transferAlways',
          transfer_busy: 'transferOnBusy',
          transfer_timeout: 'transferOnTimeout'
        }
        for (const [key, value] of Object.entries(fields_map)) {
          data[value] = data[key]
          delete data[key]
        }
        var req = dispatch(
          'requestApi',
          {
            url: get_api,
            data: data
          }, { root: true }
        ).then(
          resp => {
            if (resp.data.result == 'success') {
              commit('add_message', { type: 'success', message: `Успешное обновление физической линии` }, { root: true })
            }
          })
        if (object.internal_line_service_id && old_data.internal_line_service_id !== object.internal_line_service_id) {
          req = req.then(() => dispatch('requestApi', {
            url: `${root}/detach`,
            data: {
              customer_id: old_data.customer_id,
              entity_id: old_data.entity_id,
              service_id: old_data.service_id
            }
          }, { root: true })).then(() => dispatch('requestApi', {
            url: `${root}/attach`,
            data: {
              customer_id: old_data.customer_id,
              entity_id: old_data.entity_id,
              service_id: old_data.service_id,
              internal_number_service_id: object.internal_line_service_id
            }
          }, { root: true }))
        }
        req = req.then(() => dispatch('get', object.obj_id))
        return req

      },
    }
  },
  save_method: (comp) => {

    comp.$bvModal.msgBoxConfirm('Продолжая настройку физической линии, будет изменен внутренний номер. Прежнему сотруднику не смогут дозвониться по его внутреннему номеру на стационарный телефон. Продолжить?', {
      okVariant: 'obit',
      okTitle: 'Подтвердить',
      cancelTitle: 'Отмена',
      hideHeaderClose: false,
      centered: true
    }).then(
      ok => {
        if (ok) comp.$emit('save')
      }
    )
  },
  fields: {
    service_id: id_field_desc,
    caption: { label: 'Название', isKey: true, isName: true },
    lineNumber: { label: 'Внутренний номер', isKey: true },
    employee: buildRelatedField('Сотрудник', 'employee', { root: '/settings/employee', cell_options: { with_links: true } }),
    internalNumberId: buildRelatedField('Номер', 'internal_line2'),
    internal_line_service_id: buildRelatedField('Внутренний номер', 'internal_line2',
      { root: '/sip/internal_line2', options: { wait_for: ["internal_line2", "employee"] }, cell_options: { with_links: true, related_target: 'number' } }
    ),
    status: {
      label: 'Статус', cell: isActive, key: 'status', cell_options: { rule: value => value }
    },
    transfer: {
      label: "Переадресация",
      target: "forward",
      comp: ForwardInput,
    },
    transfer_always: {
      label: 'Постоянная переадресация',
      options: { placeholder: 'Введите номер телефона' }
    },
    transfer_busy: {
      label: 'Переадресация при занятой линии',
      options: { placeholder: 'Введите номер телефона' }
    },
    transfer_timeout: {
      label: 'Переадресация при таймауте',
      options: { placeholder: 'Введите номер телефона' }
    },
  },
  list_fields: [
    { label: 'Название', key: 'obj_name', cell: keyCell },
    'internal_line_service_id', 'employee', 'status',
    {
      label: '', key: 'act2',
      cell: ActionsPhys
    },
  ],
  list_fields_search: ['caption', 'internal_line_service_id', 'employee'],
  view_fields: ['caption', 'internal_line_service_id', 'transfer', {
    label: 'Статус', comp: isActive, target: 'status', options: {
      rule: (v) => v
    }
  }],
  edit_fields: ['caption', 'internal_line_service_id', 'transfer',],
}

export { PHYSICAL_DESC }