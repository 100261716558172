<template>
  <b-nav-item
    variant="obit"
    :class="['menu-item', additional_classes]"
    v-bind="bind_props"
    v-if="(item.items || []).length == 0"
    :style="vars"
  >
    {{ item.title }}
    <obit-icon
      class="side-menu-icon"
      v-if="item.descr"
      icon="question"
      v-b-tooltip.v-light="item.descr"
    />
  </b-nav-item>
  <div v-else :class="{ active: sub_active, 'sub-with-items': true }">
    <b-nav-item
      variant="obit"
      :class="['menu-item', { active: sub_active }]"
      v-b-toggle="`sc_${index}`"
      v-bind="{ ...(item.props || {}), ...$attrs }"
      :style="vars"
    >
      {{ item.title }}
      <span><icon :icon="icons.chevron"/></span>
    </b-nav-item>
    <b-collapse :id="`sc_${index}`" v-model="opened">
      <side-menu-option
        v-for="(sub_item, indx) in item.items"
        v-bind:key="indx"
        :item="sub_item"
        :index="sub_index(indx)"
        ref="sub_items"
      />
    </b-collapse>
  </div>
</template>

<script>
import { BCollapse, BNavItem } from "bootstrap-vue";
import { as_icon, Icon } from "../layot";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import ObitIcon from "./ObitIcon.vue";

export default {
  name: "SideMenuOption",
  components: { BNavItem, BCollapse, Icon, ObitIcon },
  props: {
    item: {
      required: true,
    },
    index: {
      required: false,
      default: "0",
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loaded = true;
      this.opened = this.sub_active;
    });
  },
  data() {
    return {
      loaded: false,
      opened: this.sub_active,
    };
  },
  computed: {
    bind_props() {
      let ret = {
        ...this.item.props,
        ...this.$attrs,
      };
      const def_func = () => false;
      const act = ret["active"] || def_func;
      ret["active"] = act(this.$route, this.item);
      return ret;
    },
    level() {
      return `${this.index}`.split("_").length;
    },
    icons() {
      return {
        chevron: as_icon(this.opened ? faChevronUp : faChevronDown),
      };
    },
    additional_classes() {
      return {
        [`sub-menu-level-${this.level}`]: true,
        active: this.sub_active,
      };
    },
    vars() {
      return {
        "--padding-left": `calc(30px + ${(this.level - 1) * 0.5}em)`,
        "--border-size": `calc(0.25em * ${this.level})`,
      };
    },
    sub_active() {
      const refs = this.loaded ? this.$refs.sub_items || [] : [];
      return refs.some((r) => r.$el.children[0].classList.contains("active"));
    },
  },
  methods: {
    sub_index(i) {
      return `${this.index}_${i}`;
    },
  },
};
</script>

<style lang="scss">
@import "../../../static/SCSS/obit-colors.scss";

.sub-with-items.active {
  border: 0;
  border-left: $obit-warning 0.25em solid;
}
$gray: $obit-active;
.menu-item {
  color: $gray;
  font-size: 12px;
  line-height: 14.4px;
  width: 100%;
  font-weight: 700;
  @media screen and (max-width: 800px) {
    width: fit-content;
  }
  &.active {
    a.nav-link {
      color: $gray;
    }
  }
  a.nav-link {
    color: $obit-black;
    text-transform: uppercase;
    padding: 15px 30px;
    font-family: "latoheavy", Arial, sans-serif;
    &:hover {
      color: $obit-warning;
    }
    @media screen and (max-width: 800px) {
      width: max-content;
      padding-bottom: 22px;
      &.active {
        border: 0;
        border-bottom: $obit-warning 0.25em solid;
        color: $gray;
        fill: $gray;
      }
    }
    @media screen and (min-width: 801px) {
      padding-left: var(--padding-left);

      &.active {
        border: 0;
        border-left: $obit-warning 0.25em solid;
        color: $gray;
        fill: $gray;
        padding-left: 26px;
      }
    }
  }
}
.side-menu-icon {
  margin-left: 5;
}
</style>
