<template>
  <spinner v-if="this.loading" />
  <div v-else-if="!this.loading" v-bind="$attrs">
    <slot name="searchbar">
      <component
        :is="filter_component"
        :objects_in="objs"
        v-model="objs_filtered"
        :description="description"
        v-if="!disable_search"
        @filter="reset_page"
      />
    </slot>
    <component
      :is="table_comp"
      :objects="objects"
      :description="description"
      :fields="fields"
      :use_paginator="use_paginator"
      ref="objects-list"
      v-bind="$attrs"
    >
      <template
        v-for="(_, name) in $scopedSlots"
        :slot="name"
        slot-scope="slotData"
        ><slot :name="name" v-bind="slotData" />
      </template>
    </component>
  </div>
  <component v-else-if="description.empty_comp" :is="description.empty_comp" />
</template>

<script>
import { IBox, Icon, as_icon } from "../layot";
import {
  BTable,
  BButton,
  BButtonGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  BSpinner,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { OBJ_COMPONENTS } from "../../constants";
import { bindEvent, type_val } from "../../functions";
import store from "../../store";
import ObjTable from "../objects/ObjTable";
import EventBus from "../EventBus";
import {
  faSync,
  faPlus,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { WithRelated, FilterMixin } from "../mixins";
import Spinner from "../obit/Spinner";
import { uniqWith } from "lodash-es";

export default {
  name: "ObjList",
  components: {
    IBox,
    BInputGroupPrepend,
    BTable,
    BButton,
    BButtonGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    Icon,
    BModal,
    BSpinner,
    Spinner,
  },
  props: {
    obj_type: {
      default: "user",
      type: String,
    },
    all: {
      required: false,
      type: Boolean,
      default: false,
    },
    additional_filter: {
      required: false,
      type: Function,
    },
    additional_data: {
      required: false,
      type: Function,
    },
    fields: {
      required: false,
      default: null,
      type: Array,
    },
    items: {
      required: false,
      default: undefined,
      type: Array,
    },
    disable_search: {
      required: false,
      default: false,
      type: Boolean,
    },
    use_paginator: {
      required: false,
      default: true,
      type: Boolean,
    },
  },
  mixins: [EventBus, WithRelated],
  data: function () {
    return {
      filter: "", // фильтр
      // objs: [], // объекты
      to_delete: "", // кого удалить
      to_delete_name: "", // имя
      show_delete_modal: false, // модалка для удаления
      loading: false, // статус загрузки
      icons: {
        sync: as_icon(faSync),
        plus: as_icon(faPlus),
        eye: as_icon(faEye),
        trash: as_icon(faTrash),
      },
      objs_filtered: [],
    };
  },
  mounted() {
    this.mount_method();
  },
  beforeDestroy() {
    this.obj_type;
    this.description;
    //this.$eventBus.$off('customer_changed')
  },
  computed: {
    // obj_type: () => 'user', //  тип объекта
    filter_component() {
      return this.description.list_filter_comp || FilterMixin;
    },
    filter_func() {
      return this.additional_filter ? this.additional_filter : () => true;
    },
    objs() {
      this.obj_type;
      let objs;
      if (this.items && type_val(this.items) == "Array") {
        objs = this.items;
      } else {
        objs = this.$store.getters[`${this.obj_type}/objects`];
      }
      return objs;
    },
    has_sync() {
      return this.has_method("list");
    },
    has_add() {
      return this.has_method("add");
    },
    table_comp: () => ObjTable,
    objects() {
      // обработанные объекты
      let ret = this.objs.filter(this.filter_func).map((row) => {
        let new_row = this.row_func(row);
        return new_row;
      });
      const filter = this.description.list_filter;
      if (filter) {
        ret = ret.filter(filter);
      }
      if (!this.disable_search) {
        ret = ret.filter((o) => this.objs_filtered.indexOf(o.obj_id) >= 0);
      }
      return ret;
    },
    current_user: () => store.getters.current_user, // текущий пользователь
    description() {
      // описание
      this.obj_type;
      return OBJ_COMPONENTS[this.obj_type] || {};
    },
    list_key() {
      const desc = this.description;
      return desc.obj_listkey || desc.obj_sendkey || desc.obj_key || "id";
    },
    url() {
      return this.description.urls.list;
    },
    filter_string() {
      let ret = [];
      const list_fields = this.description.list_fields_search || [];
      for (const f of list_fields) {
        let desc = this.description.fields[f];
        if (type_val(desc) == "String") {
          ret.push(`${desc}`);
        } else {
          let label = desc.label || f;
          ret.push(`${label}`);
        }
      }
      return ret.join(", ");
    },
  },
  methods: {
    reset_page() {
      this.$refs["objects-list"].page = 1;
    },
    has_method(method) {
      const urls = this.description.urls || {};
      const url = urls[method];
      return url || this.description[`custom_${method}`];
    },
    mount_method() {
      this.obj_type;
      this.description;
      const uniq = this.description.unique_for;
      //  const bus = this.$eventBus
      this.load_data();
      if (uniq) {
        if (type_val(uniq) == "String") {
          bindEvent(this, `${uniq}_changed`, () => {
            this.load_data();
          });
        } else if (type_val(uniq) == "Array") {
          uniq.map((u) =>
            bindEvent(this, `${u}_changed`, () => {
              this.load_data();
            })
          );
        }
      }
      bindEvent(this, `loaded:${this.obj_type}`, () => {
        this.$forceUpdate();
      });
      // на всякий получим связанные
      let related = [];
      if (this.obj_type == "internal_line2") {
        related.push("pbx_group");
      }
      for (const field of this.description.list_fields) {
        if (type_val(field) == "String") {
          const desc = this.description.fields[field] || {};
          if (desc.related) {
            related.push(desc.related);
          }
        } else {
          if (field.related) {
            if (type_val(field.related) == "String") {
              related.push(field.related);
            } else {
              related = related.concat(field.related);
            }
          }
        }
      }
      related = uniqWith(related);
      Promise.all(related.map((r) => this.$store.dispatch(`${r}/list`)));
      // Object.values(this.description.fields).map((field_descr) => {
      //   if (
      //     type_val(field_descr) == "Object" &&
      //     field_descr.related &&
      //     field_descr.related !== this.obj_type
      //   ) {
      //     this.$store.dispatch(`${field_descr.related}/list`);
      //   }
      // });
    },
    load_data() {
      // загрузка данных
      this.obj_type;
      this.loading = true;
      return this.$store
        .dispatch(`${this.obj_type}/list`, { force: true })
        .then(() => (this.loading = false));
    },
    row_func(row) {
      // обработка каждой строки
      let add_data = this.additional_data ? this.additional_data(row) : {};
      let obj = Object.assign({}, row, add_data);
      if (this.row_deleted(row)) {
        obj["_rowVariant"] = "secondary";
      }
      return obj;
    },
    row_deleted(row) {
      // строка удалена
      return row[this.description.delete_key];
    },
    fetch_data() {
      this.obj_type;
      return this.$store
        .dispatch(`${this.obj_type}/list`)
        .then(() => this.$forceUpdate());
    },
    show_delete() {
      // показать окно удаления
      this.show_delete_modal = true;
    },
    delete_obj() {
      // удалить объект

      this.$forceUpdate();
    },
    refresh() {
      this.obj_type;
      //this.objs = objs
      this.$forceUpdate();
    },
  },
  watch: {
    obj_type: function () {
      this.mount_method();
      this.$forceUpdate();
    },
  },
};
</script>

<style lang='scss'>
.objects-list {
  background-color: white;
  .objects-dsip,
  .objects-internet {
    td.field_name a.number-cell {
      white-space: break-spaces;
    }
    td.field_date_begin_dateonly {
      white-space: nowrap;
    }
  }
  .objects-calculation {
    td.field_bill,
    td.field_payment {
      text-align: right;
    }
  }
  .objects-bill {
    td.field_amount {
      text-align: right;
    }
  }
}
</style>