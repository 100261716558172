import { merge } from "lodash-es";
import store from "../../../store";
import { CONFIGS } from "./components/index";
import { cloneDeep } from 'lodash-es'

const types = Object.values(CONFIGS).map(
  c => c.objType
)
const configs_dict = Object.values(CONFIGS).reduce(
  (r, conf) => Object.assign(r, { [conf.objType]: conf }), {}
)
function rebuild_data(data, additional_raw_data) {
  let service_types = {}
  for (const f of types) {
    if (f != 'unknown') {
      store.getters[`${f}/objects`].map(o => {
        service_types[o.service_id.toLowerCase()] = f
        service_types[o.service_id.toUpperCase()] = f
      })
    }
  }
  for (const [t, objs] of Object.entries(additional_raw_data)) {
    objs.map(o => {
      if (types.indexOf(t) == -1) {
        service_types[o.service_id.toLowerCase()] = 'unknown';
        service_types[o.service_id.toUpperCase()] = 'unknown';
      }
    })
  }
  // постпроим стартовое дерево
  let nodes = {
    start: {
      name: "start",
      id: "start",
      position: [0, 0],
      data: {
        name: "start",
      },
      outputs: {
        start: {
          connections: [],
        },
      },
      inputs: {},
    },
  };
  let data_id = {}
  // построим data_id: id нужно на случай кривых айдишников
  data.map(
    (service, i) => {
      const id = `${i}`
      data_id[service.service_id.toLowerCase()] = id
      data_id[service.service_id.toUpperCase()] = id
    }
  )
  for (const service of data) {
    const service_type = service_types[service.service_id]
    // const pos = [0, 0 * i];
    const config = configs_dict[service_type] || configs_dict['unknown'];
    const objs = additional_raw_data[service_type] || additional_raw_data['unknown'] || [];
    if (service_type) {
      const _node = {
        name: service_types[service.service_id],
        id: service.service_id,
        position: service.coords ? [service.coords.x, service.coords.y] : store.getters['routing/position'](service.service_id),
        data: {
          service_id: service.service_id,
          raw: service,
          raw_data: objs.find(s => s?.service_id?.toLowerCase() == service?.service_id?.toLowerCase()) || {
            caption: 'unknown',
            description: 'unknown',
            service_id: service.service_id,
            service_type: service_type,
          },
          show: true,
          has_root: false,
          config: config
        },
        outputs: {},
        inputs: {
          // start: { connections: [] }
        },
      }
      let node = cloneDeep(_node)
      if (node.name != 'city_number') {
        // добавим информацию о том, если связь с city_number
        node.data.has_root = false
      }
      const defLoadFunc = (nodes, node, service) => {
        const outputs = service.outputs.filter(c => c.service_id).reduce(
          (r, d) => {
            r[d.key] = {
              connections: [
                {
                  node: d.service_id,
                  input: 'call',
                  data: {}
                }
              ]
            }
            return r
          }, {})
        return merge({}, node, { outputs: outputs })
      }
      const loadFunc = (config || {}).load_data || defLoadFunc
      nodes[service.service_id] = loadFunc(nodes, node, service);
      // let node = {
      //   name: "verb",
      //   id: service.service_id,
      //   data: {
      //     options: service.options,
      //     outputs: service.outputs || [],
      //     inputs: {},
      //     raw_data: service,
      //     name: service.service_type,
      //     id: service.service_id,
      //   },
      //   position: pos,
      //   outputs: build_outputs(service.outputs || [], service),
      //   inputs: {},
      // };
      // 
    }
  }

  Object.entries(nodes).map(([id, node]) => {
    Object.entries(node.outputs || {}).map(([key, conn]) => {
      const connections = conn.connections;
      for (const c of connections) {
        if (c.node) {
          let _existed_connections = nodes[c.node]?.inputs[c.input] || {
            connections: [],
          };
          let existed_connections = _existed_connections.connections || [];
          existed_connections.push({ output: key, node: id, data: {} });
          if (!nodes[c.node]) continue
          if (!nodes[c.node].inputs) {
            nodes[c.node].inputs = {}
          }
          nodes[c.node].inputs[c.input] = _existed_connections;
        }
      }
    });
  });
  // удалим без связей
  // const cr_node = store.getters['routing/created_node']?.toLowerCase()
  Object.values(nodes).map(
    (node) => {
      const inputs_count = Object.values(node.inputs || {}).length
      const outputs_count = Object.values(node.outputs || {}).length
      const conn_count = inputs_count + outputs_count
      if (conn_count == 0 && node.name != 'start') {
        node.data.show = false
      }
      // if (cr_node && cr_node == node.id.toLocaleLowerCase()) {
      //   node.data.show = true
      //   node.data.new = true
      // }
      if (node.name == 'city_number' || node.name == 'SIPTermination') {
        const outputs = node.outputs.default?.connections || []
        // добавим has_root
        if (!outputs.length) {
          node.data.show = false
        } else {
          node.data.show = true
          for (const o of outputs) {
            nodes[o.node].data.has_root = true
          }
        }
        // тока что созданная нода
      }
    }
  )
  // без инпутов соединим со стартом
  Object.entries(nodes).map(([id, node]) => {
    if (id != 'start') {
      if (!Object.keys(node.inputs || {}).length && id == "city_number") {
        let _existed_connections = nodes.start.outputs.start.connections;
        _existed_connections.push({ node: id, input: "start" });
        nodes.start.outputs.start.connections = _existed_connections;
        node.inputs.start = { connections: [{ node: "start", output: "start" }] };
      }
      // if (!Object.keys(node.inputs || {}).length && id != 'city_number' && Object.keys(node.outputs || {}).length) {
      //   let _existed_connections = nodes.start.outputs.start.connections;
      //   _existed_connections.push({ node: id, input: "start" });
      //   nodes.start.outputs.start.connections = _existed_connections;
      //   node.inputs.start = { connections: [{ node: "start", output: "start" }] };
      // }
    }
  });
  return nodes;
}

function pack_data(json, _old_data) {
  let new_data = _old_data.reduce(
    (r, c) => merge(r, { [c.service_id]: c }),
    {}
  )
  let ids_desc = Object.values(json.nodes).reduce(
    (r, node) => merge(r, { [node.id]: node.data.service_id }),
    {}
  )
  for (const node of Object.values(json.nodes)) {
    // пропустим старт
    if (node.name == 'start') continue
    const defaultPackFunction = (n) => {
      let outputs = []
      for (const [name, { connections }] of Object.entries(n.outputs || {})) {
        if (connections.length) {
          outputs = outputs.concat(
            connections.map(c => ({ key: name, service_id: ids_desc[c.node] }))
          )
        } else {
          if (node.name != 'city_number') {
            outputs = outputs.concat(
              [{ key: name, service_id: null }]
            )
          } else {
            if (node.data.show) {
              const line = store.getters['city_number/object'](node.data.service_id)
              store.commit('add_message', { type: 'warning', message: `Линия ${line.number} ни с чем не связана. Это правило будет проигнорированно` })
            }
          }
        }
      }
      return outputs
    }
    const packFunction = configs_dict[node.name].save_data || defaultPackFunction
    new_data[node.id].outputs = packFunction(node, _old_data, ids_desc)
    let pos = store.getters['routing/position'](node.id)
    new_data[node.id].coords = { x: pos[0] || 0, y: pos[1] || 0, z: pos[2] || 0 }
    // if (node.name == 'city_number') {
    //   new_data[node.id].outputs = [
    //     ...node.outputs.default.connections.map(
    //       c => ({ key: 'default', service_id: ids_desc[c.node] || null })
    //     ),
    //     ...node.outputs.limit.connections.map(
    //       c => ({ key: 'limit', service_id: ids_desc[c.node] || null })
    //     )]
    // }
  }
  return Object.values(new_data)
}

export { rebuild_data, pack_data }