<template>
  <b-input-group>
    <b-form-input
      v-model="val"
      type="number"
      min="0"
      max="9999"
      onkeyup="if(parseInt(this.value)>9999){ this.value =9999; return false; }"
    />
    <b-input-group-append>
      <b-form-radio-group
        v-model="val"
        :options="category_timeout_options"
        buttons
        button-variant="outline-primary"
      />
    </b-input-group-append>
  </b-input-group>
</template>


<script>
import { InputMixin } from "../../mixins";
import { BFormInput, BFormRadioGroup } from "bootstrap-vue";
export default {
  components: { BFormInput, BFormRadioGroup },
  props: ["placeholder"],
  name: "MaxLength",
  mixins: [InputMixin],
  data() {
    return {
      category_timeout_options: [
        { text: "5 чел.", value: 5 },
        { text: "10 чел.", value: 10 },
        { text: "нет", value: 0 },
      ],
    };
  },
};
</script>



<style lang="scss" scoped>
.text-input {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  .text-input {
    width: 65px;
    text-align: center;
    padding: 5px;
  }
}
.text-block {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  color: #adadad;
}
</style> 