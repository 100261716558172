<template>
  <div>
    <obj-form v-model="object" :description="description" mode="new" />
    <b-button>Сохранить</b-button>
  </div>
</template>

<script>
import ObjForm from "../../ObjForm";
import { PERM_OBJ } from "./description";

export default {
  name: "UserPermManager",
  components: { ObjForm },
  data() {
    return {
      description: PERM_OBJ,
      object: {},
    };
  },
};
</script>

<style>
</style>