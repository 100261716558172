<template>
  <b-list-group>
    <b-list-group-item
      v-for="(obj, indx) in objs_filtered"
      v-bind:key="indx"
      href="#"
      :active="customer == obj.id"
      @click.prevent="set_customer(obj.id)"
    >
      <h4>{{ obj.title }}</h4>
      <span>{{ obj.description }}</span>
    </b-list-group-item>
    <b-list-group-item
      :to="{ name: 'object', params: { type: 'customer', id: 'new' } }"
      active
      variant="primary"
    >
      <icon :icon="icons.plus" /> Добавить
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import CustomerList from "./CustomerList";
import { mapMutations, mapGetters } from "vuex";
import { as_icon, Icon } from "../../layot";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default {
  name: "CustomerSelectTable",
  mixins: [CustomerList],
  components: { Icon },
  data() {
    return {
      icons: {
        plus: as_icon(faPlus),
      },
    };
  },
  computed: {
    ...mapGetters(["customer"]),
  },
  methods: {
    ...mapMutations(["set_customer"]),
  },
};
</script>