<template>
  <b-card>
    <b-card-body>
      <ul>
        <li v-for="(val, key) in value" v-bind:key="key">
          {{ key }}: {{ val }}
        </li>
      </ul>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  name: "resource",
  props: ["value"],
};
</script>