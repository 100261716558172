<template>
  <arkhive-list
    :description="calls_description"
    action="call/tarif_list"
    keyword="tarrif-calls"
    :filter_func="filter_func"
    v-slot="{ fetch }"
    ref="list"
    @update_count="count = $event"
  >
    <b-form inline class="filter">
      <period-input v-model="period_data" @input="fetch()" />
      <b-col flex class="d-flex flex-end" v-if="show_export">
        <b-form-group label=" ">
          <b-button
            variant="outline-export"
            class="btn-filter-height btn-right btn-filter-text"
            @click="export_file = true"
          >
            <obit-icon icon="xls" />
            ЭКСПОРТ
          </b-button>
        </b-form-group>
      </b-col>
      <!--b-form-group label=" ">
        <b-button variant="outline-primary" @click="show_filter()" disabled>
          Фильтры
        </b-button>
      </b-form-group -->
    </b-form>

    <b-modal v-model="filter_show" title="Фильтр" no-close-on-backdrop>
      <obj-form
        v-model="filter_additional"
        :description="filter_description"
        raw
        mode="edit"
      >
        <template v-slot:field(call_type)>
          <h2>Входящие</h2>
          <b-form-checkbox-group
            stacked
            :options="[
              { text: 'Принятые', value: 'pr' },
              { text: 'Пропущенные', value: 'prop' },
            ]"
          />
          <h2>Исходящие</h2>
          <b-form-checkbox-group
            stacked
            :options="[
              { text: 'Отвеченные', value: '1' },
              { text: 'Без ответа', value: '2' },
              { text: 'Занято', value: '3' },
            ]"
          />
        </template>
      </obj-form>
    </b-modal>
    <b-modal
      v-model="export_file"
      title="Экспорт файла"
      ok-title="Экспорт"
      no-close-on-backdrop
      @ok="export_data()"
    >
      <b-form-radio-group
        v-model="export_mode"
        :options="export_options"
        stacked
      ></b-form-radio-group>
    </b-modal>
  </arkhive-list>
</template>

<script>
import startOfWeek from "date-fns/startOfWeek/index";
import {
  texttimeformatter,
  number_formatter,
  apiDateFormatter,
  dateformatter_standart,
} from "../../formatters";
import TarrifDirectionIconVue from "../calls/TarrifDirectionIcon.vue";
import ArkhiveList from "../objects/ArkhiveList.vue";
import { buildRelatedField } from "../objects/fields";
import ObjForm from "../ObjForm.vue";
import { endOfMonth, startOfMonth, endOfWeek } from "date-fns";
import { as_icon, Icon } from "../layot";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { download_responce_ab } from "../../functions";
import CallTimeVue from "../calls/CallTime.vue";
// import DatePicker from "../objects/Bill/DatePicker.vue";
import periodInput from "../calls/periodInput.vue";
periodInput;
const call_description = {
  verbose: "Звонки",
  fields: {
    type: { label: "Тип", cell: TarrifDirectionIconVue },
    date: { label: "Дата", sortable: true, cell: CallTimeVue },
    from: { label: "Кто", table_formatter: number_formatter, sortable: true },
    to: { label: "Кому", table_formatter: number_formatter, sortable: true },
    // zone: { label: "Зона", sortable: true },
    direction: { label: "Направление", sortable: true },
    //direction_group: { label: "Направление (доп)", sortable: true },
    duration: {
      label: "Длительность",
      table_formatter: (value) => texttimeformatter(value * 60),
      sortable: true,
    },
    price: {
      label: "Стоимость",
      table_formatter: (val) => `${val.toFixed(2)} ₽`,
      sortable: true,
    },
  },
};
const filter_descr = {
  verbose: "Фильтр",
  fields: {
    call_type: "Результаты звонков",
    ext: buildRelatedField("Внеш. номера", "city_number"),
    lines: buildRelatedField("Внутр. номера", "internal_line2"),
    pbx_group: buildRelatedField("Группы", "pbx_group"),
  },
};

export default {
  components: { ArkhiveList, ObjForm, Icon, periodInput },
  name: "tarifDetail",
  mounted() {
    this.$watch(
      () => JSON.stringify([this.$store.getters.current("entity", "instance")]),
      () => {
        this.$refs.list.fetch();
      }
    );
  },
  computed: {
    show_export() {
      return !!this.count;
    },
    icons: () => ({
      close: as_icon(faTrash),
    }),
    calls_description: () => call_description,
    filter_description: () => filter_descr,
    export_options: () => [
      { text: "Excel", value: "xlsx" },
      { text: "JSON", value: "json" },
      { text: "CSV", value: "csv" },
    ],
    date_filter_text() {
      var text = this.dt_variants[this.filter_type];
      if (this.filter_type == "period") {
        if (this.date_start && !this.date_end) {
          text = `C ${this.date_start}`;
        } else if (!this.date_start && this.date_end) {
          text = `По ${this.date_end}`;
        } else if (this.date_start && this.date_end) {
          text = `${this.date_start} - ${this.date_end}`;
        }
      }
      return text;
    },
    period_data: {
      get() {
        return { date_start: this.date_start, date_end: this.date_end };
      },
      set(to) {
        const { date_start, date_end } = to;
        [this.date_end, this.date_start] = [date_end, date_start];
      },
    },
  },
  data: () => ({
    filter_show: false,
    filter_additional: {},
    filter_type: "month",
    isCloseable: false,
    date_filter: false,
    date_start: dateformatter_standart(startOfMonth(new Date())),
    date_end: dateformatter_standart(endOfMonth(new Date())),
    export_file: false,
    export_mode: "",
    count: 0,
  }),
  methods: {
    filter_func() {
      let ret = {};
      switch (this.filter_type) {
        case "today":
          ret["date_start"] = apiDateFormatter(new Date());
          break;
        case "yestoday":
          ret["date_start"] = apiDateFormatter(
            new Date(new Date() - 24 * 60 * 60 * 1000)
          );
          ret["date_end"] = apiDateFormatter(new Date());
          break;
        case "week":
          ret["date_start"] = apiDateFormatter(startOfWeek(new Date()));
          ret["date_end"] = apiDateFormatter(endOfWeek(new Date()));
          break;
        case "month":
        case "last_month":
        case "period":
          ret["date_start"] = this.date_start;
          ret["date_end"] = this.date_end;
      }
      return ret;
    },

    show_filter() {
      this.filter_show = true;
    },

    handleHide(bvEvent) {
      if (!this.isCloseable) {
        bvEvent.preventDefault();
      } else {
        this.$refs.date_dropdown.hide();
      }
    },

    closeMe() {
      this.isCloseable = true;
      this.$refs.date_dropdown.hide();
    },

    export_data() {
      let url = "/pbx/reports/tariff_calls";
      let data = {
        ...this.$store.getters.current("customer", "entity", "contract"),
        order: "date",
        count: this.$refs.list.count,
        from: 0,
        direction: "desc",
        mode: this.export_mode,
        name: `tariff_calls.${this.export_mode}`,
        ...this.filter_func(),
      };
      this.$store
        .dispatch(
          "requestApi",
          {
            url: url,
            data: data,
            additional_config: {
              responseType: "arraybuffer",
            },
          },
          { root: true }
        )
        .then((resp) => {
          download_responce_ab(resp, `callhistory.${this.export_mode}`);
        });
    },
  },
};
</script>

<style lang='scss'>
$additional_field_length: 150px;
.tarrif-calls {
  form.form-inline.filter {
    justify-content: start;
    .col.d-flex {
      flex-grow: 1;
      width: fit-content;
      max-width: fit-content;
      min-width: fit-content;
      &.additional-filter {
        & > fieldset {
          margin: 0;
          margin-right: 0.5em;
          & > [role="group"] {
            display: flex;
            flex-wrap: nowrap;
            & > * {
              margin-right: 1em;
            }
            .legend {
              min-width: 2em;
              padding-right: 0.5em;
              text-align: right;
            }
          }
        }
      }
    }
  }
  table {
    td.field_from,
    td.field_to {
      div {
        white-space: nowrap;
      }
    }
    td.field_price {
      div {
        text-align: right;
      }
    }
  }
}
</style>