<template>
  <b-button
    v-if="showButton"
    v-bind="{ ...$attrs, ...buttonProps }"
    class="smart-button"
    @click="$emit('click')"
  >
    <slot name="pre" />
    <slot>
      <component :is="osnIcon" v-bind="osnIconProps" v-if="osnIcon" />
      {{ osnPart }}
    </slot>
    <slot name="replace_post">
      <span class="second-part">
        <slot name="post">
          {{ secondPart }}
        </slot>
      </span>
      {{ thirdPart }}
    </slot>
  </b-button>
</template>

<script>
import { BButton } from "bootstrap-vue";
export default {
  name: "SmartButton",
  components: { BButton },
  props: {
    osnPart: {
      type: String,
      required: false,
    },
    osnIcon: {
      type: Object,
      required: false,
    },
    osnIconProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    secondPart: {
      type: String,
      required: false,
    },
    thirdPart: {
      type: String,
      required: false,
    },
    buttonProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    showFunction: {
      type: Function,
      required: false,
      default: () => {
        return true;
      },
    },
  },
  computed: {
    showButton() {
      return this.showFunction(this);
    },
  },
};
</script>

<style lang='scss'>
.smart-button {
  .second-part {
    body.vertical & {
      display: none;
    }
  }
}
</style>