<template>
  <div class="call_status">
    <icon-stack :class="[real_color, 'call-status']">
      <icon :icon="icons.phone" class="call-icon" />
      <icon
        :icon="real_direction"
        style="top: -100%"
        v-if="real_direction !== 'local'"
      />
      <icon :icon="icons.slash" v-if="show_slash" />
    </icon-stack>
  </div>
</template>

<script>
import { Icon, IconStack, as_icon } from "../layot";
import {
  faPhone,
  faArrowLeft,
  faArrowRight,
  faSlash,
} from "@fortawesome/free-solid-svg-icons";
import CellVue from "../mixins/Cell.vue";
export default {
  name: "CallStatus",
  mixins: [CellVue],
  props: {
    direction: {
      required: false,
      default: null,
      type: String,
    },
    color: {
      required: false,
      default: null,
      type: String,
    },
    slashed: {
      required: false,
      default: null,
      type: Boolean,
    },
  },
  components: { Icon, IconStack },
  data() {
    return {
      icons: {
        phone: as_icon(faPhone),
        slash: as_icon(faSlash),
      },
    };
  },
  computed: {
    call() {
      return this.item;
    },
    _direction() {
      if (this.call.direction == "internal") {
        return this.call._raw.direction == "incoming" ? "inner" : "outer";
      } else {
        return this.call.direction == "incoming" ? "inner" : "outer";
      }
    },
    real_direction() {
      const icon_directions = {
        outer: as_icon(faArrowLeft),
        inner: as_icon(faArrowRight),
      };
      if (this.direction !== null) {
        return icon_directions[this.direction];
      }

      return icon_directions[this._direction];
    },
    show_slash() {
      if (this.slahed !== null) {
        return this.slashed;
      }
      return this.call.type_additional == 3;
    },
    real_color() {
      if (this.color !== null) {
        return `text-${this.color}`;
      }
      if (this.call.direction == "local") {
        if (this.call._raw.dialstatus == "ANSWER") {
          return "text-success";
        }
        return "text-danger";
      }

      if (this.call.direction == "outgoing") {
        const color_outgoing = {
          ANSWER: "text-success",
          NOANSWER: "text-warning",
          CANCEL: "text-danger",
        };
        return color_outgoing[this.call._raw.dialstatus] || "text-danger";
      }

      if (
        this.call.direction == "incoming" ||
        this.call.direction == "internal"
      ) {
        const color_incoming = {
          ANSWER: "text-success",
          NOANSWER: "text-danger",
          CANCEL: "text-danger",
        };
        return color_incoming[this.call._raw.dialstatus] || "text-danger";
      }
      return "text-warning";
    },
  },
};
</script>

<style lang='scss'>
.call_status {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 9px;
}
.call-icon {
  font-size: 1.7em;
}
td.field_icon,
th.field_icon {
  width: 4em;
}
</style>