import FinObj from "./FinObj"
import { buildRelatedField } from "../fields"
import { download_from_responce, id_field_desc, resolveWithPromise, type_val } from "@/functions.js";
import LinkComp from "./LinkComp"
import ActFilter from "./ActFilter"
import { cloneDeep, orderBy } from "lodash-es";
import EmptyComp from "./EmptyComp"
import { money_format } from "../../../formatters";

const doc_types = [
  { text: 'Акт сверки', value: '1CAktSverki' },
  { text: 'Акт/Оказание услуг', value: '1COkazUsl' },
  { text: 'Оказание услуг МНМГ', value: '1COkazUslMnMg' },
  { text: 'Расшифровка', value: '1CRashifr' },
  { text: 'Счет', value: '1CSchet' },
  { text: 'Счет-фактура', value: '1CSchetFakt' },
  { text: 'Уведомление', value: '1CUvedomlen' },
  { text: 'Информационное письмо', value: 'InfoDoc1' },
  { text: 'Трафик Телефония', value: 'ReportCalls' },
  { text: 'Трафик IP', value: 'ReportInet' },
]
export { doc_types }

const fetch_archive = ({ getters, dispatch }, { data }) => {
  const url = getters.description.urls.list
  const send_data = getters.pre_data(data, 'list')
  return dispatch(
    'requestApi',
    {
      url,
      data: send_data
    }, { root: true }
  ).then(resp => {
    let ret = { total: 0, items: [] }
    if (resp.data.result == 'success') {
      const items = resp.data.payload.items.map(
        i => getters.post_data(i, 'list')
      )
      ret = {
        items,
        total: resp.data.payload.total
      }
    }
    return ret
  })
}
export { fetch_archive }

const storage = {
  getters: {
    post_data_list: () => (obj) => ({ doc_id: obj.DocumentID }),
    objects: (state, getters, rootState, rootGetters) => {
      let objs = state.objects
      if (getters.description.unique_for) {
        const key = getters.description.unique_for_key || `${getters.description.unique_for}_id`
        const un = rootGetters[`current_${getters.description.unique_for}`]
        objs = objs.filter(o => o[key] == un)
      }
      return orderBy(cloneDeep(objs.map(r => getters.restore_object(cloneDeep(r)))), getters.description.sort_by || 'obj_id', getters.description.sort_order || 'desc')
    },
  },
  actions: {
    list({ getters, commit, dispatch }, data = {}) {
      const conf = data || {}
      const force = conf.force || false
      const list_api = getters.has_api.list
      commit('load_status', 'loading')
      if (!list_api || (!force && Date.now() - getters.last_update < 5000)) {
        commit('load_status', 'success')
        return resolveWithPromise(getters.objects)
      } else if (type_val(list_api) == 'String') {
        return dispatch('requestApi', { url: list_api, data: getters.pre_data({}, 'list') }, { root: true }).then(
          d => {
            if (d.data.result == 'success') {
              commit('clear')
              commit('load_status', 'success')
              commit('load', d.data.payload.items.map(r => getters.post_data(r, 'list')))
              return d.data.payload
            }
            commit('load_status', 'error')
          }
        )
      }
    },
    fetch_document({ dispatch, getters, commit }, { data, name }) {
      const file_name = name
      return dispatch('requestApi', {
        url: getters.description.urls.fetch,
        data: data,
        additional_config: {
          responseType: 'blob'
        }
      }, { root: true }).then(
        (response) => {
          if (response.status == 200) {
            download_from_responce(response, `${file_name}.pdf`)
          } else {
            commit('add_message', { type: 'danger', message: 'не удалось скачать' }, { root: true })
          }
        }
      )
    },
    fetch_package({ dispatch, getters, commit, rootGetters }) {
      const url = getters.description.urls.archive
      const data_to_send = {
        contract_id: rootGetters.current_contract,
        customer_id: rootGetters.current_customer
      }
      return dispatch(
        'requestApi',
        {
          url: url,
          data: data_to_send,
          additional_config: {
            responseType: 'blob'
          }
        }, { root: true }
      ).then(
        (response) => {
          if (response.status == 200) {
            download_from_responce(response, 'file.zip')
          } else {
            commit('add_message', { type: 'danger', message: 'не удалось скачать' }, { root: true })
          }
        }
      )
    },
    fetch_archive
  },
  mutations: {
    clear(state) {
      state.objects = []
    }
  },
}

const act_storage = {
  getters: {
    objects(state, getters, rootState, rootGetters) {
      return rootGetters['bill/objects'].filter(obj => obj.type === "1CAktSverki")
    }
  },
  actions: {
    list({ dispatch }) {
      return dispatch('bill/list', null, { root: true })
    },
    fetch_archive: ({ dispatch }, data) => {
      data.data.filter = [{
        "field": "type",
        "comparison": "eq",
        "value": "1CAktSverki"
      }]
      return dispatch(
        'bill/fetch_archive', data, { root: true }
      )
    }
  }
}

const DOC_DESC = {
  verbose: 'Бухгалтерский документ',
  plural: 'Бухгалтерские документы',
  obj_comp: FinObj,
  objs_comp: FinObj,
  // list_filter_comp: FinFilter,
  storage: storage,
  unique_for: ['contract', 'customer'],
  urls: {
    list: 'contract/accountingRecords',
    fetch: 'contract/fetchDocument',
    archive: 'contract/fetchArchive'
  },
  editable: false,
  empty_comp: EmptyComp,
  sort_by: 'date',
  fields: {
    doc_id: id_field_desc,
    date: {
      label: 'Дата',
      table_formatter: (val) => {
        const date = val.split(' ')[0];
        var ymd = date.split("-");
        return ymd.reverse().join(".");
      },
      sortable: true
    },
    number: { label: 'Номер', sortable: true },
    typeTitle: { label: 'Тип', sortable: true },
    amount: { label: 'Сумма', sortable: true, table_formatter: money_format },
    contract: buildRelatedField('Договор', 'contract'),
    type: { label: "Тип", sortable: true },
    url: { label: 'Скачать файл', cell: LinkComp },
  },
  edit_fields: [],
  view_fields: ['date', 'type', 'url'],
  list_fields: ['date', 'number', 'typeTitle', 'amount', 'url']
}

const ACT_DESC = {
  verbose: 'Акт сверки',
  plural: 'Акты сверки',
  obj_comp: FinObj,
  objs_comp: FinObj,
  list_filter_comp: ActFilter,
  storage: act_storage,
  unique_for: 'contract',
  editable: false,
  empty_comp: EmptyComp,
  fields: {
    doc_id: id_field_desc,
    date: {
      label: 'Дата',
      table_formatter: (val) => {
        const date = val.split(' ')[0];
        var ymd = date.split("-");
        return ymd.reverse().join(".");
      },
      sortable: true
    },
    number: { label: 'Номер', sortable: true },
    typeTitle: { label: 'Тип', sortable: true },
    amount: { label: 'Сумма', sortable: true },
    contract: buildRelatedField('Договор', 'contract'),
    type: { label: "Тип", sortable: true },
    url: { label: 'Скачать файл', cell: LinkComp },
  },
  edit_fields: [],
  view_fields: ['date', 'type', 'url'],
  list_fields: ['date', 'number', 'url']
}

export { DOC_DESC, ACT_DESC }
