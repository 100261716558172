<template>
  <span>{{ real_value }}</span>
</template>

<script>
import { type_val } from "../../functions";
import CellVue from "../mixins/Cell.vue";
export default {
  name: "selectCell",
  mixins: [CellVue],
  props: {
    keyText: {
      required: false,
      type: String,
      default: "text",
    },
    keyValue: {
      required: false,
      type: String,
      default: "value",
    },
  },
  computed: {
    real_value() {
      const field_descr = this.field.field_desc;
      const options = field_descr.options.options;
      let ret = this.value;
      if (options) {
        if (type_val(options[0]) == "Object") {
          const opt_val = options.filter(
            (row) => row[this.keyValue] == this.value
          );
          if (opt_val.length == 1) {
            ret = opt_val[0][this.keyText];
          }
        }
      }
      //const real_value = options.filter(val)
      return ret;
    },
  },
};
</script>

<style>
</style>