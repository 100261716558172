import { actions_field } from '../../../functions'
import { buildPasswordField, buildRelatedField, instance_field } from '../fields'
import LoginCell from './LoginCell'
import { Validator, internalLineLengthValidator, internalLineValidator, positiveValue } from '../../../validators'
import { isNumber } from 'lodash'
import { SERVICE_TYPE_CITY_LINE } from '../ExtLine'

const INTEXT_LINKS = {
	list: `/pbx/services/v2/internalLine/list`,
}
// const STRATEGY_TYPES = {
// 	P: 'Паралелльный',
// 	S: 'Последовательный'
// }

// const calc_transfer = obj => {
// 	let ret = 0
// 	if (obj.transfer_always || obj.transfer_timeout) {
// 		ret = 2
// 	}
// 	if (obj.transfer_always) {
// 		ret = 1
// 	}
// 	return ret
// }

const store = {
	getters: {
		objects: (state, getters, rootState, rootGetters) => {
			let objs = state.objects
			const key = 'customer_id'
			const un = rootGetters['current_customer']
			objs = objs.filter(o => o[key] == un)
			return objs.map(r => getters.restore_object(r))
		},
		unique_for: (state, getters, rootState, rootGetters) => {
			let ret = {}
			const instance_id = rootGetters.current_instance
			if (instance_id) {
				const instance = rootGetters['instance/object'](instance_id)
				if (instance) {
					const namespace = instance.namespace
					ret['namespace'] = namespace
				}
			}
			return ret
		},
	},
}

const INTEXT_OBJ = {
	urls: INTEXT_LINKS,
	obj_key: 'resource_id',
	verbose: 'Линия',
	plural: 'Линии',
	next_new_url: '/intext/',
	next_edit_url: NaN,
	new_default: {},
	delete_key: 'date_stop',
	storage: store,
	unique_for: 'entity',
	obj_name: 'number',
	obj_template: obj => {
		let name = `${obj.login}`
		if (obj._ready) {
			const instance = obj._related('instance_id')
			name = `${obj.login}@${instance.namespace}`
		}
		return name
	},
	//obj_template: template`test ${'number'} ${'entity_id'}`,
	fields: {
		resource_id: {
			label: "ID",
			options: {
				required: true,
				disabled: true
			},
			editable: false
		},
		entity_id: buildRelatedField(
			'Договор', 'entity', {}, { editable: false }
		),
		instance_id: instance_field,
		out: buildRelatedField(
			'Внешняя линий', SERVICE_TYPE_CITY_LINE
		),
		line_number: {
			label: 'Внутренний номер',
			editable: false,
			isKey: true,
			required: true,
			validator: (val, obj) => Validator(
				val, obj, isNumber, internalLineLengthValidator, internalLineValidator, positiveValue
			),
			list_options: { // опциональные папаметры для b-table
				sortable: true
			},
			options: {
				type: 'number',
				min: 0
			},
			description: 'Не может начинаться с единицы'
		},
		login: {
			label: 'Логин',
			cell: LoginCell,
			list_options: { // опциональные папаметры для b-table
				sortable: true,
				// formatter: (value, key, obj) => {
				//     //console.debug('_frmtr', obj, key, value)
				//     const ent = obj._related('entity_id')
				//     const name = obj.obj_name
				//     let ret = name
				//     if (ent._ready) {
				//         ret = `${ent.obj_name}+${name}`
				//     }
				//     return ret
				// }
			}
		},
		password: buildPasswordField('Пароль'),
		// transfer_always: buildRelatedField('Постоянная переадресация', 'internal_line'),
		// transfer_busy: buildRelatedField('Переадресация при занятой линии',  'internal_line'),
		// transfer_timeout: buildRelatedField('Переадресация при таймауте', 'internal_line')
		transfer_always: 'Постоянная переадресация',
		transfer_busy: 'Переадресация при занятой линии',
		transfer_timeout: 'Переадресация при таймауте',
		date_stop: 'Остановлено'
	},
	list_fields: ['line_number', 'login', 'password', actions_field],
	list_fields_search: ['line_number', 'login'],
	new_fields: ['line_number', 'login'],
	edit_fields: [
		'line_number', 'login', 'password', 'out', 'transfer_always', 'transfer_busy', 'transfer_timeout',
		'date_stop'
	],
}
export { INTEXT_LINKS, INTEXT_OBJ }
