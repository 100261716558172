import { StuffProfile, UserList } from './User'
import { EntityObj, EntityList } from './Entity'
import { ServiceObj, ServiceList } from './Service'
import { Intext, IntextList } from './IntLine'
import { CustomerList, CustomerObj } from './Customer'
import { EmployeeList, EmployeeObj } from './Employee'
import { InstanceList, InstanceObj } from './Instance'
import Obj from './Obj'
import ObjList from './ObjList'
import * as calls_descs from '../calls/description'
import * as int_line from './InternalLine/description'
import * as ext_line from './ExtrenalLine/description'
import ArchiveList from './ArkhiveList'

const COMPONENTS = {
    // user: [StuffProfile, UserList],
    // entity: [EntityObj, EntityList],
    // // service: [ServiceObj, ServiceList],
    // internal_line: [Intext, IntextList],
    // customer: [CustomerObj, CustomerList],
    // employee: [EmployeeObj, EmployeeList],
    // instance: [InstanceObj, InstanceList]
    user: [Obj, ObjList],
    entity: [Obj, ObjList],
    // service: [ServiceObj, ServiceList],
    internal_line: [Obj, ObjList],
    customer: [Obj, ObjList],
    employee: [Obj, ObjList],
    instance: [Obj, ObjList]
}

export {
    StuffProfile, UserList, CustomerList,
    EntityObj, EntityList, EmployeeList,
    ServiceObj, ServiceList, InstanceList,
    Intext, IntextList, CustomerObj, EmployeeObj, InstanceObj,
    COMPONENTS, calls_descs, ext_line, int_line, ArchiveList, Obj, ObjList
}

