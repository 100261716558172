
export default {
  name: 'routing',
  namespaced: true,
  state: () => ({
    has_positions: false,
    has_transform: false,
    transform: { k: 0, x: 0, y: 0 },
    created_node: null,
    nodes_postions: {}
  }),
  getters: {
    position: (state) => node_id => state.nodes_postions[node_id] || [0, 0],
    zoom: (state) => state.transform,
    has_positions: state => state.has_positions,
    has_transform: (state) => state.has_transform,
    created_node: state => state.created_node,

  },
  mutations: {
    reset: (state) => {
      state.nodes_postions = {}
      state.created_node = null,
        state.has_positions = false
      state.has_transform = false
    },
    reset_created: (state) => state.created_node = null,
    position: (state, { id, position }) => {
      state.has_positions = true
      state.nodes_postions[id] = position
    },
    zoom: (state, transform) => {
      state.has_transform = true
      state.transform = transform
    },
    set_created: (state, node) => {
      state.created_node = node
    },
  },
  actions: {
    position: ({ commit }, { id, position }) => commit('position', { id, position }),
    zoom: ({ commit }, transform) => commit('zoom', transform)
  }
}