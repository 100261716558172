const defHandler = (event) => console.log('handle event', event)

function processValue(value) {
  const isFunction = typeof value === 'function';
  const ret = {
    handler: isFunction ? value : value.handler || defHandler,
    exclude: isFunction ? [] : value.exclude || []
  }
  return ret
}


export default {
  bind: function (el, binding) {
    const { handler, exclude } = processValue(binding.value);
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children

      if (!(el == event.target || el.contains(event.target) || exclude.some(el2 => el2 == event.target || el2.contains(event.target)))) {
        // and if it did, call method provided in attribute value
        handler(event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },

  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
}