<template>
  <div class="objects-list">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Pbx",

  data() {
    return {

    }
  },

  methods: {
  },
};
</script>

<style  lang='scss'>

</style>