import CalcFilter from "./CalcFilter"
import { id_field_desc, resolveWithPromise, type_val } from "@/functions.js";
import { cloneDeep, orderBy } from "lodash-es";
import { money_format } from "../../../formatters";
import { fetch_archive } from './doc_description'
import EmptyComp from "./EmptyComp"

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

const storage = {
  getters: {
    post_data_list: () => () => ({ calc_id: uuidv4() }),
    objects: (state, getters, rootState, rootGetters) => {
      let objs = state.objects
      if (getters.description.unique_for) {
        const key = getters.description.unique_for_key || `${getters.description.unique_for}_id`
        const un = rootGetters[`current_${getters.description.unique_for}`]
        objs = objs.filter(o => o[key] == un)
      }
      return orderBy(cloneDeep(objs.map(r => getters.restore_object(cloneDeep(r)))), getters.description.sort_by || 'obj_id', getters.description.sort_order || 'desc')
    },
  },
  actions: {
    list({ getters, commit, dispatch }) {
      const list_api = getters.has_api.list
      commit('load_status', 'loading')
      if (!list_api || Date.now() - getters.last_update < 5000) {
        commit('load_status', 'success')
        return resolveWithPromise(getters.objects)
      } else if (type_val(list_api) == 'String') {
        return dispatch('requestApi', { url: list_api, data: getters.pre_data({}, 'list') }, { root: true }).then(
          d => {
            if (d.data.result == 'success') {
              commit('clear')
              commit('load_status', 'success')
              commit('load', d.data.payload.items.map(r => getters.post_data(r, 'list')))

              return d.data.payload
            }
            commit('load_status', 'error')
          }
        )
      }
    },
    fetch_archive
  },
  mutations: {
    clear(state) {
      state.objects = []
    }
  },
}

const CALC_DESC = {
  verbose: 'Акт',
  plural: 'Акты',
  // obj_comp: FinObj,
  // objs_comp: FinObj,
  list_filter_comp: CalcFilter,
  storage: storage,
  unique_for: ['contract', 'customer'],
  urls: {
    list: 'contract/calculations'
  },
  editable: false,
  empty_comp: EmptyComp,
  sort_by: 'date',
  fields: {
    calc_id: id_field_desc,
    date: {
      label: 'Дата',
      table_formatter: (val) => {
        const date = val.split(' ')[0];
        var ymd = date.split("-");
        return ymd.reverse().join(".");
      },
      sortable: true
    },
    number: { label: 'Номер', sortable: true },
    description: { label: 'Расшифровка', sortable: true },
    type: { label: 'Тип', sortabel: true },
    // contract: buildRelatedField('Договор', 'entity'),
    payment: { label: 'Оплачено', sortable: true, table_formatter: money_format },
    bill: { label: 'Начислено', sortable: true, table_formatter: money_format },
    balance: { label: 'Баланс', sortable: true },
  },
  edit_fields: [],
  view_fields: ['date', 'number', 'type', 'description', 'payment', 'bill'],
  list_fields: ['number', 'date', 'type', 'description', 'payment', 'bill']
}

export { CALC_DESC }
