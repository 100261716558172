<template>
  <b-form
    :inline="inline"
    :class="[classes, `object-form-${obj_type || 'unknown'}`, `mode-${mode}`]"
    v-bind="form_bind"
  >
    <slot name="pre" />
    <component
      :is="get_rowcomponent(field)"
      v-for="(field, index) in shown_fields()"
      v-bind:key="index"
      :label="get_label(field)"
      v-bind="row_props(field)"
    >
      <template class="" #label>
        {{ get_label(field) }}
        <obit-icon
          icon="question"
          v-if="field_description(field).question"
          class=""
          v-b-tooltip.hover.v-light="field_description(field).question"
        />
      </template>
      <slot
        :name="'field(' + field + ')'"
        v-if="field !== break_form_field"
        :field="field"
        :description="field_description(field)"
        :item="object"
        :target="get_target(field)"
        :value="object[get_target(field)]"
      >
        <component
          v-bind:is="get_component(field)"
          v-model="object[get_target(field)]"
          v-bind="field_options(field)"
          :state="has_errors(field)"
          @input="update_obj(get_target(field), $event)"
          :ref="`field[${field_key(field)}]`"
        />
      </slot>
      <b-form-text v-if="field_desc(field)">
        {{ field_desc(field) }}
      </b-form-text>
      <b-form-invalid-feedback :state="has_errors(field)">
        <span v-for="(err, indx) in get_errors(field)" v-bind:key="indx">
          {{ err }}<br />
        </span>
      </b-form-invalid-feedback>
    </component>
    <slot />
  </b-form>
</template>

<script>
import { Icon, library } from "../layot/FontAwesomeIcon";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { METHODS_MIX, COMPUTED_MIX, OBJECT_MODES } from "./WithDescription";
import { BreakForm } from "../layot";
import { break_form_field } from "../objects/fields";
import { build_default } from "../../object";
import { BFormGroup, BFormRow } from "bootstrap-vue";
import { type_val } from "../../functions";
import ObitIcon from "../obit/ObitIcon";
import { DEFAULT_LINE_PROPS } from "../../constants";

library.add(faPlus, faTrash);
export default {
  name: "BaseForm",
  components: {
    BreakForm,
    Icon,
    ObitIcon,
  },
  props: {
    autocomplete_off: {
      required: false,
      default: false,
      type: Boolean,
    },
    mode: {
      default: "view",
      type: String,
      validator: (value) => OBJECT_MODES.indexOf(value) >= 0,
    },
    limitFields: {
      required: false,
      type: Array,
    },
    withoutLabels: {
      required: false,
      type: Boolean,
    },
    inline: {
      required: false,
      type: Boolean,
      default: false,
    },
    classes: {
      required: false,
      type: Object,
      default() {
        return {};
      },
    },
    replace_fields: {
      required: false,
      type: Array,
      default: () => [],
    },
    line_props: {
      required: false,
      type: Object,
      default: undefined,
    },
    raw: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.mode === "new") {
      this.$nextTick(() => {
        for (const [key, val] of Object.entries(this.build_default())) {
          this.update_obj(key, val);
        }
      });
    }
  },
  provide() {
    return {
      __obj: this.object,
      update_obj: this.update_obj,
    };
  },
  computed: {
    ...COMPUTED_MIX,
    default_value() {
      // значение по-умолчан
      return Object.assign(
        {},
        this.description?.new_default ?? build_default(this.description)
      );
    },
    row_component() {
      return this.withoutLabels ? BFormRow : BFormGroup;
    },
    break_form_field: () => break_form_field,
    form_bind() {
      let ret = {};
      if (this.autocomplete_off) {
        ret["autocomplete"] = "off";
      }
      return ret;
    },
  },
  methods: {
    ...METHODS_MIX,
    build_default() {
      let obj = {};
      this.fields.map((field) => {
        let desc = this.field_description(field);
        if (desc.default) {
          obj[field] = desc.default;
        } else if (field == "customer_id") {
          obj["customer_id"] = this.$store.state.customer;
        }
      });
      //console.debug('builded default', this.fields, obj)
      return obj;
    },
    calc_options: () => {
      return {};
    }, //вычисляемы опции для поля
    reset: function () {
      this.object = Object.assign({}, this.old_object); // cброс
    },
    get_rowcomponent(field) {
      return field !== break_form_field ? this.row_component : BreakForm;
    },
    add_resourse(field) {
      const res = this.field_description(field);
      const target = this.get_target(field);
      if (!this.object[target]) {
        this.object[target] = [];
      }
      this.object[target].push(res.default || "");
      this.$forceUpdate();
    },
    row_props(field) {
      const form_props = this.description.row_props ?? null;
      const field_props = this.field_description(field).row_props ?? null;
      const ret =
        field_props ?? form_props ?? this.line_props ?? DEFAULT_LINE_PROPS;
      const class_name = type_val(field) == "Object" ? field.target : field;
      return { class: [class_name], ...ret };
    },
    field_desc(field) {
      return this.field_description(field).description;
    },
    remove_resource(field, indx) {
      const target = this.get_target(field);
      this.object[target] = this.object[target].filter((row, i) => i != indx);
      this.$forceUpdate();
    },
    update_obj(target, value, emit = true) {
      if (this.mode != "view") {
        this.edited_obj[target] = value;
        if (emit) this.$emit("change_target", { target: target, value: value });
        if (this.raw) this.$emit("input", this.object);
        this.$forceUpdate();
      }
    },
    shown_fields() {
      return this.fields.filter((row) => this.show_field(row));
    },
    _save() {
      const r = this.validate();
      if (r) {
        this.update_data();
      }
      return r;
    },
  },
};
</script>

<style lang='scss'>
@import "../../../static/SCSS/obit-colors.scss";
.mode-view legend.col-form-label {
  font-size: 14px;
  color: $obit-darkgray;
}
</style>