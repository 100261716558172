<script>
import ObjList from '../ObjList'
import { eventBus } from '../..'
export default {
    name: 'CustomerList',
    mixins: [ObjList],
    computed: {
        obj_type: () => 'customer',
    },
    mounted() {
        eventBus.$on('updatedCustomer', this.$forceUpdate())
    },
    beforeDestroy() {
        eventBus.$off('updatedCustomer')
    }
}
</script>