var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.real_show),expression:"real_show"}],class:[
    `${_vm.config.objType}-node`,
    'node',
    {
      'node-hover': _vm.hover,
      'node-new': _vm.node_new > 0,
      'node-search': _vm.node_search,
    },
  ],attrs:{"no-body":""},on:{"mouseover":function($event){return _vm.isHover(true)},"mouseleave":function($event){return _vm.isHover(false)}}},[_vm._v(" "+_vm._s(_vm.get_data(_vm.node.data))+" "),_c('b-card-title',{staticClass:"title"},[_vm._l((_vm.inputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],key:output.key,class:['input-socket', output.key],attrs:{"type":"input","socket":output.socket}})}),_vm._v("Голосовое меню "),_c('obj-modal',{staticClass:"settings",attrs:{"buttonProps":{
        variant: 'outline',
        class: 'border-0',
      },"obj_id":_vm.service.service_id,"obj_type":"ivr","noDelete":""},on:{"click":function($event){_vm.modal_show = true}},scopedSlots:_vm._u([{key:"btnContent",fn:function(){return [_c('icon',{attrs:{"icon":_vm.icons.gear}})]},proxy:true},{key:"form",fn:function(){return [_c('b-form-group',{attrs:{"label":"Кнопки","label-cols":"3"}},[_c('b-checkbox-group',{staticClass:"ivr-buttons",attrs:{"buttons":""},model:{value:(_vm.enabled_outputs),callback:function ($$v) {_vm.enabled_outputs=$$v},expression:"enabled_outputs"}},_vm._l((_vm.btn_options),function(o){return _c('b-checkbox',{key:o.order,class:[`b-${o.order}`],attrs:{"button-variant":"outline-primary","disabled":_vm.output_active(o.val),"value":o.val}},[_vm._v(" "+_vm._s(o.val)+" ")])}),1)],1)]},proxy:true}])})],2),_c('b-modal',{attrs:{"title":"Редактирование"},model:{value:(_vm.modal_show),callback:function ($$v) {_vm.modal_show=$$v},expression:"modal_show"}},[_vm._l((_vm.controls()),function(control,_i){return _c('div',{directives:[{name:"control",rawName:"v-control",value:(control),expression:"control"}],key:_i,staticClass:"control"})}),_c('b-form')],2),(_vm.service)?_c('h2',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.service.obj_name)+" ")]):_vm._e(),_c('h2',{staticClass:"name file"},[_c('media-name-cell-vue',{attrs:{"item":_vm.service,"related":"","value":_vm.service.player,"field":{
        key: 'hello',
        field_desc: {
          target: 'hello',
        },
      },"index":0}})],1),_c('h2',{staticClass:"name file"},[_c('media-name-cell-vue',{attrs:{"item":_vm.service,"related":"","value":_vm.service.player,"field":{
        key: 'prompt',
        field_desc: {
          target: 'prompt',
        },
      },"index":0}})],1),_vm._l((_vm.outputs()),function(output){return _c('div',{key:output.key,staticClass:"output-line"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_output(output)),expression:"show_output(output)"}],staticClass:"out-ivr"},[_c('span',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm._f("human_name")(output.key)))]),_c('Socket',{directives:[{name:"socket",rawName:"v-socket:input",value:(output),expression:"output",arg:"input"}],class:['output-socket', output.key],attrs:{"type":"output","socket":output.socket}})],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }