import { BFormRadioGroup, BFormSelect } from "bootstrap-vue";
import template from '@/template'
// import {actions_field} from '../functions'

const SEX_OPTIONS = [
    { value: 0, text: 'М' },
    { value: 1, text: 'Ж' },

]
const USER_LINKS = {
    //get: 'users/info',
    update: 'users/update',
    add: 'users/register',
    delete: 'users/close',
    // list: 'user/list',
}

const USER_OBJ = {
    urls: USER_LINKS, // ссылки 
    obj_key: 'user_id', // ключевой параметр
    obj_name: 'login', // ключ для репрезентации
    verbose: 'Пользователь', // название 
    plural: 'Пользователи', // название мн. число
    next_new_url: '/user/', // ссылка после создания
    next_edit_url: NaN,
    obj_template: template`${'firstName'} ${'lastName'}`,
    new_default: {}, // значение по-умолчанию.
    delete_key: 'deleted',
    fields: { // описание полей
        login: {
            label: 'Логин пользователя',  // название
            description: 'Логин пользователя для входа в систему',
            options: { // опциональные параметры для компонента
                placeholder: 'Обязательно',
            },
            list_options: { // опциональные папаметры для b-table
                sortable: true
            }
        },
        password: {
            label: "Пароль",
            options: {
                type: 'password',
            }
        },
        firstName: "Имя",
        middleName: "Отчество",
        lastName: 'Фамилия',
        mobile: {
            label: "Номер телефона",
            options: {
                type: 'tel'
            }
        },
        email: {
            label: "EMail",
            options: {
                type: 'email'
            }
        },
        sex: {
            label: 'Пол',
            comp: BFormSelect,
            options: { options: SEX_OPTIONS }
        }
    },
    list_fields: [  //  поля для списка
        'first_name',
        { label: 'Контакты', key: 'contacts' },
        { label: 'Доб. номер', key: 'inner_number' },
        { label: 'Роль', key: 'role' },
        { label: 'Статус', key: 'status' },
        // actions_field
    ],
    list_fields_search: ['login', 'email', 'lastName', 'firstName', 'middleName', 'mobile'], // поля для поиска в списке
    view_fields: [
        'firstName', 'middleName', 'lastName',
        'mobile', 'email', 'sex'
    ],
    edit_fields: [ // поля для редактирования
        'firsName', 'middleName', 'lastName',
        'mobile', 'email'
    ],
    new_fields: [ // для создания
        'firstName', 'middleName', 'lastName',
        'mobile', 'email'
    ]
}
const type_options = [
    { text: 'Админ', value: 'admin' },
    { text: 'Оператор', value: 'operator' },
]
const perm_options = [
    { text: 'Нет доступа', value: null },
    { text: 'Просмотр', value: 'view' },
    { text: 'Администрирование', value: 'edit' },
]
const PERM_OBJ = {
    name: 'Доступы',
    fields: {
        type: {
            label: 'Доступы к разделам',
            comp: BFormRadioGroup,
            options: { options: type_options, buttons: true, 'button-variant': 'outline-primary' }
        },
        calls: {
            label: 'Звонки',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        route: {
            label: 'Маршрутизация',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        users: {
            label: 'Пользователи',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        intext: {
            label: 'Внутренние номера',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        group: {
            label: 'Группы',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        address_book: {
            label: 'Адресная книга',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        tarrifs: {
            label: 'Тарифв',
            comp: BFormSelect,
            options: { options: perm_options }
        },
        settings: {
            label: 'Настройки',
            comp: BFormSelect,
            options: { options: perm_options }
        },
    }
}

export { PERM_OBJ, USER_LINKS, USER_OBJ, SEX_OPTIONS }