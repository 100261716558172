<template>
  <div>
    <b-form inline class="margform">
      <!-- <b-form-radio-group label=" ">
        <b-radio-group
          :options="call_types"
          buttons
          button-variant="outline-primary"
        />
      </b-form-radio-group> -->
      <b-form-radio-group
        buttons
        v-model="period"
        :options="period_options"
        button-variant="outline-primary"
      />
      <date-picker
        v-model="date"
        v-if="period != 'today'"
        @input="update_date"
      />
      <!-- <b-button
        variant="outline-obit"
        style="margin-left: 8px; margin-right: 8px"
        v-if="reset"
        @click="reset_dates()"
        ><icon :icon="icons.reset"
      /></b-button> -->
      <!-- <b-button variant="outline-obit" class="leftmargin" @click="apply_filter">
        ПРИМЕНИТЬ</b-button
      > -->
    </b-form>
  </div>
</template>

<script>
import DatePicker from "../components/objects/Bill/DatePicker.vue";
import {
  format,
  endOfDay,
  endOfMonth,
  endOfToday,
  endOfWeek,
  startOfDay,
  startOfMonth,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { parseISO } from "date-fns/esm/fp";
import { datetimeformatter } from "../formatters";

export default {
  name: "DashFilter",
  components: {
    DatePicker,
  },
  mounted() {
    this.start = datetimeformatter(startOfToday());
    this.end = datetimeformatter(endOfToday());
    this.$watch("period", (to) => {
      if (to == "today") {
        this.start = datetimeformatter(startOfToday());
        this.end = datetimeformatter(endOfToday());
      } else {
        this.reset = true;
        const funcs = {
          days: {
            start: startOfDay,
            end: endOfDay,
          },
          weeks: {
            start: startOfWeek,
            end: endOfWeek,
          },
          months: {
            start: startOfMonth,
            end: endOfMonth,
          },
        };
        this.start = datetimeformatter(
          funcs[this.period]["start"](parseISO(this.date))
        );
        this.end = datetimeformatter(
          funcs[this.period]["end"](parseISO(this.date))
        );
      }
    });
    this.apply_filter();
    // this.start = startOfToday();
    // this.end = endOfToday();
  },
  data() {
    return {
      start: null,
      end: null,
      period: "today",
      date: null,
      reset: false,
    };
  },
  created() {
    this.date = format(new Date(), "yyyy-MM-dd");
  },
  computed: {
    period_options: () => [
      { value: "today", text: "Сегодня" },
      { value: "days", text: "День" },
      { value: "weeks", text: "Неделя" },
      { value: "months", text: "Месяц" },
    ],
    call_types: () => [
      { value: null, text: "Все" },
      { value: "extrenal", text: "Внешние" },
      { value: "internal", text: "Внутренние" },
    ],
  },
  updated() {
    this.apply_filter();
  },
  methods: {
    show_reset_button() {
      if (this.start || this.end) {
        return true;
      }
      return false;
    },
    reset_dates() {
      this.date = null;
      this.period = "today";
      this.reset = false;
      this.start = datetimeformatter(startOfToday());
      this.end = datetimeformatter(endOfToday());
      this.$emit("apply_filter", {
        start: this.start,
        end: this.end,
        period: this.period,
      });
    },
    apply_filter() {
      this.$emit("apply_filter", {
        start: this.start,
        end: this.end,
        period: this.period,
      });
    },
    update_date(to) {
      const funcs = {
        days: {
          start: startOfDay,
          end: endOfDay,
        },
        weeks: {
          start: startOfWeek,
          end: endOfWeek,
        },
        months: {
          start: startOfMonth,
          end: endOfMonth,
        },
      };
      this.reset = true;
      this.start = datetimeformatter(funcs[this.period]["start"](parseISO(to)));
      this.end = datetimeformatter(funcs[this.period]["end"](parseISO(to)));
    },
  },
};
</script>

<style scoped>
.leftmargin {
  margin-left: 8px;
}
.margform > * {
  margin: 10px;
  border-spacing: 7px 11px;
}
.btn-filter {
  color: #3e3e3e;
  background-color: white;
  border-color: #ededed;
  padding: 12px 24px;
  border-radius: 6px;
  border: 1px solid #ced4da;
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.btn-filter:hover {
  background-color: #54bae7 !important;
  border-color: #54bae7 !important;
  color: white !important;
}
.btn-filter:focus {
  background-color: #54bae7 !important;
  border-color: #54bae7 !important;
  color: white !important;
}
</style>