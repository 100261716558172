<template>
  <b-checkbox v-model="val" :value="500" :unchecked-value="0" />
</template>
<script>
import { InputMixin } from "../../mixins";
export default {
  mixins: [InputMixin],
};
</script>

<style>
</style>