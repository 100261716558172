<template>
  <list
    :objects="objects"
    :description="description"
    :fields="fields"
    :methods="methods"
  >
    <template v-slot:cell(first_name)="obj">
      <router-link
        :to="{ name: 'object', params: { type: 'user', id: obj.item.user_id } }"
        class="no-margin text-dark"
      >
        {{ obj.item.first_name }} {{ obj.item.last_name }}
        <icon icon="user" v-if="obj.item.user_id == current_user_id" />
      </router-link>
      <p class="no-margin text-muted">
        {{ obj.item.position || "Должность не указана" }}
      </p>
    </template>
    <template v-slot:cell(contacts)="obj" :formatter="phone_formatter">
      <p class="no-margin" v-if="obj.item.mobile_phone">
        {{ phone_formatter(obj.item.mobile_phone) }}
      </p>
      <p class="text-muted no-margin" v-if="obj.item.email">
        {{ obj.item.email }}
      </p>
    </template>
    <template v-slot:cell(inner_number)="obj">
      {{ add_numbers[obj.item.user_id] }}
    </template>
    <template v-slot:cell(status)="obj">
      <span v-if="obj.item.deleted" class="text-danger"> Не активен </span>
      <span v-else class="text-success"> Активен </span>
    </template>
  </list>
</template>
<script>
import ObjTable from "../ObjTable";
import { Icon } from "../../layot";
import { number_formatter } from "../../../formatters";

export default {
  name: "UTable",
  components: { Icon },
  mixins: [ObjTable],
  data() {
    return {
      current_user_id: this.$store.state.user.user_id,
      add_numbers: {},
    };
  },
  methods: {
    phone_formatter: number_formatter,
  },
};
</script>

<style scoped>
p.no-margin {
  margin: 0;
}
</style>