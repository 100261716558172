<template>
  <b-overlay :show="wait">
    <b-input-group>
      <multiselect
        v-model="fake_val"
        :options="ops"
        :multiple="multiple"
        :disabled="disabled"
        v-bind="select_options"
        :taggable="add_select"
        @tag="addTag($event)"
        class="select2"
        ref="select"
      >
        <template v-slot:noOptions>
          <p>Нет вариантов</p>
        </template>
      </multiselect>
      <b-input-group-append
        v-if="related && !disabled && addButton"
        @click="wait = true"
      >
        <obj-modal
          obj_id="new"
          :obj_type="related"
          mode="new"
          :fields="new_fields"
          @request="catch_request"
          @close="wait = false"
          :modalProps="rModalProps"
        >
          <template #default="{ show_modal }">
            <b-button
              variant="outline-obit"
              class="border-0"
              @click="show_modal"
            >
              <icon :icon="icons.plus" />
            </b-button>
          </template>
        </obj-modal>
      </b-input-group-append>
    </b-input-group>
  </b-overlay>
</template>

<script>
import { type_val } from "../../functions";
import { isEqual, cloneDeep, merge } from "lodash-es";
import Multiselect from "vue-multiselect";
import { Icon, as_icon } from ".";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ObjModal from "../objects/ObjModal.vue";
import { OBJ_COMPONENTS } from "../../object";

export default {
  name: "CustomSelect",
  components: {
    Multiselect,
    Icon,
    ObjModal,
  },
  mounted() {
    if (this.multiple && this.add_select) {
      this.mounted_value = cloneDeep(this.value);
    }
    this.$watch(() => JSON.stringify(this.opts), this.$forceUpdate);
    this.$watch("disabled", this.$forceUpdate);
    this.$watch(() => JSON.stringify(this._obj), this.$forceUpdate);
    if (
      this.wait_for &&
      type_val(this.wait_for) == "Array" &&
      this.wait_for.length
    ) {
      for (const w of this.wait_for) {
        this.$store.dispatch(`${w}/list`);
      }
    }
    if (this.defaultFirstAvailable && this.related) {
      if (!this.fake_val) {
        this.fake_val = this.$store.getters[`${this.related}/objects`][0];
      }
    }

    // this.mounted = true;
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    default: {
      type: Function,
      required: false,
      default: null,
    },
    defaultFirstAvailable: {
      type: Boolean,
      required: false,
      default: false,
    },
    value: {
      required: true,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    related: {
      required: false,
      type: String,
    },
    addButton: {
      required: false,
      default: false,
      type: Boolean,
    },
    options: {
      required: false,
      default: () => [],
      type: [Function, Object, Array],
    },
    add_select: {
      required: false,
      default: false,
      type: Boolean,
    },
    list_key: {
      required: false,
      default: "value",
      type: [String, Function],
    },
    list_text: {
      required: false,
      default: "text",
      type: [String, Function],
    },
    modalProps: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    multiple: {
      required: false,
      type: Boolean,
    },
    wait_for: {
      required: false,
      type: Array,
      default: null,
    },
    config: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    field_desc: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    new_fields: {
      required: false,
      default: () => [],
      type: Array,
    },
    _obj: {
      required: false,
      type: Object,
      default: () => ({}),
    },
    parent: {
      required: false,
      type: Object,
      default: null,
    },
    no_clear: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: "",
      show_list: false,
      mounted: false,
      wait: false,
      mounted_value: null,
    };
  },
  computed: {
    icons: () => ({
      plus: as_icon(faPlus),
    }),
    rModalProps() {
      return merge({ static: true }, this.modalProps);
    },
    select_options() {
      return Object.assign(
        {
          "track-by": this.list_key,
          label: this.list_text,
          "allow-empty": !this.required,
          placeholder: this.field_desc.placeholder || "Выберите",
          selectLabel: "Нажмите ENTER для выбора",
          selectGroupLabel: "Нажмите ENTER для выбора группы",
          selectedLabel: "Выбрано",
          deselectLabel: "Нажмите ENTER для отмены выбора",
          deselectGroupLabel: "Нажмите ENTER для отмены выбора группы",
        },
        this.config
      );
    },
    is_empty() {
      return this.multiple
        ? this.fake_val.length == 0
        : this.fake_val === undefined;
    },
    opts() {
      let ret = {};
      if (this.multiple) {
        ret = this.fake_val.reduce(
          (r, row) =>
            Object.assign(r, {
              [this.get_hashable(row, true)]: true,
            }),
          {}
        );
      } else {
        ret = { [this.get_hashable(this.fake_val, true)]: true };
      }
      return ret;
    },
    ops() {
      let ret = [];
      if (type_val(this.options) == "Object") {
        ret = this.options.map((row) =>
          Object.assign(
            {},
            {
              value: row,
              text: row,
            }
          )
        );
      } else if (type_val(this.options) == "Function") {
        ret = this.options();
      } else if (this.related) {
        ret = this.$store.getters[`${this.related}/objects`];
      } else {
        ret = this.options || [];
      }
      return ret;
    },
    fake_val: {
      get() {
        let ret;
        let val = this.value;
        if (type_val(val) == "String") {
          val = val.toLowerCase();
        }
        if (this.multiple) {
          ret = cloneDeep(this.value || []);
          val = this.value || [];
          // выправим для объектв
          if (this.related) {
            let vals = val;
            ret = this.ops.filter((r) => {
              let o = this.get_value(r);
              return vals.indexOf(o) != -1;
            });
            // manage tags
            if (this.no_clear) {
              let missing_objects = [];
              const ops_dict = this.ops.reduce(
                (r, v) => ({ ...r, [v[this.list_key]]: v }),
                {}
              );
              for (const v of vals) {
                if (!ops_dict[v]) {
                  missing_objects.push({
                    [this.list_key]: v,
                    [this.list_text]: v,
                    obj_name: v,
                  });
                }
              }
              ret = ret.concat(missing_objects);
            }
            return ret;
          } else {
            ret = this.ops.filter((r) => {
              return val.indexOf(this.get_value(r)) != -1;
            });
          }
        } else {
          if (val === null || this.value === undefined) {
            return val;
          }
          if (type_val(val) == "Object") {
            ret = Object.entries(this.value).reduce(
              (r, [k, v]) => Object.assign(r, { [k]: v || undefined }),
              {}
            );
            if (Object.values(ret).every((r) => r === undefined)) {
              ret = undefined;
            }
          } else {
            ret = this.ops.find((o) => this.get_value(o) == val);
          }
        }
        return ret;
      },
      set(val) {
        // выправим, покольку мультиселек выдает объекты целиком
        let ret;
        if (this.multiple) {
          ret = val.map((r) => this.get_value(r));
        } else {
          ret = this.get_value(val);
        }
        this.$emit("input", ret);

        this.$nextTick(() => this.$forceUpdate());
      },
    },
  },
  methods: {
    addTag(val) {
      let ret;
      if (type_val(this.options) == "Object") {
        ret = this.options.map((row) =>
          Object.assign(
            {},
            {
              value: row,
              text: row,
            }
          )
        );
      } else if (this.related) {
        ret = {
          list_text: val,
          list_key: val,
          obj_name: val,
        };
        ret[this.list_key] = val;
        ret[this.list_text] = val;
      } else {
        ret = this.options || [];
      }
      this.ops.push(ret);
      this.fake_val = this.fake_val.concat([ret]);
    },
    catch_request(req) {
      const descr = OBJ_COMPONENTS[this.related];
      const obj_id = req[descr.obj_key];
      this.fake_val = this.$store.getters[`${this.related}/object`](obj_id);
      this.wait = false;
    },
    _get(row, key) {
      let ret = row;
      if (row === undefined) return ret;
      const _key = this[key];
      if (type_val(_key) == "String") {
        if (this.related && this.multiple) {
          ret = row[_key];
        } else {
          ret = row[_key].toLowerCase();
        }
      } else if (type_val(_key) == "Function") {
        ret = _key(row);
      }
      return ret;
    },
    get_value(row) {
      return this._get(row, "list_key");
    },
    get_text(row) {
      return this._get(row, "list_text");
    },
    get_hashable(row, raw = false) {
      let ret = raw ? row : this.get_value(row);
      if (type_val(ret) == "Object") {
        ret = JSON.stringify(ret);
      }
      return ret;
    },
    compareItems(item1, item2) {
      const i1 = this.get_value(item1);
      const i2 = this.get_value(item2);
      return isEqual(i1, i2);
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang='scss'>
@import "../../../static/SCSS/obit-colors.scss";
.multiselect {
  &.select2 {
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    margin-top: 0px;
  }
  &.multiselect--disabled .multiselect__current,
  &.multiselect--disabled .multiselect__select {
    color: $obit-primary;
    background-color: transparent;
  }
  .multiselect__tags-wrap {
    .multiselect__tag {
      background-color: $obit-primary;
      .multiselect__tag-icon:focus,
      .multiselect__tag-icon:hover {
        background: $obit-danger;
      }
    }
  }
  .multiselect__option {
    &.multiselect__option--highlight {
      background: white;
      outline: none;
      color: $obit-warning;
      &::after {
        background: white;
        color: $obit-warning;
      }
    }
    &.multiselect__option--selected {
      background-color: $obit-primary;
      color: white;
      &::after {
        background: $obit-primary;
        color: white;
      }
      &.multiselect__option--highlight {
        background: $obit-danger;
        outline: none;
        color: white;
        &::after {
          background: $obit-danger;
          color: white;
        }
      }
    }
  }
}
</style>
