var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.real_show),expression:"real_show"}],class:[
    `${_vm.config.objType}-node`,
    'node',
    {
      'node-hover': _vm.hover,
      'node-new': _vm.node_new > 0,
      'node-search': _vm.node_search,
    },
  ],attrs:{"no-body":""},on:{"mouseover":function($event){return _vm.isHover(true)},"mouseleave":function($event){return _vm.isHover(false)}}},[_vm._l((_vm.inputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:output",value:(output),expression:"output",arg:"output"}],key:output.key,class:['input-socket', output.key],attrs:{"type":"input","socket":output.socket}})}),_vm._v(" "+_vm._s(_vm.get_data(_vm.node.data))+" "),_c('b-card-title',{staticClass:"title"},[_vm._v("SIP Входящий звонок ")]),(_vm.service)?_c('h2',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm._f("phone")(_vm.service.obj_name))+" ")]):_vm._e(),_vm._l((_vm.outputs()),function(output){return _c('Socket',{directives:[{name:"socket",rawName:"v-socket:output",value:(output),expression:"output",arg:"output"}],key:output.key,class:['output-socket', output.key],attrs:{"type":"output","socket":output.socket}})}),_c('b-modal',{attrs:{"title":"настройки"},model:{value:(_vm.modal_show),callback:function ($$v) {_vm.modal_show=$$v},expression:"modal_show"}},_vm._l((_vm.controls()),function(control,_i){return _c('div',{directives:[{name:"control",rawName:"v-control",value:(control),expression:"control"}],key:_i,staticClass:"control"})}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }