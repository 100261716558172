<template>
  <fragment>
    <b-form-checkbox :disabled="disabled"
      >Автоматически добавить праздники России</b-form-checkbox
    >
    <b-input-group>
      <div class="form-control big">
        <b-badge v-for="(d, indx) in val" v-bind:key="indx">
          {{ d }}
          <icon :icon="icons.del" v-if="!disabled" @click="delete_date(indx)" />
        </b-badge>
      </div>
    </b-input-group>
    <div>
      <date-picker
        v-if="!disabled"
        class="sm-1"
        button-only
        v-model="new_date"
        @input="add_new_date"
        :date-disabled-fn="date_disabled"
        :show_input="false"
        button-variant="outline-obitless"
      >
        <icon :icon="icons.plus" /> Добавить
      </date-picker>
      <b-button v-if="!disabled" @click="clear" variant="outline-obitless">
        <icon :icon="icons.trash" /> Сбросить все
      </b-button>
      <!-- <b-button
        v-for="(_, year, indx) in show_add ? dates_to_add : {}"
        v-bind:key="indx"
        @click="add_all_dates(year)"
      >
        Добавить {{ year }}
      </b-button> -->
      <!-- <b-form-datepicker
        v-if="show_add"
        class="sm-1"
        v-model="new_date"
        @input="add_new_date"
        :date-disabled-fn="date_disabled"
      /> -->
    </div>
  </fragment>
</template>

<script>
import { timestamp } from "../../../formatters";
import { as_icon, Icon } from "../../layot";
import { faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import weekends from "../../../weekends.json";
import { isAfter, parseISO, toDate } from "date-fns";
import { Fragment } from "vue-fragment";
import DatePicker from "../Bill/DatePicker.vue";

export default {
  name: "weekendsInput",
  components: {
    Icon,
    Fragment,
    DatePicker,
  },
  props: {
    value: {
      required: true,
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  filters: {
    dt_format: timestamp,
  },
  data() {
    const data = weekends;
    return {
      show_add: false,
      new_date: undefined,
      dates_to_add: data,
    };
  },
  computed: {
    val: {
      get() {
        return this.value || [];
      },
      set(to) {
        this.$emit("input", to);
      },
    },
    icons: () =>
      Object.assign({
        plus: as_icon(faPlus),
        del: as_icon(faTimes),
        trash: as_icon(faTrash),
      }),
  },
  methods: {
    add_new_date() {
      const val = this.val.concat([this.new_date]);
      this.$emit("input", val);
      this.show_add = false;
    },
    add_all_dates(year) {
      let dtd = this.dates_to_add[year];
      dtd = dtd.filter((val) => isAfter(parseISO(val), toDate(Date.now())));
      this.val = this.val.concat(dtd);
      this.show_add = false;
    },
    clear() {
      this.val = [];
    },
    delete_date(indx) {
      this.val = this.val.filter((_, i) => i !== indx);
    },
    date_disabled(date) {
      const holidays = this.val;
      return holidays.indexOf(date) >= 0;
    },
  },
};
</script>

<style scoped>
.form-control.big {
  max-height: auto;
  height: inherit;
  min-height: 2.5em;
}
</style>