import { actions_field, id_field_desc } from '@/functions.js'
import { description_field } from "../fields"

const root = 'customer'
const URLS = {
    add: `${root}/register`,
    get: `${root}/info`,
    update: `${root}/update`,
    delete: `${root}/close`,
    list: `${root}/list`,
}
export default {
    verbose: 'Клиент услуг',
    plural: 'Клиенты услуг',
    urls: URLS,
    obj_key: `${root}_id`,
    obj_name: 'title',
    delete_key: 'dateStop',
    type: 'customer',
    storage: {
        getters: {
            calced_options: () => obj => ({ customer_id: obj.customer_id.toUpperCase(), obj_id: obj.customer_id.toUpperCase(), obj_key: obj.customer_id.toUpperCase() })
        }
    },
    fields: {
        customer_id: id_field_desc,
        title: { label: 'Название', required: true },
        description: description_field,
    },
    list_fields: ['title', 'description', actions_field],
    list_fields_search: ['title', 'description'],
    new_fields: ['title'],
    edit_fields: ['title', 'description']
}