<template>
  <b-row>
    <b-col>
      <component :is="comp" :obj_type="type" :obj_id="id" />
    </b-col>
  </b-row>
</template>

<script>
// @ is an alias to /src
import { OBJ_COMPONENTS } from "../constants";
import DefObjectVue from "../components/objects/DefObject.vue";
export default {
  name: "Obj",
  props: {
    variant: {
      type: String,
      default: "default",
    },
    obj_type: {
      required: false,
      type: String,
      default: null,
    },
    obj_id: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    id() {
      return this.$route.params.id || this.obj_id;
    },
    page_variant() {
      return this.desc.page_variant || this.variant;
    },
    type() {
      return this.$route.params.type || this.obj_type;
    },
    desc() {
      return OBJ_COMPONENTS[this.type] || {};
    },
    comp() {
      const comp = this.desc.obj_comp || DefObjectVue;
      return comp;
    },
    title() {
      return this.desc.verbose;
    },
  },
};
</script>
