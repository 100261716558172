<template>
  <div class="calltime-cell">
    <span clas="date">{{ v | date_only }}</span>
    <span class="text-muted time">{{ v | time_only }}</span>
  </div>
</template>

<script>
import { Cell } from "../mixins";
import { ru } from "date-fns/locale";
import { formatWithOptions } from "date-fns/fp";
import { type_val } from "../../functions";
import { parseISO } from "date-fns";
export default {
  name: "CallTime",
  mixins: [Cell],
  computed: {
    v() {
      let ret = this.unformatted;
      if (type_val(ret) == "String") {
        ret = parseISO(this.unformatted);
      }
      return ret;
    },
  },
  filters: {
    date_only(d) {
      return formatWithOptions({ locale: ru }, "dd.MM.yyyy")(d);
    },
    time_only(d) {
      return formatWithOptions({ locale: ru }, "HH:mm:ss")(d);
    },
  },
};
</script>

<style lang='scss'>
.calltime-cell {
  & > * {
    width: 100%;
    display: block;
  }
  .time {
    margin-top: 5px;
  }
}
</style>