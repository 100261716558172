import { id_field_desc } from "@/functions.js"
import { BFormSelect, BFormTextarea } from "bootstrap-vue"
import { customer_field, buildRelatedField } from "../fields"
import ContactsCell from './ContactsSell'
import ActionsEmpl from './ActionsEmpl'
import { DateTimePicker } from '../../layot'
import { EmployeeObj } from "."
import { multipleCell, } from "../../shared"
// import ServicesField from './ServicesField'
import EmployeeStatus from './EmployeeStatus'
// import RolesInput from '../../shared/RolesInput'
//import { ObitIcon } from "../../obit"
import { apiDateFormatter } from "../../../formatters"
import { startOfDay } from "date-fns"
import EmployeeFilter from './Filter'
import EmailInput from './EmailInput'
import { ObitIcon } from "../../obit/index"
import { SmartButton } from "../../layot/index"
//import { SmartButton } from "../../layot/index"

const root = 'employee'
const URLS = {
  add: `${root}/create`,
  // get: `${root}/info`,
  update: `${root}/update`,
  delete: `${root}/delete`,
  list: `${root}/list`,

  list_by_customer: `${root}/list`,

  check: `${root}/check`,
  approve_new: `${root}/approve_new`,
  approve_existing: `${root}/approve_existing`

}
const fields_view = [
  // { label: 'Статус', key: 'isActive', target: 'deleted', comp: isActive },
  'title',
  { label: 'Должность', target: 'position', editable: false },
  'mobile_phone',
  'email',
  // { label: 'Доступные сервисы', key: 'services' },
  // { label: 'Доступ к договору', key: 'entities' },
  { label: 'Статус', comp: EmployeeStatus, key: 'status', options: { delete_key: 'deleted' } },
  // {
  //   label: 'Роли', target: 'roles',
  //   comp: RolesInput
  // }, 
  'group',
]

const empl_statuses = {
  active: "Активен",
  "not-approved": "Отправлено приглашение",
  deleted: "Удален",
  "not-active": "без доступа",
  "has-no-email": "Нет приглашения",
}

const storage = {
  getters: {
    deleted(state, getters) {
      return getters.objects.filter(r => r.deleted)
    },
    calced_options: (state, getters, rootState, rootGetters) => obj => {
      let status = "not-active";
      if (obj.deleted) {
        status = "deleted";
      } else if (!obj.approved) {
        if (obj.email) {
          status = "not-approved";
        } else {
          status = "has-no-email";
        }
      } else if (obj.approved) {
        status = "active";
      }

      let group = rootGetters['group/objects'].filter(g => g.members.includes(obj.employee_id)).map(o => o.obj_id)
      let ret = {
        status,
        status_human: empl_statuses[status] || 'неизвестный статус',
        group,
      }

      return ret
    }
  },
  actions: {
    get({ dispatch, getters, commit }, obj_id) {
      return dispatch('list')
        .then(() => dispatch('role/list', null, { root: true }))
        .then(
          () => dispatch(
            'requestApi', {
            url: 'roles/list/employee',
            data: {
              employee_id: obj_id,
              customer_id: getters.object(obj_id).customer_id
            }
          }, { root: true }
          )
        )
        .then(
          r => {
            if (r.data.result == 'success') {
              commit('update', { obj_id: obj_id, roles: r.data.payload.map(_r => _r.role_id) })
            }
          }
        )
    },
    update({ dispatch, getters, commit }, obj) {
      let upd_obj = Object.assign({}, obj)
      const get_api = getters.has_api.update
      const upd_roles = upd_obj.roles;
      if (upd_roles !== undefined) {
        delete upd_obj.roles
      }
      upd_obj = getters.pre_data(upd_obj, 'list')
      const object = getters.object(upd_obj.employee_id)
      return dispatch('requestApi', {
        url: get_api,
        data: upd_obj
      }, { root: true }).then(
        r => {
          if (r.data.result == 'success') {
            let upd = getters.post_data(r.data.payload, 'update')
            commit('update', upd)
            return r.data.payload
          }
        }
      ).then(
        () => {
          if (upd_roles === undefined) {
            return
          }
          const roles = object.roles || []
          let new_roles = [], to_remove_roles = [].concat(roles)
          upd_roles.map(
            id => {
              if (roles.indexOf(id) == -1) {
                new_roles.push(id)
              }

              to_remove_roles = to_remove_roles.filter(m => m != id)
            }
          )
          let promises = []
          new_roles.map(
            id => promises.push(
              dispatch(
                'requestApi', {
                url: 'roles/add_role',
                data: {
                  role_id: id,
                  employee_id: obj.employee_id,
                  customer_id: upd_obj.customer_id
                }
              }, { root: true }
              )
            )
          )
          to_remove_roles.map(
            id => promises.push(
              dispatch(
                'requestApi', {
                url: 'roles/remove_role',
                data: {
                  role_id: id,
                  employee_id: obj.employee_id,
                  customer_id: upd_obj.customer_id
                }
              }, { root: true }
              )
            )
          )
          return Promise.all(promises).then(
            () => dispatch('list').then(() => dispatch('get', obj.employee_id))
          )
        }
      )
    },
    get_actions({ dispatch, getters },
      {
        obj_id,
        start: start = startOfDay(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)),
        end: end = Date.now()
      }) {
      return dispatch(
        'requestApi',
        {
          url: '/actions/employee',
          data: {
            ...getters.unique_for,
            employee_id: obj_id,
            start: apiDateFormatter(start),
            end: apiDateFormatter(end)
          },
        }, { root: true }
      ).then(
        r => {
          if (r.data.result == 'success') {
            return r.data.payload.map(
              line => {
                const splited = line.split(' ')
                const date = new Date(splited.slice(0, 2))
                const message = splited.slice(2).join(' ')
                return { date, message }
              }
            )
          }
        }
      )
    }
  }
}
export default {
  verbose: 'Сотрудник',
  plural: 'Пользователи',
  no_create_button: true,
  // create_button_text: 'Создать нового пользователя',
  create_button: SmartButton,
  list_filter_comp: EmployeeFilter,
  create_button_props: {
    osnPart: 'Создать нового',
    osnIcon: ObitIcon,
    osnIconProps: { icon: 'plus' },
    secondPart: 'Пользователя',
    showFunction: (inst) => {
      return inst.$store.getters.user_info.isEngineer
    }
  },
  urls: URLS,
  obj_key: `${root}_id`,
  obj_name: 'title',
  obj_comp: EmployeeObj,
  beforeDelete: (obj_id, store) => {
    let ret = true
    if (obj_id) {
      ret = store.getters['internal_line2/objects'].find(
        line => (line.employee || '').toLowerCase() == obj_id.toLowerCase()
      ) ? [false, 'Этот сотрудник приявязан к номеру'] : [true, 'Можно удалить']
    }
    return ret
  },
  // custom_list_func: get_objects,
  unique_for_customer: true,
  unique_for: 'customer',
  list_url: { name: 'settings', query: { page: 'employee' } },
  storage: storage,
  fields: {
    employee_id: id_field_desc,
    title: { label: 'ФИО' },
    created: {
      label: 'Создан', editable: false, edit_comp: DateTimePicker,
    },
    customer_id: customer_field,
    email: { label: 'Email', options: { type: 'email' } },
    description: {
      label: 'Описание', comp: BFormTextarea, options: { rows: '3', 'max-rows': '6' },
      description: 'Комментарий'
    },
    position: 'Должность',
    mobile_phone: {
      label: 'Номер телефона', options: { type: 'tel' },
      ret_target: 'mobile',
      validator: (val) => {
        if (!val) return null
        return 100 <= parseInt(val) && parseInt(val) <= 199 ? ['не может быть в промежутке от 100 до 199'] : null
      }
    },
    isCustomerOwner: {
      label: 'Админ',
      send_target: 'customer_owner',
      comp: BFormSelect,
      options: { options: [0, 1] }
    },
    roles: 'Роли',
    ext_line: buildRelatedField('Линии', 'internal_line', {}, { multiple: true, rule: obj => !obj.lines ? true : false }),
    group: buildRelatedField(
      'Группы', 'group',
      { multiple: true, editable: false, options: { wait_for: ["group", 'internal_line2'] } },

    )

  },
  list_fields: [
    {
      label: 'ФИО и должность',
      key: 'fio_dol',
      cell: multipleCell,
      cell_options: {
        keys: ['title', 'position'],
        isPk: true
      }
    }, {
      label: 'Контакты',
      key: 'contacts',
      cell: ContactsCell
    },
    {
      label: 'Статус',
      key: 'status',
      cell: EmployeeStatus
    }, {
      label: '', key: 'act2',
      cell: ActionsEmpl
    },
  ],
  list_fields_search: ['title', 'email', 'description', 'mobile_phone'],

  view_fields: fields_view,
  edit_fields: [
    'title',
    { label: 'Email', comp: EmailInput, key: 'email', target: 'email', options: { type: 'email' }, rule: (item) => item.status != 'has-no-email' },
    'position',
    'mobile_phone',
    // {
    //   label: 'Доступы к сервисам', target: 'services',
    //   row_props: {
    //     'label-cols': undefined
    //   },
    //   comp: ServicesField
    // },
    { label: 'Статус пользователя', comp: EmployeeStatus, key: 'status', options: { delete_key: 'deleted' } },
    'group',
    // {
    //   label: 'Роли', target: 'roles',
    //   comp: RolesInput
    // }
  ],
  new_fields: [
    'title',
    {
      label: 'E-mail пользователя*',
      required: true,
      target: 'email',
      key: 'email'
    }, {
      label: 'Должность',
      target: 'position',
    },
    'mobile_phone',
    // {
    //   label: 'Доступы с сервисам',
    //   comp: ServicesField,
    //   row_props: {
    //     'label-cols': 0
    //   }
    // }

  ],

}