<template>
  <b-form
    inline
    class="margform"
    @keyup.enter.stop.prevent="apply_filter"
    @keydown.enter.stop.prevent="apply_filter"
  >
    <b-form-group label="За период с" label-cols="5">
      <date-picker v-model="date_int.date_start" />
    </b-form-group>
    <b-form-group label="по" label-cols="2">
      <date-picker v-model="date_int.date_end" />
    </b-form-group>
    <b-form-group v-if="show_reset_button()">
      <b-button
        variant="outline"
        class="text-primary"
        style="margin-left: 8px; margin-right: 8px"
        @click="reset_dates()"
        ><obit-icon icon="close"
      /></b-button>
    </b-form-group>
    <b-form-group>
      <b-button
        variant="outline-primary"
        @click="apply_filter"
        class="leftmargin"
        >ПРИМЕНИТЬ</b-button
      ></b-form-group
    >
  </b-form>
</template>

<script>
import { FILTER_DESC } from "./filter_description";
// import EventBusVue from '../../EventBus.vue';
import FilterMixinVue from "../../mixins/FilterMixin.vue";
import { compareAsc, isWithinInterval } from "date-fns";
import { parseISO } from "date-fns/fp";
// import { bindEvent } from '../../../functions';
import DatePicker from "./DatePicker";
import ObitIcon from "../../obit/ObitIcon.vue";

export default {
  name: "FinFilter",
  mixins: [FilterMixinVue],
  components: {
    DatePicker,
    ObitIcon,
  },
  data() {
    return {
      filter_descr: FILTER_DESC,
      date_int: {
        date_start: null,
        date_end: null,
      },
    };
  },
  methods: {
    rule(row) {
      //debugger; // eslint-disable-line no-debugger
      const [date] = row.date.split(" ");
      var interval_filter = true;
      const row_date = parseISO(date);
      if (this.date_int.date_start && this.date_int.date_end) {
        const start_date = parseISO(this.date_int.date_start);
        const end_date = parseISO(this.date_int.date_end);
        interval_filter = isWithinInterval(row_date, {
          start: start_date,
          end: end_date,
        });
      } else if (this.date_int.date_start) {
        const start_date = parseISO(this.date_int.date_start);
        interval_filter = compareAsc(row_date, start_date) >= 0;
      } else if (this.date_int.date_end) {
        const end_date = parseISO(this.date_int.date_end);
        interval_filter = compareAsc(end_date, row_date) >= 0;
      }
      return interval_filter;
    },
    show_reset_button() {
      if (this.date_int.date_start || this.date_int.date_end) {
        return true;
      }
      return false;
    },
    reset_dates() {
      this.date_int.date_start = null;
      this.date_int.date_end = null;
    },
  },
};
</script>

<style>
.leftmargin {
  margin-left: 8px;
}
.margform > * {
  margin-top: 10px;
}
</style>