<template>
  <b-modal
    v-model="show_modal"
    :title="titlePopup"
    :ok-title="buttonOkText"
    cancel-variant="outline-obit"
    lazy
    @ok="handleOk"
  >
    <div v-if="modalToggler">
      {{ modal_description }}
    </div>
    <div v-if="!modalToggler">
      <obj-form
        obj_type="integration"
        mode="new"
        :eventBus="bus"
        v-model="new_integration"
        :description="descr"
        ref="form"
      >
      </obj-form>
    </div>
  </b-modal>
</template>


<script>
import { ObjForm } from "..";
import { bindEvent } from "../../functions";
import { bitrixData } from "../objects/integration";
export default {
  name: "IntegrationModal",
  //inject: ["page_context"],
  components: { ObjForm },
  provide() {
    return { objsComp: this };
  },
  mounted() {
    bindEvent(this, `added:integration`, (integration) => {
      this.$router.push({ path: `/sip/integration/${integration.service_id}` });
    });
  },
  data() {
    let new_integration = {};
    for (const field of bitrixData.new_fields) {
      new_integration[field] = undefined;
    }
    return {
      show_modal: false,
      modalToggler: true,

      price: 500,
      new_integration,
      address: "",
      token: "",
      selected: null,
      options: [
        { value: null, text: "Битрикс24" },
        { value: "a", text: "Битрикс25" },
        { value: "b", text: "Битрикс26" },
      ],
    };
  },

  computed: {
    descr: () => bitrixData,
    bus() {
      return this;
    },
    titlePopup() {
      return this.modalToggler
        ? "Согласие со стоимостью"
        : "Настройки интеграции";
    },
    buttonOkText() {
      return this.modalToggler ? "Согласен" : "Подключить";
    },
    modal_description() {
      return `Абонентская плата: ${this.price} руб. в месяц `;
    },
  },

  methods: {
    show(subject) {
      if (subject == "CRM1") {
        this.feedback_data = {
          title: "Согласен Hello World !!!",
          comments: "",
        };
      }
      this.show_modal = true;
    },

    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      //alert('Сработала функция нажатия на ОК')

      if (!this.modalToggler) {
        //alert('Сработала функция нажатия на ОК - По второму окну')
        this.show_modal = false;

        this.modalToggler = !this.modalToggler;
        //this.$router.push({ path: "/sip/integration/integration_id" });

        const def_save = () => this.$emit("save");
        const method = this.descr.save_method || def_save;
        method(this);

        // console.log("data_to_send", data_to_send);

        return;
      }

      this.modalToggler = !this.modalToggler;

      if (!this.modalToggler) {
        //alert('Изменения контента во втором окне')
        //вызвать метод из другой компоненты

        return;
      }
    },
  },
};
</script>

<style lang='scss'>
</style>