import { BFormSelect, BFormTextarea } from "bootstrap-vue"
// import { Select2} from '../layot'
import relatedCell from '../shared/relatedCell'
import selectCell from '../shared/selectCell'
import { booleanCell, passwordCell } from "../shared"
import { DateTimePicker, MonthPicker, passwordInput, Select2 } from "../layot"
import { merge } from "lodash-es"
import store from "../../store"
import { formatWithOptions } from "date-fns/esm/fp"
import { ru } from "date-fns/locale"
import { datetimeformatter } from "../../formatters"
import { boolInput } from "../shared/index"



const build_blank_option = (name = 'Выберите', def_value = null) => {
    return {
        text: name,
        value: def_value,
        disabled: true,
    }
}

const buildBooleanField = (label, reverse = false, config = {}) => merge(
    {}, {
    label: label,
    comp: boolInput,
    cell: booleanCell,
    cell_options: { reverse: reverse },
    options: { required: false }
}, config
)

const buildPasswordField = (label, config = {}) => merge({}, {
    label: label,
    comp: passwordInput,
    cell: passwordCell
}, config
)

const buildOptionsField = (label, options = [], config = {}) => {
    // let placeholder = build_blank_option(opts['placeholder'] || '--- Выберите ---')
    let real_options = []
    if (Object.prototype.toString.call(options).slice(8, -1) == 'Object') {
        Object.entries(options).map(([val, key]) => real_options.push(
            { text: key, value: val }
        ))
    } else {
        real_options = options
    }
    return merge(
        {}, {
        label: label,
        comp: BFormSelect,
        cell: selectCell,
        options: {
            options: real_options,
            form_control: true
        },
    }, config
    )
}
const buildDateTimeField = (label, config = {}) => merge({}, {
    label: label,
    comp: DateTimePicker,
    table_formatter: datetimeformatter
}, config)

const buildMonthField = (label, config = {}) => merge({}, {
    label: label,
    comp: MonthPicker,
    _table_formatter: formatWithOptions({ locale: ru }, "d MMMM yyyy HH:mm:ss")
}, config)

const buildRelatedField = (label, related, config = {}) => {
    const component = Select2
    const multiple = config.multiple || false
    const cfg = {
        label: label,
        related: related,
        build_options: true,
        custom_multiple: true,
        comp: component,
        cell: relatedCell,
        options: {
            required: true,
            multiple: multiple,
            options: () => store.getters[`${related}/objects`],
            related: related,
            list_key: 'obj_key',
            list_text: 'obj_name',
            wait_for: [related,]
        }
    }
    return merge({}, cfg, config)
}

const customer_field = buildRelatedField('Клиент', 'customer')
const instance_field = buildRelatedField('Инстанс', 'instance')
const description_field = {
    label: 'Описание',
    comp: BFormTextarea
}
const break_form_field = '__break__'

export {
    buildRelatedField, buildOptionsField,
    customer_field, description_field, instance_field,
    break_form_field, build_blank_option, buildBooleanField,
    buildPasswordField, buildDateTimeField, buildMonthField
}