<template>
  <div>
    {{ numbers }}
  </div>
</template>

<script>
export default {
  name: "ConfigrNumbers",
  props: {
    numbers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style>
</style>