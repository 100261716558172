<template>
  <b-form-input v-model="val" v-bind="{ disabled, state, ...$attrs }" />
</template>

<script>
import { BFormInput } from "bootstrap-vue";
const not_req_fields = ["disabled", "state", "multiple"].reduce(
  (ret, v) => Object.assign(ret, { [v]: { required: false } }),
  {}
);

export default {
  name: "FormInput",
  inject: ["__obj"],
  props: {
    value: {
      required: true,
    },
    convert_type: {
      required: false,
      type: Function,
      default: null,
    },
    multi_target: {
      required: false,
      type: Array,
      default: null,
    },
    ...not_req_fields,
    _obj: {
      required: false,
      type: Object,
    },
    field_desc: {
      required: false,
      type: Object,
    },
    parent: {
      required: false,
      type: Object,
    },
    _wait_for: {
      required: false,
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    if (this._wait_for.length)
      Promise.all(
        this._wait_for.map((type) => this.$store.dispatch(`${type}/list`))
      );
  },
  computed: {
    val: {
      get() {
        return this.value;
      },
      set(to) {
        const real_to = this.convert_type ? this.convert_type(to) : to;
        this.$emit("input", real_to);
      },
    },
  },
  comp() {
    return this.field_desc.comp || BFormInput;
  },
};
</script>

<style>
</style>