<template>
	<div id="page">
		<b-navbar
				class="topbar"
				variant="white"
				toggleable="lg"
		>
			<b-navbar-brand>
        <logo />
			</b-navbar-brand>
		</b-navbar>

		<div class="menu"></div>
		<div class="d-flex flex-column" id="page-wrapper">
			<div class="page" style="flex-grow: 1">
        <b-form @submit.prevent="onSubmit">
          <b-form-group label="Лицензия" label-cols="2">
            <b-form-textarea
                v-model="license"
                placeholder="Вставьте лицензию..."
                rows="10"
                max-rows="6"
                required
            />
          </b-form-group>

          <b-form-group label-cols="2">
            <small v-if="textError" class="d-block mb-2 text-danger">
              {{textError}}
            </small>

            <b-button variant="obit" type="submit">Отправить</b-button>
          </b-form-group>
        </b-form>
			</div>
			<b-footer/>
		</div>
	</div>
</template>

<script>
import {BNavbar, BNavbarBrand} from "bootstrap-vue";
import store from '@/store'
import Footer from "../../components/layot/Footer.vue";
import Logo from '@/components/obit/Logo.vue'

export default {
	name: "License",

	data() {
		return {
			license: ``,
      textError: null
		}
	},

	components: {BNavbarBrand, BNavbar, "b-footer": Footer, Logo},

	computed: {
	},

	methods: {
    async onSubmit() {
      this.textError = null
      const resp = await store.dispatch(`requestApi`, {
        url: `/license/activate`,
        data: {token: this.license}
      }, {root: true})

      if (resp.data?.result === `success`) {
        await this.$router.push(`/`)
      } else {
        this.textError = resp.response?.data?.text
      }
		}
	},
};
</script>

<style  lang='scss'>
</style>
