<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
        active: show_additional,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
    v-parent-class="{ 'active-node': show_additional }"
  >
    {{ get_data(node.data) }}
    <!--- hide title <div class="title">{{node.name}}</div> -->
    <!-- Outputs-->
    <b-card-title class="title">
      <Socket
        v-for="output in inputs()"
        :key="output.key"
        v-socket:input="output"
        type="input"
        :class="['input-socket', output.key]"
        :socket="output.socket"
      />Группа
      <b-button-group>
        <b-dropdown
          v-if="service"
          dropright
          no-caret
          variant="outline"
          toggle-class="border-0"
          class="additional-info"
          @show="fetch_additional_data()"
          @hidden="show_additional = false"
          boundary="window"
        >
          <template #button-content>
            <icon class="title-icon" :icon="icons.bars" />
          </template>
          <b-overlay :show="!additional_data">
            <b-dropdown-header>
              {{ strategy }}
            </b-dropdown-header>

            <b-dropdown-item
              v-for="item in members.slice(0, 4)"
              :key="item.lineNumber"
            >
              <b>{{ item.lineNumber }}</b> - {{ line(item) }}
              <!-- {{ get_number(item.service.service_id).employee.obj_name }} -->
            </b-dropdown-item>
            <b-dropdown-header v-if="members.length > 4">
              еще {{ members.length - 4 }}
            </b-dropdown-header>
          </b-overlay>
        </b-dropdown>
      </b-button-group>
    </b-card-title>
    <b-modal v-model="modal_show" title="настройки">
      <div
        class="control"
        v-for="(control, _i) in controls()"
        :key="_i"
        v-control="control"
      />
    </b-modal>
    <h2 class="name" v-if="service">
      {{ service.obj_name }} - {{ service.caption }}
    </h2>
    <div v-for="output in outputs()" :key="output.key" class="out-line">
      <span class="name out-line-span">{{ output.name }}</span>
      <Socket
        v-socket:input="output"
        type="output"
        :class="['output-socket', output.key]"
        :socket="output.socket"
      />
    </div>
    <!-- Inputs-->
    <!-- <div class="input" v-for="input in inputs()" :key="input.key">
      <Socket
        v-socket:input="input"
        type="input"
        :socket="input.socket"
      ></Socket>
      <div class="input-title" v-show="!input.showControl()">
        {{ input.name }}
      </div>
      <div
        class="input-control"
        v-show="input.showControl()"
        v-control="input.control"
      ></div>
    </div> -->
  </b-card>
</template>

<script>
import VueRenderPlugin from "rete-vue-render-plugin";
import { as_icon, Icon } from "@/components/layot";
import { faBars, faCog } from "@fortawesome/free-solid-svg-icons";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";
import parent_class from "../../../../../directives/parent_class";

export default {
  name: "PBXGroupEditorComponent",
  mixins: [ComponentMixinVue],
  directives: {
    "parent-class": parent_class,
  },
  components: {
    Socket: VueRenderPlugin.Socket,
    Icon,
  },
  data: () => ({
    modal_show: false,
    additional_data: false,
    members: [],
    show_additional: false,
  }),
  methods: {
    fetch_additional_data() {
      this.show_additional = true;
      if (!this.additional_data) {
        this.$store
          .dispatch("pbx_group/get_members", this.service_id)
          .then((resp) => {
            this.members = resp.data.payload;
            this.additional_data = true;
          });
      }
    },
    line(item) {
      let ret = this.$store.getters["internal_line2/object"](
        item.service.service_id
      );
      let ret_name = "----";
      if (ret?.employee) {
        let employee = this.$store.getters["employee/object"](ret.employee);
        ret_name = employee?.obj_name ?? "Не загружено";
      }
      return ret_name;
    },
  },
  computed: {
    icons: () => ({
      gear: as_icon(faCog),
      bars: as_icon(faBars),
    }),
    config: () => config,
    strategy() {
      const strategys = {
        ringall: "Одновременный дозвон",
        rrmemory: "Последовательный дозвон",
        linear: "Последовательно с первого",
      };
      let ret =
        strategys[this.service.strategy] ||
        `Порядок дозвона ${this.service.strategy}`;
      return ret;
    },
  },
  watch: {
    service_id(to, from) {
      if (to != from) {
        this.additional_data = false;
      }
    },
  },
};
</script>

<style lang='scss'>
@import "@/../static/SCSS/obit-colors.scss";
.node.pbx_group-node {
  max-width: 250px;
  .name {
    $size: 2em;
    font-size: $size;
    line-height: 1.2em;
    max-width: calc(100% - 20px);
    font-weight: 300;
  }
  .title {
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // border-bottom: 1px solid $obit-muted;

    background: #54bae7;
    font-weight: 300;
    border-radius: 4px;
    color: #fff;
    .title-icon {
      color: #fff;
    }
    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }
    .input-socket.input {
      margin-left: -12px !important;
    }
  }
  .out-line {
    height: 3em;
    .name {
      display: block;
      padding-left: 10px;
    }
    .output-socket.output {
      position: relative;
      left: calc(100% - 30px);
      top: -2.5em;
      &.timeout {
        background-color: $obit-danger !important;
      }
    }
    .out-line-span {
      font-size: 24px;
      border: 2px solid #54bae7;
      border-radius: 50px;
      max-width: 146px;
      width: 100%;
      margin-left: 178px;
      padding: 0 10px;
      padding-left: 20px;
    }
  }
}
</style>