<template>
  <b-form-input :value="val" @change="val = $event" :disabled="disabled" />
</template>

<script>
import { InputMixin } from "../../mixins";

export default {
  name: "LoginSIPInput",
  mixins: [InputMixin],
  props: {
    raw: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return { val_login: "" };
  },
  methods: {
    generate_new_sip_login() {
      const pattern = new RegExp(`^user${this._obj.number}s\\d+$`);
      const n = (this.devices || [])
        .filter((d) => d.type == "sip" && pattern.test(d.login || ""))
        .reduce((r, d) => {
          let spl = d.login.split("s");
          if (spl.length) {
            let part = parseInt(spl[spl.length - 1]);
            r = Math.max(r, part + 1);
          }
          return r;
        }, 0);
      return `user${this._obj.number}s${n}`;
    },
  },
  computed: {
    val: {
      get() {
        const namespace =
          this._obj.namespace || this.$store.getters.current_namespace;
        const number = this.value || this.generate_new_sip_login();
        return `${namespace}+${number}`;
      },
      set(to) {
        let spl = to.split("+");
        if (spl.length > 1) {
          let nwe_val = spl.slice(1).join("+");
          this.$emit("input", nwe_val);
        } else {
          this.$emit("input", to);
        }
      },
    },
  },
};
</script>

<style>
.transfer-options {
  margin-top: 0.5em;
}
</style>