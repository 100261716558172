import { actions_field, id_field_desc, resolveWithPromise } from '@/functions.js'
import { buildOptionsField, buildRelatedField } from '../fields'
const entity_root = 'pbx/entity'
const ENTITY_LINKS = {
  add: `${entity_root}/add`,
  get: `${entity_root}/get`,
  update: `${entity_root}/update`,
  list: `${entity_root}/list`,
  delete: `${entity_root}/close`
}
const default_type = 'ДОГОВОР'
const type_options = [
  default_type, 'СПЕЦИФИКАЦИЯ'
]
const storage = {
  actions: {
    list({ dispatch, getters, commit, rootGetters }, d = undefined) {
      const { full: full = true } = d || {}
      let r = resolveWithPromise()
      if (full) {
        r = r
          .then(() => dispatch('instance/list', null, { root: true }))
          .then(
            () => {
              const instances = rootGetters['instance/objects']
              const data_to_send = getters.pre_data({}, 'list')
              return Promise.all(
                instances.map(
                  i => dispatch(
                    'requestApi',
                    {
                      url: ENTITY_LINKS.list,
                      data: Object.assign({}, data_to_send, { instance_id: i.instance_id })
                    },
                    { root: true }
                  )
                )
              ).then(
                reqs => {
                  const entities = reqs.map(r => r.data.payload || []).flat().map(
                    r => {
                      const data_save = getters.post_data(r, 'list');
                      const repl_data = { instance_id: r.instance_id, };
                      return Object.assign({}, data_save, repl_data);
                    }
                  )
                  commit('load', entities)
                }
              )
            }
          )
      }
      return r
    }
  }
}

const ENTITY_OBJ = {
  urls: ENTITY_LINKS,
  obj_key: 'entity_id',
  obj_sendkey: 'entity_id',
  obj_listkey: 'id',
  obj_name: 'name',
  verbose: 'Договор',
  plural: 'Договора',
  // next_new_url: '/entity/',
  next_edit_url: NaN,
  new_default: {},
  delete_key: 'date_stop',
  unique_for: ['customer'],
  page_variant: 'sip',
  no_create_button: true,
  storage: storage,
  fields: {
    entity_id: id_field_desc,
    instance_id: buildRelatedField(
      'Инстанс', 'instance'
    ),
    customer_id: buildRelatedField(
      'Клиент', 'customer'
    ),
    name: 'Название',
    type: buildOptionsField(
      'Тип', type_options,
      {
        required: true,
        default: default_type
      }
    ),
  },
  list_fields: ['name', 'type', 'instance_id', actions_field],
  list_fields_search: ['name', 'type'],
  edit_fields: [
    'instance_id', 'name', 'type',
  ],
  new_fields: [
    'type', 'name', 'instance_id'
  ],
  view_fields: [
    'type', 'name', 'instance_id'
  ]
}
export { ENTITY_LINKS, ENTITY_OBJ }
