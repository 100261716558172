<template>
  <div>
    <b-form-checkbox-group
      stacked
      class="call-level-input"
      v-if="!disabled"
      :options="options"
      v-model="val"
    />
    <div v-else>
      <p v-for="indx in val" v-bind:key="indx">- {{ get_text(indx) }}</p>
      <p v-if="!val.length">не определено</p>
    </div>
  </div>
</template>

<script>
import { range, difference } from "lodash-es";
import { InputMixin } from "../../mixins";

export default {
  name: "CallsLevelInput",
  mixins: [InputMixin],
  data() {
    return {
      test_val: this.val,
    };
  },
  mounted() {
    if (this.parent.mode == "new") {
      this.$emit("input", 4);
    }
  },
  computed: {
    description() {
      return this._obj._description || {};
    },
    field_description() {
      const fields = this.description.fields || {};
      return fields.call_perms || { options: { options: [] } };
    },
    options() {
      return this.field_desc.options.options.map((name, indx) => ({
        value: `${parseInt(indx) + 2}`,
        text: name,
      }));
    },
    val: {
      get() {
        const val = this.value || 0;
        const ret = val <= 1 ? [] : range(2, val + 1);
        return ret.map((r) => `${r}`);
      },
      set(to) {
        const strTo = to.map((t) => `${t}`);
        const old_value = this.val.map((t) => `${t}`);
        // добавляется
        let set_to = undefined;
        if (strTo.length > old_value.length) {
          const diff2 = difference(strTo, old_value);
          set_to = parseInt(diff2[0]);
        } else if (old_value.length > strTo.length) {
          const diff1 = difference(old_value, strTo);
          set_to = parseInt(diff1[0]) - 1;
        }
        if (set_to !== undefined) {
          this.$emit("input", set_to);
        }
      },
    },
  },
  methods: {
    get_text(val) {
      return this.options.filter((r) => r.value == val)[0].text;
    },
  },
};
</script>

<style lang='scss'>
.call-level-input {
  .custom-control {
    margin-bottom: 0.25em;
  }
}
</style>