<template>
  <div
    class="shortText"
    ref="self"
    v-b-tooltip="
      media_obj.obj_name && media_obj.obj_name.length > 25
        ? {
            variant: 'light',
            title: media_obj.obj_name,
            customClass: 'big_tooltip',
            container: $refs.self,
            placement: 'bottom',
          }
        : null
    "
  >
    {{ media_obj.obj_name }}
  </div>
</template>

<script>
import CellVue from "../mixins/Cell.vue";
export default {
  name: "shortText",
  mixins: [CellVue],
  props: {
    max_length: {
      type: Number,
      default: 25,
    },
  },
  computed: {
    media_obj() {
      return this.$store.getters["media/object"](this.value);
    },
    text() {
      return this.media_obj?.obj_name || " -- ";
    },
  },
};
</script>

<style lang="scss">
.shortText {
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* -webkit-line-clamp: 5; */
  max-width: 330px;
}
.big_tooltip {
  .tooltip-inner {
    max-width: 348px !important;
    width: 100% !important;
  }
}
</style>


