<template>
  <b-overlay :show="loading">
    <b-button
      v-if="show_button"
      variant="outline-obitless"
      class="play-cell"
      @click="
        $eventBus.$emit('show-log-media', url);
        loading = true;
      "
    >
      <obit-icon icon="play" />
    </b-button>
  </b-overlay>
</template>

<script>
import { EventBus } from "..";
import { BButton } from "bootstrap-vue";
import CellVue from "../mixins/Cell.vue";
import ObitIcon from "../obit/ObitIcon.vue";
import { bindEvent } from "../../functions";

const IGNORE_STATUSES = ["NOANSWER", "CANCEL", "CONGESTION"];
// const test_url =
//   "/pbx/reports/call_record?id=def5020014c440267bfa588dcd6362fc6214a03490ae1016fbda2e28a424109d28533f79a73c4a7743870c0a0f4bead1c6925263a60213c40185496c20021aed227dcb6afd9d59b605e4e56c7161a8d8ba1cecba98f3f42aecaa17803aaf327b90ebd43c07893a0dc4c6a1469147a0&bind=morty";
export default {
  name: "PlayCell",
  mixins: [CellVue, EventBus],
  components: { BButton, ObitIcon },
  mounted() {
    bindEvent(
      this,
      "show-log-media-loaded",
      (url) => {
        if (url == this.url) {
          this.loading = false;
        }
      },
      this.$eventBus
    );
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    url() {
      return this.item.call_id;
    },
    show_button() {
      return (
        IGNORE_STATUSES.indexOf(this.item._raw.dialstatus) == -1 &&
        !!this.item.len &&
        this.item._raw.record_url
      );
    },
  },
};
</script>

<style lang='scss'>
.play-cell {
  padding: unset;
  .has_record {
    color: red;
  }
  .svg-inline--fa {
    vertical-align: bottom;
  }
}
td.field_record,
th.field_record {
  width: 50px;
}
</style>