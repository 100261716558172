<template>
  <list
    :objects="objects"
    :description="description"
    :fields="fields"
    :methods="methods"
    ref="list"
    :use_paginator="use_paginator"
    v-bind="$attrs"
  >
    <template
      v-for="(_, name) in $scopedSlots"
      :slot="name"
      slot-scope="slotData"
    >
      <slot :name="name" v-bind="slotData" />
    </template>
  </list>
</template>

<script>
import { BaseObjectsList } from "../layot";
export default {
  name: "ObjTable",
  components: {
    list: BaseObjectsList,
  },
  props: {
    objects: {
      type: Array,
    },
    fields: {
      type: Array,
    },
    description: {
      type: Object,
    },
    methods: {
      type: Object,
    },
    use_paginator: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  methods: {
    reset_page() {
      this.$refs["list"].reset_page();
    },
  },
};
</script>