<template>
  <b-card
    :class="[
      `${config.objType}-node`,
      'node',
      {
        'node-hover': hover,
        'node-new': node_new > 0,
        'node-search': node_search,
      },
    ]"
    no-body
    v-show="real_show"
    @mouseover="isHover(true)"
    @mouseleave="isHover(false)"
  >
    {{ get_data(node.data) }}
    <b-card-title class="title">
      <Socket
        v-for="input in inputs()"
        :key="input.key"
        v-socket:input="input"
        type="input"
        :class="['input-socket', input.key]"
        :socket="input.socket"
      />
      {{ config.name }}
      <obj-modal
        v-if="service"
        obj_type="play_file"
        :obj_id="service_id"
        class="node-edit"
        noDelete
      >
        <template v-slot:default="m">
          <b-button pill variant="outline" sm @click="m.show_modal()">
            <icon class="title-icon" :icon="icons.gear" />
          </b-button>
        </template>
      </obj-modal>
    </b-card-title>
    <b-modal v-model="modal_show" title="настройки">
      <p v-if="service">{{ service.obj_id }}</p>
      <p>{{ node.data.raw }}</p>
      <div
        class="control"
        v-for="(control, _i) in controls()"
        :key="_i"
        v-control="control"
      />
    </b-modal>

    <h2 class="name">
      <media-name-cell-vue
        v-if="service.mediaFileId"
        :item="service"
        related
        :value="service.mediaFileId"
        :field="{
          key: 'mediaFileId',
          field_desc: {
            target: 'mediaFileId',
          },
        }"
        :index="0"
      />
    </h2>

    <fragment>
      <Socket
        v-for="output in outputs()"
        :key="output.key"
        v-socket:input="output"
        type="output"
        :class="['output-socket', output.key]"
        :socket="output.socket"
      />
    </fragment>
  </b-card>
</template>


<script>
import MediaNameCellVue from "../../../../objects/media/MediaNameCell.vue";
import ObjModal from "../../../../objects/ObjModal.vue";
import ShortTextSpan from "../../../../shared/shortTextSpan.vue";
import ComponentMixinVue from "../ComponentMixin.vue";
import config from "./config";

const max_length = 13;

export default {
  name: "PlayFileComponent",
  mixins: [ComponentMixinVue],
  components: { MediaNameCellVue, ObjModal, ShortTextSpan },

  computed: {
    config: () => config,
    fileName() {
      let ret = "";
      if (this.service && this.service.mediaFileId) {
        ret = this.service._related("mediaFileId")?.obj_name || "";
      }
      return ret;
    },
    shortName() {
      let ret = "";
      if (this.fileName.length >= max_length + 3) {
        ret = this.fileName.slice(0, max_length) + "...";
      } else {
        ret = this.fileName;
      }
      return ret;
    },
  },
};
</script>

<style lang='scss'>
.node {
  &.play_file-node {
    max-width: inherit;
    .node-edit {
      display: inline;
      border: none;
      .title-icon {
        color: #fff;
      }
    }
    .subname {
      font-weight: 300;
      padding: 20px;
    }
    h2.name {
      max-width: 350px;
      line-height: 1em;
      overflow-wrap: break-word;
    }
  }
}
</style>