<template>
  <b-form-select
    type="text"
    :readonly="readonly"
    :value="value"
    @input="change($event)"
    text-field="obj_name"
    value-field="obj_id"
    :options="select_options"
  />
</template>


<script>
const obj_type = "disa";
import store from "@/store";
export default {
  name: `${obj_type.replace("_", "")}Control`,
  props: ["readonly", "emitter", "ikey", "getData", "putData"],
  data() {
    return {
      value: 0,
    };
  },
  computed: {
    select_options() {
      return store.getters[`${obj_type}/objects`];
    },
  },
  methods: {
    change(e) {
      this.value = e;
      this.update();
    },
    update() {
      if (this.ikey) this.putData(this.ikey, this.value);
      this.emitter.trigger("process");
    },
  },
  mounted() {
    this.value = this.getData(this.ikey);
    store.dispatch(`${obj_type}/list`);
  },
};
</script>