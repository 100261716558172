<template>
  <b-overlay :show="loading" class="tariff-settings">
    <portal to="create_button">
      <b-button-group v-if="show_save_button">
        <b-button variant="outline-primary" @click="save_data()">
          <icon :icon="icons.save" /> Сохранить
        </b-button>
        <b-button variant="outline-danger" @click="clear_data()">
          <icon :icon="icons.cancel" />
          Отмена
        </b-button>
      </b-button-group>
      <b-button v-else variant="outline-primary" @click="load_data()">
        <icon :icon="icons.reload" />
      </b-button>
    </portal>

    <!-- <div>
      <b-button @click="goToIntegrationSetting()"
        >Настройки интеграции</b-button
      >
    </div> -->
    <obj-list :fields="headers" :items="forNerZull()" disable_search :sticky-header="true" class="tariff-users-giraffe">
      <template #searchbar>
        <b-input-group class="search">
          <template #prepend>
            <b-dropdown :text="search_all_obj.text" :variant="search_all_obj.variant || 'obit'">
              <b-dropdown-item v-for="o in search_all_options" :key="o.value" @click="search_all = o.value"
                :variant="o.value === search_all ? 'primary' : 'outline'">{{ o.text }}</b-dropdown-item>
            </b-dropdown>
          </template>

          <b-input @focus="focus = true" @blur="focus = false" placeholder="Поиск" v-model="search_text" :class="[
            'search-input',
            {
              'border-right-0': show_clear_filter,
              'focus-input': show_clear_filter,
            },
          ]" />

          <b-button v-if="show_clear_filter" variant="outline" :class="[
            'border-left-0',
            'border',
            'text-primary',
            'clear-input',
            { focus: focus },
          ]" @click="clear_filter()">
            <obit-icon icon="cancel" />
          </b-button>

          <template #append>
            <b-select v-if="!!companyData.length" v-model="search_group" class="group_search">
              <b-select-option :value="null"> Все отделы </b-select-option>
              <b-select-option v-for="c in companyData" :key="c.id" :value="c.id">
                {{ c.name }}
              </b-select-option>
            </b-select>
          </template>
        </b-input-group>
      </template>
      <template #cell(fullName)="{ item, value }">
        <span v-if="item.crm">
          {{ item.crm.fullName || "--- неизвестно ---" }}
        </span>
        <span v-else-if="value"> {{ value }} </span>
        <span v-else> --- информация недоступна --- </span>
      </template>

      <template #cell(behemoth_id)="{ value, item }">
        <b-button v-if="!value" variant="outline-primary" @click="selectUser(item)">
          <icon :icon="icons.plus" />
        </b-button>
        <b-button-group v-else>
          <b-button @click="selectUser(item)" variant="outline-primary">
            <icon :icon="icons.edit" />
            {{ restore_line_name(value) }}
          </b-button>
          <b-button variant="danger" @click="mark_as_delete(item)">
            <icon :icon="icons.delete" />
          </b-button>
        </b-button-group>
      </template>
      <template #cell(work_department)="{ item }">
        <b-badge v-for="dep in item._groups" :key="dep.id" :variant="dep.leader == item.id ? 'warning' : 'primary'">{{
          dep.name }}</b-badge>
      </template>
      <template #cell(status)="{ value, item }">
        <b-button v-if="value" :variant="value.variant" @click="clear_actions(item)">
          <icon :icon="value.icon" />
        </b-button>
      </template>
    </obj-list>
    <b-modal v-model="show_modal" @ok="save_connection" id="modal-prevent-closing" ok-title="Выбрать"
      title="Выбор внутреннего номера">
      <obj-form v-if="current_user_obj" mode="edit" v-model="current_user_obj" raw
        :description="{ fields: edit_user_descr }" />
    </b-modal>
    <b-modal ok-title="Понимаю" v-model="show_disconnect" @ok="save_connection_replace"
      title="Связь будет переопределена!" lazy>
      Разорвана связь между

      <span v-if="this.user_connect">
        {{ this.user_connect.crm.fullName }}
        {{ this.user_connect.extension }} и
        {{ restore_line_name(this.user_connect.behemoth_id) }}
      </span>
      <br />
      и установлена связь:<br />
      {{ this.current_user_obj.fullName }}
      {{ this.current_user_obj.extension }}
      <span v-if="this.user_connect">
        и ({{ this.user_connect.extension }})
        {{ restore_line_name(this.user_connect.behemoth_id) }}
      </span>
    </b-modal>
  </b-overlay>
</template>

<script>
import ObitIcon from "../../components/obit/ObitIcon.vue";

import { Portal } from "portal-vue";
//import Multiselect from "vue-multiselect";
import { ObjForm } from "../../components";
import { buildRelatedField } from "../../components/objects/fields";

//import { download_responce_ab } from "../../functions";

import ObjList from "../../components/objects/ObjList.vue";
import { uuidv4 } from "../../functions";
import { as_icon, Icon } from "../../components/layot";
import {
  faBan,
  faCheck,
  faEdit,
  faMinus,
  faPlus,
  faSave,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    ObjList,
    ObjForm,
    Portal,
    Icon,
    ObitIcon,
  },

  mounted() {
    Promise.all([
      process.env.VUE_APP_IS_EMPLOYEE !== `false` ? this.$store.dispatch("employee/list") : true,
      this.$store.dispatch("internal_line2/list"),
    ]);
    this.$watch(
      () =>
        JSON.stringify(
          this.$store.getters.current("customer", "entity", "instance")
        ),
      () => this.load_data()
    );
    this.load_data();

    // this.fetchUsersData();
    // this.fetchCompanyData();
  },

  data() {
    return {
      user_connect: null,
      show_disconnect: false,

      selected: null,
      search_text: "",
      search_group: null,
      search_all: "all",
      customer_id__and__number: {},
      current_user_obj: {},
      loading: false,
      actions_disconnect: {},
      actions_connect: {},
      replace_userdata: {},
      show_save_button: false,
      selected_user: null,
      usersData: [],
      companyData: [],
      focus: false,
      line_number: null,
      headers: [
        { key: "fullName", target: "fullName", label: "ФИО", variant: "grey" },
        { key: "work_department", label: "Отдел", variant: "grey" },
        {
          key: "extension",
          label: "Номер",
          variant: "grey",
          formatter: (value, f, item) => item.crm?.extension ?? "---",
        },
        { key: "behemoth_id", label: "Внутрение номера ВАТС", variant: "crm" },
        {
          key: "status",
          label: "Заявка",
          variant: "crm",
          formatter: (value, f, item) => this.item_status(item),
        },
      ],
      /*
      usersData: [
        {
          user_id: 1,
          name: "Иванов Иван Иванович",
          work_position: "Водитель",
          work_department: "Логистика",
          telephone: "+79118887711",
          additional_number: "987",
          isChecked: false,
          employee: null,
        },

      ],
      */
    };
  },

  props: {
    obj_id: {
      type: String,
      required: true,
    },
  },

  methods: {
    restore_line_name(line_id) {
      return this.lines_names[line_id.toLowerCase()] ?? "";
    },
    clear_filter() {
      this.search_all = null;
      this.search_text = "";
      this.search_group = null;
    },
    clear_actions(item) {
      delete this.replace_userdata[item.id];
      delete this.actions_disconnect[item.id];
      delete this.actions_connect[item.id];
      this.$forceUpdate();
    },
    clear_data() {
      this.show_save_button = false;
      this.replace_userdata = {};
      this.actions_disconnect = {};
      this.actions_connect = {};
    },
    mark_as_delete(item) {
      //console.log("delete item", item);
      var old = { ...this.usersData.find((u) => u.id == item.id) };
      old.behemoth_id = null;
      this.replace_userdata[item.id] = old;
      if (this.actions_connect[item.id]) {
        delete this.actions_connect[item.id];
      }
      this.actions_disconnect[item.id] = {
        external_crm_id: old.crm?.id ?? old.crm_user_id,
        internal_line_id: item.behemoth_id,
      };
      this.show_save_button = true;
      this.$forceUpdate();
    },
    save_data() {
      const act_d_len = Object.values(this.actions_disconnect).length;
      const act_c_len = Object.values(this.actions_connect).length;

      let promise = Promise.resolve();

      if (act_d_len) {
        promise = promise.then(() => {
          return this.$store
            .dispatch("requestApi", {
              url: "pbx/services/v2/crmIntegrations/disconnect",
              data: {
                ...this.$store.getters.current("customer", "entity"),
                service_id: this.obj_id,
                items: Object.values(this.actions_disconnect),
              },
            })
            .then(
              (resp) => {
                if (resp?.data?.result == "success") {
                  this.$store.commit("add_message", {
                    type: "success",
                    message: `Успешно отсоединены ${act_d_len} пользователей`,
                  });
                } else {
                  this.$store.commit("add_message", {
                    type: "danger",
                    header: `Ошибка ${resp.response.data.code}`,
                    messages: [
                      `Исключение: ${resp.response.data.exception}`,
                      `${resp.response.data.text}`,
                    ],
                  });
                }
                return Promise.resolve();
              },
              (error) => {
                console.error("error", error);
                this.$store.commit("add_message", {
                  type: "danger",
                  message: "Ошибка при отсоединении",
                });
                return Promise.resolve();
              }
            );
        });
      }

      if (act_c_len) {
        promise = promise.then(() => {
          return this.$store
            .dispatch("requestApi", {
              url: "pbx/services/v2/crmIntegrations/connect",
              data: {
                ...this.$store.getters.current("customer", "entity"),
                service_id: this.obj_id,
                items: Object.values(this.actions_connect),
              },
            })
            .then(
              (resp) => {
                if (resp?.data?.result == "success") {
                  this.$store.commit("add_message", {
                    type: "success",
                    message: `Успешно соединены ${act_c_len} пользователей`,
                  });
                } else {
                  this.$store.commit("add_message", {
                    type: "danger",
                    message: `${resp.response.data.exception}: ${resp.response.data.text}`,
                  });
                }
                return Promise.resolve();
              },
              (error) => {
                console.error("error connect", error);
                this.$store.commit("add_message", {
                  type: "danger",
                  message: "ошибка при соединении пользователей",
                });
                return Promise.resolve();
              }
            );
        });
      }

      promise = promise.then(() => {
        this.load_data().then(() => {
          this.clear_data();
        });
      });
      return promise;
    },
    save_connection(e) {
      e.preventDefault();

      let valid = true;

      let user_connected = this.usersData.find((u) => {
        return u.behemoth_id == this.current_user_obj.behemoth_id;
      });

      if (user_connected) {
        valid = false;
        //если есть подключенный юзер, то выводим модалку предупреждающую об отсоединении
        this.show_disconnect = true;

        // добавочный номер того юзера, с кем будет переопределена связь
        this.user_connect = user_connected;
      }

      this.edit_connect(valid, null);

      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });

      //старый код

      /*
      let old_obj = this.generate_current_user(this.selected_user);
      console.log("old_obj", old_obj); 

      let valid = false;
      
      if (valid) {
        if (
          old_obj.behemoth_id &&
          old_obj.behemoth_id != this.current_user_obj.behemoth_id
        ) {
          console.log("old_obj.behemoth_id", old_obj.behemoth_id);
          this.actions_disconnect[old_obj.id] = {
            external_crm_id: old_obj.crm?.id ?? old_obj.crm_user_id,
            internal_line_id: old_obj.behemoth_id,
          };
          this.show_save_button = true;
        }
        if (this.current_user_obj.behemoth_id) {
          console.log(
            "this.current_user_obj.behemoth_id",
            this.current_user_obj.behemoth_id
          );
          console.log("this.actions_connect", this.actions_connect);

          //в пустой объект помещается содержимое, пример: {
          //     "external_crm_id": "10",
          //     "internal_line_id": "5afb9b14-14c6-4a38-92ac-0876f7e01f6c"
          // }
          this.actions_connect[this.current_user_obj.id] = {
            external_crm_id:
              this.current_user_obj.crm?.id ??
              this.current_user_obj.crm_user_id,
            internal_line_id: this.current_user_obj.behemoth_id,
          };

          this.show_save_button = true;
        }

        // номер линии
        const number = this.$store.getters["internal_line2/object"](
          this.current_user_obj.behemoth_id
        )?.number;
        this.line_number = number;
        console.log("number", number);
        console.log("this.line_number", typeof this.line_number);

        // если интеграция существует, то есть number есть в списке подключенных
        if (number) {
          // откроем вторую модалку или сменим контент у этой
        }

        this.replace_userdata[this.current_user_obj.id] = {
          ...this.usersData.find((u) => u.id == this.current_user_obj.id),
          behemoth_id: this.current_user_obj.behemoth_id,
          new_number: number,
        };
      }*/
    },
    save_connection_replace() {
      let valid = true;
      let trigger_mark = true;
      this.edit_connect(valid, trigger_mark, this.user_connect);
    },

    edit_connect(valid, trigger_func, user_connected) {
      if (trigger_func) {
        this.mark_as_delete(user_connected);
      }

      let old_obj = this.generate_current_user(this.selected_user);

      if (valid) {
        if (
          old_obj.behemoth_id &&
          old_obj.behemoth_id != this.current_user_obj.behemoth_id
        ) {
          this.actions_disconnect[old_obj.id] = {
            external_crm_id: old_obj.crm?.id ?? old_obj.crm_user_id,
            internal_line_id: old_obj.behemoth_id,
          };
          this.show_save_button = true;
        }
        if (this.current_user_obj.behemoth_id) {
          this.actions_connect[this.current_user_obj.id] = {
            external_crm_id:
              this.current_user_obj.crm?.id ??
              this.current_user_obj.crm_user_id,
            internal_line_id: this.current_user_obj.behemoth_id,
          };

          this.show_save_button = true;
        }

        // номер линии
        const number = this.$store.getters["internal_line2/object"](
          this.current_user_obj.behemoth_id
        )?.number;
        this.line_number = number;

        this.replace_userdata[this.current_user_obj.id] = {
          ...this.usersData.find((u) => u.id == this.current_user_obj.id),
          behemoth_id: this.current_user_obj.behemoth_id,
          new_number: number,
        };
      }
    },

    forNerZull() {
      let text = this.search_text.toLowerCase();
      return this.noEmptyNames().filter((r) => {
        const obj = this.$store.getters["internal_line2/object"](r.behemoth_id);
        let line_name = r.behemoth_id ? obj?.obj_name : "";
        let ret = true;
        if (text) {
          ret = false;
          let line = `${r?.fullName ?? ""} ${r.crm?.fullName ?? ""
            } ${line_name} ${obj?.number} ${r?.extension ?? ""} ${r.crm?.extension ?? ""
            }`.toLowerCase();
          if (line.indexOf(text.toLowerCase()) > -1) {
            ret = true;
          }
        }
        if (this.search_group) {
          let sub_ret = false;
          if (r._groups_ids.indexOf(this.search_group) > -1) {
            sub_ret = true;
          }
          ret &= sub_ret;
        }
        if (this.search_all != "all") {
          ret &= !!r.crm?.extension;
        }

        return ret;
      });
    },
    noEmptyNames() {
      // return this.usersData.filter((el) => el.fullName.trim() != "");
      return this.usersData.map((u) => {
        let r = this.replace_userdata[u.id] ?? u;
        r._groups = this.get_deprataments(u.id);
        r._groups_ids = r._groups.map((g) => g.id);
        r._cellVariants = {};
        if (
          !!r.crm?.extension &&
          r.extension !== r.crm?.extension &&
          !!r.behemoth_id
        ) {
          r._cellVariants["extension"] = "info";
        }
        if (r.new_number) {
          if (r.new_number == r.crm.extension) {
            r._cellVariants["extension"] = "success";
          } else {
            r._cellVariants["extension"] = "sunrise";
          }
        }
        return r;
      });
    },
    fetchUsersData() {
      let url = "pbx/services/v2/crmIntegrations/users";
      let data = {
        customer_id: this.$store.getters["current_customer"],
        entity_id: this.$store.getters["current_entity"],
        service_id: this.obj_id,
      };
      //console.log("this.$store", this.$store);
      //console.log("data", data);

      return this.$store
        .dispatch(
          "requestApi",
          {
            url: url,
            data: data,
          },
          { root: true }
        )
        .then((resp) => {
          let usersData = resp.data.payload.map((u) => ({
            ...u,
            id: u.crm?.id ?? u.crm_user_id ?? uuidv4(),
          }));
          return usersData;
        });
    },

    fetchCompanyData() {
      let url = "pbx/services/v2/crmIntegrations/companyStructure";
      let data = {
        customer_id: this.$store.getters["current_customer"],
        entity_id: this.$store.getters["current_entity"],
        service_id: this.obj_id,
      };
      // console.log("this.$store", this.$store);
      // console.log("data", data);
      let realCompanyData = [];
      let parseData = (p, parent = null) => {
        p.parent = parent;
        realCompanyData.push(p);
        if (p.children.length >= 1) {
          for (const i of p.children) {
            parseData(i, p.id);
          }
        }
      };
      return this.$store
        .dispatch(
          "requestApi",
          {
            url: url,
            data: data,
          },
          { root: true }
        )
        .then((resp) => {
          resp.data.payload.map((r) => parseData(r));
          //parseData(resp.data.payload);
          return realCompanyData;
        });
    },

    get_deprataments(user_id) {
      let ret = [];
      for (const dep of this.companyData) {
        if (dep.members.indexOf(user_id) != -1) {
          ret.push(dep);
        }
      }
      return ret;
    },
    load_data() {
      this.loading = true;

      return Promise.all([this.fetchUsersData(), this.fetchCompanyData()]).then(
        ([usersData, companyData]) => {
          this.usersData = usersData;
          this.companyData = companyData;
          this.loading = false;
        },
        () => {
          this.usersData = [];
          this.companyData = [];
          this.loading = false;
        }
      );
    },
    item_status(item) {
      let ret = null;
      if (this.actions_disconnect[item.id] && this.actions_connect[item.id]) {
        ret = {
          variant: "warning",
          icon: this.icons.edit,
        };
      } else if (this.actions_disconnect[item.id]) {
        ret = {
          variant: "danger",
          icon: this.icons.delete,
        };
      } else if (this.actions_connect[item.id]) {
        ret = {
          variant: "success",
          icon: this.icons.plus,
        };
      }
      return ret;
    },

    goToIntegrationSetting() {
      this.$router.push({ path: "integration_setting/recording_call" });
    },
    generate_current_user(id) {
      let item = {
        ...(this.usersData.find((u) => u.id == id) || {}),
      };
      item.fullName = item?.crm?.fullName ?? "";
      item.extension = item?.crm?.extension ?? "";
      return item;
    },
    // по клику на + при открытии модального окна
    selectUser(user) {
      //user.isChecked = !user.isChecked

      this.current_user_obj = this.generate_current_user(user.id);

      this.selected_user = user.id;

      // user.additional_number // по этому номеру будем искать соответсвие в ВАТС и делать его по умолчанию в поиске

      // console.log('user', user)

      // console.log('user.additional_number', user.additional_number)

      // console.log('this.bats_employees', this.bats_employees)
    },
  },

  computed: {
    search_all_obj() {
      return (
        this.search_all_options.find((o) => o.value === this.search_all) ?? {}
      );
    },
    search_all_options() {
      return [
        { value: "all", text: "Все" },
        { value: null, text: "С номерами", variant: "danger" },
      ];
    },
    lines_names() {
      return this.$store.getters["internal_line2/objects"].reduce(
        (r, i) => ({ ...r, [i.obj_id.toLowerCase()]: i.obj_name }),
        {}
      );
    },
    show_clear_filter() {
      return (
        this.search_text.length ||
        this.search_group != null ||
        this.search_all != null
      );
    },
    icons: () => ({
      plus: as_icon(faPlus),
      edit: as_icon(faEdit),
      save: as_icon(faSave),
      cancel: as_icon(faBan),
      reload: as_icon(faSync),
      new: as_icon(faCheck),
      delete: as_icon(faMinus),
      trash: as_icon(faTrash),
    }),
    show_save() {
      return Object.keys(this.replace_userdata).length;
    },
    edit_user_descr() {
      return {
        fullName: { label: "Пользователь в CRM", options: { disabled: true } },
        extension: { label: "Номер в CRM", options: { disabled: true } },
        behemoth_id: buildRelatedField(
          "Внутренний номер BATC",
          "internal_line2"
        ),
      };
    },

    show_modal: {
      get() {
        //console.log("!!this.selected_user", !!this.selected_user);
        return !!this.selected_user;
      },
      set(to) {
        if (to === false) {
          //console.log(this.usersData.find((u) => u.id == this.selected_user));
          this.selected_user = null;
        }
      },
    },
    /* show_modal_disconnect: {
      get() {
        //return false;
        return this.show_disconnect;
      },
      set(to) {
        if (to === false) {
          console.log(this.usersData.find((u) => u.id == this.selected_user));
          this.selected_user = null;
        }
      },
    }, */

    bats_employees() {
      //console.log( [{obj_key: null, obj_name:'Пусто'}, ...this.bats_users]  )

      //return [{obj_key: null, obj_name:'Пусто'}, ...this.bats_users];
      return [...this.bats_users];
    },

    bats_users() {
      let bats_users = this.$store.getters["employee/objects"];

      //console.log('bats_users', bats_users)

      return bats_users;
    },

    bats_lines() {
      let bast_lines = this.$store.getters["internal_line2/objects"];

      return bast_lines;
    },
  },
};
</script>

<style lang="scss">
$borderParam: 1px solid #123;
@import "@/../static/SCSS/obit-colors.scss";

.tariff-settings {
  .search {
    max-width: 930px;
    margin-top: 25px;
    margin-bottom: 0.5em;

    .search-input {
      border-radius: 6px;
      margin-right: 4px;

      &.focus-input {
        border-radius: 6px 0 0 6px;
        margin-right: 0;
      }
    }

    .btn {
      border-radius: 0 0.25rem 0.25rem 0;
      padding-bottom: 0;
      padding-top: 0;
      margin-right: 4px;

      &.dropdown-toggle {
        border-radius: 6px;
      }
    }
  }

  .b-table-sticky-header {
    max-height: calc(100vh - 530px);

    .vertical & {
      max-height: calc(100vh - 150px);
    }
  }

  .objects-table {
    table {

      td.table-grey,
      th.table-grey {
        background-color: lighten(#e0e722, 38%);
      }

      td.table-crm,
      th.table-crm {
        background-color: lighten(green, 72%);
      }
    }
  }

  .group_search {
    max-width: 20em;
  }

  .focus {
    border-color: var(--primary) !important;
  }

  .table-employee-list {
    display: grid;
    grid-template-columns: 5fr 2fr;
    border: $borderParam;

    .table__row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      &:first-child {
        background-color: aqua;
      }

      &.active {
        background-color: rgb(181, 159, 204);
      }
    }

    .table__col {
      padding: 20px 15px;
      border-bottom: $borderParam;

      &:not(:first-child) {
        border-left: $borderParam;
      }
    }

    .table-vats {
      background: rgb(255, 255, 196);
      padding: 0 20px 20px;
      border-left: $borderParam;
    }

    .table-vats__col {
      padding: 20px 15px;
      border-bottom: $borderParam;
    }

    .table__name {
      height: 58px;
    }

    .table__id {
      text-align: center;
      height: 58px;
    }

    .table__buttons {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }
}
</style>