<template>
  <div class="object-form integration-settings">
    <b-row class="item">
      <b-col>
        <obj-form
          :description="form_description"
          v-model="obj"
          mode="edit"
          ref="edit_form"
        >
          <div class="hr-line-dashed" />
          <div class="form-group row">
            <b-button
              variant="outline-obit"
              :disabled="!($refs.edit_form && $refs.edit_form.has_changes)"
              @click="save_integration"
            >
              Сохранить
            </b-button>
            <b-button variant="outline-obit"> Отмена </b-button>
          </div>
        </obj-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ObjForm } from "../..";
import { buildBooleanField } from "../fields";
import totalCheckboxVue from "./totalCheckbox.vue";
const options = { disabled: true };
const settings = {
  fields: {
    incomming_call_record: buildBooleanField("Запись входящего звонка", false, {
      description:
        "Входящий звонок клиента и ссылка на запись разговора записывается в CRM",
      options,
    }),
    outgoing_call_record: buildBooleanField("Запись исходящего звонка", false, {
      description:
        "Исходящий звонок клиента и ссылка на запись разговора записывается в CRM",
      options,
    }),
    incomming_call_cart: buildBooleanField("Карточка входящего звонка", false, {
      description:
        "При входящем звонке всплывает карточка клиента с его именем",
      options,
    }),
    click_call: buildBooleanField("Клик на номере клиента", false, {
      description:
        "Кликом по номеру клиента в CRM совершается исходящий звонок клиенту",
      options,
    }),
    calls_to_manager: buildBooleanField(
      "Перевод входящего звонка на менеджера",
      false,
      {
        description: "Настроить",
        comp: totalCheckboxVue,
        default: 0,
      }
    ),
  },
};

export default {
  props: {
    obj_id: {
      required: true,
      type: String,
    },
  },
  components: { ObjForm },
  mounted() {
    this.$watch(
      () => JSON.stringify(this.integration),
      () => {
        this.obj.calls_to_manager = this.integration?.timeout ?? 0;
      }
    );
    this.$nextTick(() => {
      this.obj.calls_to_manager = this.integration?.timeout ?? 0;
      this.$refs.edit_form.edited_obj.calls_to_manager =
        this.integration?.timeout ?? 0;
    });
  },
  data() {
    return {
      obj: {
        incomming_call_record: true,
        outgoing_call_record: true,
        incomming_call_cart: true,
        click_call: true,
        calls_to_manager: 0,
      },
    };
  },
  computed: {
    form_description: () => settings,
    integration() {
      return this.$store.getters["integration/object"](this.obj_id);
    },
  },
  methods: {
    save_integration() {
      const timeout = this.$refs.edit_form.edited_obj.calls_to_manager;
      const data_to_send = {
        timeout,
        service_id: this.obj_id,
        obj_id: this.obj_id,
      };
      this.$store.dispatch("integration/update", data_to_send);
    },
  },
};
</script>

<style lang='scss'>
.integration-settings {
  .form-group.row {
    button.btn:not(:first-child) {
      margin-left: 0.5em;
    }
  }
}
</style>