<template>
  <div>
    <h1>{{ obj.number }}</h1>
    <span>{{ obj.status }}</span>
    <b-tabs>
      <b-tab title="Информация">
        <br />
        <component
          :is="comp"
          obj_type="external_line"
          :obj_id="obj_id"
          mode="view"
        />
      </b-tab>
      <b-tab title="Тарификация"></b-tab>
      <b-tab title="Договор">
        <br />
        <obj-form v-model="doc" :description="doc_descr" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import ObjForm from "../../ObjForm";
import Obj from "../Obj";
const obj_type = "external_line";

const doc_description = {
  verbose: "Реквизиты",
  fields: {
    inn: "ИНН",
    org: "Название организации",
    addr: "Фактический адрес",
    kpp: "КПП",
    scan: "Сканы документов",
    doc: "Договор",
  },
};
export default {
  name: "ELObj",
  props: {
    obj_id: String,
  },
  components: { Obj, ObjForm },
  data() {
    return {
      doc: doc_description.fields,
    };
  },
  computed: {
    obj() {
      return this.$store.getters.get_object(obj_type, this.obj_id);
    },
    comp: () => Obj,
    doc_descr: () => doc_description,
  },
};
</script>

<style>
</style>