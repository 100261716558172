<template>
  <component
    :is="tag"
    class="shortText"
    v-b-tooltip="realTooltipProps"
    ref="self"
  >
    {{ short_text }}
  </component>
</template>

<script>
import { merge } from "lodash-es";
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    tag: {
      type: String,
      required: false,
      default: "span",
    },
    maxLength: {
      type: Number,
      default: 25,
      required: false,
    },
    tooltipProps: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  computed: {
    short_text() {
      return this.disabled
        ? this.text
        : this.text.slice(0, this.realMaxLength + 1) + " ...";
    },
    realMaxLength() {
      return this.maxLength - 4;
    },
    disabled() {
      return this.text.length <= this.realMaxLength;
    },
    realTooltipProps() {
      return merge({}, this.tooltipProps, {
        variant: "light",
        title: this.text,
        customClass: "big_tooltip",
        disabled: this.disabled,
        //container: this.$refs.self,
        placement: "bottom",
        boundary: "window",
      });
    },
  },
};
</script>

<style>
</style>