<template>
  <div class="call_status">
    <icon-stack :class="classes">
      <icon :icon="icons.phone" :class="['call-icon']" />
      <icon
        :icon="icon"
        style="top: -100%"
        v-b-tooltip="{ title: text, variant: 'light' }"
      />
    </icon-stack>
  </div>
</template>

<script>
import {
  faArrowLeft,
  faArrowRight,
  faPhone,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { as_icon, Icon, IconStack } from "../layot";
import { Cell } from "../mixins";
const VARIANTS = {
  входящий: {
    icon: as_icon(faArrowRight),
    text: "Входящий",
    classes: ["text-success"],
  },
  исходящий: {
    icon: as_icon(faArrowLeft),
    text: "Исходящий",
    classes: ["text-success"],
  },
};

const UNKNOWN = {
  icon: as_icon(faQuestion),
  text: "????",
  classes: ["text-danger"],
};
export default {
  mixins: [Cell],
  components: { Icon, IconStack },
  computed: {
    icons: () => ({
      phone: as_icon(faPhone),
    }),
    _variant() {
      return VARIANTS[this.value] ?? UNKNOWN;
    },
    icon() {
      return this._variant.icon;
    },
    text() {
      return this._variant.text;
    },
    classes() {
      return [...this._variant.classes];
    },
  },
};
</script>

<style>
</style>