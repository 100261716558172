var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:(
    _vm.media_obj.obj_name && _vm.media_obj.obj_name.length > 25
      ? {
          variant: 'light',
          title: _vm.media_obj.obj_name,
          customClass: 'big_tooltip',
          container: _vm.$refs.self,
          placement: 'bottom',
        }
      : null
  ),expression:"\n    media_obj.obj_name && media_obj.obj_name.length > 25\n      ? {\n          variant: 'light',\n          title: media_obj.obj_name,\n          customClass: 'big_tooltip',\n          container: $refs.self,\n          placement: 'bottom',\n        }\n      : null\n  "}],ref:"self",staticClass:"shortText"},[_vm._v(" "+_vm._s(_vm.media_obj.obj_name)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }