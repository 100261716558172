//  добавим класс родительскому елементу

export default {
  inserted: function (el, binding) {
    const parent = el.parentNode
    const classes = binding.value.classes || []
    // old variant 
    if (classes.length) {
      parent.classList.add(...classes)
    }
    // console.log('classes to insert', parent.original_classes)
    for (const [cls, cond] of Object.entries(binding.value)) {
      parent.classList.toggle(cls, cond)
    }
  },
  update: function (el, binding) {
    const parent = el.parentNode
    for (const [cls, cond] of Object.entries(binding.value)) {
      parent.classList.toggle(cls, cond)
    }
  }
}