<script>
import { default_promise } from "../../constants";
import { METHODS_MIX, COMPUTED_MIX } from "./WithDescription.js";
import { bindEvent } from "../../functions";
export default {
  name: "WithApi",
  props: {
    obj_id: {
      type: String,
    },
    obj_type: {
      type: String,
    },

    cached: {
      required: false,
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      loading: false,
      old_object: {},
    };
  },
  mounted() {
    if (this.obj_type && !this.cached) {
      this.fetch_data();
    }
    if (this.bus) {
      bindEvent(
        this,
        "valid",
        (valid) => {
          if (valid) {
            this.update_data();
          }
        },
        this.bus
      );
    }
    if (this.mode) {
      const event = `updated:${this.obj_type}:${this.obj_id}`;
      bindEvent(this, event, () => this.$forceUpdate());
    }
  },
  computed: {
    ...COMPUTED_MIX,
  },
  methods: {
    ...METHODS_MIX,
    mount_loaded() {
      //get(this.$store)(this.obj_type, this.obj);
    },
    restore_obj() {
      // восстановление данных в зависимости от режима
      if (this.mode != "new") {
        let obj =
          this.$store.getters[`${this.obj_type}/object`](this.obj_id) || {}; // получим закэшированные данные
        this.load_obj(obj); // обновим данные объекта
      }
      return this.fetch_data();
    },
    build_obj(data) {
      // допишем тип и ключ в объект для работы некторых функций
      let new_data = {};
      return Object.assign(new_data, data);
    },
    load_obj(obj) {
      // запись объекта
      this.object = this.build_obj(obj);
      this.old_object = this.build_obj(obj);
    },
    fetch_data() {
      // загрузка данных их API
      this.loading = true; // мы обновляемся
      if (this.mode == "new") return default_promise;
      if (this.mode != "new" || !this.cached) {
        const { obj_type, obj_id } = this;
        if (obj_id == "new") return default_promise;
        return this.$store.dispatch(`${obj_type}/get`, obj_id);
      } else {
        this.loading = false;
      }
      return default_promise;
    },
    load_data() {
      // загрузка
      return this.fetch_data();
    },
    build_update_data() {
      let ret = {};
      this.description;
      if (this.mode === "new" && this.description.add_new_data) {
        ret = Object.assign({}, this.description.add_new_data(), ret);
      }
      const key = this.description.obj_sendkey || this.description.obj_key;
      if (this.mode === "edit") {
        ret[key] = this.obj_id;
      }

      let aval_fields = Object.keys(this.description.fields);
      for (const key of aval_fields) {
        if (
          this.edited_obj[key] !== " " &&
          this.edited_obj[key] !== undefined &&
          this.edited_obj[key] !== this.value[key]
        ) {
          ret[key] = this.edited_obj[key];
        }
      }
      return ret;
    },
    update_data() {
      if (this.mode === "new" || this.mode === "edit") {
        let data_to_send = this.build_update_data();
        const methods = {
          new: `${this.obj_type}/add`,
          edit: `${this.obj_type}/update`,
        };
        const method = methods[this.mode];
        this.$store.dispatch(method, data_to_send).then((r) => {
          this.$emit("request", r);
          //this.eventBus.$emit("request", r);
        });
        // method(this.$store)(
        //   this.object.obj_type || this.description.obj_type,
        //   this.object.obj_key,
        //   data_to_send
        // );
      }
    },
    process_update(response) {
      // обработка обновления/создания
      const data = response.payload;
      this.load_obj(data);
      this.$store.commit("add_message", { type: "success", message: "Успех!" });
    },
    process_error() {}, // обработа ошибки при загрузке
    reset() {},
  },
};
</script>