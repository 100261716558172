<template>
  <div class="spec-type">
    <p>{{ type_str }}</p>
    <span class="text-muted">{{ additional_info }}</span>
  </div>
</template>

<script>
import CellVue from "../../mixins/Cell.vue";
export default {
  name: "typeCell",
  mixins: [CellVue],
  computed: {
    type_str() {
      let ret = "";
      const rules = {
        "Рамочный договор": /^договор/i,
        "Спецификация на сервис": /^спецификация/i,
      };
      const name = this.item.name;
      for (const [n, reg] of Object.entries(rules)) {
        if (reg.test(name)) {
          ret = n;
          break;
        }
      }
      if (ret === "") {
        ret = "Неизвестный тип";
      }
      return ret;
    },
    additional_info() {
      let ret = [];
      for (const { name } of this.item.services) {
        const n = name.split(" (")[0];
        ret.push(n);
      }
      return ret.join(", ");
    },
  },
};
</script>

<style lang='scss'>
.spec-type {
  p {
    margin-bottom: 0.5em;
  }
}
</style>