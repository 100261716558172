import { id_field_desc, resolveWithPromise, type_val } from "@/functions.js"
import { buildRelatedField } from "../fields"

const CONTRACT_DESC = {
  verbose: 'Контракты',
  base_name: 'contract',
  obj_key: 'contract_id',
  obj_name: 'number',
  unique_for: 'customer',
  urls: {
    list: 'customer/info'
  },
  storage: {
    actions: {
      list({ getters, commit, dispatch }) {
        const list_api = getters.has_api.list
        commit('load_status', 'loading')

        if (!list_api || Date.now() - getters.last_update < 5000) {
          commit('load_status', 'success')
          return resolveWithPromise(getters.objects)
        } else if (type_val(list_api) == 'String') {
          return dispatch('requestApi', { url: list_api, data: getters.pre_data({}, 'list') }, { root: true }).then(
            d => {
              if (d.data.result == 'success') {
                commit('load_status', 'success')
                commit('load', d.data.payload.contracts.map(r => getters.post_data(r, 'list')))
                return d.data.payload.contracts
              }
              commit('load_status', 'error')
            }
          )
        }
      },
      finances({ commit, dispatch, rootGetters }) {
        if (rootGetters.current_contract) {
          return dispatch("requestApi", {
            url: `contract/finance`,
            data: {
              contract_id: rootGetters.current_contract,
              customer_id: rootGetters.current_customer,
            },

          }, { root: true })
            .then((resp) => {
              commit("finances", resp.data.result == 'success' ? resp.data.payload.finance : {}, { root: true });

            });
        }
      },
    }
  },
  fields: {
    contract_id: id_field_desc,
    customer_id: buildRelatedField('Договор', 'customer'),
    date: 'Дата',
    number: 'Номер'
  }
}
export default CONTRACT_DESC