<template>
  <div>
    <b-modal
      v-model="show_modal"
      title="Запрос по услуге"
      ok-title="отправить"
      cancel-variant="outline-obit"
      footer-class="manager-modal-footer"
      lazy
      @ok="send_message"
    >
      <obj-form
        v-model="feedback_data"
        :description="feedback_descr"
        raw
        mode="edit"
      />
    </b-modal>
    <b-modal
      v-model="info_modal"
      ok-title="Продолжить"
      ok-only
      :title="title_info_modal"
    >
      {{ text_info_modal }}
    </b-modal>
  </div>
</template>

<script>
// import { buildRelatedField } from "../objects/fields";
import { buildOptionsField } from "../objects/fields";
import { BFormSelect, BFormTextarea } from "bootstrap-vue";
import { ObjForm } from "../.";
import { cloneDeep } from "lodash-es";

const names_fields = {
  name: {
    label: "Имя *",
    key: "employee",
    validator: (value) => (value == "" ? ["Обязательный параметр"] : null),
  },
  email: {
    label: "Электронная почта *",
    required: true,
    validator: (value) => (value == "" ? ["Обязательный параметр"] : null),
  },
  phone: {
    label: "Номер телефона *",
    required: true,
    validator: (value) => (value == "" ? ["Обязательный параметр"] : null),
  },
};
const def_fields = {
  subject: { label: "Тема сообщения", required: true, editable: false },
  description: {
    label: "Сообщение *",
    comp: BFormTextarea,
    required: true,
    options: {
      rows: 4,
    },
  },
};

const feedback_descr = {
  _message: "Заявка отправлена. ",
  fields: {
    ...def_fields,
    ...names_fields,
  },
};

const crm_task_fields = {
  title: { label: "Тема сообщения", required: true, editable: false },
  comments: {
    label: "Сообщение *",
    comp: BFormTextarea,
    required: true,
    options: {
      rows: 4,
    },
  },
};

const variants = {
  crm_task: {
    fields: crm_task_fields,
    _message:
      "Заявка отправлена. Для обсуждения параметров интеграции менеджер свяжется в течение 24 часов",
  },
  feedback: feedback_descr,
  storage_task: {
    fields: {
      subject: { label: "Тема сообщения", required: true, editable: false },
      depth: {
        label: "Период хранения",
        comp: BFormSelect,
        options: {
          options: [
            { value: 30, text: "30 дней" },
            { value: 60, text: "60 дней" },
            { value: 180, text: "180 дней" },
          ],
        },
      },
      comments: {
        label: "Сообщение *",
        comp: BFormTextarea,
        required: true,
        options: {
          rows: 4,
        },
      },
      ...names_fields,
    },
  },
};

const subject_options = [
  { value: "building", text: "Подключение нового ТЦ/БЦ/ЖД" },
  { value: "dc", text: "Услуги Датацентра" },
  { value: "energy", text: "Энергоснабжение бесперебойное (ЭНКОМ)" },
  { value: "engineer", text: "Инженерные и кабельные работы" },
  { value: "internet", text: "Интернет" },
  { value: "it-outsource", text: "ИТ-АУТСОРСИНГ" },
  { value: "network", text: "Корпоративные сети, КПД" },
  { value: "equipment", text: "Поставка и настройка оборудования" },
  {
    value: "rasco",
    text: "Услуги для застройщиков (телефонизация, радиофикация, РАСЦО)",
  },
  {
    value: "vats",
    text: "Подключить ВАТС",
  },
  {
    value: "newnumber",
    text: "Купить новый номер",
  },
  {
    value: "storage",
    text: "Запись и хранение разноворов",
  },
  { value: "tv", text: "Цифровое ТВ" },
  { value: "vats", text: "vats" },
  { value: "video-control", text: "Видеонаблюдение" },
  { value: "vps", text: "Виртуальные серверы" },
  { value: "wifi", text: "Публичная Wi-Fi-сеть" },
  { value: "vps1", text: "1С облако" },
  { value: "vps2", text: "Отраслевые решения" },
];
const default_data = {
  description: "",
  subject: "Другое",
  name: "",
  email: "",
  phone: "",
};
const ERROR_SEND_MESSAGE =
  "Ваш запрос не отправлен. Попробуйте оформить запрос еще раз или обратитесь к вашему менеджеру по телефону +7 (812) 622 00 00";
const SUCCESS_SEND_MESSAGE =
  "Ваш запрос принят. Менеджер свяжется с вами в ближайшее время";
export default {
  name: "ManagerModal",
  components: { ObjForm },
  inject: ["page_context"],

  data() {
    return {
      show_modal: false,
      contract_deal: false,
      info_modal: false,
      text_info_modal: "",
      title_info_modal: "",
      subject: null,
      feedback_data: cloneDeep(default_data),
    };
  },
  computed: {
    feedback_descr() {
      // определение что показать
      // if (!this.subject) {
      //   return this._feedback_descr_manager();
      // } else {
      //   if (this.subject == "CRM") {
      //     return this._feedback_descr_taskCrm();
      //   } else {
      //     return this._feedback_descr_task();
      //   }
      // }
      let variant = this.variant();
      let method = `_feedback_descr_${variant}`;
      return this[method]();
    },
  },
  methods: {
    calc_title(name, def = null) {
      const t = def ?? name;
      return subject_options.find((o) => o.value == name)?.text || t;
    },
    _feedback_descr_default() {
      // стандартная форма
      return cloneDeep(variants.feedback);
    },
    _feedback_descr_manager() {
      // эта форма "связаться с мегеджером"
      let ret = cloneDeep(variants.feedback);
      ret.fields.subject = buildOptionsField("Тема сообщения", [
        { value: "Финансы", text: "Финансы" },
        { value: "Услуги", text: "Услуги" },
        { value: "Настройки", text: "Настройки" },
        { value: "Другое", text: "Другое" },
      ]);
      return ret;
    },
    _feedback_descr_taskCrm() {
      // заказать интеграцию с цри
      let ret = cloneDeep(variants.crm_task);
      return ret;
    },
    _feedback_descr_dealVats() {
      let ret = this._feedback_descr_task();
      ret.fields = Object.assign({}, crm_task_fields, names_fields);
      ret.fields.title = buildOptionsField("Тема", subject_options, {
        editable: false,
      });
      return ret;
    },
    _feedback_descr_task() {
      // из лендингов
      let ret = this._feedback_descr_default();
      ret.fields.subject = buildOptionsField(
        "Тема сообщения",
        subject_options,
        { editable: false }
      );
      return ret;
    },
    _feedback_descr_taskRecord() {
      // из лендингов
      return this._feedback_descr_dealVats();
    },
    _feedback_descr_integration() {
      let ret = this._feedback_descr_dealVats();
      delete ret.fields.title.comp;
      return ret;
    },
    reset_feedback(variant = null) {
      // сброс значений в форме
      this.feedback_data = cloneDeep(default_data);
      switch (variant) {
        case "task":
        case "manager":
        case "taskRecord":
        case "integration":
        case "dealVats":
          if (variant == "taskRecord") {
            this.feedback_data.title = "storage";
            this.feedback_data.subject = "Запись и хранение разговоров";
            this.feedback_data.description = "";
            this.feedback_data.depth = 30;
          }
          if (variant == "dealVats") {
            this.feedback_data.subject = "newnumber";
            this.feedback_data.title = "newnumber";
          }
          if (variant == "integration") {
            this.feedback_data.title = "Интеграция с другими CRM";
            this.feedback_data.subject = this.feedback_data.title;
          }
          this.$store
            .dispatch("requestApi", {
              url: "employee/info",
              data: this.$store.getters.current("customer"),
            })
            .then((resp) => {
              this.feedback_data.name = resp.data.payload.title;
              this.feedback_data.phone = resp.data.payload.mobile;
              this.feedback_data.email = String(resp.data.payload.email);
            });
          break;
      }
    },
    variant() {
      // расчет варианта
      let ret = "default";
      // доступные варианты manager, task, taskCrm, default, taskRecord
      if (this.subject == "CRM") {
        ret = "taskCrm";
      } else if (this.subject == "call_record") {
        ret = "taskRecord";
      } else if (this.subject == "vats") {
        ret = "task";
      } else if (this.subject == "crm_integration") {
        ret = "integration";
      } else if (this.subject == "newnumber") {
        ret = "dealVats";
      } else {
        if (!this.subject) {
          ret = "manager";
        } else {
          ret = "task";
        }
      }
      return ret;
    },
    show(subject) {
      // показ формы
      this.subject = subject;
      const variant = this.variant();
      this.reset_feedback(variant);
      this.contract_deal = false;
      switch (variant) {
        case "taskCrm":
          this.feedback_data = {
            title: "Интеграция с другими CRM",
            comments: "",
          };
          break;
        case "task":
          this.feedback_data.subject = subject;
          break;
      }
      // if (subject == "CRM") {
      //   this.feedback_data = { title: "Интеграция с CRM", comments: "" };
      //   this.text_info_modal =
      //     "Заявка отправлена. Для обсуждения параметров интеграции менеджер свяжется в течение 24 часов";
      // } else {
      //   if (!subject) {
      //     this.feedback_data.description = "";
      //   } else {
      //     this.contract_deal = true;
      //     this.feedback_data.subject = subject;
      //   }

      //   this.$store
      //     .dispatch("requestApi", {
      //       url: "employee/info",
      //       data: this.$store.getters.current("customer"),
      //     })
      //     .then((resp) => {
      //       this.feedback_data.name = resp.data.payload.title;
      //       this.feedback_data.phone = resp.data.payload.mobile;
      //       this.feedback_data.email = String(resp.data.payload.email);
      //     });
      // }
      this.show_modal = true;

      return;
    },
    _send_default() {
      this.$store.dispatch("CONTRACT/ask_manager", this.feedback_data).then(
        () => {
          //console.log("_send_task", resp);
          this.show_info_modal(SUCCESS_SEND_MESSAGE);
        },
        () => {
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    _send_dealVats() {
      this.feedback_data.comments = `Подобрать номер, проконсультировать по стоимости включения. ${this.feedback_data.comments}`;
      this.feedback_data.title = this.calc_title(this.feedback_data.title);
      this.feedback_data.type = "default";
      this.$store.dispatch("CRM/deal", this.feedback_data).then(
        (resp) => {
          if (resp) {
            this.show_info_modal(SUCCESS_SEND_MESSAGE);
          } else {
            this.$store.commit("add_message", {
              message: ERROR_SEND_MESSAGE,
              type: "danger",
            });
          }
        },
        () => {
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    _send_taskRecord() {
      this.feedback_data.comments = `Запрос на подкдючение услуги "запись и хранение разговоров - ${this.feedback_data.depth}". ${this.feedback_data.comments}`;
      this.feedback_data.title = this.calc_title(this.feedback_data.title);
      this.$store.dispatch("CRM/deal", this.feedback_data).then(
        (resp) => {
          if (resp) {
            this.show_info_modal(SUCCESS_SEND_MESSAGE);
          } else {
            this.$store.commit("add_message", {
              message: ERROR_SEND_MESSAGE,
              type: "danger",
            });
          }
        },
        (reason) => {
          console.log("_send_task error", reason);
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    _send_integration() {
      let data = cloneDeep(this.feedback_data);
      // обновим тему
      const subj =
        subject_options.find((opt) => opt.value == data.subject)?.text ||
        data.subject;
      data.variant = data.subject;
      data.subject = subj;
      this.$store.dispatch("CRM/crm_integration", data).then(
        (resp) => {
          if (resp) {
            this.show_info_modal(SUCCESS_SEND_MESSAGE);
          } else {
            this.$store.commit("add_message", {
              message: ERROR_SEND_MESSAGE,
              type: "danger",
            });
          }
        },
        (reason) => {
          console.log("_send_task error", reason);
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    _send_task() {
      let data = cloneDeep(this.feedback_data);
      // обновим тему
      const subj =
        subject_options.find((opt) => opt.value == data.subject)?.text ||
        data.subject;
      data.variant = data.subject;
      data.subject = subj;
      this.$store.dispatch("CONTRACT/deal", data).then(
        (resp) => {
          if (resp) {
            this.show_info_modal(SUCCESS_SEND_MESSAGE);
          } else {
            this.$store.commit("add_message", {
              message: ERROR_SEND_MESSAGE,
              type: "danger",
            });
          }
        },
        (reason) => {
          console.log("_send_task error", reason);
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    _send_taskCrm() {
      let data = cloneDeep(this.feedback_data);
      data.variant = "";
      this.$store.dispatch("CRM/deal", data).then(
        (resp) => {
          if (resp) {
            this.show_info_modal(SUCCESS_SEND_MESSAGE);
          } else {
            this.$store.commit("add_message", {
              message: ERROR_SEND_MESSAGE,
              type: "danger",
            });
          }
        },
        (reason) => {
          console.log("_send_task error", reason);
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    show_info_modal(message, title = null) {
      this.info_modal = true;
      this.text_info_modal = message;
      this.title_info_modal = title;
    },
    _send_manager() {
      this.$store.dispatch("CONTRACT/ask_manager", this.feedback_data).then(
        () => {
          //console.log("_sendManager", resp);
          this.show_info_modal(SUCCESS_SEND_MESSAGE);
        },
        (error) => {
          console.error("_sendManager error", error);
          this.$store.commit("add_message", {
            message: ERROR_SEND_MESSAGE,
            type: "danger",
          });
        }
      );
    },
    send_message() {
      const variant = this.variant();
      //console.log("use", `_send_${variant}`, this.feedback_data);
      this[`_send_${variant}`]();
    },
  },
};
</script>
