<template>
  <div class="objects-list">
    <b-row class="header">
      <b-col cols="4" md="3" xl="2">
        <span class="title">Статистика</span>
      </b-col>
      <b-col cols="8" md="9" xl="10">
        <span class="update-date"
          >Данные актуальны на: {{ dt | date_text_format }}</span
        >
        <b-button class="refresh-button" variant="link" @click="fetch_line_data"
          ><obit-icon icon="refresh" style="width: 1em"
        /></b-button>
      </b-col>
    </b-row>
    <b-row class="topic"
      ><span><obit-icon icon="system" /> Система</span></b-row
    >
    <b-row class="items">
      <div class="container-fluid">
        <scrollable>
          <div class="row flex-row flex-nowrap teledash-test-style">
            <b-col>
              <div class="dashcard top-card first-tc" style="margin-top: 0px">
                <div class="card-line stuff">
                  <b-link to="/sip/city_number">Внешние номера</b-link>
                </div>
                <div class="card-block">
                  <div class="lines-stats">
                    {{ external_lines.length }}
                  </div>
                </div>
                <div class="card-block">
                  <div class="card-line" v-if="external_lines.length == 0">
                    Нет номеров
                  </div>
                  <div
                    class="card-line stuff"
                    v-else-if="external_lines.length < 7"
                    v-for="number in external_lines"
                    v-bind:key="number.obj_id"
                  >
                    <b-link :to="`/sip/city_number/${number.obj_id}`">{{
                      number.obj_name | phone
                    }}</b-link>
                  </div>
                  <div
                    class="card-line stuff"
                    v-else
                    v-for="number in external_lines.slice(0, 4)"
                    v-bind:key="number.obj_id"
                  >
                    <b-link :to="`/sip/city_number/${number.obj_id}`">{{
                      number.obj_name | phone
                    }}</b-link>
                  </div>
                  <span v-if="external_lines.length > 6"
                    >еще {{ external_lines.length - 4 }} внешних номеров</span
                  >
                </div>
                <!-- <div class="card-block" v-if="additional_extlines > 0">
                  <div class="card-line stuff">
                    Ещё номеров: {{ additional_extlines }}
                  </div>
                </div> -->
              </div>
            </b-col>
            <b-col>
              <div
                class="dashcard top-card"
                style="margin-top: 0px; margin-left: 0px"
              >
                <div class="card-line stuff">
                  <b-link to="/sip/internal_line2">Внутренние номера</b-link>
                </div>
                <div class="card-block">
                  <div class="lines-stats">
                    {{ internal_lines.length }}
                  </div>
                </div>
                <div class="card-block">
                  <div class="card-line" v-if="internal_lines.length == 0">
                    Нет номеров
                  </div>
                  <div
                    class="card-line stuff"
                    v-else-if="internal_lines.length < 7"
                    v-for="number in internal_lines"
                    v-bind:key="number.obj_id"
                  >
                    <b-link :to="`/sip/internal_line2/${number.obj_id}`"
                      >{{ number.obj_name }}
                    </b-link>
                  </div>
                  <div
                    class="card-line stuff"
                    v-else
                    v-for="number in internal_lines.slice(0, 4)"
                    v-bind:key="number.obj_id"
                  >
                    <b-link :to="`/sip/internal_line2/${number.obj_id}`"
                      >{{ number.obj_name }}
                    </b-link>
                  </div>
                  <div v-if="internal_lines.length > 6" class="card-line">
                    <span
                      >еще {{ internal_lines.length - 4 }} внутренних
                      номеров</span
                    >
                  </div>
                </div>
                <!-- <div class="card-block">
                  <b-row class="card-line">
                    <b-col>Активных:</b-col>
                    <b-col
                      ><span class="active">{{
                        internal_lines.active
                      }}</span></b-col
                    >
                  </b-row>
                  <b-row class="card-line">
                    <b-col>На переадресации:</b-col>
                    <b-col
                      ><span>{{ internal_lines.transfer }}</span></b-col
                    >
                  </b-row>
                  <b-row class="card-line">
                    <b-col>Неактивных:</b-col>
                    <b-col
                      ><span class="inactive">{{
                        internal_lines.inactive
                      }}</span></b-col
                    >
                  </b-row>
                </div> -->
              </div>
            </b-col>
            <b-col>
              <div
                class="dashcard top-card"
                style="margin-top: 0px; margin-left: 0px"
              >
                <div class="card-line stuff">
                  <b-link to="/sip/pbx_group">Группы</b-link>
                </div>
                <div class="card-block">
                  <div class="lines-stats">
                    {{ groups.length }}
                  </div>
                </div>
                <div class="card-block" v-if="groups.length > 4">
                  <div
                    class="card-line"
                    v-for="group in groups.slice(0, 4)"
                    v-bind:key="group.obj_id"
                  >
                    <b-link :to="`/sip/pbx_group/${group.obj_id}`">
                      {{ group.number }} - {{ group.caption }}
                    </b-link>
                  </div>
                  <div class="card-line">
                    <span
                      >еще {{ groups.length - 4 }}
                      {{ (groups.length - 4) | group_padej }}</span
                    >
                  </div>
                </div>
                <div class="card-block" v-else>
                  <div
                    class="card-line"
                    v-for="group in groups"
                    v-bind:key="group.obj_id"
                  >
                    <b-link :to="`/sip/pbx_group/${group.obj_id}`">
                      {{ group.number }} - {{ group.caption }}
                    </b-link>
                  </div>
                </div>
              </div>
            </b-col>
          </div>
        </scrollable>
      </div>
    </b-row>
    <b-row class="topic"
      ><span><obit-icon icon="chart_bar" /> Статистика звонков</span></b-row
    >
    <b-row class="items">
      <b-col>
        <dash-filter ref="filter" @apply_filter="apply_filter" />
      </b-col>
    </b-row>
    <spinner v-if="loading" />
    <b-row v-else>
      <b-col cols="12" xl="7">
        <scrollable>
          <div class="dashcard graphic">
            <div class="card-line">
              Количество исходящих звонков
              <span class="sorcery">{{ out_calls_graph_data.number }}</span>
            </div>
            <chart
              :chartData="chart_data"
              :options="chart_opts"
              :height="200"
              :width="600"
              ref="calls_out"
            />
          </div>
          <div class="dashcard graphic">
            <div class="card-line">
              Количество входящих звонков
              <span class="sorcery">{{ in_calls_graph_data.number }}</span>
            </div>
            <chart
              :chartData="chart_data_in"
              :options="chart_opts"
              :height="200"
              :width="600"
              ref="calls_in"
            />
          </div>
        </scrollable>
      </b-col>
      <b-col cols="12" xl="5">
        <div class="container-fluid" style="padding-left: 0px">
          <scrollable>
            <div class="row flex-row flex-nowrap flex-xl-wrap">
              <div class="dashcard col-4 col-xl-12 small-card first-sm">
                <div class="card-line">Средняя длительность ответа</div>
                <div class="card-block small-block">
                  <div class="lines-stats small-stats">
                    {{ response_length.avg | time_string }}
                  </div>
                </div>
                <div class="card-block small-block">
                  <b-row class="card-line">
                    <b-col>Максимум</b-col>
                    <b-col
                      ><span style="color: #54bae7">{{
                        response_length.max | time_string
                      }}</span></b-col
                    >
                  </b-row>
                  <b-row class="card-line">
                    <b-col>Минимум</b-col>
                    <b-col
                      ><span style="color: #54bae7">{{
                        response_length.min | time_string
                      }}</span></b-col
                    >
                  </b-row>
                </div>
              </div>
              <div
                class="dashcard col-4 col-xl-12 small-card bad"
                style="margin-left: 30px !important"
              >
                <div class="card-line">Непринятых вызовов</div>
                <div class="card-block small-block">
                  <div class="lines-stats small-stats">
                    {{ lost_calls.sum }}
                  </div>
                </div>
                <div class="card-block small-block">
                  <b-row class="card-line">
                    <b-col>Неотвеченных</b-col>
                    <b-col
                      ><span>{{ lost_calls.ignored }}</span></b-col
                    >
                  </b-row>
                  <b-row class="card-line">
                    <b-col>Сброшено</b-col>
                    <b-col
                      ><span>{{ lost_calls.dropped }}</span></b-col
                    >
                  </b-row>
                </div>
              </div>
              <div
                class="dashcard col-4 col-xl-12 small-card"
                style="margin-left: 30px !important"
              >
                <div class="card-line">Средняя длительность разговора</div>
                <div class="card-block small-block">
                  <div class="lines-stats small-stats">
                    {{ talk_length.avg | time_string }}
                  </div>
                </div>
                <div class="card-block small-block">
                  <b-row class="card-line">
                    <b-col>Максимум</b-col>
                    <b-col
                      ><span style="color: #54bae7">{{
                        talk_length.max | time_string
                      }}</span></b-col
                    >
                  </b-row>
                  <b-row class="card-line">
                    <b-col>Минимум</b-col>
                    <b-col
                      ><span style="color: #54bae7">{{
                        talk_length.min | time_string
                      }}</span></b-col
                    >
                  </b-row>
                </div>
              </div>
            </div>
          </scrollable>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Chart from "./Chart";
import DashFilter from "./DashFilter";
import Scrollable from "simplebar-vue";
import ObitIcon from "../components/obit/ObitIcon.vue";
import Spinner from "../components/obit/Spinner.vue";
import { merge } from "lodash-es";
import {
  datetimeformatter,
  number_formatter,
  time_string,
} from "../formatters";

export default {
  name: "TeleDash",
  components: {
    Chart,
    DashFilter,
    Scrollable,
    ObitIcon,
    Spinner,
  },
  mounted() {
    this.get_data();
    this.fetch_line_data();
  },
  filters: {
    date_text_format: datetimeformatter,
    phone: number_formatter,
    group_padej: (count) => {
      let c = Number(String(count).slice(-1));
      if (c == 1) {
        return "группа";
      }
      if (c >= 2 && c <= 4) {
        return "группы";
      }
      return "групп";
    },

    time_string,
  },
  data: () => ({
    dt: null,
    loading: false,
    filter_data: {},
    lost_calls: {
      sum: 0,
      ignored: 0,
      dropped: 0,
    },
    talk_length: {
      avg: 0,
      min: 0,
      max: 0,
    },
    response_length: {
      avg: 0,
      min: 0,
      max: 0,
    },
    out_calls_graph_data: {
      number: 0,
      calls: [],
    },
    in_calls_graph_data: {
      number: 0,
      calls: [],
    },
  }),
  computed: {
    external_lines() {
      return this.$store.getters["city_number/objects"];
      // return this.externals.slice(0, 2);
    },
    additional_extlines() {
      return this.$store.getters["city_number/objects"].length - 3;
      // return this.externals.length - 3;
    },
    internal_lines() {
      return this.$store.getters["internal_line2/objects"];
    },
    chart_opts() {
      return {
        responsive: true,
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0,
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },
    chart_style() {
      return {
        width: "900px",
        height: "140px",
        position: "relative",
      };
    },
    groups() {
      // const all = this.$store.getters["pbx_group/objects"];
      // let ret = {
      //   // all: all.length,
      //   // active: all.length,
      //   // inactive: 0,
      //   all:all,
      // };
      // return ret;
      return this.$store.getters["pbx_group/objects"];
    },
    chart_data() {
      var ret = {
        labels: [],
        datasets: [
          {
            label: "Звонок",
            backgroundColor: "#fdb600",
            startGradient: "rgba(253, 182, 0, 0.3)",
            endGradient: "rgba(253, 99, 0, 0.3)",
            borderColor: "#FD6300",
            borderWidth: { top: 3, right: 0, bottom: 0, left: 0 },
            data: [],
          },
        ],
      };
      for (var call of this.out_calls_graph_data.calls) {
        var call_date = "";
        if (
          this.filter_data.period == "today" ||
          this.filter_data.period == "days"
        ) {
          call_date = call.date.split(" ")[1].split(":");
          ret.labels.push(call_date[0] + ":" + call_date[1]);
        } else {
          call_date = call.date.split(" ")[0].split("-");
          ret.labels.push(call_date[2] + "/" + call_date[1]);
        }
        ret.datasets[0].data.push(call.length);
      }

      return ret;
    },
    chart_data_in() {
      var ret = {
        labels: [],
        datasets: [
          {
            label: "Звонок",
            backgroundColor: "lightblue",
            startGradient: "rgba(128, 255, 255, 0.3)",
            endGradient: "rgba(84, 186, 231, 0.3)",
            borderColor: "#54BAE7",
            borderWidth: { top: 3, right: 0, bottom: 0, left: 0 },
            data: [],
          },
        ],
      };
      for (var call of this.in_calls_graph_data.calls) {
        var call_date = "";
        if (
          this.filter_data.period == "today" ||
          this.filter_data.period == "days"
        ) {
          call_date = call.date.split(" ")[1].split(":");
          ret.labels.push(call_date[0] + ":" + call_date[1]);
        } else {
          call_date = call.date.split(" ")[0].split("-");
          ret.labels.push(call_date[2] + "/" + call_date[1]);
        }
        ret.datasets[0].data.push(call.length);
      }
      return ret;
    },
  },
  methods: {
    fetch_line_data() {
      Promise.all([
        this.$store.dispatch("internal_line2/list"),
        this.$store.dispatch("city_number/list"),
        this.$store.dispatch("pbx_group/list"),
        process.env.VUE_APP_IS_EMPLOYEE !== `false` ? this.$store.dispatch("employee/list") : true,
      ]);
      this.dt = Date.now();
    },
    apply_filter(new_filter) {
      this.filter_data = new_filter;
      this.get_data();
    },
    build_filter(type) {
      let ret = merge({}, this.filter_data);
      if (type == "calls/incoming") {
        if (ret.period == "today" || ret.period == "days") {
          ret.period = "hours";
        } else {
          ret.period = "days";
        }
        ret.direction = "incoming";
      } else if (type == "calls/outgoing") {
        if (ret.period == "today" || ret.period == "days") {
          ret.period = "hours";
        } else {
          ret.period = "days";
        }
        ret.direction = "outgoing";
      }
      return ret;
    },
    get_data() {
      const dispatch = this.$store.dispatch;
      // звонки
      this.loading = true;
      Promise.all([
        dispatch("call/get_home_statistics", {
          type: "duration",
          data: this.build_filter("duration"),
        }).then((ret) => {
          this.talk_length = ret.data.payload;
        }),
        dispatch("call/get_home_statistics", {
          type: "response",
          data: this.build_filter("response"),
        }).then((ret) => {
          this.response_length = ret.data.payload;
        }),
        dispatch("call/get_home_statistics", {
          type: "missed",
          data: this.build_filter("missed"),
        }).then((ret) => {
          this.lost_calls.sum = ret.data.payload.total;
          this.lost_calls.ignored = ret.data.payload.missed;
          this.lost_calls.dropped = ret.data.payload.cancelled;
        }),
        dispatch("call/get_home_statistics", {
          type: "calls",
          data: this.build_filter("calls/incoming"),
        }).then((ret) => {
          this.in_calls_graph_data.number = 0;
          this.in_calls_graph_data.calls = [];
          for (const [d, v] of Object.entries(ret.data.payload)) {
            this.in_calls_graph_data.number += v;
            this.in_calls_graph_data.calls.push({
              date: d,
              length: v,
            });
          }
        }),
        dispatch("call/get_home_statistics", {
          type: "calls",
          data: merge(
            { direction: "outgoing" },
            this.build_filter("calls/outgoing")
          ),
        }).then((ret) => {
          this.out_calls_graph_data.number = 0;
          this.out_calls_graph_data.calls = [];
          for (const [d, v] of Object.entries(ret.data.payload)) {
            this.out_calls_graph_data.number += v;
            this.out_calls_graph_data.calls.push({
              date: d,
              length: v,
            });
          }
        }),
      ]).then(() => (this.loading = false));
    },
  },
};
</script>

<style lang='scss'>
div.dashcard {
  border-radius: 6px;
  background-image: linear-gradient(#f4f4f4, #f2f9fc);
  margin: 30px;
  margin-right: 0px;
  margin-top: 30px;
  padding: 20px;
  min-height: 207px;
  .card-line {
    margin-top: 8px;
  }
  .lines-stats {
    font-size: xxx-large;
  }
  .card-block {
    margin-top: 28px;
  }
  .active {
    color: #81de48;
  }
  .inactive {
    color: #fd6300;
  }
  .stuff:not(.no-link) {
    color: #54bae7;
  }
  .sorcery {
    font-size: x-large;
    margin-left: 20px;
  }
}
div.top-card {
  max-width: 359px;
  min-height: 248px;
}
.small-card {
  min-height: 195px !important;
  margin-top: 30px !important;
  margin-bottom: 15px !important;
  max-width: 360px;
  max-height: 160px;
}
.small-block {
  margin-top: 30px !important;
}
.small-stats {
  font-size: xx-large !important;
}
.bad {
  background-image: linear-gradient(#f4f4f4, #ffd4d4) !important;
}
.topic {
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 20px !important;
  padding: 20px;
  padding-bottom: 0px;
}
.margform {
  margin-left: 15px;
}
.graphic {
  max-height: 273px;
}
@media (max-width: 1600px) {
  .graphic {
    width: 750px;
    position: relative;
    // height: 307px;
    // overflow-x: scroll;
  }
  .topic {
    margin-left: 10px !important;
  }
  div.dashcard {
    margin-left: 15px;
    padding: 15px;
    min-width: 240px;
    .small-card {
      min-width: 270px;
    }
  }
  .margform {
    margin-left: 0px;
  }
}
.obit-icon {
  height: 1.2em;
}
.update-date {
  color: #bfbfbf;
  text-transform: none !important;
  @media (max-width: 480px) {
    display: none;
  }
}

.refresh-button {
  position: absolute;
  top: -50%;
}
.first-sm {
  margin-left: 15px !important;
}

@media (max-width: 620px) {
  .refresh-button {
    top: -6%;
    right: -5%;
  }
}

@media (min-width: 1199px) {
  .first-sm {
    margin-left: 30px !important;
    margin-top: 30px !important;
  }
}

@media (min-width: 600px) and (max-width: 858px) {
  .refresh-button {
    top: -5%;
    right: -5%;
  }
}

@media (max-width: 800px) {
  .top-card {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-right: 30px !important;
  }
  .margform {
    margin-left: 20px;
  }
}

@media (min-width: 799px) and (max-width: 1200px) {
  .small-card {
    margin-left: 45px !important;
  }
}

@media (min-width: 1280px) {
  .simplebar-content-wrapper {
    overflow: hidden;
  }
  .first-tc {
    margin-left: 30px !important;
  }
}
.teledash-test-style {
  max-width: 1450px;
}
</style>
