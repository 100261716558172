<template>
  <div class="object-modal">
    <slot :show_modal="show_modal" v-if="!modalOnly">
      <b-button v-bind="buttonProps" @click="show_modal"
        ><slot name="btnContent">
          {{ mode === "new" ? "Создать" : "Редактировать" }}</slot
        ></b-button
      >
    </slot>
    <b-modal
      v-model="edit_modal"
      size="lg"
      @ok.prevent="save_method"
      @close="close"
      @cancel="close"
      no-close-on-backdrop
      v-bind="rModalProps"
    >
      <obj-form
        v-model="obj"
        :description="desc"
        :mode="mode"
        :eventBus="bus"
        :obj_id="obj_id"
        :obj_type="obj_type"
        cached
        :replace_fields="fields"
        ref="form"
        @request="catch_request"
      >
        <template v-if="!desc.hide_modal_header" v-slot:pre>
          <p>{{ obj.obj_name }}</p>
        </template>
        <slot name="form" />
      </obj-form>
      <template v-slot:modal-footer="m">
        <slot name="buttons" v-bind="{ m }">
          <b-button class="save-btn editing-save" variant="obit" @click="m.ok()"
            >Сохранить</b-button
          >
          <b-button
            class="delete-btn editing-cansel"
            variant="outline-obit"
            @click="m.cancel"
            >Отмена</b-button
          >
          <b-button
            variant="outline-obitless"
            v-if="use_delete"
            @click="delete_modal = true"
            class="editing-trash"
          >
            <obit-icon icon="trash" />
          </b-button>
        </slot>
      </template>
    </b-modal>
    <b-modal
      v-model="delete_modal"
      :title="deleteHeaderText(obj)"
      ok-variant="danger"
      ok-title="Удалить"
      @ok="delete_obj()"
      no-close-on-backdrop
    >
      <slot name="deleteModal" v-bind="{ obj }">
        Вы действительно хотите удалить {{ obj.obj_name }}
      </slot>
    </b-modal>
  </div>
</template>


<script>
import { as_icon } from "../layot";
import ObitIcon from "../obit/ObitIcon";
import { OBJ_COMPONENTS } from "../../constants";
import { bindEvent } from "../../functions";
import ObjForm from "../ObjForm";
import { faExclamationCircle, faSync } from "@fortawesome/free-solid-svg-icons";
import merge from "lodash/merge";

export default {
  name: "ObjModal",
  props: {
    obj_id: {
      required: true,
    },
    obj_type: {
      required: true,
    },
    mode: {
      required: false,
      type: String,
      default: "edit",
    },
    buttonProps: {
      required: false,
      type: Object,
      default: () => ({ variant: "outline-obit" }),
    },
    modalOnly: {
      required: false,
      type: Boolean,
      default: false,
    },
    modalProps: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    fields: {
      required: false,
      default: () => [],
      type: Array,
    },
    nextDelete: {
      required: false,
      type: String,
      default: null,
    },
    noDelete: {
      required: false,
      type: Boolean,
      default: false,
    },
    deleteHeaderText: {
      required: false,
      type: Function,
      default: (item) => `Удалить ${item._description?.verbose}`,
    },
  },
  mounted() {
    const obj_type = this.obj_type; // "added:employee:new"
    const desc = this.desc;
    const store = this.$store;
    bindEvent(this, "show", () => (this.edit_modal = true), this);
    bindEvent(this, `added:${obj_type}:${this.obj_id}`, (data) => {
      store.commit("add_message", { type: "success", message: "Создано!" });
      // store.commit('add_message', {type: 'info', message: data})
      this.$router.push({
        name: "object",
        params: { type: obj_type, id: data[desc.obj_listkey || desc.obj_key] },
      });
    });
    bindEvent(this, `updated:${obj_type}:${this.obj_id}`, () => {
      this.loading = false;
      this.savind = false;
    });
    bindEvent(this, `error:${obj_type}:${this.obj_id}`, (data) => {
      store.commit("add_message", { type: "danger", message: data });
    });
    bindEvent(
      this,
      "valid",
      (is_valid) => {
        if (is_valid) {
          this.edit_modal = false;
        }
      },
      this
    );
  },

  computed: {
    rModalProps() {
      return merge(
        {
          title: this.mode == "new" ? "Создать" : "Редактирование",
          "ok-title": this.mode == "new" ? "Создать" : "Сохранить",
        },
        this.modalProps
      );
    },
    desc() {
      return OBJ_COMPONENTS[this.obj_type] || {};
    },
    obj() {
      return this.$store.getters[`${this.obj_type}/object`](this.obj_id) || {};
    },
    bus() {
      return this;
    },
    save_msg() {
      return this.obj_id == "new" ? "Создать" : "Сохранить";
    },
    use_delete() {
      if (this.noDelete) {
        return false;
      }
      return this.mode !== "new" && !this.desc.no_trash_button;
    },
  },

  methods: {
    show_modal() {
      this.edit_modal = true;
    },
    event_to_emit(method) {
      return `${this.prefix}${method}`;
    },
    refresh() {
      this.loading = true;
      this.bus.$emit(this.event_to_emit("refresh"));
    },
    // save() {
    //   this.bus.$emit("save");
    // },
    save($event) {
      $event.preventDefault();

      if (this.$refs.form._save()) {
        this.edit_modal = false;
      }
    },
    save_method() {
      const def_save = () => this.$emit("save");
      const method = this.desc.save_method || def_save;
      method(this);
    },
    close() {
      this.bus.$emit("close");
    },
    delete_obj() {
      let allow_delete = true;
      let cause = "Невозможно удалить";
      if (this.desc.beforeDelete) {
        [allow_delete, cause] = this.desc.beforeDelete(
          this.obj_id,
          this.$store
        );
      }
      if (allow_delete) {
        const nextDelete = this.nextDelete ?? this.desc.nextDelete ?? null;
        this.$store
          .dispatch(`${this.obj_type}/delete`, this.obj_id)
          .then(() => {
            this.$emit("deleted");
            this.edit_modal = false;
            if (nextDelete) {
              // переход, если указано куда переходить
              this.$router.push(nextDelete);
            }
          });
      } else {
        if (cause) {
          this.$store.commit("add_message", {
            type: "danger",
            message: cause,
          });
        }
      }
    },
    catch_request(r) {
      this.bus.$emit("request", r);
    },
  },

  data() {
    return {
      loading: false,
      savind: false,
      has_error: false,
      icons: {
        sync: as_icon(faSync),
        error: as_icon(faExclamationCircle),
      },
      edit_modal: false,
      edit_data: {},
      delete_modal: false,
    };
  },

  components: {
    ObitIcon,
    ObjForm,
  },
};
</script>

<style lang="scss"  scoped>
.save-btn {
  order: 5;
}
.delete-btn {
  order: 4;
}
@media (max-width: 640px) {
  .editing-save {
    padding: 10px 18px;
  }
  .editing-cansel {
    padding: 10px 20px;
  }
  .editing-trash {
    padding: 0;
  }
}
</style>