<template>
  <div class="entity_status" v-if="show">
    <span class="status">{{ status }}:</span>
    <span class="detail">
      <span :class="summ_class"> {{ summ | money }} руб. </span><br />
      <span v-if="comment" :class="commment_class"> до {{ comment }}</span>
      <span v-else :class="commment_class"> {{ "&emsp;" }} </span>
    </span>
  </div>
</template>

<script>
import { ru } from "date-fns/locale";
import { isEqual } from "lodash-es";
import { money_format } from "../../formatters";
import { formatWithOptions } from "date-fns/esm/fp";

export default {
  name: "EntityStatus",
  data: () => ({
    commment_class: ["comment"],
  }),
  filters: {
    money: money_format,
  },
  computed: {
    finance() {
      return this.$store.getters.finances;
    },
    expire() {
      return this.finance.expire ? new Date(this.finance.expire) : 0;
    },
    show() {
      return this.$store.getters.current_contract && !isEqual(this.finance, {});
    },
    summ() {
      return Math.abs(this.finance.balance);
    },
    is_dept() {
      return this.finance.balance < 0;
    },
    is_past() {
      return this.expire < Date.now();
    },
    status() {
      let text = "К оплате";
      if (this.is_dept) {
        if (this.is_past) {
          text = "Задолженность";
        } else {
          text = "К оплате";
        }
      } else {
        if ((this.finance.balance || 0) > 0) {
          text = "Переплата";
        }
      }
      return text;
    },
    summ_class() {
      let classes = ["summ"];
      if (this.is_dept && this.is_past) {
        classes.push("text-danger");
      }
      return classes;
    },
    comment() {
      const ret = this.finance.expire;
      let formatted = ret
        ? formatWithOptions({ locale: ru }, "dd.MM.yyyy")(new Date(ret))
        : undefined;
      return formatted;
    },
  },
};
</script>

<style lang='scss'>
@import "../../../static/SCSS/obit-colors.scss";
.entity_status {
  & > * {
    display: inline-block;
    text-align: left;
    margin-right: 0.5em;
  }
  .status {
    .topbar.collapsed & {
      display: none;
    }
    font-weight: 600;
    vertical-align: top;
    font-size: 14px;
  }
  .detail {
    .summ {
      font-weight: 600;
      display: inline-block;
      margin-bottom: 0.25em;
      font-size: 14px;
    }
    .comment {
      display: inline-block;
      font-size: 12px;
      line-height: 14.4px;
      color: $obit-darkgray;
    }
  }
}
</style>